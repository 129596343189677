import { Button, FlexGrid, Typography, useThemeProvider } from '@albo-ui/core';
import { FaCheckCircle } from 'react-icons/fa';
import { useTranslator } from '../../providers/translator-provider';
import { inextUrl } from '../../environments/environment';

export const OpsAnalystPasswordChanged = () => {
  const { theme } = useThemeProvider();
  const {
    OPS_PASSWORD_UPDATED_SUCCESSSFULLY_TITLE,
    OPS_PASSWORD_UPDATED_SUCCESSSFULLY,
    BUTTON_LANDING_INEXT_REDIRECT,
  } = useTranslator();

  function onClick() {
    window.location.href = inextUrl;
  }

  return (
    <FlexGrid
      container
      fullWidth
      fullHeight
      direction='column'
      spacing={5}
      alignItems='center'
      justify='center'
      style={{ height: '100vh' }}
    >
      <FlexGrid item>
        <FaCheckCircle size='55px' color={theme.colors.vivere} />
      </FlexGrid>
      <FlexGrid item style={{ maxWidth: '600px', textAlign: 'center' }}>
        <Typography variant='h2' color='charcoal'>
          {OPS_PASSWORD_UPDATED_SUCCESSSFULLY_TITLE}
        </Typography>
        
      </FlexGrid>
      <FlexGrid item style={{ maxWidth: '600px', textAlign: 'center' }}>
        <Typography variant='h2' color='charcoal' style={{ fontWeight: 'normal'}}>
          {OPS_PASSWORD_UPDATED_SUCCESSSFULLY}
        </Typography>
      </FlexGrid>
      <FlexGrid item>
        <Button onClick={onClick} size='large'>
          {BUTTON_LANDING_INEXT_REDIRECT}
        </Button>
      </FlexGrid>
    </FlexGrid>
  );
};
