import { CompanyInfoAndAccountForm } from '../../../../../../../../components-quick-win/company-info-and-account-form';
import { FormContainer } from '../../../../../../../../components-quick-win/form-container';

export const CompanyInfoAndAccount = () => {
  return (
    <FormContainer>
      <CompanyInfoAndAccountForm />
    </FormContainer>
  );
};
