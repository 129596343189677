import { useCallback, useEffect, useMemo, useState } from 'react';
import { makeProvider } from 'react-provider-maker';
import { useHistory } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import useLocalStorage from '../../hooks/use-local-storage';
import useSessionStorage from '../../hooks/use-session-storage';
import { decodeParamsFromUrl } from './helper';
import { EXTERNAL_AUTH_DATA, StatusExternaAgentlLead } from './types';

type ExternalDataType = {
    agentUuid: string;
    leadUuid?: string;
} | undefined;

export const { Provider: ExternalAuthDataProvider, useProvider: useExternalAuthData } = makeProvider(() => {


    const [externalAuthData, setExternalAuthData] = useLocalStorage<ExternalDataType>(EXTERNAL_AUTH_DATA, undefined);
    const [externalAuthDataParams, setExternalAuthDataParams] = useSessionStorage<ExternalDataType>(EXTERNAL_AUTH_DATA, externalAuthData);
    const [isInvalid, setIsInvalid] = useState<StatusExternaAgentlLead>(null);
    const [temporalAuthParams, setTemporalAuthParams] = useState<any>(undefined);


    const queryExternalAuthData = useMemo(() => {

        if (temporalAuthParams && temporalAuthParams.leadUuid !== '') {
            return btoa(JSON.stringify(temporalAuthParams))
        }
        //Verifico el localStorge 
        if (externalAuthData && externalAuthData?.leadUuid !== '') {
            return btoa(JSON.stringify(externalAuthData))
        }
        //Si aun no se ha guardado en el localStorge verifico el sessionStorage
        if (externalAuthDataParams && externalAuthDataParams?.leadUuid !== '') {
            return btoa(JSON.stringify(externalAuthDataParams))
        }
        
        return undefined
    }, [externalAuthDataParams, externalAuthData, temporalAuthParams])

    const history = useHistory();

    const clearParamsFromUrl = useCallback(() => {
        history.push('/login');
    }, [history]);

    const verifyValidParams = useCallback((newParams): StatusExternaAgentlLead => {
        const authJwt = localStorage.getItem('auth_jwt')
        if (authJwt) {
            const data = jwt.decode(JSON.parse(authJwt) || '') as any;
            const { user } = data;
            if (user.referenceUuid !== newParams.agentUuid) {
                return 'ERROR'
            }
            if (user.leadUuid !== "" && user.leadUuid !== newParams.leadUuid) {
                return 'WARNING'
            }
            if (user.leadUuid === "" && newParams.leadUuid !== "") {
                return 'NEW_LEAD'
            }
            if (user.leadUuid === "" || newParams.leadUuid === "") {
                return 'EMPTY_LEAD'
            }
        }

        return "NO_SET"

    }, [])

    const verifyEmptyLead = useCallback(() => {
        const authJwt = localStorage.getItem('auth_jwt')
        if (authJwt && externalAuthData && !queryExternalAuthData) {
            const data = jwt.decode(JSON.parse(authJwt) || '') as any;
            const { user } = data;
            const isOpsAnalyst = user.role ==='OPS_ANALYST'
            if (isOpsAnalyst && user.leadUuid === "" && externalAuthData?.leadUuid === "") {
                setIsInvalid('EMPTY_LEAD');
            }
        }
    },[externalAuthData,queryExternalAuthData])

    const decodeParams = useCallback(() => {
        const params = decodeParamsFromUrl()
        if (params) {
            const verifyResult = verifyValidParams(params)
            if (verifyResult === 'NO_SET') {
                setExternalAuthDataParams(params)
                clearParamsFromUrl()
            }
            setTemporalAuthParams(params)
            setIsInvalid(verifyResult);
        }else{
            verifyEmptyLead()
        }
    }, [setExternalAuthDataParams, clearParamsFromUrl, verifyValidParams,verifyEmptyLead]);

    const forceDecodeParams = useCallback(() => {
        const params = temporalAuthParams
        if (params) {
            setIsInvalid(null);
            setExternalAuthDataParams(params)
            setExternalAuthData(params);
            clearParamsFromUrl()

        }
        return params
    }, [clearParamsFromUrl, setExternalAuthData, setExternalAuthDataParams, temporalAuthParams]);



    const persistExternalAuthData = useCallback(() => {
        setExternalAuthData(externalAuthDataParams);
    }, [externalAuthDataParams, setExternalAuthData]);

    const clearExternalAuthData = () => {
        setExternalAuthData(undefined);
        setExternalAuthDataParams(undefined)
        setIsInvalid(null)

    };

    const clearLeadFromAuthData = useCallback(() => {
        const authJwt = localStorage.getItem('auth_jwt')

        if (authJwt) {
            //TODO: obtener el JWT del back sin el leadUuid y guardarlas en el localstorage

            setExternalAuthData((prev) => ({ agentUuid: prev?.agentUuid as string, leadUuid: "" }));
            setExternalAuthDataParams((prev) => ({ agentUuid: prev?.agentUuid as string, leadUuid: "" }));
            history.push('/empty-lead');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setExternalAuthData, setExternalAuthDataParams]);



    useEffect(() => {
        decodeParams();
    }, [decodeParams]);

    return { clearExternalAuthData, externalAuthDataParams, queryExternalAuthData, persistExternalAuthData, isInvalid, forceDecodeParams, clearLeadFromAuthData };

})

