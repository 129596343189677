import { Button, FlexGrid, Image, Typography } from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import portrait from '../../images/Portrait_Solid.svg';
import logo  from '../../images/Logo_Primario_Indigo.png'
export const LandingError = () => {
  const {
    TITLE_LANDING_ERROR,
    DESCRIPTION_LANDING_ERROR,
    BUTTON_LANDING_ERROR
  } = useTranslator();
  

  return (
    <FlexGrid
      container
      direction='column'
      spacing={8}
      alignItems='center'
      justify='center'
      fullHeight
      fullWidth
      padding={8}
    >
      <FlexGrid item>
        <Image src={logo} size='200px' />
      </FlexGrid>
      <FlexGrid item>
        <Image src={portrait} size='60px' />
      </FlexGrid>
      <FlexGrid
        item
        container
        spacing={1}
        direction='column'
        alignItems='center'
        style={{ textAlign: 'center' }}
      >
        <FlexGrid item>
          <Typography variant='h2'>
            {TITLE_LANDING_ERROR}
          </Typography>
        </FlexGrid>
        <FlexGrid item  style={{ maxWidth: '520px', padding:"20px" }}>
          <Typography variant='paragraph'>
            {DESCRIPTION_LANDING_ERROR}
          </Typography>
        </FlexGrid>
      </FlexGrid>

      <FlexGrid
        item
        container
        spacing={1}
        direction='column'
        alignItems='center'
        style={{ textAlign: 'center' }}
      >
        
        <FlexGrid item>
          <Button color='indigo' size='large' onClick={()=>{
            window.location.href = '/login';
          }}>
            {BUTTON_LANDING_ERROR}
            </Button>
          
        </FlexGrid>
      </FlexGrid>
    </FlexGrid>
  );
};
