import { FormContainer } from '../../../../../../../../components-quick-win/form-container';
import { AccountPepRelationForm } from '../../../../../../../../components-quick-win/account-pep-relation-form';

export const AccountPepRelation = () => {
  return (
    <FormContainer>
      <AccountPepRelationForm />
    </FormContainer>
  );
};
