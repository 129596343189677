import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { hubspotTrackerCode } from './environments/environment';
const script = document.createElement('script');

script.async = true;
script.defer = true;
script.src = `//js.hs-scripts.com/${hubspotTrackerCode}.js`;
script.type = 'text/javascript';
script.id = 'hs-script-loader';

document.querySelector('html')?.append(script);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
