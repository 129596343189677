import { FlexGrid, useThemeProvider, Image, Typography } from '@albo-ui/core';
import logoPrimarioIndigo from '../../images/Logo_Primario_Indigo.png';
import indigoCircle from '../../images/Indigo_Circle.svg';
import pantallaLaptop from '../../images/Pantalla_Laptop.png';
import { useTranslator } from '../../providers/translator-provider';
import {
  ListItem,
  StyledTypography,
  TextContainer,
  BottomImage,
  BottomImageCircle,
} from './styled';

export const LandingWelcomeLeadGen = () => {
  const { theme } = useThemeProvider();
  const {
    LANDING_WELCOME_LIST_FIRST_ITEM,
    LANDING_WELCOME_LIST_SECOND_ITEM,
    LANDING_WELCOME_LIST_THIRD_ITEM,
    LANDING_WELCOME_TEXT,
    LANDING_WELCOME_TITLE,
  } = useTranslator();

  return (
    <FlexGrid
      container
      style={{
        backgroundColor: theme.colors.manatee,
        position: 'relative',
        overflow: 'hidden',
      }}
      padding={7}
      spacing={4}
      direction='column'
      fullWidth
      fullHeight
    >
      <FlexGrid item>
        <Image
          src={logoPrimarioIndigo}
          size='25%'
          style={{ width: ' 150px', marginLeft: '-15px' }}
        />
      </FlexGrid>
      <FlexGrid item container spacing={4} direction='column'>
        <FlexGrid item>
          <Typography
            variant='h1'
            color='primary'
            style={{ lineHeight: theme.spacesPx[6] }}
          >
            {LANDING_WELCOME_TITLE}
          </Typography>
        </FlexGrid>
        <FlexGrid item>
          <TextContainer theme={theme}>{LANDING_WELCOME_TEXT}</TextContainer>
        </FlexGrid>
        <FlexGrid item>
          <ListItem>
            <StyledTypography variant='smallTitle' color='primary'>
              {LANDING_WELCOME_LIST_FIRST_ITEM}
            </StyledTypography>
          </ListItem>
          <ListItem>
            <StyledTypography variant='smallTitle' color='primary'>
              {LANDING_WELCOME_LIST_SECOND_ITEM}
            </StyledTypography>
          </ListItem>
          <ListItem>
            <StyledTypography variant='smallTitle' color='primary'>
              {LANDING_WELCOME_LIST_THIRD_ITEM}
            </StyledTypography>
          </ListItem>
        </FlexGrid>
      </FlexGrid>
      <BottomImageCircle src={indigoCircle} size='350px' theme={theme} />
      <BottomImage src={pantallaLaptop} size='400px' theme={theme} />
    </FlexGrid>
  );
};
