import { LeadGen } from '../lead-gen';

export const SignIn = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'white',
      }}
    >
      <LeadGen />
    </div>
  );
};
