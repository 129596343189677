import { useCallback } from 'react';
import { useTranslator } from '../../providers/translator-provider';

const specialCharactersPattern = /(?=.*[!"#$%&'()*+,-./:;<=>¿?@[\]^_{|}~])/;
const twoEqualSequentialCharactersPattern = /(.)\1{2,}/;
const alphanumericPattern = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const electorKeyPattern =
  /^[A-Z]{1}[B-DF-HJ-NP-TV-Z]{1}[A-Z]{1}[B-DF-HJ-NP-TV-Z]{1}[A-Z]{1}[B-DF-HJ-NP-TV-Z]{1}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[0-9]{2}[HM]{1}[0-9]{3}$/;
const curpPattern =
  /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
const rfcPattern =
  /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})$/;

export function useValidators() {
  const {
    USE_LETTERS_AND_NUMBERS,
    NO_MORE_THAN_TWO_EQUAL_CHARACTERS_ERROR,
    DO_NOT_USE_TWO_CONSECUTIVE_CHARACTERS,
    DO_NOT_INCLUDE_WORD_ALBO,
    AT_LEAST_ONE_SPECIAL_CHARACTER,
    REQUIRED_FIELD,
    INVALID_EMAIL,
    SELECT_OPTION,
    ELECTOR_KEY_FORMAT_INVALID,
    CURP_FORMAT_INVALID,
    RFC_FORMAT_INVALID,
    INVALID_DATE_FORMAT,
    MINIMUM_PERCENTAGE_TO_REGISTER,
    CHARACTERES_AT_LEAST,
    IT_IS_REQUIRED,
    MUST_BE_BELOW_ONE_HUNDRED_YEARS,
    THE_EMPLOYEE_MUST_BE_OF_LEGAL_AGE,
    DATE_MUST_BE_BELOW_ONE_HUNDRED_YEARS
  } = useTranslator();

  const isEmpty = useCallback(
    function isEmpty(value: string | number) {
      const validation =
        typeof value === 'string'
          ? value.trim().length === 0
          : value.toString().length === 0;
      return validation ? REQUIRED_FIELD : undefined;
    },
    [REQUIRED_FIELD]
  );

  const selectIsEmpty = useCallback(
    (value: string | boolean) => {
      const validation =
        typeof value === 'string'
          ? value.trim().length === 0
          : value.toString().length === 0;
      return validation ? SELECT_OPTION : undefined;
    },
    [SELECT_OPTION]
  );

  const doNotHaveMinLength = useCallback(
    (value: string, minLength?: number) => {
      const validation = value.trim().length < (minLength ? minLength : 8);
      return validation
        ? `${IT_IS_REQUIRED} ${
            minLength ? minLength : 8
          } ${CHARACTERES_AT_LEAST} `
        : undefined;
    },
    [IT_IS_REQUIRED, CHARACTERES_AT_LEAST]
  );

  const doNotHaveSpecialCharacters = useCallback(
    (value: string) => {
      const validation = value.match(specialCharactersPattern);
      return !validation ? AT_LEAST_ONE_SPECIAL_CHARACTER : undefined;
    },
    [AT_LEAST_ONE_SPECIAL_CHARACTER]
  );

  const hasTwoConsecutiveLetterOrNumber = useCallback(
    (value: string) => {
      const characters = value.split('').map((c) => c.charCodeAt(0));
      let accumulator = 0;
      let lastCharacter = undefined;
      let isValid = true;
      for (let char of characters) {
        if (lastCharacter) {
          const result = lastCharacter - char;
          if (result === -1) accumulator++;
          else accumulator = 0;
          if (accumulator >= 2) isValid = false;
        }
        lastCharacter = char;
      }
      return !isValid ? DO_NOT_USE_TWO_CONSECUTIVE_CHARACTERS : undefined;
    },
    [DO_NOT_USE_TWO_CONSECUTIVE_CHARACTERS]
  );

  const hasTwoEqualSequentialCharacters = useCallback(
    (value: string) => {
      const validation = value
        .toLocaleLowerCase()
        .match(twoEqualSequentialCharactersPattern);
      return validation ? NO_MORE_THAN_TWO_EQUAL_CHARACTERS_ERROR : undefined;
    },
    [NO_MORE_THAN_TWO_EQUAL_CHARACTERS_ERROR]
  );

  const notValidEmail = useCallback(
    (value: string) => {
      const validation = value.match(emailPattern);
      return !validation ? INVALID_EMAIL : undefined;
    },
    [INVALID_EMAIL]
  );

  const notAlphanumeric = useCallback(
    (value: string) => {
      const validation = value.match(alphanumericPattern);
      return !validation ? USE_LETTERS_AND_NUMBERS : undefined;
    },
    [USE_LETTERS_AND_NUMBERS]
  );

  const hasAlboWord = useCallback(
    (value: string) => {
      const validation = value.toLowerCase().includes('albo');
      return validation ? DO_NOT_INCLUDE_WORD_ALBO : undefined;
    },
    [DO_NOT_INCLUDE_WORD_ALBO]
  );

  const notValidElectorKeyFormat = useCallback(
    (value: string) => {
      const validation = value.match(electorKeyPattern);
      return !validation ? ELECTOR_KEY_FORMAT_INVALID : undefined;
    },
    [ELECTOR_KEY_FORMAT_INVALID]
  );

  const notValidCurpFormat = useCallback(
    (value: string) => {
      const validation = value.match(curpPattern);
      return !validation ? CURP_FORMAT_INVALID : undefined;
    },
    [CURP_FORMAT_INVALID]
  );

  const notValidRfcFormat = useCallback(
    (value: string) => {
      const validation = value.match(rfcPattern);
      return !validation ? RFC_FORMAT_INVALID : undefined;
    },
    [RFC_FORMAT_INVALID]
  );

  const notValidDateFormat = useCallback(
    (value: string) => {
      return value.length <= 10 ? INVALID_DATE_FORMAT : undefined;
    },
    [INVALID_DATE_FORMAT]
  );

  const notMinimumPercentaje = useCallback(
    (value: string) => {
      return !(parseInt(value) >= 25)
        ? MINIMUM_PERCENTAGE_TO_REGISTER
        : undefined;
    },
    [MINIMUM_PERCENTAGE_TO_REGISTER]
  );

  const validateAge = useCallback(
    (selectedDate: string) => {
      const endDate = selectedDate ? new Date(selectedDate) : new Date();
      const currentDate = new Date();

      const endDateYears = endDate.getFullYear();
      const currentDateYears = currentDate.getFullYear();
      const age = currentDateYears - endDateYears;

      if (age > 100) return MUST_BE_BELOW_ONE_HUNDRED_YEARS;
      if (age >= 18) return undefined;

      return THE_EMPLOYEE_MUST_BE_OF_LEGAL_AGE;
    },
    [THE_EMPLOYEE_MUST_BE_OF_LEGAL_AGE, MUST_BE_BELOW_ONE_HUNDRED_YEARS]
  );

  const dateIsOverOneHundredYears = useCallback(
    (selectedDate: string) => {
      const todayOneHundredYearsAgo = new Date();
      const selectedDateByUser = selectedDate
        ? new Date(selectedDate)
        : new Date();
      todayOneHundredYearsAgo.setFullYear(
        todayOneHundredYearsAgo.getFullYear() - 100
      );

      if (selectedDateByUser <= todayOneHundredYearsAgo)
        return DATE_MUST_BE_BELOW_ONE_HUNDRED_YEARS;
      return undefined;
    },
    [DATE_MUST_BE_BELOW_ONE_HUNDRED_YEARS]
  );

  const getPasswordValidators = useCallback(() => {
    return [
      isEmpty,
      hasAlboWord,
      doNotHaveMinLength,
      hasTwoEqualSequentialCharacters,
      hasTwoConsecutiveLetterOrNumber,
      doNotHaveSpecialCharacters,
      notAlphanumeric,
    ];
  }, [
    isEmpty,
    hasAlboWord,
    hasTwoConsecutiveLetterOrNumber,
    hasTwoEqualSequentialCharacters,
    doNotHaveMinLength,
    doNotHaveSpecialCharacters,
    notAlphanumeric,
  ]);

  return {
    getPasswordValidators,
    doNotHaveMinLength,
    doNotHaveSpecialCharacters,
    hasTwoConsecutiveLetterOrNumber,
    hasTwoEqualSequentialCharacters,
    isEmpty,
    notValidEmail,
    notAlphanumeric,
    hasAlboWord,
    selectIsEmpty,
    notValidElectorKeyFormat,
    notValidCurpFormat,
    notValidRfcFormat,
    notValidDateFormat,
    notMinimumPercentaje,
    validateAge,
    dateIsOverOneHundredYears
  };
}
