import { FormContainer } from '../../../../../../../../components-quick-win/form-container';
import { ComplementaryInfoForm } from '../../../../../../../../components-quick-win/complementary-info-form';

export const ComplementaryInfo= () => {
  return (
    <FormContainer>
      <ComplementaryInfoForm />
    </FormContainer>
  );
};
