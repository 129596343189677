import { useTranslator } from '../../providers/translator-provider';
import { useValidators } from '../validators';

export type validationsType = {
  maxLength?: number;
  inputType?: 'number' | 'text' | 'email' | 'password' | 'alphanumeric';
  validators?: ((value: string) => string | undefined)[];
  pattern?:
    | 'number'
    | 'text'
    | 'email'
    | 'password'
    | 'alphanumeric'
    | 'specialTextPattern'
    | 'default';
};

export function useDefaultValidations() {
  const validators = useValidators();
  const { INVALID_POSTAL_CODE_MUST_HAVE_FIVE_DIGITS } = useTranslator();

  function nameValidations(): validationsType {
    return { maxLength: 20, validators: [validators.isEmpty] };
  }

  function nameOrDenominationValidations(): validationsType {
    return { maxLength: 50, validators: [validators.isEmpty] };
  }

  function paternalLastNameValidations(): validationsType {
    return { maxLength: 30, validators: [validators.isEmpty] };
  }

  function maternalLastNameValidations(): validationsType {
    return { maxLength: 30 };
  }
  function emailValidations(): validationsType {
    return {
      inputType: 'email',
      maxLength: 100,
      validators: [validators.isEmpty, validators.notValidEmail],
    };
  }
  function phoneNumberValidations(): validationsType {
    return {
      validators: [validators.selectIsEmpty, validators.isEmpty,function doNotHaveMinLength(value: string){return validators.doNotHaveMinLength(value, 10)} ],
    };
  }
  function curpValidations(): validationsType {
    return {
      inputType: 'alphanumeric',
      maxLength: 18,
      validators: [
        validators.isEmpty,
        validators.notAlphanumeric,
        (value: string) => validators.doNotHaveMinLength(value, 18),
        validators.notValidCurpFormat,
      ],
    };
  }

  function electorKeyValidations(): validationsType {
    return {
      inputType: 'alphanumeric',
      maxLength: 18,
      validators: [
        validators.isEmpty,
        validators.notAlphanumeric,
        (value: string) => validators.doNotHaveMinLength(value, 18),
        validators.notValidElectorKeyFormat,
      ],
    };
  }

  function rfcWithHomoclaveValidations(): validationsType {
    return {
      inputType: 'alphanumeric',
      maxLength: 13,
      validators: [
        validators.isEmpty,
        validators.notAlphanumeric,
        (value: string) => validators.doNotHaveMinLength(value, 12),
        validators.notValidRfcFormat,
      ],
    };
  }

  function postalCodeValidations(
    isForeignPostalCode?: boolean
  ): validationsType {
    return {
      inputType: isForeignPostalCode ? 'alphanumeric' : 'number',
      maxLength: isForeignPostalCode ? 10 : 5,
      validators: [
        validators.isEmpty,
        (value: string) => {
          if (isForeignPostalCode) return undefined;
          else {
            return (
              validators.doNotHaveMinLength(value, 5) &&
              INVALID_POSTAL_CODE_MUST_HAVE_FIVE_DIGITS
            );
          }
        },
      ],
    };
  }

  function countryValidations(): validationsType {
    return { maxLength: 50, validators: [validators.isEmpty] };
  }

  function cityStateValidations(): validationsType {
    return {
      maxLength: 50,
      inputType: 'text',
      validators: [validators.isEmpty],
    };
  }

  function municipalityValidations(): validationsType {
    return {
      maxLength: 50,
      inputType: 'text',
      validators: [validators.isEmpty],
    };
  }

  function neighborhoodValidations(): validationsType {
    return { maxLength: 50, validators: [validators.isEmpty] };
  }

  function streetValidations(): validationsType {
    return {
      pattern: 'specialTextPattern',
      maxLength: 40,
      validators: [validators.isEmpty],
    };
  }

  function streetAddressValidations(): validationsType {
    return {
      pattern: 'specialTextPattern',
      inputType: 'alphanumeric',
      maxLength: 10,
      validators: [validators.isEmpty],
    };
  }

  function apartamentNumberValidations(): validationsType {
    return {
      pattern: 'specialTextPattern',
      inputType: 'alphanumeric',
      maxLength: 10,
    };
  }

  function referenceValidations(): validationsType {
    return {
      pattern: 'specialTextPattern',
      inputType: 'alphanumeric',
      maxLength: 75,
    };
  }

  function comercialNameValidations(): validationsType {
    return {
      pattern: 'default',
      maxLength: 40,
      validators: [validators.isEmpty],
    };
  }

  function accountNickNameValidations(): validationsType {
    return {
      pattern: 'specialTextPattern',
      maxLength: 20,
      validators: [validators.isEmpty],
    };
  }

  function fullNameOfWhoReceivesValidations(): validationsType {
    return {
      maxLength: 40,
      validators: [validators.isEmpty],
    };
  }

  function nationalityValidations(): validationsType {
    return {
      maxLength: 20,
      validators: [validators.isEmpty],
    };
  }

  function businessNameValidations(): validationsType {
    return {
      pattern: 'default',
      maxLength: 150,
      validators: [validators.isEmpty],
    };
  }

  function servicesOfferedValidations(): validationsType {
    return {
      pattern: 'specialTextPattern',
      maxLength: 100,
      validators: [validators.isEmpty],
    };
  }

  function websiteOrSocialNetworkValidations(): validationsType {
    return {
      pattern: 'specialTextPattern',
      inputType: 'alphanumeric',
      maxLength: 40,
    };
  }

  function businessPhoneValidations(): validationsType {
    return {
      inputType: 'number',
      maxLength: 10,
      validators: [
        validators.isEmpty,
        (value: string) => validators.doNotHaveMinLength(value, 10),
      ],
    };
  }

  function socialReasonValidations(): validationsType {
    return {
      pattern: 'default',
      maxLength: 150,
      validators: [validators.isEmpty],
    };
  }

  function sourceOfAccountResourcesValidations(): validationsType {
    return {
      maxLength: 60,
      validators: [validators.isEmpty],
      pattern: 'specialTextPattern',
    };
  }

  function accountTransactionalityValidations(): validationsType {
    return {
      maxLength: 10,
      inputType: 'number',
      validators: [validators.isEmpty],
    };
  }

  function resourcesDestinationValidations(): validationsType {
    return {
      maxLength: 60,
      validators: [validators.isEmpty],
      pattern: 'specialTextPattern',
    };
  }

  function stockNameValidations(): validationsType {
    return {
      maxLength: 80,
      inputType: 'alphanumeric',
      validators: [validators.isEmpty],
    };
  }

  function blackboardKeyValidations(): validationsType {
    return {
      maxLength: 10,
      inputType: 'alphanumeric',
      validators: [validators.isEmpty],
    };
  }

  return {
    sourceOfAccountResourcesValidations,
    resourcesDestinationValidations,
    accountTransactionalityValidations,
    nameValidations,
    businessPhoneValidations,
    websiteOrSocialNetworkValidations,
    servicesOfferedValidations,
    businessNameValidations,
    paternalLastNameValidations,
    fullNameOfWhoReceivesValidations,
    maternalLastNameValidations,
    emailValidations,
    phoneNumberValidations,
    curpValidations,
    rfcWithHomoclaveValidations,
    postalCodeValidations,
    cityStateValidations,
    municipalityValidations,
    neighborhoodValidations,
    streetAddressValidations,
    streetValidations,
    apartamentNumberValidations,
    referenceValidations,
    countryValidations,
    comercialNameValidations,
    accountNickNameValidations,
    nationalityValidations,
    electorKeyValidations,
    socialReasonValidations,
    stockNameValidations,blackboardKeyValidations,
    nameOrDenominationValidations
  };
}
