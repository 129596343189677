import { LandingContactForm } from '../../components-quick-win/landing-contact-form';
import { FlexGrid, useThemeProvider } from '@albo-ui/core';
import { LandingWelcomeLeadGen } from '../../components-quick-win/landing-welcome-lead-gen';
import { FaTimes } from 'react-icons/fa';
import { IconContainer } from './styled';
import { landingPageUrl } from '../../const';

export const LeadGen = () => {
  const { theme } = useThemeProvider();

  const onCloseClick = () => {
    window.open(landingPageUrl, '_self');
  };

  return (
    <FlexGrid
      container
      fullHeight
      fullWidth
      style={{ minHeight: '100vh', height: 'auto', backgroundColor: 'white' }}
    >
      <FlexGrid item flex='0.4'>
        <LandingWelcomeLeadGen />
      </FlexGrid>
      <FlexGrid
        item
        container
        flex='0.6'
        direction='column'
        style={{ padding: `${theme.spacesPx[4]} ${theme.spacesPx[10]} ` }}
        spacing={3}
      >
        <FlexGrid item style={{ marginLeft: 'auto' }}>
          <IconContainer onClick={onCloseClick}>
            <FaTimes size='20px' color={theme.colors.primary} />
          </IconContainer>
        </FlexGrid>
        <FlexGrid item>
          <LandingContactForm />
        </FlexGrid>
      </FlexGrid>
    </FlexGrid>
  );
};
