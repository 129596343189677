import { FormContainer } from '../../../../../../../../components-quick-win/form-container';
import { BeneficiariesForm } from '../../../../../../../../components-quick-win/beneficiaries-form';

export const Beneficiaries = () => {
  return (
    <FormContainer>
      <BeneficiariesForm />
    </FormContainer>
  );
};
