import {
    Button,
    CheckBox,
    Drawer,
    DrawerActions,
    DrawerContent,
    DrawerDescription,
    DrawerTitle,
    FlexGrid,
    Form,
    FormItemProps,
    RadialButton,
    Typography,
    useFormCheckBox,
    useThemeProvider
} from '@albo-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAddressInputs } from '../../common';
import { useOnboardingForm } from '../../hooks/use-onboarding-form';
import { useTranslator } from '../../providers/translator-provider';
import { FormItems } from '../form-items';
import { mainCountry } from '../../const';
import { useDefaultValidations } from '../../utils/default-validations';
import { useValidators } from '../../utils/validators';
import { useCatalog } from '../../hooks/use-catalog';
import { useAuth } from '../../providers/auth-provider';
import { OnBoardingLoader } from '../on-boarding-loader';
import { useFormsFields } from '../../providers/onboarding-form-provider';

export type AddSoleOwnerProps = {
    uuidForm: string | undefined;
    open: boolean;
    onClose: () => void;
    openSnackbar?: (eventName: string) => void;
    showDeclaratory: boolean;
    showDocumentsPFAE: boolean;
    showOwnerCheck: boolean;
    existLead: boolean;
};

export const AddSoleOwner = ({
    onClose,
    open,
    uuidForm,
    openSnackbar,
    showDeclaratory,
    showDocumentsPFAE,
    showOwnerCheck,
    existLead
}: AddSoleOwnerProps) => {

    const formProps = useOnboardingForm({
        defaultParams: {
            phone: { phoneCode: '+52', phoneCountry:'484', phoneCodeDesc: 'Mexico' },
            address: {
                country: mainCountry,
                countryDesc: 'Mexico',
            },
            isLead: false,
        },
        onboardingFormType: 'QUICK_WIN_REAL_OWNER',
        onboardingFormRef: uuidForm,
        singleton: false,
    });

    const { formFields ,findFields} = useFormsFields();
  
    useEffect(()=>{
    findFields('QUICK_WIN_REAL_OWNER');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const { theme } = useThemeProvider();
    const { leadData } = useAuth();
    const myInfoCheckBox = useFormCheckBox('isLead', formProps.form);
    const defaultValidations = useDefaultValidations();
    const validators = useValidators();
    const [genders] = useCatalog('Genders');
    const [activities] = useCatalog('ShareholderActivities');
    const isLead = formProps.form.data.isLead;
    const identificationFileType = formProps.form.data.identificationFile?.type;
    const setErrors = formProps.form.setErrors;
    const [showCheck, setShowCheck] = useState(false);
    const [curpErrorMessage, setCurpErrorMessage] = useState<string | undefined>(undefined)
    const curpHasErrors = curpErrorMessage !== undefined
    const curp = formProps.form.data?.general?.populationid

    const { setPropByName } = formProps.form;
    const setFormData = formProps.form.setData;
    const onFormInput = formProps.form.onInput;
    const checkBoxValue = myInfoCheckBox.value;
    const {
        ADD_SOLE_OWNER,
        UPDATE,
        SOLE_OWNER,
        SOLE_OWNER_DESCRIPTION_FORM,
        CANCEL,
        PERSONAL_INFO,
        REQUIRED_FIELDS,
        HOME_ADDRESS,
        NAME,
        PATERNAL_LASTNAME,
        MATERNAL_LASTNAME,
        GENDER,
        BIRTH_DATE,
        DATE_FORMAT,
        CURP,
        CURP_HELP_DESCRIPTION,
        BIRTH_COUNTRY,
        BIRTH_STATE,
        OCCUPATION,
        EMAIL,
        SELECT_IDENTIFICATION_TYPE,
        INE,
        PASSPORT,
        OFICIAL_IDENTIFICATION_FRONT,
        UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
        OFICIAL_IDENTIFICATION_BACK,
        MEMBER_ADMINISTRATION_BOARD,
        DATA_ADMINISTRATION_BOARD,
        DECLARATORY_SOLE_OWNER,
        SAVE,
        DESCRIPTION_ADMINISTRATION_BOARD,
    } = useTranslator();

    const addressInputs = formFields('QUICK_WIN_REAL_OWNER',useAddressInputs({
        setFormData: formProps.form.setData,
        postalCode: formProps.form.data.address.zip,
        formData: formProps.form.data
    }))
    const administrationBoardValues = [
        { name: 'Presidente', value: '1' },
        { name: 'Secretario', value: '2' },
        { name: 'Miembro', value: '3' },
        { name: 'Independiente', value: '4' },
        { name: 'Suplente', value: '5' },
    ];

    const createSelect = (list: any) => {
        return list.map((item: any) => ({
            name: item.value,
            value: item.key,
        }));
    }

    const oficialDocumentIne: FormItemProps<string>[] =formFields('QUICK_WIN_REAL_OWNER', [
        {
            placeholder: OFICIAL_IDENTIFICATION_FRONT,
            name: 'identificationFile.data.front',
            type: 'fileInput',
            required: true,
            validators: [validators.isEmpty],
            help: {
                description: UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
            },
        },
        {
            placeholder: OFICIAL_IDENTIFICATION_BACK,
            name: 'identificationFile.data.back',
            required: true,
            type: 'fileInput',
            validators: [validators.isEmpty],
        }
    ]);

    const oficialDocumentPassport: FormItemProps<string>[] = formFields('QUICK_WIN_REAL_OWNER',[
        {
            placeholder: OFICIAL_IDENTIFICATION_FRONT,
            name: 'identificationFile.data.front',
            type: 'fileInput',
            required: true,
            validators: [validators.isEmpty],
            help: {
                description: UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
            },
        }
    ]);

    const administrationBoard: FormItemProps<string>[] = formFields('QUICK_WIN_REAL_OWNER',[
        {
            placeholder: MEMBER_ADMINISTRATION_BOARD,
            name: 'councilPost',
            type: 'select',
            items: administrationBoardValues,
            required: true,
            validators: [validators.selectIsEmpty],
            help: {
                description: DESCRIPTION_ADMINISTRATION_BOARD,
            },
        }
    ]);

    const onSelectOficialDocument = (selectedIndex: number) => {
        setPropByName('identificationFile', {
            data: undefined,
            type: selectedIndex === 0 ? 'ine' : 'passport',
        });
    }

    const oficialDocuments = useMemo(
        () => [
            {
                name: 'ine',
                text: INE,
                value: identificationFileType === 'ine',
            },
            {
                name: 'passport',
                text: PASSPORT,
                value: identificationFileType === 'passport',
            },
        ],
        [identificationFileType, PASSPORT, INE]
    );

    const generalInputs: FormItemProps<string>[] = formFields('QUICK_WIN_REAL_OWNER',[
        {
            placeholder: NAME,
            name: 'general.gname',
            type: 'input',
            required: true,
            disabled: myInfoCheckBox.value,
            ...defaultValidations.nameValidations(),
        },
        {
            placeholder: PATERNAL_LASTNAME,
            name: 'general.lname',
            type: 'input',
            required: true,
            disabled: myInfoCheckBox.value,
            ...defaultValidations.paternalLastNameValidations(),
        },
        {
            placeholder: MATERNAL_LASTNAME,
            name: 'general.slname',
            type: 'input',
            disabled: myInfoCheckBox.value,
            ...defaultValidations.maternalLastNameValidations(),
        },
        {
            placeholder: GENDER,
            name: 'general.gender',
            type: 'select',
            items: createSelect(genders),
            required: true,
            validators: [validators.selectIsEmpty],
        },
        {
            placeholder: `${BIRTH_DATE} ${DATE_FORMAT}`,
            name: 'general.bdate',
            type: 'date',
            inputType: 'email',
            required: true,
            validators: [validators.isEmpty, validators.notValidDateFormat],
        },
        {
            placeholder: CURP,
            name: 'general.populationid',
            type: 'input',
            inputType: 'alphanumeric',
            toUpperCase: true,
            error: curpHasErrors,
            errorMessage: curpErrorMessage,
            ...defaultValidations.curpValidations(),
            help: {
                description: CURP_HELP_DESCRIPTION,
            },
        },
        {
            placeholder: BIRTH_COUNTRY,
            name: 'general.bcountry',
            type: 'input',
            inputType: 'text',
            required: true,
            validators: [validators.isEmpty],
        },
        {
            placeholder: BIRTH_STATE,
            name: 'general.bstate',
            type: 'input',
            inputType: 'text',
            required: true,
            validators: [validators.isEmpty],
        },
        {
            placeholder: OCCUPATION,
            name: 'general.occupation',
            type: 'select',
            items: createSelect(activities),
            required: true,
            validators: [validators.selectIsEmpty],
        },
        {
            placeholder: EMAIL,
            name: 'general.email',
            type: 'input',
            required: true,
            ...defaultValidations.emailValidations(),
        },
        {
            name: 'general.phone',
            type: 'phone',
            required: true,
            disabled: myInfoCheckBox.value || isLead,
            ...defaultValidations.phoneNumberValidations(),
        },
    ]);

    const onDrawerAddButtonClick = () => {
        if (formProps.form.hasErrors || curpHasErrors) {
            formProps.form.displayErrors();
        } else {
            formProps.moveForward();
        }
    }

    const validateCurp = useCallback(
      (value: string) => {
        const notAlphanumeric = validators.notAlphanumeric(value);
        const doNotHaveMinLength = validators.doNotHaveMinLength(value, 18);
        const notValidCurpFormat = validators.notValidCurpFormat(value);

        return notAlphanumeric || doNotHaveMinLength || notValidCurpFormat;
      },
      [validators]
    );

    useEffect(() => {
      curp?.length > 0
        ? setCurpErrorMessage(validateCurp(curp))
        : setCurpErrorMessage(undefined);
    }, [curp, validateCurp]);

    useEffect(() => {
        if (showDocumentsPFAE) {
            setFormData((currentData: any) => ({
                ...currentData,
                identificationFile: {
                    type: 'ine',
                }
            }));
        }
    }, [showDocumentsPFAE, setFormData]);

    useEffect(() => {
        if (isLead) {
            setShowCheck(true);
        } else {
            if (showOwnerCheck && !existLead){
                setShowCheck(true);
            }
        }
    }, [isLead, showOwnerCheck, existLead]);

    useEffect(() => {
        if (identificationFileType !== 'ine') {
            setErrors((currentErrors: any) => ({
                ...currentErrors,
                'identificationFile.data.back': undefined,
            }));
        }
    }, [identificationFileType, setErrors]);

    useEffect(() => {
        if (checkBoxValue) {
            onFormInput((current: any) => ({
                ...current,
                general: {
                    ...current.general,
                    ...leadData.general,
                },
            }));
        } else {
            setFormData((currentData: any) => ({
                ...currentData,
                general: { phoneCode: '+52', phoneCountry:'484', phoneCodeDesc: 'Mexico' },
            }));
        }
    }, [checkBoxValue, onFormInput, setFormData, leadData]);

    const saveOnboardingFormEvent = formProps.eventName;
    useEffect(() => {
        if (saveOnboardingFormEvent) {
            onClose();
            openSnackbar && openSnackbar(saveOnboardingFormEvent);
        }
    }, [saveOnboardingFormEvent, onClose, openSnackbar]);

    if (formProps.loadingForm || formProps.movingForward || formProps.saving)
        return (
            <OnBoardingLoader
                isLoading={
                    formProps.loadingForm || formProps.movingForward || formProps.saving
                }
            />
        );

    return (
        <Form {...formProps.form} onSubmit={onDrawerAddButtonClick}>
            <Drawer open={open} onClose={onClose} position='right'>
                <DrawerTitle>
                    {uuidForm ? `${UPDATE} ${SOLE_OWNER}` : ADD_SOLE_OWNER}
                </DrawerTitle>
                <DrawerDescription>
                    <FlexGrid container fullWidth direction='column' spacing={2}>
                        <FlexGrid item>
                            <Typography variant='paragraph'>
                                {SOLE_OWNER_DESCRIPTION_FORM}
                            </Typography>
                        </FlexGrid>
                    </FlexGrid>
                </DrawerDescription>

                <DrawerContent>
                    <FlexGrid
                        item
                        container
                        fullWidth
                        spacing={4}
                        direction='column'
                        style={{ marginBottom: '60px' }}
                    >
                        {showDeclaratory && <FlexGrid item container fullWidth spacing={2} direction='column'>
                            <FlexGrid item>
                                <Typography variant='smallTitle'>
                                    {DATA_ADMINISTRATION_BOARD}
                                </Typography>
                            </FlexGrid>
                            <FlexGrid item>
                                <Typography variant='small' color='neutral'>
                                    <span style={{ color: theme.colors.required }}>*</span>{' '}
                                    {REQUIRED_FIELDS}
                                </Typography>
                            </FlexGrid>
                            <FormItems inputs={administrationBoard} />
                        </FlexGrid>}
                        <FlexGrid item container fullWidth spacing={2} direction='column'>
                            <FlexGrid item>
                                <Typography variant='smallTitle'>
                                    {PERSONAL_INFO}
                                </Typography>
                            </FlexGrid>
                            {showCheck && <FlexGrid item>
                                <CheckBox {...myInfoCheckBox} text={`Yo soy el ${SOLE_OWNER}`} />
                            </FlexGrid>}
                            {!showDeclaratory && <FlexGrid item>
                                <Typography variant='small' color='neutral'>
                                    <span style={{ color: theme.colors.required }}>*</span>{' '}
                                    {REQUIRED_FIELDS}
                                </Typography>
                            </FlexGrid>}
                            <FormItems inputs={generalInputs} />
                        </FlexGrid>

                        <FlexGrid item container fullWidth spacing={4} direction='column'>
                            <FlexGrid item>
                                <Typography variant='smallTitle'>
                                    {HOME_ADDRESS}
                                </Typography>
                            </FlexGrid>
                            <FormItems inputs={addressInputs} />
                        </FlexGrid>
                        {showDocumentsPFAE && <FlexGrid item container fullWidth spacing={4} direction='column'>
                            <FlexGrid item>
                                <Typography variant='smallTitle'>
                                    {SELECT_IDENTIFICATION_TYPE}
                                </Typography>
                            </FlexGrid>
                            <FlexGrid item container direction='row' spacing={2}>
                                {oficialDocuments.map(({ value, text }, index) => (
                                    <FlexGrid item>
                                        <RadialButton
                                            value={value}
                                            onInput={() => onSelectOficialDocument(index)}
                                            text={text}
                                        />
                                    </FlexGrid>
                                ))}
                            </FlexGrid>
                            <FlexGrid item>
                                <FormItems
                                    inputs={
                                        identificationFileType === 'ine'
                                            ? oficialDocumentIne
                                            : oficialDocumentPassport
                                    }
                                />
                            </FlexGrid>
                        </FlexGrid>}
                        {showDeclaratory && <FlexGrid container fullWidth direction='column' spacing={2}>
                            <FlexGrid item>
                                <Typography variant='paragraph'>
                                    {DECLARATORY_SOLE_OWNER}
                                </Typography>
                            </FlexGrid>
                        </FlexGrid>}
                    </FlexGrid>
                </DrawerContent>

                <DrawerActions>
                    <Button onClick={onClose} buttonType='secondary' size='normal'>
                        {CANCEL}
                    </Button>
                    <Button
                        onClick={onDrawerAddButtonClick}
                        size='normal'
                        style={{ marginLeft: theme.spacesPx[2] }}
                    >
                        {uuidForm ? UPDATE : SAVE}
                    </Button>
                </DrawerActions>
            </Drawer>
        </Form>
    );
};
