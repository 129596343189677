import {
  FaUserTie,
  FaUserFriends,
  FaFileAlt,
  FaFileInvoiceDollar,
} from 'react-icons/fa';
import { RoundedIcon, useThemeProvider } from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { ReactNode } from 'react';
import { usePmStatusQuickWin } from '../../hooks/use-pm-status-quick-win';

export type TopCard = {
  icon: ReactNode;
  title: string;
  description: string;
  url: string;
  status: string;
}[];

export const GetMenuItems = (): {
  items: TopCard;
} => {
  const { theme } = useThemeProvider();
  const {
    legalRepresentativeStatus,
    shareholdersStatus,
    companyInfoStatus,
    accountConfigStatus,
    soleOwnersStatus
  } = usePmStatusQuickWin();
  const {
    ON_BOARDING_MENU_PM_ACCOUNT_SETTINGS_CARD_DESCRIPTION,
    ACCOUNT_CONFIG,
    ON_BOARDING_MENU_PM_BUSINESS_DATA_CARD_DESCRIPTION,
    BUSINESS_DATA,
    ON_BOARDING_MENU_PM_LEGAL_REPRESENTATIVE_CARD_DESCRIPTION,
    LEGAL_REPRESENTATIVE,
    ON_BOARDING_MENU_PM_SHAREHOLDERS_CARD_DESCRIPTION,
    ON_BOARDING_MENU_PM_SHAREHOLDERS_CARD_TITLE,
  } = useTranslator();

  const legarRepresentative = (
    <RoundedIcon
      icon={<FaUserTie size='13px' color={theme.colors.charcoal} />}
      size='normal'
    />
  );

  const shareHolders = (
    <RoundedIcon
      icon={<FaUserFriends size='13px' color={theme.colors.charcoal} />}
      size='normal'
    />
  );

  const businessData = (
    <RoundedIcon
      icon={<FaFileAlt size='13px' color={theme.colors.charcoal} />}
      size='normal'
    />
  );

  const accountSettings = (
    <RoundedIcon
      icon={<FaFileInvoiceDollar size='13px' color={theme.colors.charcoal} />}
      size='normal'
    />
  );


  return {
    items: [
      {
        icon: businessData,
        title: BUSINESS_DATA,
        description: ON_BOARDING_MENU_PM_BUSINESS_DATA_CARD_DESCRIPTION,
        url: 'company-info',
        status: companyInfoStatus,
      },
      {
        icon: legarRepresentative,
        title: LEGAL_REPRESENTATIVE,
        description: ON_BOARDING_MENU_PM_LEGAL_REPRESENTATIVE_CARD_DESCRIPTION,
        url: 'legal-representative',
        status: legalRepresentativeStatus,
      },
      {
        icon: shareHolders,
        title: ON_BOARDING_MENU_PM_SHAREHOLDERS_CARD_TITLE,
        description: ON_BOARDING_MENU_PM_SHAREHOLDERS_CARD_DESCRIPTION,
        url: 'shareholders',
        status: shareholdersStatus && soleOwnersStatus,
      },
      {
        icon: accountSettings,
        title: ACCOUNT_CONFIG,
        description: ON_BOARDING_MENU_PM_ACCOUNT_SETTINGS_CARD_DESCRIPTION,
        url: 'account-config',
        status: accountConfigStatus,
      }
    ],
  };
};
