import {
  Button,
  Card,
  Description,
  FlexGrid,
  RoundedIcon,
  Typography,
  useThemeProvider,
} from '@albo-ui/core';
import { useEffect } from 'react';
import { useCookieUsePolicyNotification } from '../../providers/cookie-use-policy-notification-provider';
import { useTranslator } from '../../providers/translator-provider';
import { GetMainItems, GetRightSideLists } from './const';
import { Point } from './styled';

export type OnBoardingIntroPMProps = {
  onClick: () => void;
};

export const OnBoardingIntroPM = ({ onClick }: OnBoardingIntroPMProps) => {
  const { theme } = useThemeProvider();
  const { bottomItems, topCards } = GetMainItems();
  const { lists } = GetRightSideLists();
  const { show, cookiesNotAccepted } = useCookieUsePolicyNotification();

  const {
    ON_BOARDING_INTRO_TITLE,
    ON_BOARDING_INTRO_BUTTON_TEXT,
    ON_BOARDING_INTRO_PM_RIGHT_SIDE_TITLE,
    ON_BOARDING_INTRO_PM_SUBTITLE,
    HELP_EMAIL,
    ON_BOARDING_INTRO_PM_RIGHT_SIDE_BOTTOM_TEXT,
    ON_BOARDING_INTRO_PM_RIGHT_SIDE_BOTTOM_TEXT_DOCUMENTS
  } = useTranslator();

  useEffect(() => {
    if (cookiesNotAccepted) show();
  }, [show, cookiesNotAccepted]);

  return (
    <FlexGrid
      container
      fullWidth
      fullHeight
      style={{ backgroundColor: theme.colors.snow }}
    >
      <FlexGrid
        container
        fullHeight
        fullWidth
        padding={8}
        spacing={6}
        direction='column'
      >
        <FlexGrid
          item
          container
          direction='column'
          spacing={1}
          style={{ textAlign: 'center' }}
        >
          <FlexGrid item>
            <Typography variant='h2' color='indigo'>
              {ON_BOARDING_INTRO_TITLE}
            </Typography>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='smallTitle' color='neutral'>
              {ON_BOARDING_INTRO_PM_SUBTITLE}
            </Typography>
          </FlexGrid>
        </FlexGrid>
        <FlexGrid item container justify='space-between'>
          {topCards.map(({ icon, text }, index) => (
            <Card height='250px' width='244px'>
              <FlexGrid
                container
                fullWidth
                fullHeight
                spacing={3}
                alignItems='center'
                direction='column'
              >
                <FlexGrid item>
                  <Typography variant='h1' color='indigo'>
                    {index + 1}.
                  </Typography>
                </FlexGrid>
                <FlexGrid
                  item
                  container
                  direction='column'
                  alignItems='center'
                  spacing={1}
                  style={{ textAlign: 'center' }}
                >
                  <FlexGrid item>{icon}</FlexGrid>
                  <FlexGrid item>
                    <Typography variant='paragraph'>{text}</Typography>
                  </FlexGrid>
                </FlexGrid>
              </FlexGrid>
            </Card>
          ))}
        </FlexGrid>
        <FlexGrid item container justify='center'>
          <Button onClick={onClick} size='large' disabled={cookiesNotAccepted}>
            {ON_BOARDING_INTRO_BUTTON_TEXT}
          </Button>
        </FlexGrid>
        <FlexGrid item container justify='space-between'>
          {bottomItems.map(({ icon, text, color, width }, index) => (
            <FlexGrid item>
              <Description
                icon={<RoundedIcon size='normal' icon={icon} color={color} />}
                orientation='vertical'
                width={width}
              >
                {text}{' '}
                {index === 2 && (
                  <Typography variant='paragraph' bold>
                    {HELP_EMAIL}
                  </Typography>
                )}
              </Description>
            </FlexGrid>
          ))}
        </FlexGrid>
      </FlexGrid>
      <FlexGrid
        item
        container
        spacing={5}
        direction='column'
        style={{
          backgroundColor: theme.colors.primary,
          width: '60%',
          padding: theme.spacesPx[8],
        }}
      >
        <FlexGrid item>
          <Typography variant='h2' color='white'>
            {ON_BOARDING_INTRO_PM_RIGHT_SIDE_TITLE}
          </Typography>
        </FlexGrid>
        <FlexGrid item container direction='column' spacing={3}>
          {lists.map(({ icon, title, list }) => (
            <FlexGrid container item spacing={3}>
              <FlexGrid item>{icon}</FlexGrid>
              <FlexGrid container item spacing={1} direction='column'>
                <FlexGrid item>
                  <Typography variant='smallTitle' color='white'>
                    {title}
                  </Typography>
                </FlexGrid>
                <FlexGrid item container direction='column'>
                  {list.map((text) => (
                    <FlexGrid item container spacing={1}>
                      <FlexGrid item>
                        <Point />
                      </FlexGrid>
                      <FlexGrid item>
                        <Typography
                          variant='smallTitle'
                          color='white'
                          style={{ fontWeight: 400 }}
                        >
                          {text}
                        </Typography>
                      </FlexGrid>
                    </FlexGrid>
                  ))}
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          ))}
        </FlexGrid>
        <FlexGrid>
          <Typography variant='small' bold color='white'>
            {ON_BOARDING_INTRO_PM_RIGHT_SIDE_BOTTOM_TEXT_DOCUMENTS}
          </Typography>
        </FlexGrid>
        <FlexGrid>
          <Typography variant='small' color='white'>
            {ON_BOARDING_INTRO_PM_RIGHT_SIDE_BOTTOM_TEXT}
          </Typography>
        </FlexGrid>

      </FlexGrid>
    </FlexGrid>
  );
};
