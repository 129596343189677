type UseQueryState = {
  data?: any;
  loading: boolean;
};

export type Action =
  | { type: 'LOAD' }
  | {
      type: 'SUCCESS';
      payload: any;
    };

export const useQueryReducer = (
  state: UseQueryState,
  action: Action
): UseQueryState => {
  switch (action.type) {
    case 'LOAD':
      return { ...state, data: undefined, loading: true };
    case 'SUCCESS':
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
