import {
  ActionCard,
  FlexGrid,
  Typography,
  useDrawer,
  Skeleton,
  useDialog,
} from '@albo-ui/core';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from '../../hooks/user-query';
import { useApi } from '../../providers/api-provider';
import { useAuth } from '../../providers/auth-provider';
import { useOnHomeCBS } from '../../providers/on-home-cbs-provider';
import { useSnackbarNotifier } from '../../hooks/use-snackbar-notifier';
import { useTranslator } from '../../providers/translator-provider';
import { BottomActions } from '../bottom-actions';
import { DeletePromptDialog } from '../delete-prompt-dialog';
import { OnBoardingLoader } from '../on-boarding-loader';
import { AddPhysicalShareholder } from './add-physical-shareholder';
import { MoralShareholder } from './moral-shareholder';
import { InfoDialog } from '../info-dialog';
import { iterateShareholders } from './shareholders-owners';

export const Shareholders = () => {
  const {
    SHAREHOLDERS,
    ADD_SHAREHOLDER,
    ADD_ALL_YOUR_SHAREHOLDERS_OF_YOUR_COMPANY,
    SUCCESS_SAVING_SHAREHOLDER,
    SUCCESS_UPLOADING_INFO,
    PERCENTAGE_OF_SHARES,
    YOU_MUST_HAVE_AT_LEAST_ONE_SHAREHOLDER,
    YOU_MUST_HAVE_AT_LEAST_ONE_SHAREHOLDER_DESCRIPTION,
  } = useTranslator();
  const shareholderDrawerProps = useDrawer();
  const moralShareholderDrawerProps = useDrawer();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [api] = useApi();
  const [uuidUpdate, setUuidUpdate] = useState<undefined | string>(undefined);
  const [updatePercentage, setUpdatePercentage] = useState<number | undefined>(
    undefined
  );
  const [uuidDelete, setUuidDelete] = useState<undefined | string>(undefined);
  const [show, close] = useSnackbarNotifier();
  const deleteDialogProps = useDialog();
  const {
    open: infoDialogIsOpen,
    openDialog: openInfoDialog,
    onClose: closeInfoDialog,
  } = useDialog();
  const homeCBS = useOnHomeCBS();
  homeCBS.setShowDialog(false);
  const {
    leadData: { onboardingForms },
  } = useAuth();
  const { reloadingProfile } = useAuth();

  const shareholdersUuids = useMemo(
    () =>
      onboardingForms
        .filter(({ formType }: any) => formType === 'QUICK_WIN_SHAREHOLDER')
        .map(({ uuid }: any) => uuid),
    [onboardingForms]
  );

  const soleOwnersUuids = useMemo(
    () =>
      onboardingForms
        .filter(({ formType }: any) => formType === 'QUICK_WIN_REAL_OWNER')
        .map(({ uuid }: any) => uuid),
    [onboardingForms]
  );

  const [queryFormData, loadingForm, reloadForm] = useQuery(
    {
      name: 'get-onboarding-forms-by-uuids',
      params: {
        onboardingFormsUuids: shareholdersUuids,
      },
    },
    [shareholdersUuids]
  );

  const shareholderPF:
    | {
        name: string;
        percentage: string;
        type: string;
        beListed: boolean;
        uuid: string;
        status: string;
      }[]
    | undefined = queryFormData
    ? queryFormData.onboardingForms.map((data: any) => ({
        name: data.formData.general.gname,
        uuid: data.uuid,
        percentage: data.formData.general.percentageShares,
        type: data.formData.general.type,
        beListed: data.formData.general.stockMarketListing,
        status: data.isCompleted ? 'done' : 'pending',
      }))
    : undefined;

  const shareholderPercentageSum = shareholderPF
    ? shareholderPF.reduce(
        (total, { percentage }) => total + parseInt(percentage),
        0
      )
    : 0;

  const onAddShareholder = () => {
    shareholderDrawerProps.openDrawer();
    setUuidUpdate(undefined);
    setUpdatePercentage(undefined);
    close('');
  };

  function onBack() {
    history.push('legal-representative');
  }

  const verifySoleOwnerFlow = () => {
    let nextAccount = false;
    if (shareholderPF && shareholderPF.length > 0) {
      const prioritys = iterateShareholders(shareholderPF, false);
      const isPfae = prioritys[0];
      const isAccount = prioritys[1];

      if (isAccount && !isPfae) {
        nextAccount = true;
        deleteSoleOwners();
      }
    }
    return nextAccount;
  };

  const deleteSoleOwners = () => {
    if (soleOwnersUuids && soleOwnersUuids.length > 0) {
      soleOwnersUuids.forEach(async (soleOwner: string) => {
        await api.callService('delete-onboarding-form', {
          uuid: soleOwner,
        });
      });
    }
  };

  function onContinue() {
    if (shareholderPF?.length === 0) {
      openInfoDialog();
    } else {
      history.push(
        verifySoleOwnerFlow() ? 'account-config' : 'sole-owner-info'
      );
    }
  }

  async function onDeleteShareholderPF(uuid: string) {
    setUuidDelete(uuid);
    deleteDialogProps.openDialog();
  }

  async function deleteShareholder() {
    setLoading(true);
    await api.callService('delete-onboarding-form', {
      uuid: uuidDelete,
    });
    reloadForm();
    deleteDialogProps.onClose();
    setLoading(false);
  }

  async function onUpdateShareholderPF(uuid: string, percentage: string) {
    setUuidUpdate(uuid);
    setUpdatePercentage(parseInt(percentage));
    shareholderDrawerProps.openDrawer();
    close('');
  }

  function onDialogClose() {
    setUuidDelete(undefined);
    deleteDialogProps.onClose();
  }

  function onOpenSnackBar(eventName: string) {
    if (eventName === 'OnboardingFormUpdated') {
      show(SUCCESS_UPLOADING_INFO);
    }
    if (eventName === 'OnboardingFormCreated') {
      show(SUCCESS_SAVING_SHAREHOLDER);
    }
  }

  if (loadingForm || loading)
    return (
      <OnBoardingLoader
        isLoading={loadingForm || loading || reloadingProfile}
      />
    );

  return (
    <>
      <DeletePromptDialog
        open={deleteDialogProps.open}
        onConfirm={deleteShareholder}
        onClose={onDialogClose}
      />

      <InfoDialog
        open={infoDialogIsOpen}
        description={YOU_MUST_HAVE_AT_LEAST_ONE_SHAREHOLDER_DESCRIPTION}
        title={YOU_MUST_HAVE_AT_LEAST_ONE_SHAREHOLDER}
        onClose={closeInfoDialog}
      />

      {moralShareholderDrawerProps.open && (
        <MoralShareholder
          open={moralShareholderDrawerProps.open}
          onClose={moralShareholderDrawerProps.onClose}
          uuidForm={uuidUpdate}
        />
      )}

      {shareholderDrawerProps.open && (
        <AddPhysicalShareholder
          open={shareholderDrawerProps.open}
          onClose={shareholderDrawerProps.onClose}
          uuidForm={uuidUpdate}
          openSnackbar={onOpenSnackBar}
          totalPercentageUsed={
            updatePercentage
              ? shareholderPercentageSum - updatePercentage
              : shareholderPercentageSum
          }
        />
      )}

      <FlexGrid container direction='column' spacing={6}>
        <FlexGrid item container spacing={1} direction='column'>
          <FlexGrid item>
            <Typography variant='h2'>{SHAREHOLDERS}</Typography>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='small'>
              {ADD_ALL_YOUR_SHAREHOLDERS_OF_YOUR_COMPANY}
            </Typography>
          </FlexGrid>
        </FlexGrid>
        <FlexGrid
          container
          item
          direction='column'
          style={{ marginBottom: '100px' }}
        >
          <FlexGrid item>
            <FlexGrid item container direction='column' spacing={2}>
              <FlexGrid item>
                <ActionCard
                  title={ADD_SHAREHOLDER}
                  onClick={onAddShareholder}
                  link
                  style={{ height: '80px' }}
                />
              </FlexGrid>
              {shareholderPF &&
                shareholderPF.map(({ name, uuid, percentage ,status}, index) => (
                  <FlexGrid item>
                    <ActionCard
                      key={index}
                      title={name}
                      style={{ height: '80px' }}
                      status={status === 'pending' ? 'pending' : 'default'}
                      onDelete={() => onDeleteShareholderPF(uuid)}
                      onUpdate={() => onUpdateShareholderPF(uuid, percentage)}
                      description={`${PERCENTAGE_OF_SHARES}: ${percentage}%`}
                    />
                  </FlexGrid>
                ))}
              {loadingForm &&
                shareholdersUuids.map(() => (
                  <Skeleton width='100%' height='80px' />
                ))}
            </FlexGrid>
          </FlexGrid>
        </FlexGrid>
      </FlexGrid>
      <BottomActions
        backButton
        continueButton
        onBack={onBack}
        onContinue={onContinue}
      />
    </>
  );
};
