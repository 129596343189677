import { makeProvider } from 'react-provider-maker';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

function capitalize(word: string) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

export const { useProvider: useTracker, Provider: TrackerProvider } =
  makeProvider(() => {
    const { pathname } = useLocation();
    const timeOutRef = useRef<any>();
    useEffect(() => {
      clearTimeout(timeOutRef.current);
      var _hsq = ((window as any)._hsq = (window as any)._hsq || []);
      const navigationRouteName = pathname
        .split('/')
        .slice(-2)
        .filter((n) => n && n.trim() !== '')
        .map((n) => capitalize(n).split('-').join(' '))
        .join(' - ');

      document.title = `ABB Onboarding - ${navigationRouteName}`;
      timeOutRef.current = setTimeout(() => {
        _hsq.push(['trackPageView']);
      }, 1000);
    }, [pathname]);

    const identify = useCallback((email: string) => {
      var _hsq = ((window as any)._hsq = (window as any)._hsq || []);
      _hsq.push([
        'identify',
        {
          email,
        },
      ]);
    }, []);

    return {
      identify,
    };
  });
