import {
  ActionCard,
  FlexGrid,
  Form,
  FormItemProps,
  MultipleSelect,
  RadialButton,
  TextField,
  Typography,
  useMultipleSelect,
  useThemeProvider
} from '@albo-ui/core';
import { ListItemCard } from '../list-item-card';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useAddressInputs } from '../../common';
import { useCatalog } from '../../hooks/use-catalog';
import { useOnboardingForm } from '../../hooks/use-onboarding-form';
import { usePmStatusQuickWinAdditionalInfo } from '../../hooks/use-pm-status-quick-win-additional-info';
import { useOnHomeCBS } from '../../providers/on-home-cbs-provider';
import { useTranslator } from '../../providers/translator-provider';
import { useDefaultValidations } from '../../utils/default-validations';
import { useValidators } from '../../utils/validators';
import { BottomActions } from '../bottom-actions';
import { FormItems } from '../form-items';
import { OnBoardingLoader } from '../on-boarding-loader';
import { fromDefault, groupCompanyInfoForm, groupCompanyAddressForm } from './formDefault'
import { useFormsFields } from '../../providers/onboarding-form-provider';
import { useConfirm } from '../../components/confirm-dialog';
import { useUserRole } from '../../hooks/use-user-role';

export const AdditionalInfoForm = () => {
  const specialTextPattern = /[^a-zA-Z-ZÀ-ÿ0-9-,./:&;\u00f1\u00d1\u00B4\u00A8\s]/g;

  const formProps = useOnboardingForm({
    defaultParams: fromDefault,
    onboardingFormType: 'QUICK_WIN_COMPLEMENTARY_INFORMATION',
    singleton: true,
    nextSectionUrl: 'menu-extra-info'
  });

  const [formInvalid, setFormInvalid] = useState(false);
  const { getPropByName, setPropByName, deleteMultiplePropsByName, data, setData } = formProps.form;
  const history = useHistory();
  const { theme } = useThemeProvider();
  const validators = useValidators();
  const defaultValidations = useDefaultValidations();
  const pmStatus = usePmStatusQuickWinAdditionalInfo();

  const homeCBS = useOnHomeCBS();
  homeCBS.setShowDialog(pmStatus.additionalInfoStatus !== 'done');

  const [countries] = useCatalog('Countries');
  const coutriesSelect = countries.map((country: any) => ({ name: country.value, value: country.key }));

  const { value, onInput, filterText, onFilter, labels, onDeleteLabel, setLabels } = useMultipleSelect(coutriesSelect.length);

  const {
    YES,
    NOT,
    COMPLEMENTARY_INFORMATION_TITLE,
    ENTER_ADDITIONAL_INFO_TO_RECORD,
    HAS_FOREIGN_CLIENT,
    BUSINESS_ACTIVITY,
    HAS_POINTS_OF_SALE,
    WHO_ARE_YOUR_MAIN_CLIENTS,
    WHO_ARE_YOUR_MAIN_PROVIDERS,
    HAS_SHAREHOLDING_IN_OTHER_COMPANIES,
    IS_YOUR_COMPANY_PART_OF_A_BUSINESS_GROUP,
    BUSINESS_GROUP_INFO,
    ADDRESS,
    REQUIRED_FIELDS,
    PRODUCT_OR_SERVICE_OFFERED,
    EMPLOYEES_NUMBER,
    MONEYIN_MONTHLY,
    ANNUAL_STATEMENT,
    ANNUAL_STATEMENT_HELP_DESCRIPTION,
    QUANTITY_POINTS_OF_SALE,
    LOCATION_STATE,
    DENOMINATION,
    COUNTRY_OF_CONSTITUTION,
    SOCIAL_OBJECT,
    PARTICIPATION_PERCENTAGE,
    PERCENTAGE_SHOULD_NOT_BE_MORE_THAN_ONE_HUNDRED,
    NAME_OR_SOCIAL_REASON,
    ADD_BUSINESS,
    NAME_COMPANY_PARTICIPATION,
    COMMERCIAL_BUSSINESS,
    FINISH,
    REQUIRED_FIELD,
    UPDATE,
    TITLE_UPDATE_CUSTOMER_INFO,
    DESCRIPTION_UPDATE_CUSTOMER_INFO,
    BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO,
    SAVE_PROGRESS
  } = useTranslator();

  
  const { formFields ,findFields} = useFormsFields();
  
  useEffect(()=>{
    findFields('QUICK_WIN_COMPLEMENTARY_INFORMATION');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const { DialogConfirm, confirm: showConfirm } = useConfirm({
    title: TITLE_UPDATE_CUSTOMER_INFO,
    message: DESCRIPTION_UPDATE_CUSTOMER_INFO,
    textConfirm: BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO
  });

  const handleClickSave = async () => {
    const confirm = await showConfirm();
    if (!confirm) {
      return;
    }
    formProps.saveFormOps();
  }

  const { isOpsAnalyst } = useUserRole();

  const { hasForeignClient
    , hasPointOfSale
    , mainClients
    , mainProviders
    , hasSharesInOtherCompany
    , isPartOfGroupCompany
    , otherCompanies
    , countriesForeignClient
    , quantityPointOfSale
    , locationState
    , groupCompanyInfo
    , groupCompanyAddress } = data;
  const currentPercentage = getPropByName('groupCompanyInfo.participationPercentage');

  const hasError = formProps.form.hasErrors;
  const [loadedInfo, setLoadedInfo] = useState(false);


  /** Radios para seleccionar si tiene clinetes extranjeros */
  const radiosForeignClient = useMemo(
    () => [
      {
        name: 'yes',
        text: YES,
        value: hasForeignClient,
      },
      {
        name: 'not',
        text: NOT,
        value: !hasForeignClient,
      },
    ],
    [hasForeignClient, YES, NOT]
  );

  /** Radios para seleccionar si tiene clinetes extranjeros */
  const radiosPointsOfSale = useMemo(
    () => [
      {
        name: 'yes',
        text: YES,
        value: hasPointOfSale,
      },
      {
        name: 'not',
        text: NOT,
        value: !hasPointOfSale,
      },
    ],
    [hasPointOfSale, YES, NOT]
  );

  /** Radios para seleccionar si tiene clinetes extranjeros */
  const radiosSharesInOtherCompany = useMemo(
    () => [
      {
        name: 'yes',
        text: YES,
        value: hasSharesInOtherCompany,
      },
      {
        name: 'not',
        text: NOT,
        value: !hasSharesInOtherCompany,
      },
    ],
    [hasSharesInOtherCompany, YES, NOT]
  );

  /** Radios para seleccionar si tiene clinetes extranjeros */
  const radiosPartOfGroupCompany = useMemo(
    () => [
      {
        name: 'yes',
        text: YES,
        value: isPartOfGroupCompany,
      },
      {
        name: 'not',
        text: NOT,
        value: !isPartOfGroupCompany,
      },
    ],
    [isPartOfGroupCompany, YES, NOT]
  );

  /** Definicion de FORMS */
  const businessActivityInputs: FormItemProps<string>[] = formFields('QUICK_WIN_COMPLEMENTARY_INFORMATION', [
    {
      placeholder: PRODUCT_OR_SERVICE_OFFERED,
      name: 'activityCompany.productOrServiceOffered',
      type: 'input',
      required: true,
      ...defaultValidations.servicesOfferedValidations()
    },
    {
      placeholder: EMPLOYEES_NUMBER,
      name: 'activityCompany.quantityEmployees',
      required: true,
      type: 'numberFormatInput',
      saveAs: 'number',
      maxLength: 5,
      validators: [
        validators.isEmpty,
      ],
    },
    {
      placeholder: MONEYIN_MONTHLY,
      name: 'activityCompany.moneyinMonthly',
      type: 'numberFormatInput',
      required: true,
      prefix: '$ ',
      decimalScale: 0,
      thousandSeparator: true,
      saveAs: 'number',
      validators: [validators.isEmpty,],
    },
    {
      placeholder: ANNUAL_STATEMENT,
      name: 'activityCompany.annualStatement',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
      help: {
        description: ANNUAL_STATEMENT_HELP_DESCRIPTION,
      },
    }
  ]);

  const pointOfSaleInputs: FormItemProps<string>[] = formFields('QUICK_WIN_COMPLEMENTARY_INFORMATION', [
    {
      placeholder: QUANTITY_POINTS_OF_SALE,
      name: 'quantityPointOfSale',
      type: 'numberFormatInput',
      saveAs: 'number',
      required: true,
      maxLength: 5,
      validators: [validators.isEmpty,],
    },
    {
      placeholder: LOCATION_STATE,
      name: 'locationState',
      required: true,
      type: 'input',
      pattern: 'specialTextPattern',
      maxLength: 80,
      validators: [validators.isEmpty,],
    }
  ]);

  const groupCompanyInfoInputs: FormItemProps<string>[] = formFields('QUICK_WIN_COMPLEMENTARY_INFORMATION', [
    {
      placeholder: DENOMINATION,
      name: 'groupCompanyInfo.denomination',
      type: 'input',
      required: true,
      pattern: 'specialTextPattern',
      maxLength: 100,
      validators: [validators.isEmpty,],
    },
    {
      placeholder: COUNTRY_OF_CONSTITUTION,
      name: 'groupCompanyInfo.country',
      type: 'select',
      required: true,
      items: coutriesSelect,
      search: true,
      validators: [validators.selectIsEmpty],
    },
    {
      placeholder: SOCIAL_OBJECT,
      name: 'groupCompanyInfo.socialObject',
      type: 'input',
      required: true,
      pattern: 'specialTextPattern',
      maxLength: 80,
      validators: [validators.isEmpty,],
    },
    {
      placeholder: PARTICIPATION_PERCENTAGE,
      name: 'groupCompanyInfo.participationPercentage',
      type: 'numberFormatInput',
      suffix: ' %',
      maxLength: 5,
      saveAs: 'number',
      required: true,
      error: currentPercentage > 100,
      errorMessage: PERCENTAGE_SHOULD_NOT_BE_MORE_THAN_ONE_HUNDRED,
      validators: [
        validators.isEmpty,
        (value: string) =>
          parseInt(value) > 100
            ? PERCENTAGE_SHOULD_NOT_BE_MORE_THAN_ONE_HUNDRED
            : undefined,
      ],
    }
  ]);

  /** Lista de clientes */
  const [lstClients, setLstClients] = useState([
    {
      required: true,
      placeHolder: NAME_OR_SOCIAL_REASON,
      error: false,
      errorMessage: REQUIRED_FIELD
    },
    {
      required: false,
      placeHolder: NAME_OR_SOCIAL_REASON,
    },
    {
      required: false,
      placeHolder: NAME_OR_SOCIAL_REASON,
    },
  ]
  );

  /** Lista de providers */
  const [lstProviders, setLstProviders] = useState([
    {
      required: true,
      placeHolder: NAME_OR_SOCIAL_REASON,
      error: false,
      errorMessage: REQUIRED_FIELD
    },
    {
      required: false,
      placeHolder: NAME_OR_SOCIAL_REASON,
    },
    {
      required: false,
      placeHolder: NAME_OR_SOCIAL_REASON,
    },
  ]);


  /** Lista de providers */
  const [lstOtherCompanies, setLstOtherCompanies] = useState([{
    company: [{
      name: 'name',
      value: '',
      required: true,
      placeHolder: NAME_COMPANY_PARTICIPATION,
      error: false,
      errorMessage: REQUIRED_FIELD,

    },
    {
      name: 'commercialBusiness',
      value: '',
      required: true,
      placeHolder: COMMERCIAL_BUSSINESS,
      error: false,
      errorMessage: REQUIRED_FIELD
    }
    ]
  }]);

  const companyAddress: FormItemProps<string>[] = formFields('QUICK_WIN_COMPLEMENTARY_INFORMATION', useAddressInputs({
    setFormData: formProps.form.setData,
    postalCode: formProps.form.data.groupCompanyAddress?.zip,
    propName: 'groupCompanyAddress',
    formData: formProps.form.data
  }));

  /** Acciones de los radios */
  const onSelectRadio = (selectedIndex: number, propertyName: string) => {
    setPropByName(propertyName, selectedIndex === 0);
  }

  /** Acciones en los inputs que no se crean para FormItems */
  const onInputMainClients = (index: number, value: any) => {
    const updateMainClients = [...mainClients];
    updateMainClients[index] = value.replace(specialTextPattern, '');
    setPropByName('mainClients', updateMainClients);

    const updateLstClients = [...lstClients];
    updateLstClients[index].error = index === 0 && value === '';
    setLstClients(updateLstClients);
  }

  const onBlurMainClients = () => {
    if (mainClients.length > 0 && (!mainClients[0] || mainClients[0] === '')) {
      const updateLstClients = [...lstClients];
      updateLstClients[0].error = true;
      setLstClients(updateLstClients);
    }
  }

  const onInputMainProviders = (index: number, value: any) => {
    const updateMainProviders = [...mainProviders];
    updateMainProviders[index] = value.replace(specialTextPattern, '');;
    setPropByName('mainProviders', updateMainProviders);

    const updateLstProviders = [...lstProviders];
    updateLstProviders[index].error = index === 0 && value === '';
    setLstProviders(updateLstProviders);
  }

  const onBlurMainProviders = () => {
    if (mainProviders.length > 0 && (!mainProviders[0] || mainProviders[0] === '')) {
      const updateLstProviders = [...lstProviders];
      updateLstProviders[0].error = true;
      setLstProviders(updateLstProviders);
    }
  }

  const onInputOtherCompanies = (index: number, propertyName: string, value: any) => {
    const indexCompany = lstOtherCompanies[index].company.findIndex(item => item.name === propertyName);
    const updateLstOtherCompanies = [...lstOtherCompanies];
    updateLstOtherCompanies[index].company[indexCompany].value = value;
    updateLstOtherCompanies[index].company[indexCompany].error = value.trim() === '';
    updateLstOtherCompanies[index].company[indexCompany].errorMessage = value.trim() === '' ? REQUIRED_FIELD : '';
    setLstOtherCompanies(updateLstOtherCompanies);

    const updateOtherCompanies = [...otherCompanies];
    updateOtherCompanies[index][propertyName] = value;
    setPropByName('otherCompanies', updateOtherCompanies);
  }

  const onBlurCompanies = (index: number, propertyName: string) => {
    const indexCompany = lstOtherCompanies[index].company.findIndex(item => item.name === propertyName);
    const value = lstOtherCompanies[index].company[indexCompany].value;
    if (!value || value.trim() === '') {
      const updateLstOtherCompanies = [...lstOtherCompanies];
      updateLstOtherCompanies[index].company[indexCompany].error = true;
      updateLstOtherCompanies[index].company[indexCompany].errorMessage = REQUIRED_FIELD;
      setLstOtherCompanies(updateLstOtherCompanies);
    }
  }

  /** Acciones sobre las companias donde tiene acciones */
  const onAddShareholdingOtherCompany = () => {
    let companies = [];
    if (otherCompanies) {
      companies = [...otherCompanies];
      companies.push({
        name: '',
        commercialBusiness: ''
      })
    } else {
      companies = [{
        name: '',
        commercialBusiness: ''
      }]
    }

    setPropByName('otherCompanies', companies);
  }

  const onDeleteOtherCompany = (index: number) => {
    otherCompanies.splice(index, 1);
    setPropByName('otherCompanies', otherCompanies);
  }

  const onBack = () => {
    history.push('complementary-info');
  }

  // Carga las empresas donde tiene acciones
  useEffect(() => {
    if (otherCompanies) {
      const lstCompanies = otherCompanies.map((item: any) => {
        const company: any[] = [{
          name: 'name',
          value: item.name,
          required: true,
          placeHolder: NAME_COMPANY_PARTICIPATION,
        },
        {
          name: 'commercialBusiness',
          value: item.commercialBusiness,
          required: true,
          placeHolder: COMMERCIAL_BUSSINESS,
        }];

        return {
          company: company
        }
      });

      setLstOtherCompanies(lstCompanies);
    }
  }, [otherCompanies, setLstOtherCompanies, NAME_COMPANY_PARTICIPATION, COMMERCIAL_BUSSINESS])

  // Carga los label de los paises con actividad desde el form
  useEffect(() => {
    if (!loadedInfo && countriesForeignClient) {
      setLabels(countriesForeignClient)
      setLoadedInfo(true)
    }
  }, [loadedInfo, setLabels, setLoadedInfo, countriesForeignClient])

  // Settear el valor de los paises seleccionados en el form
  useEffect(() => {
    if (labels && labels.length > 0) {
      setPropByName('countriesForeignClient', labels);
    } else if (hasForeignClient && countriesForeignClient && countriesForeignClient.length > 0 && labels && labels.length === 0) {
      setPropByName('countriesForeignClient', labels);
    }
  }, [labels, setPropByName, countriesForeignClient, hasForeignClient])

  // Limpieza de propiedades para radio grupo empresarial
  useEffect(() => {
    if (!isPartOfGroupCompany && (groupCompanyInfo || groupCompanyAddress)) {
      deleteMultiplePropsByName(['groupCompanyInfo', 'groupCompanyAddress']);
    } else if (isPartOfGroupCompany && !groupCompanyAddress) {
      setData({ ...data, 'groupCompanyAddress': groupCompanyAddressForm, 'groupCompanyInfo': groupCompanyInfoForm })
    }
  }, [isPartOfGroupCompany, setPropByName, deleteMultiplePropsByName, groupCompanyInfo, groupCompanyAddress, data, setData])

  // Limpieza de propiedades para radio participacion accionaria
  useEffect(() => {
    if (!hasSharesInOtherCompany && otherCompanies) {
      deleteMultiplePropsByName(['otherCompanies']);
    } else if (hasSharesInOtherCompany && !otherCompanies) {
      const otherCompanies = [{
        name: '',
        commercialBusiness: ''
      }]
      setPropByName('otherCompanies', otherCompanies);
    }
  }, [hasSharesInOtherCompany, deleteMultiplePropsByName, setPropByName, otherCompanies])

  // Limpieza de propiedades para radio puntos de venta
  useEffect(() => {
    if (!hasPointOfSale && (quantityPointOfSale !== undefined || locationState !== undefined)) {
      deleteMultiplePropsByName(['quantityPointOfSale', 'locationState']);
    } else if (hasPointOfSale && quantityPointOfSale === undefined && locationState === undefined) {
      setData({ ...data, quantityPointOfSale: undefined, locationState: '' });
    }
  }, [hasPointOfSale, deleteMultiplePropsByName, quantityPointOfSale, locationState, data, setData])

  // Limpieza de propiedades para radio actividad en el extranjero
  useEffect(() => {
    if (!hasForeignClient && countriesForeignClient) {
      setLabels([])
      deleteMultiplePropsByName(['countriesForeignClient']);
    }
  }, [hasForeignClient, deleteMultiplePropsByName, setLabels, countriesForeignClient])


  // Validar form
  useEffect(() => {
    const countriesForeignClientInvalid = hasForeignClient && (countriesForeignClient === undefined || countriesForeignClient.length === 0);
    const mainsClientsInvalid = mainClients.length === 0 || mainClients[0].trim().length === 0;
    const mainsProvidersInvalid = mainProviders.length === 0 || mainProviders[0].trim().length === 0;
    const companiesInvalid = otherCompanies !== undefined && otherCompanies.filter((item: any) => item.name === '' || item.commercialBusiness === '').length > 0;

    setFormInvalid(countriesForeignClientInvalid || mainsClientsInvalid || mainsProvidersInvalid || companiesInvalid || hasError)
  }, [hasError, hasForeignClient, countriesForeignClient, mainClients, mainProviders, otherCompanies])

  if (formProps.saving || formProps.movingForward || formProps.loadingForm)
    return (
      <OnBoardingLoader
        isLoading={
          formProps.saving || formProps.movingForward || formProps.loadingForm
        }
      />
    );

  return (
    <Form {...formProps.form} onSubmit={isOpsAnalyst ? handleClickSave : formProps.moveForward}>
      <FlexGrid
        container
        fullHeight
        fullWidth
        direction='column'
        spacing={3}
        style={{ paddingBottom: '100px' }}
      >
        <FlexGrid item container direction='column' spacing={1}>
          <FlexGrid item>
            <Typography variant='h2'>{COMPLEMENTARY_INFORMATION_TITLE}</Typography>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='small'>{ENTER_ADDITIONAL_INFO_TO_RECORD}</Typography>
          </FlexGrid>
        </FlexGrid>

        <FlexGrid item>
          <Typography variant='smallTitle' color='neutral' bold>
            {HAS_FOREIGN_CLIENT}
          </Typography>
        </FlexGrid>

        <FlexGrid item container spacing={2}>
          {radiosForeignClient.map(({ value, text }, index) => (
            <FlexGrid item key={index}>
              <RadialButton
                value={value}
                onInput={() => onSelectRadio(index, 'hasForeignClient')}
                text={text}
              />
            </FlexGrid>
          ))}
        </FlexGrid>
        {
          hasForeignClient &&
          <FlexGrid item>
            <MultipleSelect
              value={value}
              onInput={onInput}
              onFilter={onFilter}
              filterText={filterText}
              items={coutriesSelect}
              labels={labels}
              onDeleteLabel={onDeleteLabel}
              placeholder='Indica en que país(es)'
              maxItemsLimit={coutriesSelect.length}
              required
            />
          </FlexGrid>
        }

        <FlexGrid item>
          <Typography variant='smallTitle' color='neutral' bold>
            {BUSINESS_ACTIVITY}
          </Typography>
        </FlexGrid>
        <FlexGrid item>
          <Typography variant='small' color='neutral' bold>
            <span style={{ color: theme.colors.required }}>*</span>{' '}
            {REQUIRED_FIELDS}
          </Typography>
        </FlexGrid>
        <FlexGrid item container fullWidth>
          <FormItems inputs={businessActivityInputs} />
        </FlexGrid>


        <FlexGrid item>
          <Typography variant='smallTitle' color='neutral' bold>
            {HAS_POINTS_OF_SALE}
          </Typography>
        </FlexGrid>
        <FlexGrid item container spacing={2}>
          {radiosPointsOfSale.map(({ value, text }, index) => (
            <FlexGrid item key={index}>
              <RadialButton
                value={value}
                onInput={() => onSelectRadio(index, 'hasPointOfSale')}
                text={text}
              />
            </FlexGrid>
          ))}
        </FlexGrid>
        {
          hasPointOfSale &&
          <FlexGrid item container fullWidth>
            <FormItems inputs={pointOfSaleInputs} />
          </FlexGrid>
        }

        <FlexGrid item>
          <Typography variant='smallTitle' color='neutral' bold>
            {WHO_ARE_YOUR_MAIN_CLIENTS}
          </Typography>
        </FlexGrid>
        <FlexGrid item container wrap fullWidth spacing={4}>
          {lstClients.map(({ placeHolder, required, error, errorMessage }, index) => (
            <FlexGrid
              item
              style={{
                width: 'calc(50% - 16px)'
              }}
            >
              <TextField placeholder={placeHolder} maxLength={70} required={required} value={mainClients[index]} error={error} errorMessage={errorMessage}
                onInput={newValue => onInputMainClients(index, newValue)} onBlur={onBlurMainClients} />
            </FlexGrid>
          ))}
        </FlexGrid>


        <FlexGrid item>
          <Typography variant='smallTitle' color='neutral' bold>
            {WHO_ARE_YOUR_MAIN_PROVIDERS}
          </Typography>
        </FlexGrid>
        <FlexGrid item container wrap fullWidth spacing={4}>
          {lstProviders.map(({ placeHolder, required, error, errorMessage }, index) => (
            <FlexGrid
              item
              style={{
                width: 'calc(50% - 16px)'
              }}
            >
              <TextField placeholder={placeHolder} maxLength={70} required={required} value={mainProviders[index]} error={error} errorMessage={errorMessage}
                onInput={newValue => onInputMainProviders(index, newValue)} onBlur={onBlurMainProviders} />
            </FlexGrid>
          ))}
        </FlexGrid>

        <FlexGrid item>
          <Typography variant='smallTitle' color='neutral' bold>
            {HAS_SHAREHOLDING_IN_OTHER_COMPANIES}
          </Typography>
        </FlexGrid>
        <FlexGrid item container spacing={2}>
          {radiosSharesInOtherCompany.map(({ value, text }, index) => (
            <FlexGrid item key={index}>
              <RadialButton
                value={value}
                onInput={() => onSelectRadio(index, 'hasSharesInOtherCompany')}
                text={text}
              />
            </FlexGrid>
          ))}
        </FlexGrid>
        {
          hasSharesInOtherCompany &&
          <Fragment>
            <FlexGrid item container direction='column' spacing={2}>
              <FlexGrid item>
                <ActionCard
                  title={ADD_BUSINESS}
                  onClick={onAddShareholdingOtherCompany}
                  link
                  style={{ height: '80px' }}
                />
              </FlexGrid>
            </FlexGrid>
            {lstOtherCompanies.map(({ company }, index) => (
              <ListItemCard onDelete={() => onDeleteOtherCompany(index)}>
                <FlexGrid item container wrap fullWidth spacing={4}>
                  {company.map(({ placeHolder, value, error, errorMessage, required, name }) => (
                    <FlexGrid
                      item
                      style={{ width: 'calc(50% - 16px)' }}
                    >
                      <TextField placeholder={placeHolder} required={required} value={value} error={error} errorMessage={errorMessage}
                        onInput={newValue => onInputOtherCompanies(index, name, newValue)} onBlur={() => onBlurCompanies(index, name)} />
                    </FlexGrid>
                  ))}
                </FlexGrid>
              </ListItemCard>
            ))}
          </Fragment>
        }

        <FlexGrid item>
          <Typography variant='smallTitle' color='neutral' bold>
            {IS_YOUR_COMPANY_PART_OF_A_BUSINESS_GROUP}
          </Typography>
        </FlexGrid>
        <FlexGrid item container spacing={2}>
          {radiosPartOfGroupCompany.map(({ value, text }, index) => (
            <FlexGrid item key={index}>
              <RadialButton
                value={value}
                onInput={() => onSelectRadio(index, 'isPartOfGroupCompany')}
                text={text}
              />
            </FlexGrid>
          ))}
        </FlexGrid>
        {
          isPartOfGroupCompany &&
          <Fragment>
            <FlexGrid item>
              <Typography variant='smallTitle' color='neutral' bold>
                {BUSINESS_GROUP_INFO}
              </Typography>
            </FlexGrid>
            <FlexGrid item container fullWidth>
              <FormItems inputs={groupCompanyInfoInputs} />
            </FlexGrid>

            <FlexGrid item>
              <Typography variant='smallTitle' color='neutral' bold>
                {ADDRESS}
              </Typography>
            </FlexGrid>
            <FlexGrid item container fullWidth>
              <FormItems inputs={companyAddress} />
            </FlexGrid>
          </Fragment>
        }
      </FlexGrid>

      <DialogConfirm />

      <BottomActions
        backButton
        saveProgressButton={pmStatus.additionalInfoStatus !== 'done' && !isOpsAnalyst}
        saveButtonText={SAVE_PROGRESS}
        continueButton
        continueButtonText={isOpsAnalyst ? UPDATE : FINISH}
        disableContinueButton={formInvalid}
        onSaveProgress={formProps.saveForm}
        onContinue={isOpsAnalyst ? handleClickSave : formProps.moveForward}
        onBack={onBack}
      />
    </Form>
  );
};
