import { FormContainer } from '../../../../../../../../components-quick-win/form-container';
import { AdditionalInfoForm } from '../../../../../../../../components-quick-win/additional-info-form';

export const AdditionalInfo = () => {
  return (
    <FormContainer>
      <AdditionalInfoForm />
    </FormContainer>
  );
};
