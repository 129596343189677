import {
  Button,
  FlexGrid,
  Form,
  FormItemProps,
  Typography,
  useForm,
  useThemeProvider,
  useTextfield,
} from '@albo-ui/core';
import { useCatalog } from '../../hooks/use-catalog';
import { useTranslator } from '../../providers/translator-provider';
import { useDefaultValidations } from '../../utils/default-validations';
import { useValidators } from '../../utils/validators';
import { FormItems } from '../form-items';
import { FaCheck } from 'react-icons/fa';
import ReactLoading from 'react-loading';
import ReCAPTCHA from 'react-google-recaptcha';
import { HiddenInput } from './styled';
import {
  OnboardingVersion,
  SubmitMoreInfoParams,
  useSubmitMoreInfo,
} from '../../providers/submit-more-info';
import useLocalStorage from '../../hooks/use-local-storage';
import { useState } from 'react';

export type SignUpFormData = {
  typeDesc?: string;
} & SubmitMoreInfoParams;

export const LandingContactForm = () => {
  const formProps = useForm<
    | SignUpFormData
    | {
        typeDesc: string;
        general: {
          email: string;
          phone: { phoneCode: string; phoneCodeDesc: string, phoneCountry: string };
        };
        onboardingVersion: OnboardingVersion;
      }
  >({
    general: {
      email: '',
      phone: { phoneCode: '+52', phoneCountry:'484', phoneCodeDesc: 'Mexico' },
    },
    typeDesc: '',
    onboardingVersion: 'QUICK_WIN',
  });
  const validators = useValidators();
  const { submitMoreInfo, eventName, loading } = useSubmitMoreInfo();
  const defaultValidations = useDefaultValidations();
  const [numberOfEmployees] = useCatalog('NumberOfEmployees');
  const { theme } = useThemeProvider();
  const hiddenInputProps = useTextfield();
  const [captchaNeeded, setCaptchaNeeded] = useState(false);
  const [captchaChecked, setCaptchaChecked] = useState<string | null>(null);
  const [currentEmail, setCurrentEmail] = useLocalStorage<undefined | string>(
    'currentEmail',
    undefined
  );
  const {
    NAME,
    PATERNAL_LASTNAME,
    MATERNAL_LASTNAME,
    PERSON_TYPE,
    PHYSICAL_PERSON_WITH_BUSINESS_ACTIVITY,
    MORAL_PERSON,
    LANDING_CONTACT_FORM_TEXT,
    SEND,
    EMAIL,
    BUSINESS_NAME,
    INTERESTED_IN_ALBO_FOR,
    SALARIES,
    BONS,
    COMMISSIONS,
    PAYMENTS_TO_SUPPLIERS,
    OTHERS,
    SIZE_OF_YOUR_TEAM,
    THANKS_WE_SENT_YOU_THE_DETAILS_TO_YOUR_EMAIL,
    REGISTER_YOUR_DATA_TO_RECEIVE_MORE_INFO,
    DISCLAIMER,
  } = useTranslator();

  const selectItems = [
    {
      name: PHYSICAL_PERSON_WITH_BUSINESS_ACTIVITY,
      value: 'PFAE',
    },
    {
      name: MORAL_PERSON,
      value: 'PM',
    },
  ];

  const reasonForBeingInterestedInAlboSelect = [
    {
      name: SALARIES,
      value: 'salaries',
    },
    {
      name: BONS,
      value: 'bons',
    },
    {
      name: COMMISSIONS,
      value: 'commissions',
    },
    {
      name: PAYMENTS_TO_SUPPLIERS,
      value: 'paymentsToSupliers',
    },
    {
      name: OTHERS,
      value: 'others',
    },
  ];

  const numberOfEmployeesSelect = numberOfEmployees
    .map((number: any) => ({
      value: number.key,
      name: number.value,
    }))
    .sort((a: any, b: any) => a.value - b.value);

  const inputs: FormItemProps<string>[] = [
    {
      placeholder: NAME,
      name: 'general.gname',
      type: 'input',
      required: true,
      ...defaultValidations.nameValidations(),
    },
    {
      placeholder: PATERNAL_LASTNAME,
      name: 'general.lname',
      type: 'input',
      required: true,
      ...defaultValidations.paternalLastNameValidations(),
    },
    {
      placeholder: MATERNAL_LASTNAME,
      name: 'general.slname',
      type: 'input',
      ...defaultValidations.maternalLastNameValidations(),
    },
    {
      name: 'general.phone',
      type: 'phone',
      required: true,
      ...defaultValidations.phoneNumberValidations(),
    },
    {
      placeholder: EMAIL,
      name: 'general.email',
      type: 'input',
      required: true,
      inputType: 'email',
      ...defaultValidations.emailValidations(),
    },
    {
      placeholder: BUSINESS_NAME,
      name: 'comercialName',
      type: 'input',
      required: true,
      validators: [validators.isEmpty],
    },
    {
      placeholder: PERSON_TYPE,
      name: 'type',
      type: 'select',
      items: selectItems,
      inputSize: '100%',
      required: true,
      validators: [validators.selectIsEmpty],
    },
    {
      placeholder: INTERESTED_IN_ALBO_FOR,
      name: 'reasonForBeingInterestedInAlbo',
      type: 'select',
      items: reasonForBeingInterestedInAlboSelect,
      required: true,
      validators: [validators.selectIsEmpty],
    },
    {
      placeholder: SIZE_OF_YOUR_TEAM,
      name: 'employeesQuantity',
      type: 'select',
      items: numberOfEmployeesSelect,
      required: true,
      validators: [validators.selectIsEmpty],
    },
  ];

  const onClickButton = async () => {
    const hasErrors = formProps.hasErrors;
    const hiddenInputValue = hiddenInputProps.value;
    const captchStatus =
      currentEmail === formProps.data.general.email ? captchaChecked : true;

    if (hasErrors && !hiddenInputValue) {
      formProps.displayErrors();
    }

    if (currentEmail === formProps.data.general.email) {
      setCaptchaNeeded(true);
    }

    if (!hasErrors && !hiddenInputValue && captchStatus) {
      delete formProps.data.typeDesc;
      setCurrentEmail(formProps.data.general.email);

      await submitMoreInfo(formProps.data as any)
        .then(() => {
          setCaptchaChecked(null);
          setCaptchaNeeded(false);
        })
        .catch(() => {
          setCurrentEmail(undefined);
        });
    }
  };

  const onCaptchaChange = (value: string | null) => {
    setCaptchaChecked(value);
  };

  return (
    <FlexGrid
      container
      fullHeight
      fullWidth
      direction='column'
      alignItems='center'
      spacing={4}
      style={{ position: 'relative' }}
    >
      <FlexGrid
        item
        container
        direction='column'
        spacing={1}
        style={{ textAlign: 'center' }}
      >
        <FlexGrid item>
          <Typography variant='h2'>
            {REGISTER_YOUR_DATA_TO_RECEIVE_MORE_INFO}
          </Typography>
        </FlexGrid>
        <FlexGrid item>
          <Typography variant='paragraph'>
            {LANDING_CONTACT_FORM_TEXT}
          </Typography>
        </FlexGrid>
      </FlexGrid>
      <FlexGrid item container fullWidth>
        <Form {...formProps} onSubmit={onClickButton}>
          <FlexGrid item container fullWidth>
            <FormItems inputs={inputs} />
          </FlexGrid>
        </Form>
      </FlexGrid>

      {captchaNeeded && (
        <FlexGrid item>
          <ReCAPTCHA
            sitekey='6LejgOkcAAAAAB7xjFx43ZxL6lmu7d7Ta_Wfvyw0'
            key='6LejgOkcAAAAAISfuadRutnajmJiBh46KVw2rbHy'
            onChange={onCaptchaChange}
          />
        </FlexGrid>
      )}

      {eventName !== 'MoreInfoSubmited' && !loading && (
        <FlexGrid item container fullWidth direction='column' spacing={2}>
          <FlexGrid item fullWidth>
            <Button onClick={onClickButton} size='fullWidth'>
              {SEND}
            </Button>
          </FlexGrid>
          <FlexGrid item style={{ marginTop: theme.spacesPx[7] }}>
            <Typography variant='small' color='charcoal'>
              {DISCLAIMER}
            </Typography>
          </FlexGrid>
        </FlexGrid>
      )}

      {eventName === 'MoreInfoSubmited' && (
        <FlexGrid
          item
          container
          direction='column'
          spacing={2}
          justify='center'
          alignItems='center'
        >
          <FlexGrid item>
            <FaCheck color={theme.colors.oliva} size='20px' />
          </FlexGrid>
          <FlexGrid item style={{ maxWidth: '342px', textAlign: 'center' }}>
            <Typography variant='paragraph' bold>
              {THANKS_WE_SENT_YOU_THE_DETAILS_TO_YOUR_EMAIL}
            </Typography>
          </FlexGrid>
        </FlexGrid>
      )}

      {loading && (
        <FlexGrid
          item
          container
          fullWidth
          fullHeight
          alignItems='center'
          justify='center'
        >
          <ReactLoading
            type='spin'
            color={theme.colors.primary}
            height='30px'
            width='30px'
          />
        </FlexGrid>
      )}

      <HiddenInput
        tabIndex={-1}
        {...hiddenInputProps}
        containerStyle={{ visibility: 'hidden', position: 'absolute' }}
      />
    </FlexGrid>
  );
};
