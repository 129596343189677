import {  
  FlexGrid,
  Typography,
  ActionCard,
  useDrawer,
  Skeleton,
  useDialog,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { BottomActions } from '../bottom-actions';
import { useAuth } from '../../providers/auth-provider';
import { OnBoardingLoader } from '../on-boarding-loader';
import { AddLegalRepresentativeDrawer } from './add-legal-representative-drawer';
import { useQuery } from '../../hooks/user-query';
import { useApi } from '../../providers/api-provider';
import { useOnHomeCBS } from '../../providers/on-home-cbs-provider';
import { DeletePromptDialog } from '../delete-prompt-dialog';
import { useSnackbarNotifier } from '../../hooks/use-snackbar-notifier';
import { InfoDialog } from '../info-dialog';
import { useUserRole } from '../../hooks/use-user-role';

export const LegalRepresentativeForm = () => {
  const [uuidUpdate, setUuidUpdate] = useState<undefined | string>(undefined);
  const [uuidDelete, setUuidDelete] = useState<undefined | string>(undefined);
  const [hasLead, setHasLead] = useState(false);
  const [loading, setLoading] = useState(false);
  const [api] = useApi();
  const history = useHistory();
  const drawerProps = useDrawer();
  const homeCBS = useOnHomeCBS();
  homeCBS.setShowDialog(false);
  const { leadData } = useAuth();
  const deleteDialogProps = useDialog();
  const [show,close] = useSnackbarNotifier()
  const {
    open: infoDialogIsOpen,
    openDialog: openInfoDialog,
    onClose: closeInfoDialog,
  } = useDialog();
  const { isOpsAnalyst } = useUserRole();
  const legalRepresentativesUuids = leadData.onboardingForms
    .filter(
      ({ formType }: any) =>
        formType === 'QUICK_WIN_LEGAL_REPRESENTATIVE'
    )
    .map(({ uuid }: any) => uuid);

  const [queryFormData, loadingForm, reloadForm] = useQuery(
    {
      name: 'get-onboarding-forms-by-uuids',
      params: {
        onboardingFormsUuids: legalRepresentativesUuids,
      },
      options: {
        manualLoad: legalRepresentativesUuids.length === 0,
      },
    },
    [leadData.onboardingForms]
  );

  const legalRepresentatives:
    | {
        name: string;
        uuid: string;
        isLead: boolean;
        status: string;
      }[]
    | undefined = queryFormData
    ? queryFormData.onboardingForms.map((data: any) => ({
        name: `${data.formData.general.gname} ${data.formData.general.lname} ${
          data.formData.general.slname ? data.formData.general.slname : ''
        }`,
        uuid: data.uuid,
        isLead: data.formData.isLead,
        status: data.isCompleted ? 'done' : 'pending',
      }))
    : undefined;

  const {
    ADD_LEGAL_REPRESENTATIVE,
    LEGAL_REPRESENTATIVE,
    LEGAL_REPRESENTATIVE_DESCRIPTION,
    LEGAL_REPRESENTATIVE_DELETE_PROMPT_DIALOG_TEXT,
    SUCCESS_SAVING_LEGAL_REPRESENTATIVE,SUCCESS_UPLOADING_INFO,
    YOU_MUST_HAVE_AT_LEAST_ONE_LEGAL_REPRESENTATIVE,
    YOU_MUST_HAVE_AT_LEAST_ONE_LEGAL_REPRESENTATIVE_DESCRIPTION
  } = useTranslator();

  const onAddAditionalAttorneyClick = () => {
    drawerProps.openDrawer();
    setUuidUpdate(undefined);
    close('')
  };

  async function onDeleteLegalRepresentative(uuid: string) {
    setUuidDelete(uuid);
    deleteDialogProps.openDialog();
  }

  async function deleteLegalRepresentative() {
    setLoading(true);
    await api.callService('delete-onboarding-form', {
      uuid: uuidDelete,
    });
    reloadForm();
    deleteDialogProps.onClose();
    setLoading(false);
  }

  function onDialogClose() {
    setUuidDelete(undefined);
    deleteDialogProps.onClose();
  }

  function onOpenSnackBar(eventName: string) {
    if (eventName === 'OnboardingFormUpdated') {
      show(SUCCESS_UPLOADING_INFO)
    }
    if(eventName === 'OnboardingFormCreated'){
      show(SUCCESS_SAVING_LEGAL_REPRESENTATIVE)
    }
  }

  async function onUpdateLegalRepresentative(uuid: string) {
    setUuidUpdate(uuid);
    drawerProps.openDrawer();
    close('')
  }

  function onBack() {
    history.push('company-info');
  }

  function onContinue() {
    if (!legalRepresentatives || legalRepresentatives.length === 0) {
      openInfoDialog()
    } else {
      if(leadData.interactionStatus === 'pending-user-approval' || isOpsAnalyst){
        history.push('/onboarding/home/menu')
      }else{
        history.push('shareholders');
      }
    }
  }

  useEffect(() => {
    const lead = legalRepresentatives?.find(({ isLead }) => isLead);
    if (legalRepresentatives && lead) setHasLead(true);
    else setHasLead(false);
  }, [legalRepresentatives, setHasLead]);

  if (
    loadingForm ||
    loading
  )
    return (
      <OnBoardingLoader
        isLoading={
          loadingForm ||
          loading
        }
      />
    );

  return (
    <>
      <DeletePromptDialog
        open={deleteDialogProps.open}
        onConfirm={deleteLegalRepresentative}
        onClose={onDialogClose}
        description = {LEGAL_REPRESENTATIVE_DELETE_PROMPT_DIALOG_TEXT}
      />

      <InfoDialog
        open={infoDialogIsOpen}
        title={YOU_MUST_HAVE_AT_LEAST_ONE_LEGAL_REPRESENTATIVE}
        description={YOU_MUST_HAVE_AT_LEAST_ONE_LEGAL_REPRESENTATIVE_DESCRIPTION}
        onClose={closeInfoDialog}
      />

      {drawerProps.open && (
        <AddLegalRepresentativeDrawer
          {...drawerProps}
          position='right'
          uuidForm={uuidUpdate}
          reloadForm={reloadForm}
          hasLead={hasLead}
          openSnackbar={onOpenSnackBar}
        />
      )}

      <FlexGrid
        container
        fullHeight
        fullWidth
        direction='column'
        spacing={3}
        style={{ marginBottom: '100px' }}
      >
        <FlexGrid item container direction='column' spacing={1}>
          <FlexGrid item>
            <Typography variant='h2'>{LEGAL_REPRESENTATIVE}</Typography>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='small'>
              {LEGAL_REPRESENTATIVE_DESCRIPTION}
            </Typography>
          </FlexGrid>
        </FlexGrid>
        <FlexGrid item container direction='column' spacing={2}>
          <FlexGrid item>
            <ActionCard
              title={ADD_LEGAL_REPRESENTATIVE}
              onClick={onAddAditionalAttorneyClick}
              link
              style={{ height: '80px' }}
            />
          </FlexGrid>
          {legalRepresentatives &&
            legalRepresentatives.map(({ name, uuid, status }, index) => (
              <FlexGrid item>
                <ActionCard
                  key={index}
                  title={name}
                  style={{ height: '80px' }}
                  status={status === 'pending' ? 'pending' : 'default'}
                  onDelete={() => onDeleteLegalRepresentative(uuid)}
                  onUpdate={() => onUpdateLegalRepresentative(uuid)}
                />
              </FlexGrid>
            ))}
          {loadingForm &&
            legalRepresentativesUuids.map(() => (
              <Skeleton width='100%' height='80px' />
            ))}
        </FlexGrid>
      </FlexGrid>
      <BottomActions
        backButton
        continueButton
        onBack={onBack}
        onContinue={onContinue}
      />
    </>
  );
};
