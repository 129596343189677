import { ReactNode, useCallback } from 'react';
import { TopBar, DashboardContainer, useThemeProvider } from '@albo-ui/core';
import { LeftSide } from './left-side';
import { RightSide } from './right-side';
import { useAuth } from '../../../providers/auth-provider';

export type LayoutProps = {
  children: ReactNode;
};

export const Layout = ({ children }: LayoutProps) => {
  const { theme } = useThemeProvider();
  const { user, logout } = useAuth();

  const onLogout = useCallback(() => {
    logout();
  }, [logout]);

  return (
    <>
      <TopBar
        leftSide={<LeftSide />}
        rightSide={<RightSide name={user?.name} onLogout={onLogout} />}
        style={{ paddingRight: theme.spacesPx[5] }}
      />
      <DashboardContainer>{children}</DashboardContainer>
    </>
  );
};
