import {
  Button,
  Card,
  FlexGrid,
  MenuItem,
  Typography,
  useThemeProvider,
} from '@albo-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { usePmStatusQuickWinAdditionalInfo } from '../../hooks/use-pm-status-quick-win-additional-info';
import { useApi } from '../../providers/api-provider';
import { useAuth } from '../../providers/auth-provider';
import { useTranslator } from '../../providers/translator-provider';
import { RISK_LEVEL_HIGH } from '../../utils/constants';
import { OnBoardingLoader } from '../on-boarding-loader';
import { GetMenuItems } from './const';
import { useSimpleAlertNotification } from '../../providers/simple-alert-provider';

export const OnBoardingAdditionalMenuPM = () => {
  const { items } = GetMenuItems();
  const { theme } = useThemeProvider();
  const [api] = useApi();
  const history = useHistory();
  const { ON_BOARDING_ADDITIONAL_MENU_TITLE, ON_BOARDING_ADDITIONAL_MENU_TEXT, SEND_VALIDATION,HELP_TEXT_ERROR_SEND_TO_VALIDATION } =
    useTranslator();
  const pmStatus = usePmStatusQuickWinAdditionalInfo();
  const {
    leadData: { uuid, riskLevel },
  } = useAuth();
  const [loading, setloading] = useState(false);
  const { reloadProfile, reloadingProfile } = useAuth();
  const {show:showAlert} =useSimpleAlertNotification();
  function onCardClick(url: string) {
    history.push(`record-extra-info/${url}`);
  }

  useEffect(() => {
    reloadProfile();
  }, [reloadProfile]);
  
  async function onSendValidationClick() {
    setloading(true);
    try {
      const resultData = await api.callService('send-to-validation', {
        leadUuid: uuid,
        type: 'validation'
      });
      if(resultData.eventName==='NotSentToValidation') {
        throw new Error('NotSentToValidation');
      }
      reloadProfile();

    } catch (error) {
      console.log(error);
      showAlert(HELP_TEXT_ERROR_SEND_TO_VALIDATION, 'error')
    }finally {
      setloading(false);
    }
   
  }

  if (loading || reloadingProfile)
    return <OnBoardingLoader isLoading={loading || reloadingProfile} />;

  return (
    <FlexGrid
      container
      fullHeight
      fullWidth
      direction='column'
      alignItems='center'
      padding={4}
      spacing={4}
      style={{ overflowY: 'auto', padding: 0 }}
    >
      <FlexGrid item container direction='column' style={{ maxWidth: '633px' }}>
        <FlexGrid
          item
          container
          direction='column'
          spacing={1}
          style={{ paddingTop: theme.spacesPx[4] }}
        >
          <FlexGrid item>
            <Typography variant='smallTitle'>
              {ON_BOARDING_ADDITIONAL_MENU_TITLE}
            </Typography>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='small'>{ON_BOARDING_ADDITIONAL_MENU_TEXT}</Typography>
          </FlexGrid>
        </FlexGrid>
      </FlexGrid>
      <FlexGrid item container direction='column'>
        <FlexGrid item container direction='column' spacing={2}>
          {items.map(({ icon, title, description, url, status }, index) => (
            <FlexGrid item key={index}>
              <MenuItem
                key={index}
                title={title}
                description={description}
                icon={icon}
                status={status as keyof typeof theme.statusCardColors}
                onClick={() => onCardClick(url)}
              />
            </FlexGrid>
          ))}
        </FlexGrid>
      </FlexGrid>
      <FlexGrid item fullWidth style={{ marginTop: 'auto' }}>
        <Card
          fullWidth
          style={{ borderColor: theme.colors.manatee, borderRadius: 0 }}
          alignItems='center'
          justifyContent='flex-end'
        >
          <Button
            onClick={onSendValidationClick}
            size='large'
            disabled={riskLevel === RISK_LEVEL_HIGH ? !pmStatus.readyToSendExtraInfo : !pmStatus.readyToSend}
          >
            {SEND_VALIDATION}
          </Button>
        </Card>
      </FlexGrid>
    </FlexGrid>
  );
};
