import { useEffect } from "react"
import { InextMessageEmptyLead } from "../../components/inext-message-empty-lead"



export const LandingEmptyLead = () => {
    useEffect(() => {
        //intento cerrar la ventana
        window.close()
    }, [])
    return  <InextMessageEmptyLead/>
}