import { FileInput, FormItemProps, formContext, FileType } from '@albo-ui/core';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useApi } from '../../providers/api-provider';
import { v1 as genuuid } from 'uuid';
import { useState } from 'react';
import { useAuth } from '../../providers/auth-provider';
import { setImmutablePropByString, getPropByString } from './helper/helper';
import { useTranslator } from '../../providers/translator-provider';
export type FormFileInputProps<T> = {
  name: string;
  isLoading?: boolean;
  loadingText?: string;
  fileExtensions?: string[];
} & FormItemProps<T>;

export const FormFileInput = <T,>({
  name,
  required,
  placeholder,
  disabled,
  help,
  validators,
  fileExtensions
}: FormFileInputProps<T>) => {
  const form = useContext(formContext);
  const formContextValue = form;
  const formItemError = formContextValue?.errors[name];
  const [api] = useApi();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const { LOADING } = useTranslator();
  const [showFileError, setShowFileError] = useState(false);
  const { FILE_TYPE_NOT_VALID_TRY_AGAIN } = useTranslator();
  const value = useMemo(
    () => getPropByString(name, form?.data),
    [name, form?.data]
  );
  const upload = useCallback(
    async ([input]) => {
      if (input) {
        setLoading(true);
        const tempUuid = genuuid();
        const filePath = `temp/${tempUuid}`;
        const { data } = await api.callService(
          'generate-signed-upload-file-url',
          {
            leadUuid: user?.leadUuid,
            path: filePath,
          }
        );

        const url = data.signeduploadUrlData.data;
        const bucket = url.split('.')[0].split('//')[1];
        await api.uploadFile(url, input);
        form?.onInput((data: any) =>
          setImmutablePropByString(name, data, {
            filePath: `leads/${user?.leadUuid}/${filePath}`,
            bucket: bucket,
            filename: input.path,
          })
        );
        setShowFileError(false);
        setLoading(false);
      } else {
        setShowFileError(true);
      }
    },
    [api, form, name, user?.leadUuid]
  );
  const del = useCallback(() => {
    form?.onInput((current: any) => {
      const data = setImmutablePropByString(name, current, undefined);
      return data;
    });
  }, [name, form]);

  useEffect(() => {
    if (validators && required) {
      let errorFound: string | undefined;

      validators.forEach((validator) => {
        if (!errorFound) errorFound = validator(value ? value : '');
      });

      if (errorFound && errorFound !== formItemError?.errorMessage) {
        const nextErrors = {
          [name]: {
            ...formItemError,
            errorMessage: errorFound,
            isEmpty:
              value === undefined ||
              (value.trim ? value.trim() === '' : value === ''),
          },
        };
        formContextValue?.onError(nextErrors);
      } else if (
        !errorFound &&
        formItemError &&
        errorFound !== formItemError?.errorMessage
      ) {
        const nextErrors = {
          [name]: undefined,
        };
        formContextValue?.onError(nextErrors);
      }
    }
  }, [
    value,
    validators,
    formItemError,
    formContextValue?.onError,
    formContextValue,
    name,
    required,
  ]);

  return (
    <FileInput
      onDelete={del}
      placeholder={placeholder}
      required={required}
      error={formItemError?.showError || showFileError}
      errorMessage={
        formItemError?.errorMessage && !showFileError
          ? formItemError?.errorMessage
          : FILE_TYPE_NOT_VALID_TRY_AGAIN
      }
      disabled={disabled}
      help={help}
      isLoading={loading}
      loadingText={LOADING}
      onInput={upload}
      items={value ? [{ path: value?.filename } as FileType] : []}
      fileExtensions={fileExtensions}
    />
  );
};
