import { Button, FlexGrid, Typography } from '@albo-ui/core';
import { useHistory } from 'react-router';
import { BottomActions } from '../../../../../../../../components-quick-win/bottom-actions';
import { FormContainer } from '../../../../../../../../components-quick-win/form-container';
import { useTranslator } from '../../../../../../../../providers/translator-provider';

export const ShareHoldersInfo = () => {
  const history = useHistory();
  const {
    YOUR_COMPANY_HAS_SHAREHOLDERS_QUESTION,
    YOUR_COMPANY_HAS_SHAREHOLDERS_DESCRIPTION,
    YES_ADD_SHAREHOLDERS,
    SKIP,
  } = useTranslator();

  function goToAddShareholders() {
    history.push('shareholders');
  }

  function onBack() {
    history.push('contract');
  }

  function onContinue() {
    history.push('company-info');
  }

  return (
    <FormContainer>
      <FlexGrid
        container
        direction='column'
        spacing={4}
        alignItems='center'
        justify='center'
        style={{ textAlign: 'center', marginTop: '100px' }}
        fullWidth
        fullHeight
      >
        <FlexGrid container item direction='column' spacing={1}>
          <FlexGrid item>
            <Typography variant='h2'>
              {YOUR_COMPANY_HAS_SHAREHOLDERS_QUESTION}
            </Typography>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='paragraph'>
              {YOUR_COMPANY_HAS_SHAREHOLDERS_DESCRIPTION}
            </Typography>
          </FlexGrid>
        </FlexGrid>
        <FlexGrid item>
          <Button size='large' onClick={goToAddShareholders}>
            {YES_ADD_SHAREHOLDERS}
          </Button>
        </FlexGrid>
      </FlexGrid>
      <BottomActions backButton onBack={onBack}>
        <Button buttonType='secondary' onClick={onContinue} size='normal'>
          {SKIP}
        </Button>
      </BottomActions>
    </FormContainer>
  );
};
