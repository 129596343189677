import { ReactNode } from 'react';
import { CSSProperties } from 'styled-components';

export type FormContainerProps = {
  children: ReactNode;
  style?: CSSProperties;
};

export const FormContainer = ({ children, style }: FormContainerProps) => {
  return (
    <div style={{ maxWidth: '785px', minWidth: '785px', ...style }}>
      {children}
    </div>
  );
};
