import { ActionCard, Card, Description, FlexGrid, Skeleton, Typography, useDialog, useDrawer, useThemeProvider } from '@albo-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { usePmStatusQuickWin } from '../../hooks/use-pm-status-quick-win';
import { useSnackbarNotifier } from '../../hooks/use-snackbar-notifier';
import { useQuery } from '../../hooks/user-query';
import { useApi } from '../../providers/api-provider';
import { useAuth } from '../../providers/auth-provider';
import { useOnHomeCBS } from '../../providers/on-home-cbs-provider';
import { useTranslator } from '../../providers/translator-provider';
import { BottomActions } from '../bottom-actions';
import { DeletePromptDialog } from '../delete-prompt-dialog';
import { OnBoardingLoader } from '../on-boarding-loader';
import { AddSoleOwner } from './add-sole-owner';
import { useStatusShareholdersAndSoleOwners } from '../../hooks/use-shareholders-and-soleowners-status';
import { FaInfoCircle } from 'react-icons/fa';

const SoleOwnerForm = () => {
    const { theme } = useThemeProvider();
    const history = useHistory();
    const soleOwnerDrawerProps = useDrawer();
    const [show, close] = useSnackbarNotifier();
    const deleteDialogProps = useDialog();
    const [api] = useApi();
    const [loading, setLoading] = useState(false);
    const [uuidUpdate, setUuidUpdate] = useState<undefined | string>(undefined);
    const [uuidDelete, setUuidDelete] = useState<undefined | string>(undefined);
    const [existLead, setExistLead] = useState(false);
    const { leadData: { onboardingForms } } = useAuth();
    const { reloadingProfile } = useAuth();
    const homeCBS = useOnHomeCBS();
    const pmStatus = usePmStatusQuickWin();

    const setShowDialog = homeCBS.setShowDialog;
    const soleOwnersStatus = pmStatus.soleOwnersStatus;
    
    const {
        showDeclaratory,
        showDocumentsPFAE,
        showOwnerCheck,
        reloadProfile
    } = useStatusShareholdersAndSoleOwners();

    const {
        SOLE_OWNERS,
        SOLE_OWNER_DESCRIPTION_1,
        SOLE_OWNER_DESCRIPTION_BOLD,
        SOLE_OWNER_DESCRIPTION_2,
        ADD_SOLE_OWNER,
        NEXT,
        SUCCESS_UPLOADING_INFO,
        SUCCESS_SAVING_SOLE_OWNER,
        REMEMBER,
        SOLE_OWNER_DESCRIPTION_REMEMBER
    } = useTranslator();

    const soleOwnersUuids = useMemo(
        () =>
            onboardingForms.filter(
                ({ formType }: any) =>
                    formType === 'QUICK_WIN_REAL_OWNER'
            ).map(({ uuid }: any) => uuid),
        [onboardingForms]
    );

    const [queryFormData, loadingForm, reloadForm] = useQuery(
        {
            name: 'get-onboarding-forms-by-uuids',
            params: {
                onboardingFormsUuids: soleOwnersUuids,
            },
        },
        [onboardingForms]
    );

    const soleOwnersArray:
        | {
            name: string;
            status: string;
            uuid: string;
            isLead: boolean;
        }[]
        | undefined = queryFormData
            ? queryFormData.onboardingForms.map((data: any) => ({
                name: `${data.formData.general.gname} ${data.formData?.general?.lname || ""} ${data?.formData?.general?.slname || ""}`,
                status: data.isCompleted ? 'done' : 'pending',
                uuid: data.uuid,
                isLead: data.formData.isLead
            }))
            : undefined;

    useEffect(() => {
        setShowDialog(soleOwnersStatus !== 'done');
    }, [setShowDialog, soleOwnersStatus]);
    
    function onBack() {
        history.push('shareholders');
    }

    function onContinue() {
        history.push('account-config');
    }

    const onAddSoleOwner = () => {
        reloadProfile();
        setExistLead(existLeadInOwners());
        soleOwnerDrawerProps.openDrawer();
        setUuidUpdate(undefined);
        close('')
    };

    const existLeadInOwners = () => {
        let exist = false;
        if (soleOwnersArray && soleOwnersArray.length > 0){
            exist = soleOwnersArray.filter(owner => owner.isLead)?.length > 0;
        }
        return exist; 
    }

    function onOpenSnackBar(eventName: string) {
        if (eventName === 'OnboardingFormUpdated') {
            show(SUCCESS_UPLOADING_INFO)
        }
        if (eventName === 'OnboardingFormCreated') {
            show(SUCCESS_SAVING_SOLE_OWNER)
        }
    }

    async function onDeleteSoleOwner(uuid: string) {
        setUuidDelete(uuid);
        deleteDialogProps.openDialog();
    }

    async function deleteSoleOwner() {
        setLoading(true);
        await api.callService('delete-onboarding-form', {
            uuid: uuidDelete,
        });
        reloadForm();
        reloadProfile();
        deleteDialogProps.onClose();
        setLoading(false);
    }

    async function onUpdateShareholderPF(uuid: string) {
        setUuidUpdate(uuid);
        soleOwnerDrawerProps.openDrawer();
        close('')
    }

    function onDialogClose() {
        setUuidDelete(undefined);
        deleteDialogProps.onClose();
    }

    if (loadingForm || loading)
        return (
            <OnBoardingLoader
                isLoading={loadingForm || loading || reloadingProfile}
            />
        );

    return (
        <>
            <DeletePromptDialog
                open={deleteDialogProps.open}
                onConfirm={deleteSoleOwner}
                onClose={onDialogClose}
            />
            {soleOwnerDrawerProps.open && (
                <AddSoleOwner
                    open={soleOwnerDrawerProps.open}
                    onClose={soleOwnerDrawerProps.onClose}
                    uuidForm={uuidUpdate}
                    openSnackbar={onOpenSnackBar}
                    showDeclaratory={showDeclaratory}
                    showDocumentsPFAE={showDocumentsPFAE}
                    showOwnerCheck={showOwnerCheck}
                    existLead={existLead}
                />
            )}
            <FlexGrid container direction='column' spacing={6}>
                <FlexGrid item container spacing={1} direction='column'>
                    <FlexGrid item>
                        <Typography variant='h2'>{SOLE_OWNERS}</Typography>
                    </FlexGrid>
                    <FlexGrid item>
                        <Typography variant='small'>
                            {SOLE_OWNER_DESCRIPTION_1}
                        </Typography>
                        <Typography variant='small' bold>
                            {SOLE_OWNER_DESCRIPTION_BOLD}
                        </Typography>
                        <Typography variant='small'>
                            {SOLE_OWNER_DESCRIPTION_2}
                        </Typography>
                    </FlexGrid>
                </FlexGrid>
                <FlexGrid item>
        <Card
          fullWidth
          alignItems='center'
          style={{
            borderColor: theme.colors.focus,

            padding: `${theme.spacesPx[3]} ${theme.spacesPx[3]}`,
          }}
        >
          <Description
            title={REMEMBER}
            titleColor='focus'
            icon={<FaInfoCircle color={theme.colors.focus} size='17px' />}
          >
            {SOLE_OWNER_DESCRIPTION_REMEMBER}
          </Description>
        </Card>
      </FlexGrid>
                <FlexGrid
                    container
                    item
                    direction='column'
                    style={{ marginBottom: '100px' }}
                >
                    <FlexGrid item>
                        <FlexGrid item container direction='column' spacing={2}>
                            <FlexGrid item>
                                <ActionCard
                                    title={ADD_SOLE_OWNER}
                                    onClick={onAddSoleOwner}
                                    link
                                    style={{ height: '80px' }}
                                />
                            </FlexGrid>
                            {soleOwnersArray &&
                                soleOwnersArray.map(
                                    ({ name, status, uuid, }, index) => (
                                        <FlexGrid item>
                                            <ActionCard
                                                key={index}
                                                title={name}
                                                style={{ height: '80px' }}
                                                status={status === 'pending' ? 'pending' : 'default'}
                                                onDelete={() => onDeleteSoleOwner(uuid)}
                                                onUpdate={() => onUpdateShareholderPF(uuid)}
                                            />
                                        </FlexGrid>
                                    )
                                )}
                            {loadingForm &&
                                soleOwnersUuids.map(() => (
                                    <Skeleton width='100%' height='80px' />
                                ))}
                        </FlexGrid>
                    </FlexGrid>
                </FlexGrid>
            </FlexGrid>
            <BottomActions
                backButton
                continueButton
                continueButtonText={NEXT}
                disableContinueButton={soleOwnersStatus !== 'done' && soleOwnersStatus !== 'pendinguserapproval'}
                onBack={onBack}
                onContinue={onContinue}
            />
        </>
    );
};

export default SoleOwnerForm;
