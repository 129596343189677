
export const snakeToCamelCase = (obj: any) => {
    var newObj: any = {};
    Object.keys(obj).forEach((d: any) => {
        // eslint-disable-next-line no-useless-escape
        newObj[d.replace(/(\_\w)/g, function (k: any) {
            return k[1].toUpperCase();
        })] = obj[d];
    });

    return newObj;
}