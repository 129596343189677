import { useAuth } from '../providers/auth-provider';
import { useQuery } from './user-query';
import { useThemeProvider } from '@albo-ui/core';
import { useEffect, useState } from 'react';

export type OnboardinFormTypes =
  | 'COMPANY_INFO_ACCOUNT'
  | 'CARD_REQUEST'
  | 'EXTRA_DOCUMENT'
  | 'BENEFICIARY'
  | 'ADMIN_PFAE';

export const usePfaeStatus = () => {
  const { theme } = useThemeProvider();
  type status = keyof typeof theme.statusCardColors;
  const { totalPercentageUsed } = useBeneficiariesData();
  const {
    leadData: { onboardingForms },
    user
  } = useAuth();
  const [benefPendingApproval, setBenefPendingApproval] = useState(false);
  const userStatusIsInfo = user?.status === 'info';

  const companyInfoAccountForm = onboardingForms.find(
    ({ formType }: any) => formType === 'QUICK_WIN_COMPANY_INFO_ACCOUNT'
  );
  const thereIsNoCompanyInfoAccountForm = !companyInfoAccountForm;
  const companyInfoFormIsCompleted = companyInfoAccountForm?.isCompleted

  
  

  const adminForm = onboardingForms.find(
    ({ formType }: any) => formType === 'QUICK_WIN_PERSONAL_INFO'
  );

  const beneficiariesPendingApproval = onboardingForms.filter(
    ({ formType, status }: any) =>
      formType === 'QUICK_WIN_BENEFICIARY' && status === 'pending-user-approval'
  ).map(({ uuid }: any) => uuid);

  const adminFormIsCompleted = adminForm?.isCompleted
  const adminIsPendingApproval = adminForm?.status as string === 'pending-user-approval'

  const adminStatus: status = adminIsPendingApproval && adminFormIsCompleted
    ? 'pendinguserapproval'
    : adminFormIsCompleted
      ? 'done'
      : 'pending';

  const contractStatus: status = adminStatus === 'done' ? 'done' : 'disabled';

  const companyInfoIsPendingApproval = companyInfoAccountForm?.status as string === 'pending-user-approval'
  const companyInfoAccountStatus: status = companyInfoIsPendingApproval && companyInfoFormIsCompleted
  ?
    'pendinguserapproval'
    : adminStatus === 'pending' && userStatusIsInfo && !adminIsPendingApproval
      ? 'disabled'
      : adminStatus === 'done' &&
        thereIsNoCompanyInfoAccountForm
        ? 'default'
        : companyInfoFormIsCompleted
          ? 'done'
          : 'pending';

  const beneficiariesStatus: status =
    benefPendingApproval
      ? 'pendinguserapproval' :
      ((companyInfoAccountStatus === 'pending' ||
        companyInfoAccountStatus === 'disabled') && userStatusIsInfo && !companyInfoIsPendingApproval) ||
        thereIsNoCompanyInfoAccountForm
        ? 'disabled'
        : (companyInfoAccountStatus === 'done' || companyInfoIsPendingApproval) && (totalPercentageUsed === 0 || totalPercentageUsed === 100)
          ? 'done'
          : 'pending';

  /*
  const cardStatus: status = cardRequestForm?.status as string === 'pending-user-approval' && cardRequestFormIsCompleted
    ? 'pendinguserapproval' :
    (beneficiariesStatus === 'pending' ||
      beneficiariesStatus === 'disabled') && userStatusIsInfo && !benefPendingApproval
      ? 'disabled'
      : thereIsCardRequestForm
        ? cardRequestFormIsCompleted
          ? 'done'
          : 'pending'
        : 'default';
*/

  const allFormsIsCompleted = adminFormIsCompleted && companyInfoFormIsCompleted && beneficiariesStatus === 'done'

  const readyToSend =
    adminStatus === 'done' &&
    companyInfoAccountStatus === 'done' &&
    beneficiariesStatus === 'done';

  useEffect(() => {
    if (beneficiariesPendingApproval.length > 0) {
      setBenefPendingApproval(true);
    }
  }, [beneficiariesPendingApproval]);

  return {
    adminStatus,
    contractStatus,
    companyInfoAccountStatus,
    beneficiariesStatus,
    readyToSend,
    allFormsIsCompleted
  };
};

const useBeneficiariesData = () => {
  const {
    leadData: { onboardingForms },
  } = useAuth();

  const beneficiariesUuids = onboardingForms
    .filter(({ formType }: any) => formType === 'QUICK_WIN_BENEFICIARY')
    .map(({ uuid }: any) => uuid);

  const [queryFormData] = useQuery(
    {
      name: 'get-onboarding-forms-by-uuids',
      params: {
        onboardingFormsUuids: beneficiariesUuids,
      },
    },
    [onboardingForms]
  );

  const beneficiaries:
    | {
      name: string;
      percentage: string;
      uuid: string;
    }[]
    | undefined = queryFormData
      ? queryFormData.onboardingForms.map((data: any) => ({
        name: `${data.formData.general.gname} ${data.formData.general.lname} ${data.formData.general.slname}`,
        percentage: `${data.formData.assignedPercentage}%`,
        uuid: data.uuid,
      }))
      : undefined;

  const totalPercentageUsed = beneficiaries
    ? beneficiaries
      ?.map(({ percentage }) => parseInt(percentage))
      .reduce((total, current) => total + current, 0)
    : 0;

  return {
    data: beneficiaries,
    totalPercentageUsed,
  };
};
