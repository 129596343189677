import {
  Button,
  DefaultComponentProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FlexGrid,
  Typography,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';

export type ExitPromptDialogProps = {
  onConfirm: () => void;
  onClose: () => void;
  open: boolean;
} & DefaultComponentProps;

export const ExitPromptDialog = ({
  onConfirm,
  onClose,
  open,
  className,
  style,
}: ExitPromptDialogProps) => {
  const {
    CANCEL,
    SAVE_AND_EXIT,
    EXIT_PROMPT_DIALOG_TEXT,
    EXIT_PROMPT_DIALOG_TITLE,
  } = useTranslator();

  return (
    <Dialog className={className} style={style} onClose={onClose} open={open}>
      <DialogTitle>{EXIT_PROMPT_DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <Typography variant='paragraph'>{EXIT_PROMPT_DIALOG_TEXT} </Typography>
      </DialogContent>
      <DialogActions>
        <FlexGrid
          container
          fullHeight
          fullWidth
          justify='space-between'
          alignItems='flex-end'
          style={{ marginTop: '20px' }}
        >
          <FlexGrid item>
            <Button onClick={onClose} buttonType='secondary' size='normal'>
              {CANCEL}
            </Button>
          </FlexGrid>
          <FlexGrid item>
            <Button onClick={onConfirm} size='normal'>
              {SAVE_AND_EXIT}
            </Button>
          </FlexGrid>
        </FlexGrid>
      </DialogActions>
    </Dialog>
  );
};
