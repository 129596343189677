import { FormContainer } from '../../../../../../../../components-quick-win/form-container';
import { Shareholders } from '../../../../../../../../components-quick-win/shareholders';

export const AddShareHolders = () => {
  return (
    <FormContainer>
      <Shareholders />
    </FormContainer>
  );
};
