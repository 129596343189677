import { useCallback, useState } from 'react';
import { makeProvider } from 'react-provider-maker';
import { useApi } from '../api-provider';

export const {
  Provider: PasswordRecoveryProvider,
  useProvider: usePasswordRecovery,
} = makeProvider(() => {
  const [api] = useApi();
  const [state, setState] = useState<{
    loading?: boolean | undefined;
    error?: 'USER_NOT_FOUND' | 'BAD' | 'AUTHORIZATION_NOT_ALLOWED';
    emailSent?: boolean;
    role?: string;
    passwordChanged?: boolean;
  }>({});

  const sendRecoverPasswordEmail = useCallback(
    async (email: string) => {
      setState({ loading: true });
      const recoverResult = await api.callService('recover-password', {
        email,
      });
      if (recoverResult.eventName === 'PasswordRecoveryEmailSent') {
        setState({
          emailSent: true,
          loading: false,
        });
      } else if (recoverResult.eventName === 'UserNotFound') {
        setState({
          error: 'USER_NOT_FOUND',
          loading: false,
        });
      }
    },
    [api, setState]
  );

  const changePassword = useCallback(
    async (password: string, token: string) => {
      setState({ loading: true });

      var recoverResult;

      try {
        recoverResult = await api.callService(
          'change-password',
          {
            password,
          },
          { signedUrlToken: token }
        );
      } catch (e: any) {
        if (e.toString().includes('401')) {
          setState({
            error: 'AUTHORIZATION_NOT_ALLOWED',
            loading: false,
          });
        } else {
          setState({
            error: 'BAD',
            loading: false,
          });
        }
      }

      if (recoverResult?.eventName === 'PasswordChanged') {
        setState({
          passwordChanged: true,
          loading: false,
          role: recoverResult?.data?.role,
        });
      }
    },
    [api, setState]
  );

  return {
    ...state,
    sendRecoverPasswordEmail,
    changePassword,
  };
});
