import {
  Button,
  Card,
  Description,
  FlexGrid,
  Typography,
  useThemeProvider,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { FaInfoCircle } from 'react-icons/fa';
import { BottomActions } from '../bottom-actions';
import { useHistory } from 'react-router';
import { useAuth } from '../../providers/auth-provider';
import { useQuery } from '../../hooks/user-query';
import { OnBoardingLoader } from '../on-boarding-loader';
import { useOnHomeCBS } from '../../providers/on-home-cbs-provider';

export type ContractProps = {
  personType: 'PM' | 'PFAE';
};

export type LegalRepresentative = {
  name: string;
  mail: string;
  phone: string;
};

export const ConfirmContract = ({ personType }: ContractProps) => {
  const {
    REMEMBER,
    MAIL,
    PHONE,
    CONFIRM_AGREEMENT_DATA,
    BE_SURE_OF,
    MAIL_AND_PHONE,
    LEGAL_REPRESENTATIVES,
    LEGAL_REPRESENTATIVE,
    ACCOUNT_ADMIN,
    OF,
    REGISTERED_ARE_CORRECT_THIS_WILL_BE_USED_FOR,
    AGREEMENT_ACCEPTANCE,
    IF_DATA_IS_WRONG_YOU_CAN_CORRECT_IT_ON_PREVIOS_MODULE,
    PHONE_OR_MAIL_INCORRECT_QUESTION,
    RETURN_TO_MODULE_OF,
    TO_CORRECT_THEM,
    EDIT,
  } = useTranslator();
  const { theme } = useThemeProvider();
  const history = useHistory();
  const {
    leadData: {
      general: { gname, lname, slname, email, phone },
    },
    user,
  } = useAuth();
  const { leadData } = useAuth();
  const homeCBS = useOnHomeCBS();
  homeCBS.setShowDialog(false);

  const legalRepresentativesUuids = leadData.onboardingForms
    .filter(({ formType }: any) => formType === 'LEGAL_REPRESENTATIVE')
    .map(({ uuid }: any) => uuid);

  const [queryFormData, loadingForm] = useQuery(
    {
      name: 'get-onboarding-forms-by-uuids',
      params: {
        onboardingFormsUuids: legalRepresentativesUuids,
      },
      options: {
        manualLoad: legalRepresentativesUuids.length === 0,
      },
    },
    [leadData.onboardingForms]
  );

  const legalRepresentatives: LegalRepresentative[] | undefined = queryFormData
    ? queryFormData.onboardingForms.map((data: any) => ({
        name: `${data.formData.general.gname} ${data.formData.general.lname} ${data.formData.general.slname}`,
        mail: data.formData.general.email,
        phone: data.formData.general.phone,
      }))
    : undefined;

  const description = {
    PM: (
      <>
        {BE_SURE_OF} <span className='f-bold'>{MAIL_AND_PHONE}</span> {OF}{' '}
        <span className='f-bold'>{LEGAL_REPRESENTATIVES}</span>{' '}
        {REGISTERED_ARE_CORRECT_THIS_WILL_BE_USED_FOR}{' '}
        <span className='f-bold'>{AGREEMENT_ACCEPTANCE}</span>.{' '}
        {IF_DATA_IS_WRONG_YOU_CAN_CORRECT_IT_ON_PREVIOS_MODULE}
      </>
    ),
    PFAE: (
      <>
        {BE_SURE_OF} <span className='f-bold'>{MAIL_AND_PHONE}</span>{' '}
        {REGISTERED_ARE_CORRECT_THIS_WILL_BE_USED_FOR}{' '}
        <span className='f-bold'>{AGREEMENT_ACCEPTANCE}</span>.{' '}
        {IF_DATA_IS_WRONG_YOU_CAN_CORRECT_IT_ON_PREVIOS_MODULE}
      </>
    ),
  };

  const previosModule = {
    PM: LEGAL_REPRESENTATIVE.toLowerCase(),
    PFAE: ACCOUNT_ADMIN.toLowerCase(),
  };

  const legalRepresentativesPFAE: LegalRepresentative[] = [
    {
      name: `${gname} ${lname} ${slname}`,
      mail: email,
      phone: phone,
    },
  ];

  function onEdit() {
    personType === 'PFAE'
      ? history.push('admin')
      : history.push('legal-representative');
  }
  function onBack() {
    history.push('admin');
  }

  function onContinue() {
    personType === 'PFAE'
      ? history.push('company-info-and-account')
      : history.push('shareholders-info');
  }

  if (loadingForm) return <OnBoardingLoader isLoading={loadingForm} />;

  return (
    <FlexGrid container fullHeight fullWidth direction='column' spacing={2}>
      <FlexGrid item>
        <Typography variant='h2'>{CONFIRM_AGREEMENT_DATA}</Typography>
      </FlexGrid>
      <FlexGrid item>
        <Card
          fullWidth
          alignItems='center'
          style={{
            borderColor: theme.colors.focus,
            padding: `0 ${theme.spacesPx[3]}`,
            minHeight: '100px',
            height: 'auto',
          }}
        >
          <Description
            title={REMEMBER}
            titleColor='focus'
            icon={<FaInfoCircle color={theme.colors.focus} size='17px' />}
          >
            {description[personType]}
          </Description>
        </Card>
      </FlexGrid>
      <FlexGrid
        item
        container
        wrap
        spacing={3}
        fullWidth
        style={{ paddingBottom: '100px' }}
      >
        {(user?.leadType === 'PFAE'
          ? legalRepresentativesPFAE
          : legalRepresentatives
          ? legalRepresentatives
          : []
        ).map(({ name, mail, phone }, index) => (
          <FlexGrid item style={{ width: 'calc(50% - 12px)' }}>
            <Card
              height='220px'
              fullWidth
              fullHeight
              alignItems='center'
              key={index}
            >
              <FlexGrid item container direction='column' spacing={3}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>{name}</Typography>
                </FlexGrid>
                <FlexGrid item container direction='column' spacing={1}>
                  <FlexGrid item>
                    <Typography variant='small' color='neutral'>
                      {MAIL}
                    </Typography>
                  </FlexGrid>
                  <FlexGrid item>
                    <Typography variant='small' color='neutral'>
                      {mail}
                    </Typography>
                  </FlexGrid>
                </FlexGrid>
                <FlexGrid item container direction='column' spacing={1}>
                  <FlexGrid item>
                    <Typography variant='small' color='neutral'>
                      {PHONE}
                    </Typography>
                  </FlexGrid>
                  <FlexGrid item>
                    <Typography variant='small' color='neutral'>
                      {phone}
                    </Typography>
                  </FlexGrid>
                </FlexGrid>
              </FlexGrid>
            </Card>
          </FlexGrid>
        ))}
        <FlexGrid item flex={`1 300px`}>
          <Card
            height='220px'
            fullWidth
            fullHeight
            style={{
              border: `1px dashed ${theme.colors.cardBorder}`,
              boxShadow: 'none',
            }}
          >
            <FlexGrid
              container
              direction='column'
              spacing={3}
              fullHeight
              fullWidth
              justify='center'
              alignItems='center'
            >
              <FlexGrid
                item
                container
                direction='column'
                spacing={2}
                justify='center'
                alignItems='center'
                style={{ textAlign: 'center' }}
              >
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    {PHONE_OR_MAIL_INCORRECT_QUESTION}
                  </Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Typography variant='small'>
                    {RETURN_TO_MODULE_OF}{' '}
                    <span className='f-bold'>{previosModule[personType]}</span>{' '}
                    {TO_CORRECT_THEM}
                  </Typography>
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item>
                <Button buttonType='secondary' size='normal' onClick={onEdit}>
                  {EDIT}
                </Button>
              </FlexGrid>
            </FlexGrid>
          </Card>
        </FlexGrid>
      </FlexGrid>
      <BottomActions
        backButton
        continueButton
        onBack={onBack}
        onContinue={onContinue}
      />
    </FlexGrid>
  );
};
