import { memo, ReactNode } from 'react';
import { Actions } from './style';
import {
  Button,
  Description,
  Typography,
  useThemeProvider,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { FaSave } from 'react-icons/fa';
import { CSSProperties } from 'styled-components';

export type BottomActionsProps = {
  children?: ReactNode;
  continueButton?: boolean;
  disableContinueButton?: boolean;
  backButton?: boolean;
  saveProgressButton?: boolean;
  continueButtonText?: string;
  saveButtonText?: string;
  onContinue?: () => void;
  onBack?: () => void;
  onSaveProgress?: () => void;
  className?: string;
  style?: CSSProperties;
};

export const BottomActions = memo(
  ({
    children,
    backButton,
    continueButton,
    onBack,
    onContinue,
    onSaveProgress,
    saveProgressButton,
    continueButtonText,
    saveButtonText,
    className,
    style,
    disableContinueButton,
  }: BottomActionsProps) => {
    const { theme } = useThemeProvider();
    const { CONTINUE, SAVE_PROGRESS, BACK } = useTranslator();
    return (
      <Actions
        className={className}
        style={{
          ...style,
          padding: `${theme.spacesPx[3]} ${theme.spacesPx[4]}`,
        }}
      >
        {backButton && (
          <Button
            onClick={onBack}
            size='normal'
            buttonType='secondary'
            style={{ marginRight: 'auto' }}
          >
            {BACK}
          </Button>
        )}

        {children}

        {saveProgressButton && (
          <Description
            icon={<FaSave size='17px' color={theme.colors.primary} />}
            onClick={onSaveProgress}
            style={{ marginRight: theme.spacesPx[5] }}
          >
            <Typography variant='smallTitle' color='primary'>
              {saveButtonText ? saveButtonText : SAVE_PROGRESS}
            </Typography>
          </Description>
        )}

        {continueButton && (
          <Button
            onClick={onContinue}
            size='normal'
            disabled={disableContinueButton}
          >
            {continueButtonText ? continueButtonText : CONTINUE}
          </Button>
        )}
      </Actions>
    );
  }
);
