import {
  Button,
  FlexGrid,
  Image,
  Typography,
  useThemeProvider,
  FormItemProps,
  Form,
  useForm,
} from '@albo-ui/core';
import logoPrimarioIndigo from '../../images/Logo_Primario_Indigo.png';
import { useTranslator } from '../../providers/translator-provider';
import { useValidators } from '../../utils/validators';
import { FormItems } from '../form-items';
import { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { OnBoardingLoader } from '../../components-quick-win/on-boarding-loader';
import { FiEyeOff, FiEye } from 'react-icons/fi';
import {
  useSignUpFromMoreInfo,
  SignUpFromMoreInfoParams,
} from '../../providers/sign-up-from-more-info';

const EyeSlashIcon = <FiEyeOff size='20px' />;
const EyeIcon = <FiEye size='20px' />;

export const SignUpFromMoreInfoForm = () => {
  const formProps = useForm<
    | (SignUpFromMoreInfoParams & { confirmPassword?: string })
    | {
        confirmPassword: string;
        password: string;
      }
  >({
    password: '',
    confirmPassword: '',
  });
  const history = useHistory();
  const { theme } = useThemeProvider();
  const validators = useValidators();
  const [hidePassword, setHidePassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const { signUpFromMoreInfo, loading } = useSignUpFromMoreInfo();
  const location = useLocation();
  const search = location.search;
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const currentEmail = query.get('email') as string;
  const {
    PASSWORD_DOES_NOT_MATCH,
    PASSWORD,
    CREATE_PASSWORD,
    CREATE_YOUR_PASSWORD_AND_COMPLETE_YOUR_BUSINESS_PROFILE,
    CONTINUE,
  } = useTranslator();

  function onPasswordIconClick() {
    setHidePassword(!hidePassword);
  }

  function onConfirmPasswordIconClick() {
    setHideConfirmPassword(!hideConfirmPassword);
  }

  const validatePasswords = (value: string) => {
    const passwordsDoNotMatch =
      formProps.data.password !== formProps.data.confirmPassword &&
      formProps.data.password.length !== 0 &&
      formProps.data.confirmPassword?.length !== 0;

    return passwordsDoNotMatch ? PASSWORD_DOES_NOT_MATCH : undefined;
  };

  const inputs: FormItemProps<string>[] = [
    {
      placeholder: CREATE_PASSWORD,
      name: 'password',
      type: 'input',
      inputType: hidePassword ? 'password' : 'default',
      required: true,
      inputSize: '100%',
      pattern: 'password',
      icon: hidePassword ? EyeSlashIcon : EyeIcon,
      onClickIcon: onPasswordIconClick,
      validators: validators.getPasswordValidators(),
    },
    {
      placeholder: PASSWORD,
      name: 'confirmPassword',
      type: 'input',
      inputType: hideConfirmPassword ? 'password' : 'default',
      pattern: 'password',
      required: true,
      inputSize: '100%',
      icon: hideConfirmPassword ? EyeSlashIcon : EyeIcon,
      onClickIcon: onConfirmPasswordIconClick,
      validators: [validators.isEmpty, validatePasswords],
    },
  ];

  const onClickButton = async () => {
    if (formProps.hasErrors) {
      formProps.displayErrors();
    } else {
      delete formProps.data.confirmPassword;
      await signUpFromMoreInfo({
        ...formProps.data,
        email: currentEmail,
      } as any).then(() => {
        history.push('confirm-email');
      });
    }
  };

  if (loading) return <OnBoardingLoader isLoading={loading} />;

  return (
    <>
      <Form {...formProps} onSubmit={onClickButton}>
        <FlexGrid
          container
          fullHeight
          fullWidth
          direction='column'
          spacing={10}
          alignItems='center'
        >
          <FlexGrid item>
            <Image src={logoPrimarioIndigo} size='163px' />
          </FlexGrid>
          <FlexGrid
            container
            spacing={10}
            direction='column'
            alignItems='center'
            fullWidth
            fullHeight
          >
            <FlexGrid
              item
              style={{
                padding: `0 ${theme.spacesPx[2]}`,
                textAlign: 'center',
              }}
            >
              <Typography variant='h2' color='indigo'>
                {CREATE_YOUR_PASSWORD_AND_COMPLETE_YOUR_BUSINESS_PROFILE}
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              direction='column'
              spacing={5}
              style={{ width: '380px' }}
            >
              <FlexGrid item>
                <FormItems inputs={inputs} />
              </FlexGrid>
              <FlexGrid item>
                <Button
                  onClick={onClickButton}
                  size='fullWidth'
                  style={{ width: '380px' }}
                >
                  {CONTINUE}
                </Button>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>
        </FlexGrid>
      </Form>
    </>
  );
};
