import {
  FlexGrid,
  RoundedIcon,
  useThemeProvider,
  Image,
  Typography,
} from '@albo-ui/core';
import { FaArrowLeft, FaEnvelope } from 'react-icons/fa';
import { useHistory } from 'react-router';
import logoPrimarioIndigo from '../../images/Logo_Primario_Indigo.png';
import { useTranslator } from '../../providers/translator-provider';
import { IconContainer } from './styled';

export const ConfirmEmail = () => {
  const { theme } = useThemeProvider();
  const history = useHistory();
  const { VERIFY_YOUR_MAIL, VALIDATE_EMAIL_DESCRIPTION, TEN_MINUTES } =
    useTranslator();

  const onBackClick = () => {
    history.push('sign-up');
  };

  return (
    <FlexGrid container direction='column' padding={8}>
      <FlexGrid item>
        <IconContainer onClick={onBackClick}>
          <FaArrowLeft size='30px' color={theme.colors.primary} />
        </IconContainer>
      </FlexGrid>
      <FlexGrid
        item
        container
        alignItems='center'
        direction='column'
        spacing={10}
      >
        <FlexGrid item>
          <Image src={logoPrimarioIndigo} size='163px' />
        </FlexGrid>
        <FlexGrid
          item
          container
          direction='column'
          spacing={4}
          alignItems='center'
          style={{ textAlign: 'center' }}
        >
          <FlexGrid item>
            <RoundedIcon
              icon={<FaEnvelope size='30px' color={theme.colors.primary} />}
              size='big'
              color='manatee'
            />
          </FlexGrid>
          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2'>{VERIFY_YOUR_MAIL}</Typography>
            </FlexGrid>
            <FlexGrid item style={{ maxWidth: '470px' }}>
              <Typography variant='paragraph'>
                {VALIDATE_EMAIL_DESCRIPTION}{' '}
                <Typography variant='paragraph' bold>
                  {TEN_MINUTES}
                </Typography>
                .
              </Typography>
            </FlexGrid>
          </FlexGrid>
        </FlexGrid>
      </FlexGrid>
    </FlexGrid>
  );
};
