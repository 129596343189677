import {
  FlexGrid,
  RoundedIcon,
  useThemeProvider,
  Image,
  Typography,
  Button,
  Alert,
  useAlert,
  CodeInput,
  Link,
  useSnackbar,
  Snackbar,
} from '@albo-ui/core';
import { useEffect, useState } from 'react';
import { FaMobileAlt, FaArrowLeft } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { OnBoardingLoader } from '../../components-quick-win/on-boarding-loader';
import useLocalStorage from '../../hooks/use-local-storage';
import logoPrimarioIndigo from '../../images/Logo_Primario_Indigo.png';
import { useSignUp } from '../../providers/sign-up-provider';
import { useTranslator } from '../../providers/translator-provider';
import { useTimers } from '../../providers/use-timer';
import { LinkExpired } from '../link-expired';
import { IconContainer } from './styled';

const timerName = 'signUp';

export const ConfirmPhone = () => {
  const { theme } = useThemeProvider();
  const alertProps = useAlert();
  const history = useHistory();
  const { openSnackbar, ...snackbarProps } = useSnackbar();
  const {
    validatePhoneCode,
    loading,
    api,
    error,
    lastCodeChangeDate,
    emailToken,
    sendAttempts,
  } = useSignUp();
  const [code, setCode] = useState('');
  const { addTimer, timers } = useTimers();
  const [loginRoute] = useLocalStorage('login-route', '');
  const [authorizationNotAllowed, setAuthorizationNotAllowed] = useState(false);
  const [resendingCode, setResendingCode] = useState(false);
  const [showError, setShowError] = useState(false);
  const {
    REGISTER_AND_VERIFY_YOUR_PHONE,
    CONFIRM_PHONE_DESCRIPTION,
    RESEND_SMS_CODE,
    VALIDATE,
    RESEND_CODE_AVAILABE_IN,
    CODE_ENTERED_IS_INCORRECT,
    UNDERSTOOD,
  } = useTranslator();

  const onBackClick = () => {
    history.push(loginRoute);
  };

  const onVerifyCode = async () => {
    await validatePhoneCode(code).then(() => {
      setShowError(true);
    });
  };

  const onCodeInput = (value: string) => {
    setCode(value);
  };

  const resendCode = async () => {
    setResendingCode(true);
    try {
      await api.callService(
        'require-auth-sms-code',
        {
          action: 'PRELEAD',
        },
        { signedUrlToken: emailToken }
      );
    } catch (e) {
      setAuthorizationNotAllowed(true);
    }

    setResendingCode(false);

    var endDate = new Date();
    endDate.setMinutes(endDate.getMinutes() + 2);
    addTimer(timerName, endDate);
  };

  const onSnackBarClose = () => {
    snackbarProps.onClose();
    setShowError(false);
  };

  useEffect(() => {
    if (error === 'INVALID_PHONE_CODE' && showError) openSnackbar();
  }, [error, openSnackbar, showError]);

  useEffect(() => {
    if (error === 'USER_NOT_ALLOWED' || (sendAttempts && sendAttempts > 2)) {
      var endDate = lastCodeChangeDate
        ? new Date(lastCodeChangeDate)
        : new Date();
      endDate.setMinutes(endDate.getMinutes() + 2);
      addTimer(timerName, endDate);
    }
  }, [error, addTimer, lastCodeChangeDate, sendAttempts]);

  if (loading || resendingCode)
    return <OnBoardingLoader isLoading={loading || resendingCode} />;

  if (error === 'AUTHORIZATION_NOT_ALLOWED' || authorizationNotAllowed)
    return <LinkExpired />;

  return (
    <div style={{ height: '100vh' }}>
      <div style={{ position: 'absolute', width: '100%' }}>
        <Alert {...alertProps} description='verificando' type='success' />
      </div>
      <Snackbar
        open={snackbarProps.open}
        message={CODE_ENTERED_IS_INCORRECT}
        action={UNDERSTOOD}
        onClose={onSnackBarClose}
      />
      <FlexGrid
        container
        direction='column'
        padding={8}
        fullWidth
        fullHeight
        style={{ backgroundColor: 'white' }}
      >
        <FlexGrid item>
          <IconContainer onClick={onBackClick}>
            <FaArrowLeft size='30px' color={theme.colors.primary} />
          </IconContainer>
        </FlexGrid>
        <FlexGrid
          item
          container
          alignItems='center'
          direction='column'
          spacing={10}
        >
          <FlexGrid item>
            <Image src={logoPrimarioIndigo} size='163px' />
          </FlexGrid>
          <FlexGrid
            item
            container
            direction='column'
            spacing={4}
            alignItems='center'
            style={{ textAlign: 'center' }}
          >
            <FlexGrid item>
              <RoundedIcon
                icon={<FaMobileAlt size='40px' color={theme.colors.primary} />}
                size='big'
                color='manatee'
              />
            </FlexGrid>
            <FlexGrid
              item
              container
              direction='column'
              spacing={4}
              alignItems='center'
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='h2'>
                    {REGISTER_AND_VERIFY_YOUR_PHONE}
                  </Typography>
                </FlexGrid>
                <FlexGrid item style={{ maxWidth: '530px' }}>
                  <Typography variant='paragraph'>
                    {CONFIRM_PHONE_DESCRIPTION}
                  </Typography>
                </FlexGrid>
              </FlexGrid>
              <FlexGrid
                item
                container
                fullWidth
                direction='column'
                spacing={3}
                style={{ maxWidth: '450px' }}
              >
                <FlexGrid item>
                  <CodeInput
                    onInput={onCodeInput}
                    size={4}
                    error={error === 'INVALID_PHONE_CODE'}
                  />
                </FlexGrid>
                <FlexGrid item>
                  {timerName in timers ? (
                    <>
                      <Typography variant='paragraph' color='lightNeutral' bold>
                        {RESEND_CODE_AVAILABE_IN}:{' '}
                      </Typography>
                      <Typography variant='paragraph' color='paragraph' bold>
                        {timers[timerName].timeLeft.value}
                      </Typography>
                    </>
                  ) : (
                    <Link onClick={resendCode} bold>
                      {RESEND_SMS_CODE}
                    </Link>
                  )}
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item fullWidth style={{ maxWidth: '450px' }}>
                <Button onClick={onVerifyCode} disabled={code.length < 4}>
                  {VALIDATE}
                </Button>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>
        </FlexGrid>
      </FlexGrid>
    </div>
  );
};
