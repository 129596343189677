import {
  Drawer,
  DrawerDescription,
  DrawerTitle,
  FlexGrid,
  Typography,
  DrawerContent,
  DrawerActions,
  Button,
  useThemeProvider,
  FormItemProps,
  Form,
  useForm,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { FormItems } from '../form-items';
import { useDefaultValidations } from '../../utils/default-validations';
import { useValidators } from '../../utils/validators';
import { useFormsFields } from '../../providers/onboarding-form-provider';
import { useEffect } from 'react';

export type AddEmployeeDrawerProps = {
  open: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
  onUpdate: (data: any) => void;
  updateData?: any;
};

export const AddEmployeeDrawer = ({
  onClose,
  open,
  updateData,
  onSave,
  onUpdate,
}: AddEmployeeDrawerProps) => {
  const {
    REQUIRED_FIELD,
    PATERNAL_LASTNAME,
    MATERNAL_LASTNAME,
    CANCEL,

    ADD,
    ADD_EMPLOYEE,
    BIRTH_DATE,
    DATE_FORMAT,
    NAME,
    WORK_POSITION,
    UPDATE_EMPLOYEE,
    UPDATE,
    ADD_EMPLOYEE_DESCRIPTION,
    PERSONAL_INFO,
  } = useTranslator();
  const { theme } = useThemeProvider();
  const formProps = useForm(updateData || {});
  const formData = formProps.data;
  const defaultValidations = useDefaultValidations();
  const validators = useValidators();

  const { formFields ,findFields} = useFormsFields();
  
  useEffect(()=>{
    findFields('QUICK_WIN_COMPANY_ORGANIZATION');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const employeeInputs: FormItemProps<string>[] = formFields('QUICK_WIN_COMPANY_ORGANIZATION', [
    {
      placeholder: NAME,
      name: 'gname',
      type: 'input',
      required: true,
      ...defaultValidations.nameValidations(),
    },
    {
      placeholder: PATERNAL_LASTNAME,
      name: 'lname',
      type: 'input',
      required: true,
      ...defaultValidations.paternalLastNameValidations(),
    },
    {
      placeholder: MATERNAL_LASTNAME,
      name: 'slname',
      type: 'input',
      ...defaultValidations.maternalLastNameValidations(),
    },
    {
      placeholder: `${BIRTH_DATE} ${DATE_FORMAT}`,
      name: 'bdate',
      type: 'date',
      inputType: 'email',
      required: true,
      validators: [
        validators.isEmpty,
        validators.notValidDateFormat,
        validators.validateAge,
      ],
    },
    {
      placeholder: WORK_POSITION,
      name: 'position',
      type: 'input',
      required: true,
      validators: [validators.isEmpty],
    },
  ]);

  const onSaveEmployee = () => {
    if (formProps.hasErrors) formProps.displayErrors();
    else {
      onSave && onSave(formData);
    }
  };

  const onUpdateEmployee = () => {
    if (formProps.hasErrors) formProps.displayErrors();
    else {
      onUpdate && onUpdate(formData);
    }
  };

  return (
    <Form
      {...formProps}
      onSubmit={updateData ? onUpdateEmployee : onSaveEmployee}
    >
      <Drawer open={open} onClose={onClose} position='right'>
        <DrawerTitle>{updateData ? UPDATE_EMPLOYEE : ADD_EMPLOYEE}</DrawerTitle>
        <DrawerDescription>
          <FlexGrid container fullWidth direction='column' spacing={2}>
            <FlexGrid item>{ADD_EMPLOYEE_DESCRIPTION}</FlexGrid>
          </FlexGrid>
        </DrawerDescription>

        <DrawerContent>
          <FlexGrid item container fullWidth spacing={4} direction='column'>
            <FlexGrid item container fullWidth spacing={2} direction='column'>
              <FlexGrid item>
                <Typography variant='smallTitle'>{PERSONAL_INFO}</Typography>
              </FlexGrid>

              <FlexGrid item>
                <Typography variant='small' color='neutral'>
                  <span style={{ color: theme.colors.required }}>*</span>{' '}
                  {REQUIRED_FIELD}
                </Typography>
              </FlexGrid>

              <FormItems inputs={employeeInputs} />
            </FlexGrid>
          </FlexGrid>
        </DrawerContent>

        <DrawerActions>
          <Button onClick={onClose} buttonType='secondary' size='normal'>
            {CANCEL}
          </Button>
          <Button
            onClick={updateData ? onUpdateEmployee : onSaveEmployee}
            size='normal'
            style={{ marginLeft: theme.spacesPx[2] }}
          >
            {updateData ? UPDATE : ADD}
          </Button>
        </DrawerActions>
      </Drawer>
    </Form>
  );
};
