import { FormItemProps, FlexGrid, FormItem } from '@albo-ui/core';
import { memo } from 'react';
import { useTranslator } from '../../providers/translator-provider';
import { FormFileInput } from '../form-file-input';
import { FormPhoneInput } from '../form-phone-input';

export type FormItemsProps<T> = {
  inputs: FormItemProps<T>[];
  inputSize?: string;
};

export const FormItems = memo(<T,>({ inputs }: FormItemsProps<T>) => {
  const { REQUIRED_FIELD, SELECT_OPTION, FILE_REQUIRED, INVALID_DATE_FORMAT } =
    useTranslator();

  const errorMessages = {
    input: REQUIRED_FIELD,
    numberFormatInput: REQUIRED_FIELD,
    select: SELECT_OPTION,
    fileInput: FILE_REQUIRED,
    date: INVALID_DATE_FORMAT,
  };

  return (
    <>
      <FlexGrid item container wrap fullWidth spacing={4}>
        {inputs.map(
          ({
            type,
            name,
            placeholder,
            required,
            help,
            error,
            errorMessage,
            inputType,
            items,
            search,
            disabled,
            description,
            validators,
            maxLength,
            toUpperCase,
            saveAs,
            icon,
            fullWidth,
            inputSize,
            pattern,
            decimalScale,
            defaultValue,
            fixedDecimalScale,
            format,
            mask,
            prefix,
            removeFormatting,
            suffix,
            thousandSeparator,
            onClickIcon,
            fileExtensions,
            autocomplete
          }) => (
            <FlexGrid
              key={name}
              item
              style={{
                width: inputSize
                  ? inputSize
                  : type === 'checkBox'
                  ? '100%'
                  : 'calc(50% - 16px)',
                marginRight: fullWidth ? 'calc(50% - 16px)' : '0',
              }}
            >
              {(type === 'input' ||
                type === 'select' ||
                type === 'date' ||
                type === 'numberFormatInput') && (
                <FormItem
                  placeholder={placeholder}
                  name={name}
                  type={type}
                  items={items}
                  inputType={inputType}
                  saveAs={saveAs}
                  help={help}
                  disabled={disabled}
                  required={required}
                  error={error}
                  errorMessage={
                    errorMessage ? errorMessage : errorMessages[type]
                  }
                  search={search}
                  description={description}
                  validators={validators}
                  maxLength={maxLength}
                  toUpperCase={toUpperCase}
                  icon={icon}
                  pattern={pattern}
                  decimalScale={decimalScale}
                  defaultValue={defaultValue}
                  fixedDecimalScale={fixedDecimalScale}
                  format={format}
                  mask={mask}
                  prefix={prefix}
                  removeFormatting={removeFormatting}
                  suffix={suffix}
                  thousandSeparator={thousandSeparator}
                  onClickIcon={onClickIcon}
                  autocomplete={autocomplete}
                />
              )}
              {type === 'phone' && (
                <FormPhoneInput
                  required={required}
                  baseName={name}
                  validators={validators}
                  disabled={disabled}
                  placeholder={placeholder}
                  help={help}
                />
              )}
              {type === 'fileInput' && (
                <FormFileInput
                  name={name}
                  type={type}
                  placeholder={placeholder}
                  error={error}
                  errorMessage={
                    errorMessage ? errorMessage : errorMessages.fileInput
                  }
                  required={required}
                  disabled={disabled}
                  help={help}
                  validators={validators}
                  fileExtensions={fileExtensions}
                />
              )}
              {type === 'checkBox' && (
                <FormItem type={type} name={name} help={help} />
              )}
            </FlexGrid>
          )
        )}
      </FlexGrid>
    </>
  );
});
