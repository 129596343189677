import { Switch, Route, Redirect } from 'react-router-dom';
import { useAuth } from './providers/auth-provider';
import { Onboarding } from './routes/onboarding';
import { SignIn } from './routes/sign-in';
import { SignUp } from './routes/sign-up';
import { PasswordRecovery } from './routes/password-recovery';
import { ConfirmEmail } from './routes/confirm-email';
import { ConfirmPhone } from './routes/confirm-phone';
import { RegisterVerifyPhone } from './routes/register-verify-phone';
import { LeadGen } from './routes/lead-gen';
import { Login } from './routes/login';
import { LoginCodeAuth } from './routes/login-code-auth';
import { Playground } from './routes/playground';
import { OnBoardingLoader } from './components-quick-win/on-boarding-loader';
import { ResetPassword } from './routes/reset-password';
import { PasswordChanged } from './routes/password-changed';
import { SignUpFromMoreInfo } from './routes/sign-up-from-more-info';
import { ErrorLanding } from './routes/error';
import { LandingEmptyLead } from './routes/empty-lead';
import { OpsAnalystPasswordChanged } from './routes/ops-password-changed';

export const MainRouter = () => {
  const authState = useAuth();
  const { isAuth, isPreLoggedIn, loading, isEmptyLead} = authState;

  if (loading && !isEmptyLead ) {
    return <OnBoardingLoader isLoading={loading} />;
  }

  return (
    <>
      {isAuth ? (
        <Switch>
          <Route path='/onboarding' component={Onboarding} />
          <Route path='/onboarding/home/menu' component={Onboarding} />
          <Route path='/error' component={ErrorLanding} />
          <Route path='/empty-lead' component={LandingEmptyLead} />
          <Redirect to='/onboarding' />
        </Switch>
      ) : isPreLoggedIn ? (
        <Switch>
          <Route path='/login-code-auth' component={LoginCodeAuth} />
          <Redirect to='/login-code-auth' />
          <Route path='/error' component={ErrorLanding} />
          <Route path='/empty-lead' component={LandingEmptyLead} />
          
        </Switch>
      ) : (
        <Switch>
          <Route path='/sign-in' component={SignIn} />
          <Route path='/sign-up' component={SignUp} />
          <Route path='/password-recovery' component={PasswordRecovery} />
          <Route path='/reset-password' component={ResetPassword} />
          <Route path='/password-changed' component={PasswordChanged} />
          <Route path='/ops-password-changed' component={OpsAnalystPasswordChanged} />
          <Route path='/confirm-email' component={ConfirmEmail} />
          <Route path='/confirm-phone' component={ConfirmPhone} />
          <Route path='/login' component={Login} />
          <Route
            path='/register-verify-phone'
            component={RegisterVerifyPhone}
          />
          <Route path='/lead-gen' component={LeadGen} />
          <Route
            path='/sign-up-from-more-info'
            component={SignUpFromMoreInfo}
          />
          <Route path='/playground' component={Playground} />
          <Route path='/error' component={ErrorLanding} />
          <Route path='/empty-lead' component={LandingEmptyLead} />
          <Redirect to='/login' />
        </Switch>
      )}
    </>
  );
};
