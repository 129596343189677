import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { OnBoardingMenuPFAE } from '../../../../components-quick-win/on-boarding-menu-pfae';
import { AccountCreation } from './routes/account-creation';
import { AccountReady } from './routes/account-ready';
import { RecordInfo } from './routes/record-info';
import { AdditionalRecordInfo } from './routes/additional-record-info';
import { Validation } from './routes/validation';
import { ContractStatus } from './routes/contract-status';
import { useAuth } from '../../../../providers/auth-provider';
import { OnBoardingMenuPM } from '../../../../components-quick-win/on-boarding-menu-pm';
import { Revision } from './routes/revision';
import { OnBoardingAdditionalMenuPM } from '../../../../components-quick-win/on-boarding-additional-menu-pm';
import { UploadDocuments } from './routes/upload-documents';
import { useUserRole } from '../../../../hooks/use-user-role';



/**
 * Verifica el estado y el role del lead para mostrar el menu correspondiente
 * @param param0 
 * @returns statusLead
 */
const getStatusLead = ({ status, isOpsAnalyst }: any) => {
  const statusLead = isOpsAnalyst &&
    (status === 'revision' ||
      status === 'validating')
    ? 'info' : status;

  return statusLead;
}

const RoutesPfae = ({ url, user, isOpsAnalyst, interactionStatus }: any) => {
  const statusLead = getStatusLead({ status: user?.status, isOpsAnalyst });

  if (interactionStatus === 'pending-user-approval' && !isOpsAnalyst)
  return (
    <Switch>
        <Route path={`${url}/revision`} component={Revision} />
        <Route path={`${url}/menu`} component={OnBoardingMenuPFAE} />
        <Route path={`${url}/record-info`} component={RecordInfo} />
        <Redirect to={`${url}/revision`} />
      </Switch>
  )

  return <>
    {statusLead === 'info' &&
      (
        <Switch>
          <Route path={`${url}/record-info`} component={RecordInfo} />
          <Route exact path={`${url}/menu`} component={OnBoardingMenuPFAE} />
          <Redirect to={`${url}/menu`} />
        </Switch>
      )}
    {(statusLead === 'validating' ||
      statusLead === 'documents-required') && (
        <Switch>
          <Route path={`${url}/validation`} component={Validation} />
          <Route path={`${url}/upload-documents`} component={UploadDocuments} />
          <Redirect to={`${url}/validation`} />
        </Switch>
      )}
   
    {statusLead === 'signing' && (
      <Switch>
        <Route path={`${url}/contract-status`} component={ContractStatus} />
        {/* <Route path={`${url}/sign`} component={Sign} /> */}
        <Redirect to={`${url}/contract-status`} />
      </Switch>
    )}

    {statusLead === 'creating-account' && (
      <Switch>
        <Route path={`${url}/account-creation`} component={AccountCreation} />
        <Redirect to={`${url}/account-creation`} />
      </Switch>
    )}
    {statusLead === 'done' && (
      <Switch>
        <Route path={`${url}/account-ready`} component={AccountReady} />
        <Redirect to={`${url}/account-ready`} />
      </Switch>
    )}
  </>
}

const RoutesPM = ({ url, user, isOpsAnalyst, interactionStatus }: any) => {

  const statusLead = getStatusLead({ status: user?.status, isOpsAnalyst });


  if (interactionStatus === 'pending-user-approval' && !isOpsAnalyst)
    return (
      <Switch>
        <Route path={`${url}/revision`} component={Revision} />
        <Route path={`${url}/menu`} component={OnBoardingMenuPM} />
        <Route path={`${url}/record-info`} component={RecordInfo} />
        <Redirect to={`${url}/revision`} />
      </Switch>
    )



  return <> {
    statusLead === 'info'
    && (
      <Switch>
        <Route path={`${url}/record-info`} component={RecordInfo} />
        <Route path={`${url}/menu`} component={OnBoardingMenuPM} />
        <Redirect to={`${url}/menu`} />
      </Switch>
    )}
    {(statusLead === 'revision' ||
      statusLead === 'request-extrainfo') && (
        <Switch>
          <Route path={`${url}/revision`} component={Revision} />
          <Redirect to={`${url}/revision`} />
        </Switch>
      )}

    {statusLead === 'pending-extrainfo' && (
      <Switch>
        <Route path={`${url}/record-extra-info`} component={AdditionalRecordInfo} />
        <Route path={`${url}/menu-extra-info`} component={OnBoardingAdditionalMenuPM} />
        <Redirect to={`${url}/menu-extra-info`} />
      </Switch>
    )}
    {(statusLead === 'validating' ||
      statusLead === 'documents-required') && (
        <Switch>
          <Route path={`${url}/validation`} component={Validation} />
          <Route path={`${url}/upload-documents`} component={UploadDocuments} />
          <Redirect to={`${url}/validation`} />
        </Switch>
      )}
    {statusLead === 'signing' && (
      <Switch>
        <Route path={`${url}/contract-status`} component={ContractStatus} />
        {/* <Route path={`${url}/sign`} component={Sign} /> */}
        <Redirect to={`${url}/contract-status`} />
      </Switch>
    )}

    {statusLead === 'creating-account' && (
      <Switch>
        <Route path={`${url}/account-creation`} component={AccountCreation} />
        <Redirect to={`${url}/account-creation`} />
      </Switch>
    )}
    {statusLead === 'done' && (
      <Switch>
        <Route path={`${url}/account-ready`} component={AccountReady} />
        <Redirect to={`${url}/account-ready`} />
      </Switch>
    )}
  </>
}


export const HomeRouter = () => {
  const { url } = useRouteMatch();
  const { user, leadData: { interactionStatus } } = useAuth();
  const { isOpsAnalyst } = useUserRole();
  const leadType = user?.leadType;
  // Cada ruta se debe validar basada en el status ej. { status=='info' && <Route path={`${url}/record-info`} component={RecordInfo} />}
  return leadType === 'PFAE' ?
    <RoutesPfae url={url} user={user} isOpsAnalyst={isOpsAnalyst} interactionStatus={interactionStatus} />
    : <RoutesPM url={url} user={user} isOpsAnalyst={isOpsAnalyst} interactionStatus={interactionStatus} />
    ;
};
