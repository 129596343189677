import { FlexGrid, Image, Typography } from '@albo-ui/core';
import { useTranslator } from '../../../providers/translator-provider';
import portrait from '../../../images/Portrait_Solid.svg';
import { useAuth } from '../../../providers/auth-provider';
import { OnBoardingLoader } from '../../on-boarding-loader';

export const Rejected = () => {
  const {
    WE_ARE_SORRY_TO_INFORM_YOU_HAVE_BEEN_REJECTED,
    WE_ARE_SORRY_TO_INFORM_YOU_HAVE_BEEN_REJECTED_DESCRIPTION,
    REQUEST_REJECTED,
    STATUS,
  } = useTranslator();

  const { loading } = useAuth();

  if (loading) return <OnBoardingLoader isLoading={loading} />;

  return (
    <FlexGrid
      container
      direction='column'
      spacing={5}
      alignItems='center'
      style={{ maxWidth: '530px' }}
    >
      <FlexGrid item>
        <Image src={portrait} size='60px' />
      </FlexGrid>
      <FlexGrid
        item
        container
        spacing={1}
        direction='column'
        alignItems='center'
        style={{ textAlign: 'center' }}
      >
        <FlexGrid item>
          <Typography variant='smallTitle'>
            {WE_ARE_SORRY_TO_INFORM_YOU_HAVE_BEEN_REJECTED}
          </Typography>
        </FlexGrid>
        <FlexGrid item style={{ maxWidth: '418px' }}>
          <Typography variant='paragraph'>
            {WE_ARE_SORRY_TO_INFORM_YOU_HAVE_BEEN_REJECTED_DESCRIPTION}
          </Typography>
        </FlexGrid>
      </FlexGrid>

      <FlexGrid
        item
        container
        spacing={1}
        direction='column'
        alignItems='center'
        style={{ textAlign: 'center' }}
      >
        <FlexGrid item>
          <Typography variant='paragraph'>{STATUS}</Typography>
        </FlexGrid>
        <FlexGrid item>
          <Typography variant='smallTitle' color='sunset'>
            {REQUEST_REJECTED}
          </Typography>
        </FlexGrid>
      </FlexGrid>
    </FlexGrid>
  );
};
