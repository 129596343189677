import { useCallback, useRef } from 'react';
import { makeProvider } from 'react-provider-maker';
import { useApi } from '../api-provider';
import { useAuth } from '../auth-provider';
import { initializeApp, database, auth } from "firebase";


export const { Provider: FirebaseProvider, useProvider: useFirebase } =
    makeProvider(() => {
        const {user} =useAuth()
        const [api] = useApi();
        const dataBase= useRef<database.Database>();
        const firebaseisInitialized = useRef(false)
        
        const appRef = useRef<firebase.app.App>()
       
        const loadSAFirebase = useCallback(async() => {
            try {
                if(appRef.current){
                    return dataBase.current;
                }
                if(firebaseisInitialized.current){
                    return dataBase.current;
                }
                firebaseisInitialized.current=true;
                const data = await api.callService("obtain-credentials-firebase",{
                    userUuid: user?.userUuid,
                })
                const firebaseSA = data.sensitiveData.firebase as any;  
                let firebaseConf = window.atob(firebaseSA);
                // eslint-disable-next-line
                firebaseConf = firebaseConf.replace(/([{,])(\s*)([A-Za-z0-9_\-]+?)\s*:/g, '$1"$3":');
                firebaseConf = JSON.parse(firebaseConf);

                appRef.current= initializeApp(firebaseConf);;
                auth(appRef.current);
                dataBase.current=database(appRef.current);
            } catch (error) {
                firebaseisInitialized.current=false;
                console.error("Error al conectarse firebase",error)
            }
                
        }, [api,user])

        const subscribeMultisessionEvent = (cb:()=>void)=>{
            const ref = dataBase.current?.ref(
                `onBoardingMultiSessionEvents/${user?.userUuid}`
            );
            
            ref?.on('value',(snapshot)=>{
                let event = snapshot.val()
                if(event && event.status){
                     cb()
                }
            },(error: any)=>{
                console.log("Error al escuchar eventos de firebase",error)
            })
        }

        return {dataBase, loadSAFirebase,subscribeMultisessionEvent,firebaseisInitialized};
    });