export const i18nDefault = {
  HELLO: 'Hola a todos',
  DISCLAIMER: 'albo empresa es un servicio operado por Inteligencia en Finanzas, S.A.P.I. de C.V., I.F.P.E. (albo) mediante el cual se pueden llevar a cabo operaciones de recepción, transferencia de recursos o dispersión de pagos en la cuenta de Fondos de Pago Electrónico. albo es una empresa debidamente autorizada por la Comisión Nacional Bancaria y de Valores para organizarse y operar como Institución de Fondos de Pago Electrónico de conformidad con la Ley para Regular las Instituciones de Tecnología Financiera y las disposiciones que de ella emanan.',
  BUSINESS_HELP_EMAIL: 'alboempresas@albo.mx',
  HELP_EMAIL: 'ayudaempresas@albo.mx',
  HELP_TITLE: 'Ayuda',
  REQUIRED_FIELD: 'Campo obligatorio',
  REQUIRED_FIELDS: 'Campos obligatorios',
  COUNTRY: 'País',
  POSTAL_CODE: 'Código postal',
  POSTAL_CODE_HELP_DESCRIPTION:
    'Al ingresar tu código postal, algunos datos de la dirección se autocompletarán.',
  STATE: 'Estado',
  TOWN: 'Ciudad',
  TOWN_MUNICIPALITY: 'Alcaldía / Municipio',
  NEIGHBORHOOD: 'Colonia',
  STREET: 'Calle',
  STREET_ADDRESS: 'Número exterior',
  APARTAMENT_NUMBER: 'Número interior',
  REFERENCE: 'Referencia',
  HOME: 'Inicio',
  ADMINS: 'Administradores',
  PLATFORM_ADMINS: 'Administradores de la plataforma',
  AREA: 'Área',
  ADD: 'Agregar',
  MAIL: 'Correo',
  PHONE: 'Teléfono',
  EDIT: 'Editar',
  MAIL_AND_PHONE: 'correo y teléfono',
  NEXT: 'Siguiente',

  LANDING_WELCOME_TITLE: 'Conoce más de albo empresa',
  LANDING_WELCOME_TEXT:
    'albo empresa es la plataforma para hacer y recibir pagos 24/7, con la flexibilidad que tu negocio necesita',
  LANDING_WELCOME_LIST_FIRST_ITEM: 'Sin comisiones ni costos de apertura',
  LANDING_WELCOME_LIST_SECOND_ITEM: 'Operación 100% digital y segura',
  LANDING_WELCOME_LIST_THIRD_ITEM: 'Tu cuenta lista en 96 horas',

  EXIT_PROMPT_DIALOG_TITLE: '¡Guarda tu avance!',
  EXIT_PROMPT_DIALOG_TEXT:
    'Podrás regresar para continuar la carga de tu información más tarde.',
  CANCEL: 'Cancelar',
  SAVE_AND_EXIT: 'Guardar y salir',

  USER_ALREADY_EXISTS_DIALOG_TITLE: 'Usuario ya existente',
  USER_ALREADY_EXISTS_DIALOG_TEXT:
    'Detectamos que ya existe un usuario registrado con el correo o teléfono que ingresaste.',
  USER_ALREADY_EXISTS_DIALOG_BUTTON_TEXT_CANCEL: 'Regresar al sitio',
  USER_ALREADY_EXISTS_DIALOG_BUTTON_TEXT_CONFIRM: 'Inicia sesión',

  DELETE_PROMPT_DIALOG_TITLE: '¿Seguro que deseas realizar esta acción?',
  DELETE_PROMPT_DIALOG_TEXT:
    'Si continuas y cambias de opinión, tendrás que agregar al accionista nuevamente.',
  LEGAL_REPRESENTATIVE_DELETE_PROMPT_DIALOG_TEXT:
    'Si continuas y cambias de opinión, tendrás que agregar al representante legal nuevamente.',
  EMPLOYEE_DELETE_PROMPT_DIALOG_TEXT:
    'Si continuas y cambias de opinión, tendrás que agregar al colaborador nuevamente.',

  DELETE: 'Eliminar',

  LOGIN_FORM__TITLE: 'Perfil de empresa',
  PASSWORD: 'Contraseña',
  ENTER: 'Entrar',
  FORGET_MY_PASSWORD: 'Olvidé mi contraseña',

  ON_BOARDING_INTRO_TITLE: '¡Te registraste con éxito!',
  ON_BOARDING_INTRO_PM_SUBTITLE:
    'Estamos listos para continuar con la apertura de tu cuenta albo empresa de forma 100% digital. ¡Adiós visitas a sucursales!',
  ON_BOARDING_INTRO_BOTTOM_FIRST_ITEM_TEXT:
    'Tu información es confidencial y está segura en albo',
  ON_BOARDING_INTRO_BOTTOM_SECOND_ITEM_TEXT:
    'Puedes pausar tu registro y retomarlo en cualquier momento sin peder tu avance',
  ON_BOARDING_INTRO_BOTTOM_THIRD_ITEM_TEXT: 'Si tienes dudas, escríbenos a',

  ON_BOARDING_INTRO_BUTTON_TEXT: 'Comenzar',

  ON_BOARDING_INTRO_PM_TOP_FIRST_ITEM_TEXT:
    'Registra a los propietarios reales y accionistas',
  ON_BOARDING_INTRO_PM_TOP_SECOND_ITEM_TEXT:
    'Sube los documentos e información de tu empresa',
  ON_BOARDING_INTRO_PM_TOP_THIRD_ITEM_TEXT:
    'Cuéntanos cómo usarás tu cuenta albo y solicita tarjetas para tu equipo',
  ON_BOARDING_INTRO_PM_RIGHT_SIDE_TITLE:
    'Documentos requeridos para abrir tu cuenta de albo empresa',
  ON_BOARDING_INTRO_PM_RIGHT_SIDE_BOTTOM_TEXT:
    '*Propietarios reales con más del 25% de la compañía. Si uno de los propietarios mayoritarios es Persona Moral, te pediremos algunos datos adicionales',
  ON_BOARDING_INTRO_PM_RIGHT_SIDE_BOTTOM_TEXT_DOCUMENTS:
    'Procura que todos tus documentos sean legibles y vigentes, así tu proceso será más rápido.',
 
    ON_BOARDING_INTRO_PM_RIGHT_SIDE_FIRST_SUBTITLE: 'Documentos de la compañia',
  ON_BOARDING_INTRO_PM_RIGHT_SIDE_FIRST_LIST_FIRST_ITEM: 'Acta constitutiva con registro público.',
  ON_BOARDING_INTRO_PM_RIGHT_SIDE_FIRST_LIST_SECOND_ITEM: 'Cédula RFC',
  ON_BOARDING_INTRO_PM_RIGHT_SIDE_FIRST_LIST_THIRD_ITEM:
    'Comprobante de domicilio',
  ON_BOARDING_INTRO_PM_RIGHT_SIDE_FIRST_LIST_FOUR_ITEM:
    'E.firma',  

  ON_BOARDING_INTRO_PM_RIGHT_SIDE_SECOND_SUBTITLE:
    'Documentos del representante legal',
  ON_BOARDING_INTRO_PM_RIGHT_SIDE_SECOND_LIST_FIRST_ITEM:
    'INE o pasaporte vigente',
  ON_BOARDING_INTRO_PM_RIGHT_SIDE_SECOND_LIST_SECOND_ITEM: 'RFC',

  ON_BOARDING_INTRO_PM_RIGHT_SIDE_THIRD_SUBTITLE:
    'Documentos de propietarios reales*',
  ON_BOARDING_INTRO_PM_RIGHT_SIDE_THIRD_LIST_FIRST_ITEM:
    'INE o pasaporte vigente',
  ON_BOARDING_INTRO_PM_RIGHT_SIDE_THIRD_LIST_SECOND_ITEM: 'CURP',
  ON_BOARDING_INTRO_PM_RIGHT_SIDE_THIRD_LIST_THIRD_ITEM: 'RFC',
  ON_BOARDING_INTRO_PM_RIGHT_SIDE_THIRD_LIST_FOURTH_ITEM:
    'Comprobante de domicilio',

  ON_BOARDING_INTRO_PFAE_SUBTITLE:
    'Estás cerca de obtener tu cuenta de albo empresa. De cumplir con todos los requisitos, en 96 horas tendrás tu cuenta lista para recibir y transferir dinero a todo México.',
  ON_BOARDING_INTRO_PFAE_TOP_FIRST_ITEM_TEXT:
    'Para abrir una cuenta de empresa necesitas tener una cuenta de albo',
  ON_BOARDING_INTRO_PFAE_TOP_SECOND_ITEM_TEXT:
    'Sube los documentos e información de tu negocio',
  ON_BOARDING_INTRO_PFAE_TOP_THIRD_ITEM_TEXT:
    'Cuéntanos cómo usarás tu cuenta albo y solicita tarjetas para tu equipo',
  ON_BOARDING_INTRO_PFAE_RIGHT_SIDE_TITLE:
    'Documentos requeridos para abrir tu cuenta de albo empresa',
  ON_BOARDING_INTRO_PFAE_RIGHT_SIDE_BOTTOM_TEXT:
    '*Propietarios reales con más del 25% de la compañía. Si uno de los propietarios mayoritarios es Persona Moral, te pediremos algunos datos adicionales',

  ON_BOARDING_INTRO_PFAE_RIGHT_SIDE_LIST_FIRST_ITEM:
    'Correo con el que te registraste en la app de albo personal',
  ON_BOARDING_INTRO_PFAE_RIGHT_SIDE_LIST_SECOND_ITEM:
    'Cédula RFC que valide que estás registrado como Persona Física con Actividad Empresarial',
  ON_BOARDING_INTRO_PFAE_RIGHT_SIDE_LIST_THIRD_ITEM:
    'Comprobante de Comprobante de domicilio (menor a 3 meses)',
  ON_BOARDING_INTRO_PFAE_RIGHT_SIDE_LIST_FOURTH_ITEM: 'CURP',

  ON_BOARDING_MENU_TITLE: 'Registra la información de tu empresa',
  ON_BOARDING_MENU_TEXT:
    'Los datos solicitados son determinados por la Ley para regular las Instituciones de Tecnología Financiera, las cuales son supervisadas por la Comisión Nacional Bancaria y de Valores y Banco de México',
  ON_BOARDING_ADDITIONAL_MENU_TITLE: 'Completa la siguiente información',
  ON_BOARDING_ADDITIONAL_MENU_TEXT:
    'Los datos adicionales solicitados, son determinados por la Ley para regular las Instituciones de Tecnología Financiera, las cuales son supervisadas por la Comisión Nacional Bancaria y de Valores y Banco de México',

  ACCOUNT_ADMIN: 'Administrador de cuenta',
  ACCOUNT_ADMIN_DESCRIPTION:
    'La persona que registres como Administrador, podrá hacer uso del dinero de la cuenta, cambiar configuraciones y recibirá notificaciones de todos los movimientos y actualizaciones. Puedes agregar más Administradores después.',
  ON_BOARDING_MENU_PFAE_ADMIN_ACCOUNT_CARD_DESCRIPTION:
    'Registra la información de contacto y una identificación',

  ON_BOARDING_MENU_PFAE_CONFIRM_CONTRACT_DATA_CARD_TITLE:
    'Confirmación de datos para contrato',
  ON_BOARDING_MENU_PFAE_CONFIRM_CONTRACT_DATA_CARD_DESCRIPTION:
    'Confirma el correo y teléfono al que enviaremos el contrato',

  BUSINESS_DATA_ACCOUNT: 'Datos de empresa y cuenta',
  ON_BOARDING_MENU_PFAE_BUSINESS_DATA_ACCOUNT_CARD_DESCRIPTION:
    'Cuéntanos sobre tu negocio',

  BENEFICIARIES: 'Beneficiarios',
  ON_BOARDING_MENU_PFAE_BENEFICIARIES_CARD_DESCRIPTION:
    'Agrega a los beneficiarios de tu cuenta',

  CARDS: 'Tarjetas',
  ON_BOARDING_MENU_PFAE_CARDS_CARD_DESCRIPTION:
    'Si las necesitas, solicita tarjetas albo para tus colaboradores y proveedores',

  LEGAL_REPRESENTATIVE: 'Representante legal',
  ON_BOARDING_MENU_PM_LEGAL_REPRESENTATIVE_CARD_DESCRIPTION:
    'Ingresa los datos de los representantes de tu empresa',

  ON_BOARDING_MENU_PM_CONFIRM_CONTRACT_DATA_CARD_TITLE:
    'Confirmación de datos para contrato',
  ON_BOARDING_MENU_PM_CONFIRM_CONTRACT_DATA_CARD_DESCRIPTION:
    'Confirma el correo y teléfono al que enviaremos el contrato',

  ON_BOARDING_MENU_PM_SHAREHOLDERS_CARD_TITLE: 'Accionistas',
  ON_BOARDING_MENU_PM_SHAREHOLDERS_CARD_DESCRIPTION:
    'Agrega la información y documentos de cada uno de los accionistas de la empresa',

  BUSINESS_DATA: 'Datos de la empresa',
  ON_BOARDING_MENU_PM_BUSINESS_DATA_CARD_DESCRIPTION:
    'Sube tu Acta Constitutiva, RFC, constancia de e.firma y logo',

  ORGANIZATION_BUSINESS_DATA: 'Organigrama empresarial',
  ON_BOARDING_MENU_PM_ORGANIZATION_BUSINESS_DATA_CARD_DESCRIPTION:
    'Agrega los datos del Director General de la empresa y sus reportes directos.',

  PEP_DATA: 'Persona Expuesta Políticamente',
  ON_BOARDING_MENU_PM_PEP_DATA_CARD_DESCRIPTION:
    'Agrega a personas con funciones políticas, gubernamentales, judiciales o militares.',

  ON_BOARDING_MENU_PM_ADDITIONAL_INFORMATION_COMPANY:
    'Datos adicionales de la empresa',
  ON_BOARDING_MENU_PM_COMPLEMENTARY_INFORMATION_COMPANY:
    'Información complementaria',
  ON_BOARDING_MENU_PM_ADDITIONAL_INFORMATION_COMPANY_CARD_DESCRIPTION:
    'Agrega la información relacionada a los ingresos de la empresa.',
  ON_BOARDING_MENU_PM_ADDITIONAL_INFORMATION_COMPANY_FINISH_CARD_DESCRIPTION:
    'Agrega la información relacionada a los ingresos de la empresa para concluir el expediente.',
  PEP_DATA_SHORT: 'PEP',

  ON_BOARDING_MENU_PM_EXTRA_INFO_DATA_CARD_DESCRIPTION:
    'Agrega los datos complementarios de la empresa para concluir el expediente.',

  ACCOUNT_CONFIG: 'Personaliza tu cuenta',
  ON_BOARDING_MENU_PM_ACCOUNT_SETTINGS_CARD_DESCRIPTION:
    'Personaliza tu cuenta según las necesidades de tu empresa',

  ON_BOARDING_MENU_PM_ADMINS_CARD_TITLE: 'Administradores',
  ON_BOARDING_MENU_PM_ADMINS_CARD_DESCRIPTION:
    'Registra los datos e identificación del administrador de la cuenta',

  ON_BOARDING_MENU_PM_CARDS_CARD_TITLE: 'Tarjetas',
  ON_BOARDING_MENU_PM_CARDS_CARD_DESCRIPTION:
    'Si las necesitas, solicita tarjetas albo para tus colaboradores y proveedores',

  LANDING_CONTACT_FORM_TITLE: 'Conoce más de albo empresa',
  LANDING_CONTACT_FORM_TEXT:
    'Completa tus datos y nos pondremos en contacto contigo',

  NAME: 'Nombre(s)',
  LAST_NAMES: 'Apellidos',
  CONTACT_PHONE_NUMBER: 'Teléfono de contacto',
  YOUR_JOB_EMAIL: 'Tu correo de trabajo',
  PERSON_TYPE: 'Tipo de persona',
  PERSON_TYPE_PLACEHOLDER: 'La cuenta que abrirás es para uso de una',
  INTERESTED_IN_ALBO_FOR: 'Me interesa albo para',
  SIZE_OF_YOUR_TEAM: 'Tamaño de tu equipo',
  SEND: 'Enviar',

  SIGN_UP_FORM_FORM_TITLE: 'Abre tu cuenta albo empresa',
  SIGN_UP_FORM_FORM_TEXT: 'Completa los datos y crea una contraseña segura.',

  PATERNAL_LASTNAME: 'Apellido paterno',
  MATERNAL_LASTNAME: 'Apellido materno',
  CREATE_PASSWORD: 'Crea una contraseña',
  PASSWORD_HELP_TITLE: 'Contraseña segura',
  AT_LEAST_EIGHT_CHARACTERS: 'Al menos 8 caracteres',
  NO_MORE_THAN_TWO_SECUENCIAL_CHARACTERS:
    'No más de 2 caracteres secuenciales (ej. 123)',
  NO_MORE_THAN_TWO_EQUAL_CHARACTERS: 'No más de 2 caracteres iguales (ej. 222)',
  NO_MORE_THAN_TWO_EQUAL_CHARACTERS_ERROR:
    'No se permite escribir más de 2 caracteres consecutivos iguales',
  DO_NOT_INCLUDE_WORD_ALBO_OR_YOUR_BUSINESS_NAME:
    'No incluyas la palabra albo ni el nombre de tu empresa',
  CONFIRM_YOUR_PASSWORD: 'Confirma tu contraseña',
  CONTINUE: 'Continuar',

  ADMIN_FORM_FORM_TITLE: 'Administrador de cuenta',
  ADMIN_FORM_FORM_TEXT: 'Registra tus datos para la apertura de tu cuenta.',

  REMEMBER: 'Recuerda',
  ADMIN_FORM_CARD_DESCRIPTION:
    'Asegúrate de registrar los datos de manera correcta, estos mismos serán usados para configurar la',
  ADMIN_FORM_CARD_SIGN_CONTRACT_TEXT: 'firma de tu contrato.',

  EMAIL: 'Correo electrónico',
  OCCUPATION: 'Ocupación',
  COUNTRY_CODE: 'Código de país',
  PHONE_NUMBER: 'Número celular',
  BIRTH_COUNTRY: 'País de nacimiento',
  BIRTH_DATE: 'Fecha de nacimiento',
  OFICIAL_IDENTIFICATION: 'Identificación oficial',
  OFICIAL_IDENTIFICATION_FRONT: 'Identificación oficial frente',
  OFICIAL_IDENTIFICATION_BACK: 'Identificación oficial vuelta',
  OFICIAL_IDENTIFICATION_SELFIE: 'Una selfie del rostro',
  OFICIAL_IDENTIFICATION_SELFIE_HELP: 'Sube una foto sosteniendo la identificación junto al rostro, donde ambos se vean claramente.',
  OFICIAL_IDENTIFICATION_HELP_DESCRIPTION:
    'Sube una foto en formato jpg o png de tu identificación oficial. INE o Pasaporte vigente',
  CURP: 'CURP',
  CURP_HELP_DESCRIPTION:
    'Código alfanumérico único de identidad de 18 caracteres. Lo puedes consultar aquí: https://www.gob.mx/curp/',
  PLATFORM_ROLE: 'Rol en la plataforma',

  COMPANY_INFO_ACCOUNT: 'Datos de empresa y cuenta',
  COMPANY_INFO_ACCOUNT_FORM_TEXT:
    'Cuéntanos un poco de tu empresa. Toda tu información es confidencial y está protegida.',

  RFC_WITH_HOMOCLAVE: 'RFC con homoclave',
  THIRTEEN_CHARACTER_ALPHANUMERIC_KEY:
    'Clave conformada por una longitud alfanumérica de 13 caracteres',
  TWELVE_CHARACTER_ALPHANUMERIC_KEY:
    'Clave conformada por una longitud alfanumérica de 12 caracteres',
  RFC_CEDULE_OR_CIF: 'Cédula RFC o CIF',
  RFC_CEDULE: 'Cédula RFC',
  BUSINESS_RFC_CEDULE: 'Cédula RFC reciente de la empresa',
  BUSINESS_RFC_CEDULE_HELP_DESCRIPTION:
    'Sube el RFC de la empresa en formato PDF',

  COMERCIAL_NAME: 'Nombre comercial',
  COMERCIAL_NAME_HELP_DESCRIPTION:
    'El nombre con el que se conoce comercialmente a tu producto o servicio',

  ACCOUNT_NICKNAME: 'Alias de cuenta',
  ACCOUNT_NICKNAME_HELP_DESCRIPTION:
    'Elige un alias para identificar esta cuenta; por ejemplo: "Nómina", "Proveedores", "Sucursal A", etc.',

  ACCOUNT_SOCIETY_TYPE: 'Tipo de sociedad',

  LOGO: 'Logotipo',
  LOGO_HELP_DESCRIPTION: 'Usa una imagen en formato JPG o PNG, menor a 2MB',

  MAIN_BUSINESS_ACTIVITY: 'Actividad económica de la empresa o negocio',
  MAIN_BUSINESS_ACTIVITY_HELP_DESCRIPTION: 'La que indica en tu CIF',

  SOURCE_ACCOUNT_RESOURCES: 'Origen de los recursos de la cuenta',
  EXPECTED_RESOURCES_USAGE: 'Uso principal de esta cuenta',
  EXPECTED_RESOURCES_USAGE_HELP_DESCRIPTION:
    'El uso principal que le darás a los recursos que genera tu empresa',

  ACCOUNT_CONFIG_TARGET_ACCOUNT_RESOURCES:
    'Destino principal del dinero de la cuenta',
  ACCOUNT_CONFIG_SECTION_TITLE_ACCOUNT_DEPOSIT: 'Depósitos a la cuenta',
  ACCOUNT_CONFIG_SECTION_FIELD_MONTHY_MONEY_IN: 'Depósitos estimados al mes',
  ACCOUNT_CONFIG_SECTION_FIELD_MONTHY_MONEY_IN_AMOUNT: 'Monto mensual estimado',
  ACCOUNT_CONFIG_SECTION_FIELD_MONTHY_MONEY_IN_ACCOUNTS:
    'Cantidad de cuentas de las que recibes',

  ACCOUNT_CONFIG_SECTION_TITLE_ACCOUNT_TRANSFER:
    'Transferencias a otras cuentas',
  ACCOUNT_CONFIG_SECTION_FIELD_MONTHY_MONEY_OUT:
    'Transferencias estimadas al mes',
  ACCOUNT_CONFIG_SECTION_FIELD_MONTHY_MONEY_OUT_AMOUNT:
    'Monto mensual estimado',
  ACCOUNT_CONFIG_SECTION_FIELD_MONTHY_MONEY_OUT_ACCOUNTS:
    'Cantidad de cuentas a las que envías',
  ACCOUNT_CONFIG_SECTION_FIELD_SELFIE: 'Prueba de vida',
  ACCOUNT_CONFIG_SECTION_FIELD_SELFIE_DESCRIPTION:
    'Sube una selfie sosteniendo tu identificación oficial, esto nos ayudará a validar que eres real.',
  AVERAGE_MONTHLY_TRANSACTIONALITY_EXPECTED:
    'Transaccionalidad mensual promedio',
  AVERAGE_MONTHLY_TRANSACTIONALITY_EXPECTED_HELP_DESCRIPTION:
    'El dinero mensual que vas a recibir, enviar y gastar.',
  AVERAGE_MONTHLY_TRANSACTIONALITY_EXPECTED_HELP_DESCRIPTION_MDP:
    'MDP = "millones de pesos"',

  ADDRESS_VOUCHER: 'Comprobante de domicilio',
  ADDRESS_VOUCHER_HELP_DESCRIPTION:
    'Carga un formato JPG, PNG o PDF. El comprobante debe de ser menor a 3 meses',
  ADDRESS_FOR_NOTIFICATIONS: 'Domicilio para notificaciones',
  HOME_ADDRESS: 'Domicilio Particular',

  COMPANY_ADMINS_TEXT:
    'Las personas que registres aquí recibirán notificaciones relacionadas a la apertura y movimientos de la cuenta. Además serán las que tendrán acceso y manejarán el dinero de la misma.',
  ADD_ADMIN: 'Agregar administrador',
  ADD_ADMIN_DESCRIPTION:
    'Ingresa los datos de la persona que tendrá acceso a la plataforma',
  COMPLETE_YOUR_INFORMATION: 'Completa la información',
  USE_THIS_PERSON_AS_MAIN_ADMIN: 'Esta persona será el administrador principal',
  CARDS_FOR_YOUR_COLLABORATORS: 'Tarjetas para tus colaboradores',
  NUMBER_OF_CARDS_REQUIRED: 'Cantidad de tarjetas requeridas',
  TOTAL_GUARANTEE_DEPOSIT: 'Total depósito en garantía',
  PREFERRED_DELIVERY_TIME: 'Horario preferente de entrega',
  DATA_OF_WHO_RECEIVES: 'Datos de quien recibe',
  FULL_NAME_OF_WHO_RECEIVES: 'Nombre completo de quien recibe',
  DELIVERY_ADDRESS: 'Domicilio de entrega',
  USE_MY_FISCAL_ADDRESS: 'Usar la dirección fiscal',
  USE_SAME_ADDRESS_OF_MY_BUSINESS: 'Usar la misma dirección de mi negocio',
  CURRENCY: 'MXN',

  CARD_REQUEST_INFO_TITLE:
    'Tus colaboradores también pueden tener una cuenta albo',
  CARD_REQUEST_INFO_LIST_FIRST_ITEM:
    'Manejo desde la app móvil. ¡Adiós sucursales, filas y comisiones! ',
  CARD_REQUEST_INFO_LIST_SECOND_ITEM:
    'Tarjeta Mastercard internacional para compras y pagos en todo el mundo.',
  CARD_REQUEST_INFO_LIST_THIRD_ITEM: '2 retiros en cajero sin comisión al mes.',
  CARD_REQUEST_INFO_LIST_FOURTH_ITEM:
    'Paga nómina a tus colaboradores de forma fácil.',
  CARD_REQUEST_INFO_DESCRIPTION_FIRST_PART:
    'Cada tarjeta tiene un depósito en garantía de $50 pesos + IVA que',
  CARD_REQUEST_INFO_DESCRIPTION_SECOND_PART:
    'cuando tus colaboradores activan su tarjeta en la app.',
  CARD_REQUEST_INFO_DESCRIPTION_BOLD_TEXT: 'se reembolsa completamente',
  REQUEST_CARDS: 'Solicitar tarjetas',
  CORPORATE_CARD: 'Tarjeta corporativa',
  COMING_SOON: '¡Próximamente!',
  MORE_INFO: '+ Más información',
  CLOSE_SESION: 'Cerrar sesión',
  INFORMATION: 'Información',
  REVISION: 'Revisión',
  EXTRA_INFO: 'Información adicional',
  VALIDATION: 'Validación',
  IDENTITY_VALIDATION: 'Validación de identidad',
  IDENTITY_VALIDATION_MANUAL: 'Validación de identidad manual',
  IDENTITY_VALIDATION_DESCRIPTION: 'Al iniciar el proceso de validación se guardará de forma automática la información del representante legal. A continuación vamos a validar tu identidad. Para esto vamos a necesitar que nos otorgues permisos para utilizar la cámara de tu computadora o tu teléfono.',
  AGREEMENT: 'Contrato',
  ACCOUNT_CREATED: 'Alta de cuenta',
  WELCOME: '¡Bienvenido!',
  LOGIN_YOUR_ACCOUNT: 'Ingresa a tu cuenta',
  NEED_HELP: '¿Necesitas ayuda?',
  POWERED_BY: 'Powered by',
  PHONE_OR_MAIL_INCORRECT_QUESTION: '¿El correo o teléfono son incorrectos?',
  RETURN_TO_MODULE_OF: 'Regresa al módulo de',
  TO_CORRECT_THEM: 'para corregirlos',
  CONFIRM_AGREEMENT_DATA: 'Confirmación de datos para contrato',
  AGREEMENT_ACCEPTANCE: 'aceptación del contrato',
  BE_SURE_OF: 'Asegúrate que el',
  OF: 'de los',
  REGISTERED_ARE_CORRECT_THIS_WILL_BE_USED_FOR:
    'registrados sean los correctos, estos serán usados para la',
  LEGAL_REPRESENTATIVES: 'Representantes legales',
  IF_DATA_IS_WRONG_YOU_CAN_CORRECT_IT_ON_PREVIOS_MODULE:
    'Si alguno de estos datos es incorrecto por favor corrígelo en el módulo anterior.',
  YOUR_ACCOUNT_IS_BEING_CREATED: 'Firma de contrato completada',
  CREATING_ACCOUNT_DESCRIPTION:
    'En menos de 4 días, tu cuenta estará lista y podrás comenzar a usarla. Te notificaremos por correo y por este medio.',
  THANKS_FOR_BEING_PART_OF_ALBO_WE_ARE_WORKING_ON_CREATING_YOUR_ACCOUNT:
    '¡Gracias por ser parte de albo! Estamos trabajando para que comiences a usar tu cuenta cuanto antes.',
  CONTRACT_STATUS_DONE_DESCRIPTION:
    'Validaremos la aceptación del contrato para avanzar a la apertura de la cuenta. Por favor revisa tu correo, este será el medio para notificarte el estatus de apertura. Toma en cuenta que si existen más personas involucradas en el contrato, todos deben aceptarlo.',
  CONTRACT_ACCEPTED: 'Contrato aceptado',
  CONTRACT_PENDING: 'Contrato pendiente de aceptación',
  CONTRACT_MUST_BE_ACCEPTED_BY: 'El contrato debe de ser aceptado por los',
  YOU_REGISTERED_SO_OUR_TEAM_CAN_PROCEED_TO_CREATE_YOUR_ACCOUNT:
    'que registraste para que nuestro equipo pueda avanzar a la apertura de la cuenta.',
  THANKS_WE_ARE_REVISING_YOUR_DOCUMENTS:
    '¡Gracias! Estamos revisando tus documentos',
  DOCUMENTS_PENDING_DESCRIPTION:
    'Te notificaremos vía correo electrónico y por este medio una vez que los validemos o en caso de necesitar información adicional.',
  DOCUMENTS_REQUIRED_DESCRIPTION:
    'Después de revisar la documentación, requerimos que se vuelvan a cargar algunos documentos, mismos que vendrán especificados en el siguiente paso. Esto es necesario para poder avanzar con el proceso de apertura de tu cuenta.',
  EXTRA_INFO_REQUIRED_DESCRIPTION:
    'Una vez que los validemos o en caso de necesitar información adicional, te contactaremos en tu correo y en esta plataforma.',
  DOCUMENTS_PENDING: 'Validando documentos',
  DOCUMENTS_REQUIRED: 'Requerimos más información',
  STATUS: 'Estatus',
  WE_NEED_ADITIONAL_INFO: 'Necesitamos información adicional',
  UPLOAD_INFO: 'Subir información',
  SHOW_DOCS: 'Ver documentos',
  YOUR_ALBO_ACCOUNT_HAS_BEEN_CREATED: '¡Tu cuenta albo está lista!',
  WELCOME_WE_SENT_TO_YOUR_MAIL_IMPORTANT_INFORMATION:
    'Te damos la bienvenida. Enviamos información importante a tu correo.',
  GO_TO_MY_ACCOUNT: 'Entrar a mi cuenta',
  E_SIGN: 'e.firma',
  E_SIGN_HELP_DESCRIPTION: 'Es el certificado vigente emitido por el SAT.',
  E_SIGN_ALTERNATIVE: 'Alternativa a archivo e.firma',
  E_SIGN_ALTERNATIVE_HELP_DESCRIPTION:
    'Si no tienes la constancia de emisión de la firma Electrónica Avanzada FIEL o e. firma, puedes subir el nùmero de serie del Certificado Digital',
  SOCIAL_REASON: 'Razón social',
  SOCIAL_REASON_HELP_DESCRIPTION: 'Tal como aparece en tu RFC',
  ECONOMIC_ACTIVITY: 'Actividad económica',
  ECONOMIC_ACTIVITY_HELP_DESCRIPTION:"Escríbela tal y como se encuentra en tu acta constitutiva.",
  CERTIFICATE_OF_VULNERABLE_ACTIVITY: 'Certificado de actividad vulnerable',
  CERTIFICATE_OF_VULNERABLE_ACTIVITY_HELP_DESCRIPTION:
    'Aplica solamente si tu actividad económica es considerada vulnerable',
  SERVICES_OFFERED: 'Servicios que ofrece',
  COUNTRY_OF_CONSTITUTION: 'País de constitución',
  CERTIFICATE_CONSTITUTIVE: 'Acta constitutiva',
  CERTIFICATE_CONSTITUTIVE_HELP_DESCRIPTION:"Asegúrate que tu acta tenga adjunto el Registro Público de la Propiedad y Comercio (RPP).",
  DATE_OF_CONSTITUTION: 'Fecha de constitución',
  

  EMPLOYEES_NUMBER: 'Número de empleados',
  TOTAL_NUMBER_OF_EMPLOYEES: 'Número total de trabajadores',
  WEBSITE_OR_SOCIAL_NETWORK: 'Sitio web o redes sociales',
  BUSINESS_EMAIL: 'Correo electrónico de la empresa',
  BUSINESS_PHONE: 'Teléfono de la empresa',
  BUSINESS_PHONE_HELP_DESCRIPTION: 'El número de teléfono fijo de tu negocio',
  FISCAL_ADDRESS: 'Domicilio fiscal',
  FISCAL_ADDRESS_DESCRIPTION: 'Esta dirección se mostrará en tus estados de cuenta.',
  PHYSICAL_ADDRESS: 'Domicilio físico',
  ADDRESS_BUSINESS: 'Domicilio de la empresa',
  ALL_INFO_IS_CONFIDENTIAL:
    'Toda tu información es confidencial. Únicamente la usaremos para crear tu expediente.',
  BENEFICIARY_RELATIONSHIP: 'Parentesco del beneficiario',
  ASSIGNED_PERCENTAGE: 'Porcentaje asignado',
  BENEFICIARIES_DESCRIPTION:
    'Los beneficiarios son las personas que recibirían los fondos de la cuenta en caso de fallecimiento del titular. Puedes agregar los que quieras, siempre y cuando se asigne el 100% de los fondos.',
  ADD_BENEFICIARY: 'Agregar beneficiario',
  ADD_BENEFICIARY_DESCRIPTION: 'Agrega la información de tu beneficiario',
  PENDING_INFO: 'Información pendiente',
  ADDRESS: 'Domicilio',
  GENERAL_INFO: 'Información general',
  ACCOUNT_CONFIG_DESCRIPTION:
    'Esta información nos ayudará a conocer el perfil de tu cuenta y a detectar actividad inusual para mayor seguridad.',
  LEGAL_REPRESENTATIVE_REMEMBER_FIRST_PART_DESCRIPTION:
    'El representante legal principal (y los apoderados adicionales, si es que existen) son las únicas personas facultadas para firmar el contrato, además se les creará un registro como',
  OF_THE_PLATFORM: 'de la plataforma.',
  LEGAL_REPRESENTATIVE_DESCRIPTION:
    'Agrega al o los representantes legales de tu empresa. Debes agregar al menos uno para continuar.',
  LEGAL_REPRESENTATIVE_DESCRIPTION_DRAWER:
    'Es la única persona con la facultad para firmar el contrato, al registrarlo se le creará un acceso como administrador en la plataforma.',
  USE_MY_INFO: 'Usar mi información',
  I_AM_LEGAL_REPRESENTATIVE: 'Yo soy Representante legal',
  WHAT_ID_WILL_YOU_USE: '¿Qué identificación usarás?',
  POWER_OF_ATTORNEY: 'Poder notarial del representante legal',
  POWER_OF_ATTORNEY_HELP_DESCRIPTION:
    'Carga un formato PDF. Es un documento público que utiliza la persona física o moral para designar a otro como representante legal, de manera que pueda actuar en su nombre',
  LEGAL_REPRESENTATIVE_WITH_MORE_THAN_TWENTY_FIVE_PERCENT:
    'El representante legal es Accionista con más del 25% de la empresa',
  LEGAL_REPRESENTATIVE_OR_FAMILIAR_PEP:
    'El representante legal o un pariente es PEP (Persona expuesta políticamente)',
  CHECK_THE_OPTION_THAT_APPLIES: 'Marca la opción que aplique',
  MAIN_LEGAL_REPRESENTATIVE_IS_PEP: 'El representante legal principal es PEP',
  PEP_IS_FAMILY: 'El PEP es un pariente',
  PEP_IS_FAMILY_AND_MAIN_LEGAL_REPRESENTATIVE:
    'El PEP es un pariente y el representante legal principal',
  ENTER_PEP_INFO: 'Ingresa la información del PEP',
  ENTER_FAMILIAR_PEP_INFO: 'Ingresa la información del pariente PEP',
  DEPENDENCY_NAME: 'Nombre de la dependencia',
  ANTIQUITI: 'Antigüedad',
  POSITION_HELD: 'Puesto desempeñado',
  ADD_ADITIONAL_ATTORNEY: 'Agregar apoderado adicional',
  ADD_LEGAL_REPRESENTATIVE: 'Agregar representante legal',
  ADD_ADDITIONAL_ATTORNEY_TITLE:
    'Si la firma de tu contrato será mancomunada, agrega a los apoderados adicionales',
  SHAREHOLDERS: 'Accionistas',
  SHAREHOLDERS_DESCRIPTION:
    'Agrega los accionistas dependiendo del tipo de persona que es',
  ADD_SHAREHOLDER: 'Agregar accionista',
  ADD_SHAREHOLDER_OF: 'Agregar accionista de',
  ADD_MORAL_SHAREHOLDER: 'Agregar accionista de tipo persona moral',
  PHYSICAL_PERSON_WITH_BUSINESS_ACTIVITY:
    'Persona física con actividad empresarial',
  SHAREHOLDER_INFO: 'Información de accionista',
  MORAL_SHAREHOLDER_SOCIAL_REASON:
    'Ingresa la razón social de la empresa accionista',
  BUSINESS_SOCIAL_REASON: 'Razón social de la empresa',
  ENTER_BUSINESS_SHAREHOLDERS:
    'Ingresa los accionistas de la empresa que estás registrando',
  PERCENTAGE_OF_SHARES: 'Porcentaje de acciones',
  PERCENTAGE_OF_SHARES_ASSIGNED: 'Porcentaje de acciones asignado',
  BACK: 'Atrás',
  PHYSICAL_PERSON: 'Persona física',
  MORAL_PERSON: 'Persona moral',
  VALIDATE_EMAIL: 'Valida tu correo electrónico',
  VERIFY_YOUR_MAIL: 'Verifica tu correo',
  VALIDATE_EMAIL_DESCRIPTION:
    'Revisa el correo que te enviamos y haz click en el botón para verificarlo. El correo expira en',
  TEN_MINUTES: '10 minutos',
  REGISTER_AND_VERIFY_YOUR_PHONE: 'Registra y verifica tu teléfono',
  REGISTER_AND_VERIFY_YOUR_PHONE_DESCRIPTION:
    'Ingresa el número de celular que quieres asociar a tu cuenta. Aquí recibirás un código vía SMS para ingresar de forma segura a la plataforma.',
  CONFIRM_PHONE_DESCRIPTION:
    'Ingresa el código de 4 dígitos que recibiste por SMS',
  SEND_CODE: 'Enviar código',
  RESEND_SMS_CODE: 'Reenviar código SMS',
  VALIDATE: 'Validar',
  SELECT_OPTION: 'Selecciona una opción',
  FILE_REQUIRED: 'Carga de archivo obligatoria',
  SEND_VALIDATION: 'Enviar a validación',
  SEND_REVISION: 'Enviar a revisión',
  USE_LETTERS_AND_NUMBERS: 'Debes combinar letras y números',
  MINIMUM: 'Mínimo',
  CHARACTERS: 'caracteres',
  DO_NOT_USE_TWO_CONSECUTIVE_CHARACTERS:
    'No se permite escribir más de 2 caracteres de forma secuencial',
  DO_NOT_INCLUDE_WORD_ALBO: 'No se permite usar la palabra "albo"',
  AT_LEAST_ONE_SPECIAL_CHARACTER:
    'Debes usar al menos un carácter especial (ej.  ¡!#$*=@_+:)',
  PASSWORD_DOES_NOT_MATCH: 'La contraseña no coincide',
  INVALID_EMAIL: 'Formato de correo incorrecto',
  YOUR_COMPANY_HAS_SHAREHOLDERS_QUESTION: '¿Tu empresa tiene accionistas?',
  YOUR_COMPANY_HAS_SHAREHOLDERS_DESCRIPTION:
    'En caso de que los tenga, deberás agregar la información de cada uno',
  YES_ADD_SHAREHOLDERS: 'Si, agregar accionistas',
  GENDER: 'Género',
  NATIONALITY: 'Nacionalidad',
  OCUPATION_PROFESSION_ACTIVITY: 'Ocupación, profesión o actividad',
  PASSPORT: 'Pasaporte',
  INE: 'INE',
  SELECT_IDENTIFICATION_TYPE:
    'Selecciona el tipo de identificación que cargarás',
  UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION:
    'Sube una foto en formato jpg o png de tu INE (frente y vuelta). Cuida que sea legible y esté vigente',
  INCORRECT_EMAIL_OR_PASSWORD_TRY_AGAIN:
    'El correo o contraseña son incorrectos. Intenta nuevamente',
  UNDERSTOOD: 'Entendido',
  ENTER_FOUR_DIGIT_CODE_SENT_TO_PHONE:
    'Ingresa el código de 4 dígitos enviado al número',
  ENTER_FOUR_DIGIT_CODE_SENT_TO_REGISTERED_PHONE:
    'Ingresa el código de 4 dígitos enviado al celular registrado',
  AUTHENTICATION_REQUIRED: 'Autenticación requerida',
  RESTORE_PASSWORD: 'Restablecer contraseña',
  RESTORE_PASSWORD_DESCRIPTION:
    'Ingresa el correo que utilizaste para el registro a tu expediente para recibir las instrucciones de recuperación de tu contraseña',
  COME_BACK_TO_LOGIN: 'Regresar a login',
  EMAIL_SENT_FOLLOW_INSTRUCTIONS:
    'Correo enviado, sigue las instrucciones para recuperar tu contraseña.',
  ERROR_SENDING_EMAIL_MESSAGE:
    'Error al enviar el correo. Utiliza el correo que usaste para el registro de tu expediente.',
  CODE_ENTERED_IS_INCORRECT:
    'El código que ingresaste es incorrecto. Intenta nuevamente',
  EMAIL_VERIFIED_CONTINUE_REGISTERING_YOUR_PHONE:
    'Correo verificado con éxito. Continua al registro de tu teléfono.',
  EMAIL_VERIFIED: 'Tu correo está verificado.',
  SAVE_PROGRESS: 'Guardar avance',
  FINISH: 'Finalizar',
  SKIP_AND_FINISH: 'Saltar y finalizar',
  ADVICE_OF_PRIVACY: 'Aviso de privacidad',
  ON_CLICK_CONTINUE_YOU_ACCEPT_THE: 'Al dar click en “Continuar” aceptas el',
  RESEND_CODE_AVAILABE_IN: 'Reenvío disponible en',
  IF_MARKED_THIS_LEGAL_REPRESENTATIVE_WILL_BE_ADDED_TO_SHAREHOLDERS_MODULE:
    'Al marcar esta casilla, el representante legal también será agregado al módulo de accionistas.',
  LEGAL_REPRESENTATIVE_IS_PEP_HELP_DESCRIPTION:
    'Es aquella persona que desempeña o ha desempeñado funciones públicas destacadas en un país extranjero o en territorio nacional, considerando entre otros, a los jefes de estado o de gobierno, líderes políticos, funcionarios gubernamentales, judiciales o militares de alta jerarquía, altos ejecutivos de empresas estatales o funcionarios o miembros importantes de partidos políticos.',
  FEDERAL_ENTITY_OF_BIRTH: 'Entidad Federativa de Nacimiento',
  FEDERAL_ENTITY_OF_BIRTH_HELP_DESCRIPTION:
    'Este campo solo aplica para personas con nacionalidad Mexicana.',
  ELECTOR_KEY: 'Clave de Elector',
  ELECTOR_KEY_HELP_DESCRIPTION:
    'Podrás encontrar la clave en la parte frontal de tu INE. Se conforma de 18 caracteres.',
  ELECTOR_KEY_FORMAT_INVALID: 'Formato de Clave de elector inválido',
  CURP_FORMAT_INVALID: 'Formato de CURP inválido',
  RFC_FORMAT_INVALID: 'Formato de RFC inválido',
  SAVE: 'Guardar',
  RETURN: 'Regresar',
  EXCEEDS_TOTAL_PERCENTAGE:
    'El porcentaje debe ser menor o igual al disponible',
  BENEFICIARY_SAVED: 'Beneficiario guardado',
  CHECK_AND_ACCEPT_AGREEMENT: 'Revisa y acepta el contrato',
  PEOPLE_INVOLVED: 'Involucrados',
  VALIDATE_AND_ACCEPT: 'Validar y aceptar',
  ACCEPT_FULL_READING_CONTRACT: 'Acepta la lectura total del contrato',
  SMS_AUTHENTICATION_REQUIRED: 'Autenticación SMS requerida',
  I_ACCEPT_I_HAVE_READ_CONTRACT_TEXT:
    'Acepto que he leído el contrato en su totalidad y estoy de acuerdo con las declaraciones del mismo',
  SIGNED: 'Firmado',
  PENDING: 'Pendiente',
  FAILED: 'Fallida',
  RETRY_FAILED: 'Intento fallido',
  SUCCESSFUL: 'Exitosa',
  UPDATE_PASSWORD: 'Actualizar contraseña',
  RESET_YOUR_PASSWORD: 'Reestablece tu contraseña',
  WRITE_YOUR_NEW_PASSWORD: 'Escribe tu nueva contraseña',
  CONFIRM_YOUR_NEW_PASSWORD: 'Confirma tu nueva contraseña',
  HAVING_SECURE_PASSWORD_HELP_YOU_PROTECT_YOUR_ACCOUNT:
    'Tener una contraseña segura te ayuda a proteger tu cuenta',
  MEET_PASSWORD_REQUIREMENTS: 'Cumple con los requisitos para la contraseña:',
  USE_EIGHT_OR_MORE_CHARACTERS: 'Usa 8 o mas caracteres',
  USE_MIXED_LETTERS_NUMBERS_SPECIAL_CHARACTERS:
    'Utiliza una combinación de letras, números y caracteres especiales',
  DO_NOT_USE_MORE_THAN_TWO_EQUAL_CHARACTERS:
    'No utilices más de 2 caracteres iguales',
  DO_NOT_USE_MORE_THAN_TWO_SECUENCIAL_CHARACTERS:
    'No utilices más de 2 caracteres de forma secuencial',
  DO_NOT_USE_ALBO_WORD_OR_CLIENT_IDENTIFICATION:
    'No puede incluir la palabra albo ni el identificador de cliente',
  PASSWORD_UPDATED_SUCCESSSFULLY:
    'La contraseña ha sido actualizada con éxito.',
  YOU_WILL_RECEIVE_AN_EMAIL_WITH_THE_CONFIRMATION:
    'Recibirás un correo electrónico con la confirmación.',
  OPS_PASSWORD_UPDATED_SUCCESSSFULLY_TITLE:
    'Tu contraseña se creó correctamente.',
  OPS_PASSWORD_UPDATED_SUCCESSSFULLY:
    'Ingresa a iNext, selecciona un prospecto e inicia sesión en onboarding para actualizar su información.',
  

  LOG_IN: 'Iniciar sesión',
  LOADING: 'Cargando...',
  RELATIONSHIP: 'Parentesco',
  LINK_EXPIRED: 'Enlace expirado',
  MAKE_YOUR_REGISTRATION_AGAIN_TO_RECEIVE_VERIFICATION_EMAIL:
    'Realiza nuevamente tu registro para recibir un correo de verificación',
  GO_TO_SIGN_UP: 'Ir a registro',
  NO_MORE_THAN_THREE_MONTHS: '(No mayor a 3 meses)',
  INVALID_DATE_FORMAT: 'Formato de fecha inválido',
  BIRTH_STATE: 'Estado de nacimiento',
  DELETE_BENEFICIARY_DESCRIPTION:
    'Recuerda que los beneficiarios que registres deben sumar el 100%.',
  PREFERED_DELIVERY_TIME: 'Horario preferente de entrega',
  ADD_ME_TO_WAIT_LIST: 'Unirme a la lista de espera',
  JOIN_WAIT_LIST:
    'Únete a la lista de espera y sé de los primeros en probar la tarjeta corporativa.',
  NEXT_TIME: 'En otro momento',
  EXIT: 'Salir',
  YOU_HAVE_BEEN_ADDED_TO_WAIT_LIST:
    'Te haz unido a la lista. ¡Espera noticias muy pronto!',
  REMAINING_PERCENTAGE_TO_ASSIGNED: 'Porcentaje restante por asignar:',
  MINIMUM_PERCENTAGE_TO_REGISTER: 'El porcentaje mínimo a registrar es 25%',
  SKIP: 'Saltar',
  IS_LEAD_DESCRIPTION:
    'Si deseas eliminar a este accionista debes desactivar la opción “El representante legal es Accionista con más del 25% de la empresa” ubicada en el módulo de Representante Legal.',
  THIS_PERSON_IS_ALSO: 'Esta persona también es',
  COMPLETE_INFO_TO_REGISTER_AS: 'Completa la información como',
  SHAREHOLDER: 'accionista',
  IF_YOU_WANT_TO_DELETE_LEAD_SHAREHOLDER_UNCHECK_OPTION:
    'Si deseas eliminarlo del listado de accionistas desactiva la opcion',
  IN_LEGAL_REPRESENTATIVE_MODULE: 'en el módulo de Representante legal',
  THIS_PERSON_IS_ALSO_PEP: 'Esta persona también es PEP',
  SHORT_LEGAL_REPRESENTATIVE: 'Rep.Legal',
  THIS_PERSON_IS_ALSO_LEGAL_REPRESENTATIVE_DESCRIPTION:
    'Esta persona también es representatne legal. Si quieres hacer cambios de información deberás regresar a la seción de "Representante legal"',
  COMPLETE_YOUR_INFO_THIS_CARD_CAN_NOT_BE_DELETED:
    'Completa y guarda tu información. Esta tarjeta no puede se eliminada.',
  COMPLETE_YOUR_PENDING_INFO_ADMIN:
    'Completa la información pendiente para tu registro de',
  ADMIN: 'Administrador',
  DELETE_ADMIN_DIALOG_DESCRIPTION:
    'Si continuas y cambias de opinión, tendrás que agregar al administrador nuevamente.',
  ACCEPT: 'Aceptar',
  TEMPORAL_BLOCK: 'Bloqueo temporal',
  TEMPORAL_BLOCK_DESCRIPTION:
    'Superaste el número de intentos para ingresar a la plataforma, espera',
  TO_TRY_AGAIN: ' para volver a hacerlo.',
  IF_THE_PROBLEM_PERSISTS_CONTACT_US_IN:
    'Si el problema persiste, contáctanos en:',
  COMPLETE_DOCUMENTATION_TEXT:
    '¡Completaste todos los módulos! Ahora, envía tus documentos a validación.',
  UPLOAD_OF_DOCUMENTS: 'Carga de documentos',
  UPLOAD_OF_DOCUMENTS_DESCRIPTION:
    'Carga los formatos de los documentos aquí mencionados y vuelve a enviar a validación para que nuestro equipo pueda continuar con el proceso de revisión.',
  UPLOAD_DOCUMENTS_AGAIN: 'Carga nuevamente los archivos de',
  UPLOAD_REQUIRED_DOCUMENTS: 'Carga la documentación adicional requerida',
  DETAIL: 'Detalle',
  TEMPORAL_BLOCK_INFO_DESCRIPTION_FIRST_PART:
    'Superaste el número de intentos para ingresar el código SMS, espera',
  TEMPORAL_BLOCK_INFO_DESCRIPTION_SECOND_PART:
    'para volver a entrar: por seguridad serás dirigido al inicio de sesión',
  FILE_TYPE_NOT_VALID_TRY_AGAIN:
    'Tipo de archivo no válido. Intenta nuevamente',
  SIGN_CONTRACT_TEMPORAL_BLOCK_DESCRIPTION_FIRST_PART:
    'Superaste el número de de intentos para ingresar el código SMS de firma de contrato. Espera',
  SIGN_CONTRACT_TEMPORAL_BLOCK_DESCRIPTION_SECOND_PART:
    'para volver a ingresar e intentarlo',
  SIGN_CONTRACT: 'Firmar contrato',
  PERSONAL_INFO: 'Información personal',
  PERSONAL_INFO_DESCRIPTION:
    'Registra la información de contacto y una identificación',
  EMAIL_YOU_REGISTERED_IN_ALBO: 'Correo con el que te registraste en albo',
  EMAIL_YOU_REGISTERED_IN_ALBO_DESCRIPTION:
    'Captura el correo con el que abriste cuenta en la app de albo. Puedes dejar el campo vacío si aún no descargas la app, sin embargo es requisito para la apertura de tu cuenta en albo empresa.',
  BUSINESS_ADDRESS: 'Dirección de la empresa',
  WITH_MORE_THAN_TWENTY_FIVE_PERCENTAGE_SHARES: 'con más del 25% de acciones',
  IS_THE_SAME_AS_FISCAL_ADDRESS: 'Es el mismo que el domicilio de la empresa',
  ONLY_ADDS_SHAREHOLDERS_UP_TO_TWENTY_FIVE_PERCENTAGE:
    'Agrega únicamente a los accionistas que tienen más del 25% de participación.',
  THE_PERSON_REGISTERED_HERE_WILL_HAVE_ADMINISTRATOR_ACCESS:
    'La persona que registres aquí también se le creará un acceso de administrador.',
  I_WILL_BE_THE_PRINCIPAL_ACCOUNT_ADMIN: 'Yo soy el Administrador de la cuenta',
  UPLOAD_INE: 'Carga de INE',
  IS_THE_SAME_AS_CIF_ADDRESS: 'Es el mismo que el registrado en el CIF',
  PERCENTAGE_TO_BE_ASSIGNED: 'Porcentaje por asignar',
  DO_YOU_WANT_TO_DELETE_THIS_BENEFICIARY_QUESTION:
    '¿Quieres eliminar este beneficiario?',
  BENEFICIARY_ADDED: 'Beneficiario agregado',
  YOU_HAVE: 'Tienes',
  LEFT_TO_ASSIGN_TO_THE_OTHERS_BENEFICIARIES:
    'restante por asignar a otros beneficiarios.',
  CHANGE_YOUR_BENEFICIARIES_PERCENTAGES:
    'Cambia el porcentaje de tus beneficiarios',
  CHANGE_YOUR_BENEFICIARIES_PERCENTAGES_DESCRIPTION:
    'Ajusta los porcentajes de los beneficiarios registrados para poder agregar a otro beneficiario. Recuerda que en conjunto, deben ser el 100%',
  BENEFICIARY_UPDATED: 'Beneficiario actualizado',
  SIGN_YOUR_CONTRACT: 'Firma tu contrato',
  SIGN_YOUR_CONTRACT_DESCRIPTION:
    '¡Tus documentos ya están validados!, Ahora, revisa tu correo para ver y firmar el contrato de forma digital a través de la plataforma “weetrust”.',
  BUSINESS_NAME: 'Nombre de la empresa',
  SALARIES: 'Salarios',
  BONS: 'Bonos',
  COMMISSIONS: 'Comisiones',
  PAYMENTS_TO_SUPPLIERS: 'Pago a proveedores',
  OTHERS: 'Otros',
  THANKS_WE_SENT_YOU_THE_DETAILS_TO_YOUR_EMAIL:
    '¡Gracias! Te enviamos más detalles a tu correo.',
  CREATE_YOUR_PASSWORD_AND_COMPLETE_YOUR_BUSINESS_PROFILE:
    'Crea tu contraseña y completa tu perfil de empresa',
  REGISTER_YOUR_DATA_TO_RECEIVE_MORE_INFO:
    'Registra tus datos para recibir más información',
  CORPORATE_EMAIL: 'Correo corporativo',
  CORPORATE_EMAIL_HELP_TITLE: 'Asegúrate de tener acceso al correo',
  CORPORATE_EMAIL_HELP: 'Enviaremos una liga de acceso a este correo para verificarlo.',
  CONTACT_PHONE: 'Teléfono de contacto',
  IT_IS_REQUIRED: 'Se requieren',
  CHARACTERES_AT_LEAST: 'caracteres como mínimo',
  DATE_FORMAT: '(DD/MM/YYYY)',
  GO_THROUGH_PASSWORD_PROCESS_AGAIN_REMEMBER_LINK_HAS_AN_EXPIRATION_OF_24_HRS:
    'Realiza nuevamente el proceso de cambio de contraseña. Recuerda que el enlace que recibas tiene una duración de 24hrs.',
  ACCOUNT_ADMIN_DATA: 'Administrador de la cuenta',
  CARDS_REQUEST_FORM_DESCRIPTION:
    'Solicita tarjetas albo para tus colaboradores o proveedores sin costo* y recíbelas en la dirección que nos indiques.',
  CARDS_REQUEST_FORM_FOOTER:
    'Cada tarjeta tiene un depósito en garantía de $58 pesos (IVA incluido) que se te devuelve íntegramente una vez que tus colaboradores activen sus tarjetas desde la app de albo. Consulta los detalles en el contrato. Te contactaremos para coordinar el pago previó al envio.',
  UPDATE: 'Actualizar',
  INVALID_POSTAL_CODE_MUST_HAVE_FIVE_DIGITS:
    'Código postal no válido. Debe de tener una longitud de 5 dígitos',
  INVALID_POSTAL_CODE_CONTACT_CUSTOMER_SERVICE:
    'Este código no tuvo resultados. Verifícalo ',
  ADDRESS_REGISTERED_CIF: 'Domicilio registrado en el CIF',
  PERSONAL_DATA: 'Datos personales',
  BENEFICIARY: 'beneficiario',
  SUCCESS_SAVING_SHAREHOLDER: 'Accionista guardado con éxito.',
  SUCCESS_UPLOADING_INFO: 'Información actualizada con éxito.',
  SUCCESS_SAVING_LEGAL_REPRESENTATIVE:
    'Representante legal guardado con éxito.',
  ADD_ALL_YOUR_SHAREHOLDERS_OF_YOUR_COMPANY:
    'Agrega a todos los accionistas de tu empresa.',
  NAME_OR_DENOMINATION: 'Nombre o denominación',
  OR: 'o',
  TOWN_STATE: 'Ciudad / Estado',
  CONSTITUTION: 'constitución',
  BIRTH_PLACE: 'Lugar de nacimiento',
  PARTICIPATION_PERCENTAGE: 'Porcentaje de participación',
  IS_LISTED_ON_THE_STOCK_MARKET: '¿Cotiza en la bolsa de valores?',
  YES: 'Sí',
  NO: 'No',
  STOCK_NAME: 'Nombre de la bolsa',
  BLACKBOARD_KEY: 'Clave de pizarra (ticker)',
  PERCENTAGE_SHOULD_NOT_BE_MORE_THAN_ONE_HUNDRED:
    'El porcentaje no debe ser mayor al 100%',
  OTHER_MOMENT: 'En otro momento',
  DO_YOU_WANT_ALBO_CARDS_FOR_YOUR_COLLABORATORS:
    '¿Quieres tarjetas albo para tus colaboradores?',
  COOKIE_USE_POLICY: 'Política de uso de Cookies.',
  WHEN_USING_THE_PLATFORM_YOU_ACCEPT_OUR:
    'Al usar la plataforma, aceptas nuestra',
  LOG_IN_TO_YOUR_ACCOUNT: 'Ingresa a tu cuenta',
  RELATIONSHIP_WITH_PEP_QUESTION:
    '¿Alguien dentro de la empresa es una Persona Expuesta Políticamente? ',
  RELATIONSHIP_WITH_PEP_DESCRIPTION:
    'Una persona Expuesta Políticamente (PEP) tiene o ha tenido funciones políticas, gubernamentales, judiciales o militares de alta jerarquía, en México o el extranjero en los últimos 12 meses. ',
  MESSAGE_LOADER:
    'Permite el acceso a tu ubicación para poder acceder a la plataforma. Esto lo puedes hacer desde la barra de direcciones de tu navegador.',
  REASON_ACCOUNT: 'Motivo de esta cuenta',
  SOLE_OWNER: 'propietario real',
  SOLE_OWNERS: 'Propietarios reales',
  SOLE_OWNER_DESCRIPTION_1:
    'Es la persona física que obtiene los beneficios derivados de la cuenta y es el verdadero dueño de los recursos, es decir, ',
  SOLE_OWNER_DESCRIPTION_BOLD:
    'la persona física que, directa o indirectamente, tenga el 25% o más de la composición accionaria o del capital social. ',
  SOLE_OWNER_DESCRIPTION_2:
    'En caso de no existir, se considerará que el control lo ejerce el administrador o administradores de esa persona moral.',
  SOLE_OWNER_DESCRIPTION_REMEMBER:"Recuerda registrar a todas las personas físicas que, directa o indirectamente, tengan el 25% o más de la composición accionaria o del capital social.", 
  SOLE_OWNER_DESCRIPTION_FORM:
    'Es la persona física que funge como el verdadero dueño de los recursos de la empresa y tiene la facultad de hacer uso, disposición y dispersión de ellos.',
  ADD_SOLE_OWNER: 'Agregar propietario real',
  SUCCESS_SAVING_SOLE_OWNER: 'Propietario real guardado con éxito.',
  DATA_ADMINISTRATION_BOARD: 'Datos del Consejo de Administración',
  MEMBER_ADMINISTRATION_BOARD: 'Puesto del Consejo',
  DESCRIPTION_ADMINISTRATION_BOARD:
    'El consejo de Administración o Administrador Unico es el órgano o persona responsable de dirigir la administración y control de una empresa',
  DECLARATORY_SOLE_OWNER:
    'Bajo protesta de decir verdad, declaro que no existe una persona física que posea, directa o indirectamente, el 25% o más de la composición accionaria o del capital social de mi representada, por lo que los propietarios reales son los administradores, quienes ejercen el Control de la administración de la sociedad',
  ADDITIONAL_INFORMATION: 'Datos adicionales de la empresa',
  COMPLEMENTARY_INFORMATION_TITLE: 'Información complementaria',
  ENTER_ADDITIONAL_INFO_TO_RECORD:
    'Ingresa los datos para completar el expediente de la empresa.',
  HAS_FOREIGN_CLIENT: '¿Tienes clientes y/o proveedores en el extranjero?',
  BUSINESS_ACTIVITY: 'Actividad de la empresa',
  HAS_POINTS_OF_SALE: '¿Tienes sucursales o puntos de venta?',
  WHO_ARE_YOUR_MAIN_CLIENTS: '¿Quiénes son tus principales clientes?',
  WHO_ARE_YOUR_MAIN_PROVIDERS: '¿Quiénes son tus principales proveedores?',
  HAS_SHAREHOLDING_IN_OTHER_COMPANIES:
    '¿Tienes participación accionaria en otras empresas?',
  IS_YOUR_COMPANY_PART_OF_A_BUSINESS_GROUP:
    '¿Tu empresa es parte de un grupo empresarial?',
  BUSINESS_GROUP_INFO: 'Información del grupo empresarial',
  PRODUCT_OR_SERVICE_OFFERED: 'Productos o servicios que comercializa',
  MONEYIN_MONTHLY: 'Ingresos mensuales aproximados',
  ANNUAL_STATEMENT: 'Declaración anual de impuestos reciente',
  ANNUAL_STATEMENT_HELP_DESCRIPTION:
    'Sube tu declaración anual en PDF o JPG. En caso de que tu empresa se haya constituido en menos de un año sube tu declaración mensual.',
  QUANTITY_POINTS_OF_SALE: 'Número de sucursales o puntos de venta',
  LOCATION_STATE: 'Estados donde se ubican',
  DENOMINATION: 'Denominación',
  SOCIAL_OBJECT: 'Objecto social',
  NAME_OR_SOCIAL_REASON: 'Nombre o razón social',
  ADD_BUSINESS: 'Agregar empresa',
  NAME_COMPANY_PARTICIPATION: 'Nombre de la empresa en la que participa',
  COMMERCIAL_BUSSINESS: 'Giro comercial de la empresa',
  NOT: 'No',

  //ACCOUNT PEP FORM
  ACCOUNT_FORM_PEP_RELATION_TITLE: 'Personas Expuestas Políticamente',
  ACCOUNT_FORM_PEP_DESCRIPTION:
    'Agrega información de las personas cercanas a la empresa que tengan un cargo político, gubernamental, judicial o militar de alto rango.',

  ACCOUNT_FORM_PEP_HAVE_PEP_RELATION: 'Relación con la empresa',
  ACCOUNT_FORM_PEP_HAVE_PEP_RELATION_HELP:
    'Una Persona Expuesta Políticamente (PEP) tiene o ha tenido funciones políticas, gubernamentales, judiciales o militares de alta jerarquía, en México o el extranjero.',
  ACCOUNT_FORM_PEP_PLACE_OF_RESIDENCE: 'Lugar de residencia',

  ACCOUNT_FORM_PEP_JOB_UI_TITLE: 'Datos del puesto',
  ACCOUNT_FORM_PEP_JOB_UI_DESCRIPTION:
    'Los datos son confidenciales y únicamente los usaremos para crear tu expediente.',

  ACCOUNT_FORM_PEP_DEPENDECY: 'Nombre de la dependencia',
  ACCOUNT_FORM_PEP_JOP_POSITION: 'Cargo',
  ACCOUNT_FORM_PEP_ESTIMATED_SALARY: 'Sueldo estimado (MXN)',
  ACCOUNT_FORM_PEP_CURRENLY_WORKS_HERE: 'El cargo está vigente',
  ACCOUNT_FORM_PEP_DATE_OF_ADMISSION: 'Fecha de inicio del cargo (DD/MM/AAAA)',
  ACCOUNT_FORM_PEP_END_DATE: 'Fecha de fin del cargo (DD/MM/AAAA)',
  ACCOUNT_FORM_PEP_HAS_DEPENDENTS_TITLE: 'Tiene cónyugue o dependientes económicos',
  ACCOUNT_FORM_PEP_HAS_DEPENDENTS_DESCRIPTION: 'En caso de tener cónyugue o pendientes económicos es necesario agregarlos para poder continuar.',

  ACCOUNT_FORM_PEP_MODAL_TITLE: 'Agregar cónyugue o dependiente económico',
  ACCOUNT_FORM_PEP_MODAL_TITLE_UPDATE: 'Actualizar cónyugue o dependiente económico',

  ACCOUNT_FORM_PEP_MODAL_TITLE_ON_UPDATE: 'Actualizar cónyugue o dependiente económico',
  ACCOUNT_FORM_PEP_MODAL_DESCRIPTION: 'Ingresa los siguientes datos para completar el perfil',

  ACCOUNT_FORM_PEP_MODAL_FORM_TITLE: 'Información personal',
  ACCOUNT_FORM_PEP_MODAL_FORM_RELATION_TYPE: 'Tipo de relación con la PEP',

  ACCOUNT_FORM_PEP_DEFAULT_RELANTION_DESC: 'No hay relación con una PEP',
  ACCOUNT_FORM_PEP_DEFAULT_RELANTION_VALUE: '1',

  DO_YOU_WANT_TO_DELETE_THIS_DEPENDENT_QUESTION:
    '¿Quieres eliminar este dependiente?',

  DELETE_DEPENDENT_DESCRIPTION:
    'Recuerda que debes agregar como mínimo a un dependiente en caso de que PEP los tenga',
  DEPENDENT_ADDED: 'Dependiente agregado',
  DEPENDENT_UPDATED: 'Dependiente actualizado',



  ACCOUNT_FORM_PEP_MODAL_HAS_DEPENDENT_SI: 'Si',
  ACCOUNT_FORM_PEP_MODAL_HAS_DEPENDENT_NO: 'No',

  GENERAL_DIRECTOR_DATA: 'Información del Director General',
  WORK_POSITION: 'Cargo',
  EMPLOYEES_WHO_REPORT_TO_THE_DIRECTOR_COMPANY:
    'Colaboradores que reportan directamente al Director de la empresa',
  YOU_NEED_TO_ADD_AT_LEAST_ONE_EMPLOYEE_TO_CONTINUE:
    'Es necesario que agregues al menos un colaborador para continuar',
  ADD_EMPLOYEE: 'Agregar colaborador',
  ADD_EMPLOYEE_DESCRIPTION:
    'Ingresa los datos del colaborador de la jerarquía inmediata inferior al Director General de la empresa.',
  UPDATE_EMPLOYEE: 'Actualizar colaborador',
  THE_EMPLOYEE_MUST_BE_OF_LEGAL_AGE: 'El colaborador debe ser mayor de edad',
  MUST_BE_BELOW_ONE_HUNDRED_YEARS: 'Se debe ingresar menos de 100 años.',
  MUST_BE_BELOW_TODAY: 'Se debe ingresar una fecha menor a la actual',

  YEARS_CARRYING: 'Años desempeñando la actividad',
  ANOTHER_SOURCE_INCOME: '¿Tiene otra fuente de ingresos?',
  OTHER_SOURCE: 'Fuente de ingresos adicional',
  NAME_WITHOUT_ABBREVIATION: 'Escribe el nombre completo sin abreviaturas',
  YOU_MUST_HAVE_AT_LEAST_ONE_SHAREHOLDER: 'Debes tener al menos un accionista',
  YOU_MUST_HAVE_AT_LEAST_ONE_SHAREHOLDER_DESCRIPTION:
    'Para continuar, es necesario que agregues al menos un accionista de la empresa.',
  YOU_MUST_HAVE_AT_LEAST_ONE_LEGAL_REPRESENTATIVE:
    'Debes tener al menos un representante legal',
  YOU_MUST_HAVE_AT_LEAST_ONE_LEGAL_REPRESENTATIVE_DESCRIPTION:
    'Para continuar, es necesario que agregues al menos un representante legal de la empresa.',
  DATE_MUST_BE_BELOW_ONE_HUNDRED_YEARS: 'La fecha debe ser menor a 100 años.',
  SELFIE_OF_YOUR_FACE: 'Una selfie del rostro',
  SELFIE_OF_YOUR_FACE_HELP_DESCRIPTION: 'Sube una foto sosteniendo la identificación junto al rostro, donde ambos se vean claramente',
  WE_ARE_SORRY_TO_INFORM_YOU_HAVE_BEEN_REJECTED: 'Lamentamos informarte que tu solicitud fue rechazada',
  WE_ARE_SORRY_TO_INFORM_YOU_HAVE_BEEN_REJECTED_DESCRIPTION: 'En cumplimiento a nuestras políticas internas, no podemos ofrecerte nuestros servicios en este momento. Lamentamos los inconvenientes que esto pueda ocasionarte.',
  REQUEST_REJECTED: 'Solicitud rechazada',
  DELETE_LAST_BENEFICIARY_DESCRIPTION: 'Será eliminado definitivamente de tu registro. Al no tener beneficiarios, nadie podrá reclamar los fondos de la cuenta en caso de fallecimiento del titular.',
  BENEFICIARY_DELETED_SUCCESSFULLY: 'Beneficiario eliminado con éxito',
  MULTISESSION_DIALOG_TITLE: 'Se ha iniciado sesión en otro dispositivo',
  MULTISESSION_DIALOG_DESCRIPTION_1: 'Si no fuiste tú, ponte en contacto de inmediato con nosotros en',
  MULTISESSION_DIALOG_DESCRIPTION_2: 'para poder apoyarte. Por seguridad de tu cuenta, la plataforma no permite sesiones activas simultáneas.',
  REVISION_PENDING_TITLE: '¡Actualizamos tu información!',
  REVISION_PENDING_FIRST_DESCRIPTION: 'Validamos tus documentos y con ellos actualizamos tu información.',
  REVISION_PENDING_SECOND_DESCRIPTION: 'Revisa que la información de las secciones azules sea correcta y si hay secciones en amarillo, completa tus datos faltantes para continuar con tu proceso.',
  REVIEW_MY_INFO: 'Revisar mi información',
  REVISION_PENDING: 'Revisión pendiente',
  CONFIRM: 'Confirmar',
  IDENTITY_VALIDATE: 'Validar identidad',
  DESCRIPTION_STATUS_INITIATED: 'Validación pendiente',
  DESCRIPTION_STATUS_FAILED: 'Validación fallida',
  DESCRIPTION_STATUS_SUCCESSFUL: 'Validación exitosa',
  DESCRIPTION_STATUS_MANUAL: 'Validación manual pendiente',


  TITLE_DIALOG_WARNING_AGENT: '¡Aún no terminas el prospecto anterior!',
  DESCRIPTION_DIALOG_WARNING_AGENT: 'No olvides guardar tu avance de la edición en el prospecto anterior antes de empezar uno nuevo.',
  BUTTON_DIALOG_WARNING_AGENT: 'Iniciar con el nuevo prospecto' ,
  BUTTON_CLOSE_DIALOG_WARNING_AGENT: 'Regresar',


  TITLE_LANDING_ERROR: 'Error al consultar el lead.',
  DESCRIPTION_LANDING_ERROR: 'Hubo un problema al realizar la acción. Por favor inicia sesión e intenta de nuevo.',
  BUTTON_LANDING_ERROR: 'Volver' ,
  
  TITLE_LANDING_INEXT_REDIRECT: 'Ingresa a iNext para continuar',
  BUTTON_LANDING_INEXT_REDIRECT: 'Ir a iNext',

  TITLE_UPDATE_CUSTOMER_INFO: "¿Quieres actualizar la información del cliente?",
  DESCRIPTION_UPDATE_CUSTOMER_INFO: "El cliente recibirá una notificación por correo 30 minutos después de hacer la última actualización de sus datos.",
  BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO: "Actualizar y notificar",

  OPS_ANALYST_UPDATE_SUCCESS: 'Se actualizó la información con éxito y en 30 minutos se le notificará al cliente',


  HELP_TEXT_ERROR_SEND_TO_VALIDATION:"Hubo un error al enviar tus datos a validación, escríbenos a ayudaempresas@albo.mx para continuar con tu proceso.",
 
};

