import { FormContainer } from '../../../../../../../../components-quick-win/form-container';
import { CompanyInfoForm } from '../../../../../../../../components-quick-win/company-info-form';

export const CompanyInfo = () => {
  return (
        <FormContainer>
          <CompanyInfoForm/>
        </FormContainer>
  );
};
