import {
  FlexGrid,
  Image,
  Typography,
  Button,
  CodeInput,
  useSnackbar,
  Snackbar,
  Link,
  useDialog,
} from '@albo-ui/core';
import { useEffect, useState } from 'react';
import { OnBoardingLoader } from '../../components-quick-win/on-boarding-loader';
import logoPrimarioIndigo from '../../images/Logo_Primario_Indigo.png';
import { useAuth } from '../../providers/auth-provider';
import { useTranslator } from '../../providers/translator-provider';
import { useTimers } from '../../providers/use-timer';
import { TemporalBlockInfoDialog } from '../../components-quick-win/temporal-block-info-dialog';

const timerName = 'login';

export const LoginCodeAuth = () => {
  const [code, setCode] = useState('');
  const { openSnackbar, ...snackbarProps } = useSnackbar();
  const { addTimer, timers } = useTimers();
  const [resendingCode, setResendingCode] = useState(false);
  const {
    maskedPhone,
    validatePhoneCode,
    loading,
    error,
    api,
    logout,
    lastCodeChangeDate,
  } = useAuth();
  const dialogProps = useDialog();

  const {
    ENTER,
    AUTHENTICATION_REQUIRED,
    ENTER_FOUR_DIGIT_CODE_SENT_TO_PHONE,
    CODE_ENTERED_IS_INCORRECT,
    UNDERSTOOD,
    RESEND_CODE_AVAILABE_IN,
    RESEND_SMS_CODE,
  } = useTranslator();

  const onVerifyCode = async () => {
    await validatePhoneCode(code);
  };

  const onCodeInput = (value: string) => {
    setCode(value);
  };

  const resendCode = async () => {
    setResendingCode(true);
    await api.callService('require-auth-sms-code', {
      action: 'LOGIN',
    });
    setResendingCode(false);
    var endDate = new Date();
    endDate.setMinutes(endDate.getMinutes() + 2);
    addTimer(timerName, endDate);
  };

  const onCloseDialog = () => {
    dialogProps.onClose();
    logout();
  };

  useEffect(() => {
    if (error === 'INVALID_PHONE_CODE') openSnackbar();
  }, [error, openSnackbar]);

  const openDialog = dialogProps.openDialog;

  useEffect(() => {
    if (error === 'USER_BLOCKED') openDialog();

    if (error === 'USER_NOT_ALLOWED') {
      var endDate = lastCodeChangeDate
        ? new Date(lastCodeChangeDate)
        : new Date();
      endDate.setMinutes(endDate.getMinutes() + 2);
      addTimer(timerName, endDate);
    }
  }, [error, addTimer, openDialog, lastCodeChangeDate]);

  if (loading || resendingCode)
    return <OnBoardingLoader isLoading={loading || resendingCode} />;

  return (
    <FlexGrid
      container
      direction='column'
      padding={8}
      fullWidth
      fullHeight
      style={{ backgroundColor: 'white', minHeight: '100vh', height: 'auto' }}
    >
      <TemporalBlockInfoDialog
        open={dialogProps.open}
        onClose={onCloseDialog}
      />
      <Snackbar
        open={snackbarProps.open}
        message={CODE_ENTERED_IS_INCORRECT}
        action={UNDERSTOOD}
        onClose={snackbarProps.onClose}
      />
      <FlexGrid
        item
        container
        alignItems='center'
        direction='column'
        spacing={10}
      >
        <FlexGrid item>
          <Image src={logoPrimarioIndigo} size='163px' />
        </FlexGrid>
        <FlexGrid
          item
          container
          direction='column'
          spacing={6}
          alignItems='center'
          style={{ textAlign: 'center' }}
        >
          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2'>{AUTHENTICATION_REQUIRED}</Typography>
            </FlexGrid>
            <FlexGrid item style={{ maxWidth: '530px' }}>
              <Typography variant='paragraph'>
                {ENTER_FOUR_DIGIT_CODE_SENT_TO_PHONE}{' '}
                <span className='f-bold'>{maskedPhone}</span>
              </Typography>
            </FlexGrid>
          </FlexGrid>
          <FlexGrid
            item
            container
            fullWidth
            direction='column'
            spacing={3}
            style={{ maxWidth: '450px' }}
          >
            <FlexGrid item>
              <CodeInput
                onInput={onCodeInput}
                size={4}
                error={error === 'INVALID_PHONE_CODE'}
              />
            </FlexGrid>
            <FlexGrid item>
              {timerName in timers ? (
                <>
                  <Typography variant='paragraph' color='lightNeutral' bold>
                    {RESEND_CODE_AVAILABE_IN}:{' '}
                  </Typography>
                  <Typography variant='paragraph' color='paragraph' bold>
                    {timers[timerName].timeLeft.value}
                  </Typography>
                </>
              ) : (
                <Link onClick={resendCode} bold>
                  {RESEND_SMS_CODE}
                </Link>
              )}
            </FlexGrid>
          </FlexGrid>
          <FlexGrid item fullWidth style={{ maxWidth: '450px' }}>
            <Button onClick={onVerifyCode} disabled={code.length < 4}>
              {ENTER}
            </Button>
          </FlexGrid>
        </FlexGrid>
      </FlexGrid>
    </FlexGrid>
  );
};
