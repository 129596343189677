import { FormContainer } from '../../../../../../../../components-quick-win/form-container';
import { CompanyOrganizationForm } from '../../../../../../../../components-quick-win/company-organization-form';

export const CompanyOrganization = () => {
  return (
    <FormContainer>
      <CompanyOrganizationForm />
    </FormContainer>
  );
};
