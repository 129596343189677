import {
  CheckBox,
  FlexGrid,
  Form,
  FormItemProps,
  Typography,
  useThemeProvider,
  useFormCheckBox,
  ActionCard,
  useDrawer,
  useDialog,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { FormItems } from '../form-items';
import { useValidators } from '../../utils/validators';
import { useDefaultValidations } from '../../utils/default-validations';
import { BottomActions } from '../bottom-actions';
import { useEffect, useState } from 'react';
import { useOnboardingForm } from '../../hooks/use-onboarding-form';
import { OnBoardingLoader } from '../on-boarding-loader';
import { useOnHomeCBS } from '../../providers/on-home-cbs-provider';
import { usePmStatusQuickWinAdditionalInfo } from '../../hooks/use-pm-status-quick-win-additional-info';
import { AddEmployeeDrawer } from './add-employee-drawer';
import { useItemsArrayManager } from '../../hooks/use-items-array-manager';
import { DeletePromptDialog } from '../delete-prompt-dialog';
import { useAuth } from '../../providers/auth-provider';
import { useFormsFields } from '../../providers/onboarding-form-provider';
import { useConfirm } from '../../components/confirm-dialog';
import { useUserRole } from '../../hooks/use-user-role';

type EmployeeType = {
  name: string;
};

export const CompanyOrganizationForm = () => {
  const formProps = useOnboardingForm({
    defaultParams: {
      isLead: false,
      general: {
        position: 'Director General',
      },
    },
    onboardingFormType: 'QUICK_WIN_COMPANY_ORGANIZATION',
    nextSectionUrl: 'pep-info',
    singleton: true,
  });
  const myInfoCheckBox = useFormCheckBox('isLead', formProps.form);
  const { theme } = useThemeProvider();
  const validators = useValidators();
  const defaultValidations = useDefaultValidations();
  const pmStatusAdditionalInfo = usePmStatusQuickWinAdditionalInfo();
  const homeCBS = useOnHomeCBS();
  const drawerProps = useDrawer();
  const deleteDialogProps = useDialog();
  const setShowDialog = homeCBS.setShowDialog;
  const companyOrganizationStatus =
    pmStatusAdditionalInfo.companyOrganizationStatus;
  const [updateIndex, setUpdateIndex] = useState<number | undefined>(undefined);
  const [deleteIndex, setDeleteIndex] = useState<number | undefined>(undefined);
  const {
    addItem,
    data: employeesData,
    updateItem,
    deleteItem,
    updateData,
  } = useItemsArrayManager({
    updateIndex,
    baseData: formProps.form.data.employees,
  });
  const { leadData } = useAuth();
  const setFormData = formProps.form.setData;
  const onFormInput = formProps.form.onInput;
  const checkBoxValue = myInfoCheckBox.value;
  const doesNotHaveEmployees = employeesData.length === 0;

  const {
    ORGANIZATION_BUSINESS_DATA,
    ON_BOARDING_MENU_PM_ORGANIZATION_BUSINESS_DATA_CARD_DESCRIPTION,
    REQUIRED_FIELD,
    DATE_FORMAT,
    USE_MY_INFO,
    GENERAL_DIRECTOR_DATA,
    NAME,
    PATERNAL_LASTNAME,
    MATERNAL_LASTNAME,
    BIRTH_DATE,
    WORK_POSITION,
    EMPLOYEES_WHO_REPORT_TO_THE_DIRECTOR_COMPANY,
    YOU_NEED_TO_ADD_AT_LEAST_ONE_EMPLOYEE_TO_CONTINUE,
    ADD_EMPLOYEE,
    EMPLOYEE_DELETE_PROMPT_DIALOG_TEXT,
    CONTINUE,
    UPDATE,
    TITLE_UPDATE_CUSTOMER_INFO,
    DESCRIPTION_UPDATE_CUSTOMER_INFO,
    BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO,
    SAVE_PROGRESS
  } = useTranslator();

  const { formFields ,findFields} = useFormsFields();
  
  useEffect(()=>{
    findFields('QUICK_WIN_COMPANY_ORGANIZATION');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const { DialogConfirm, confirm: showConfirm } = useConfirm({
    title: TITLE_UPDATE_CUSTOMER_INFO,
    message: DESCRIPTION_UPDATE_CUSTOMER_INFO,
    textConfirm: BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO
  });

  const handleClickSave = async () => {
    const confirm = await showConfirm();
    if (!confirm) {
      return;
    }
    formProps.saveFormOps();
  }

  const { isOpsAnalyst } = useUserRole();

  const employees: EmployeeType[] | undefined = employeesData?.map(
    (item: any) => ({
      name: `${item.gname} ${item.lname} ${item.slname}`,
    })
  );

  const generalDirectorInputs: FormItemProps<string>[] = formFields('QUICK_WIN_COMPANY_ORGANIZATION', [
    {
      placeholder: NAME,
      name: 'general.gname',
      type: 'input',
      required: true,
      disabled: checkBoxValue,
      ...defaultValidations.nameValidations(),
    },
    {
      placeholder: PATERNAL_LASTNAME,
      name: 'general.lname',
      type: 'input',
      required: true,
      disabled: checkBoxValue,
      ...defaultValidations.paternalLastNameValidations(),
    },
    {
      placeholder: MATERNAL_LASTNAME,
      name: 'general.slname',
      type: 'input',
      disabled: checkBoxValue,
      ...defaultValidations.maternalLastNameValidations(),
    },
    {
      placeholder: `${BIRTH_DATE} ${DATE_FORMAT}`,
      name: 'general.bdate',
      type: 'date',
      inputType: 'email',
      required: true,
      validators: [
        validators.isEmpty,
        validators.notValidDateFormat,
        validators.validateAge,
      ],
    },
    {
      placeholder: WORK_POSITION,
      name: 'general.position',
      type: 'input',
      required: true,
      disabled: true,
    },
  ]);

  const onAddEmployeeClick = () => {
    drawerProps.openDrawer();
  };

  const onDeleteEmployeeClick = (index: number) => {
    setDeleteIndex(index);
    deleteDialogProps.openDialog();
  };

  const onUpdateEmployeeClick = (index: number) => {
    setUpdateIndex(index);
    drawerProps.openDrawer();
  };

  const updateEmployeeData = (data: any) => {
    updateItem(data);
    drawerProps.onClose();
    setUpdateIndex(undefined);
  };

  const saveEmployee = (data: any) => {
    addItem(data);
    drawerProps.onClose();
  };

  const closeDeletePrompt = () => {
    setDeleteIndex(undefined);
    deleteDialogProps.onClose();
  };

  const deleteEmployee = () => {
    deleteItem(deleteIndex);
    deleteDialogProps.onClose();
  };

  useEffect(() => {
    if (checkBoxValue) {
      onFormInput((current: any) => ({
        ...current,
        general: {
          ...current.general,
          gname: leadData.general.gname,
          lname: leadData.general.lname,
          slname: leadData.general.slname,
        },
      }));
    } else {
      setFormData((currentData: any) => ({
        ...currentData,
        general: { position: 'Director General' },
      }));
    }
  }, [checkBoxValue, onFormInput, setFormData, leadData]);

  useEffect(() => {
    setFormData((currentData: any) => ({
      ...currentData,
      employees: employeesData,
    }));
  }, [employeesData, setFormData]);

  useEffect(() => {
    setShowDialog(companyOrganizationStatus !== 'done');
  }, [setShowDialog, companyOrganizationStatus]);

  if (formProps.saving || formProps.movingForward || formProps.loadingForm)
    return (
      <OnBoardingLoader
        isLoading={
          formProps.saving || formProps.movingForward || formProps.loadingForm
        }
      />
    );

  return (
    <>
      <DeletePromptDialog
        open={deleteDialogProps.open}
        description={EMPLOYEE_DELETE_PROMPT_DIALOG_TEXT}
        onConfirm={deleteEmployee}
        onClose={closeDeletePrompt}
      />

      {drawerProps.open && (
        <AddEmployeeDrawer
          {...drawerProps}
          onSave={saveEmployee}
          onUpdate={updateEmployeeData}
          updateData={updateData}
        />
      )}

      <Form {...formProps.form} onSubmit={isOpsAnalyst ? handleClickSave : formProps.moveForward}>
        <FlexGrid
          container
          fullHeight
          fullWidth
          direction='column'
          spacing={3}
          style={{ paddingBottom: '100px' }}
        >
          <FlexGrid item container direction='column' spacing={1}>
            <FlexGrid item>
              <Typography variant='h2'>{ORGANIZATION_BUSINESS_DATA}</Typography>
            </FlexGrid>
            <FlexGrid item>
              <Typography variant='small'>
                {
                  ON_BOARDING_MENU_PM_ORGANIZATION_BUSINESS_DATA_CARD_DESCRIPTION
                }
              </Typography>
            </FlexGrid>
          </FlexGrid>
          <FlexGrid container item spacing={2} direction='column'>
            <FlexGrid item>
              <Typography variant='smallTitle'>
                {GENERAL_DIRECTOR_DATA}
              </Typography>
            </FlexGrid>
            <FlexGrid item>
              <CheckBox {...myInfoCheckBox} text={USE_MY_INFO} />
            </FlexGrid>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='small' color='neutral' bold>
              <span style={{ color: theme.colors.required }}>*</span>{' '}
              {REQUIRED_FIELD}
            </Typography>
          </FlexGrid>
          <FlexGrid item container fullWidth>
            <FormItems inputs={generalDirectorInputs} />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={1}>
            <FlexGrid item>
              <Typography variant='smallTitle'>
                {EMPLOYEES_WHO_REPORT_TO_THE_DIRECTOR_COMPANY}
              </Typography>
            </FlexGrid>
            <FlexGrid item>
              <Typography variant='small'>
                {YOU_NEED_TO_ADD_AT_LEAST_ONE_EMPLOYEE_TO_CONTINUE}
              </Typography>
            </FlexGrid>
          </FlexGrid>
          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <ActionCard
                title={ADD_EMPLOYEE}
                onClick={onAddEmployeeClick}
                link
                style={{ height: '80px' }}
              />
            </FlexGrid>
            {employees &&
              employees.map(({ name }, index) => (
                <FlexGrid item key={index}>
                  <ActionCard
                    title={name}
                    style={{ height: '80px' }}
                    status='default'
                    onDelete={() => onDeleteEmployeeClick(index)}
                    onUpdate={() => onUpdateEmployeeClick(index)}
                  />
                </FlexGrid>
              ))}
          </FlexGrid>
        </FlexGrid>

        <DialogConfirm />

        <BottomActions
          saveProgressButton={
            pmStatusAdditionalInfo.companyOrganizationStatus !== 'done' && !isOpsAnalyst
          }
          saveButtonText={SAVE_PROGRESS}
          continueButton
          continueButtonText={isOpsAnalyst ? UPDATE : CONTINUE}
          onSaveProgress={formProps.saveForm}
          onContinue={isOpsAnalyst ? handleClickSave : formProps.moveForward}
          disableContinueButton={doesNotHaveEmployees}
        />
      </Form>
    </>
  );
};
