import { OnBoardingLoader } from '../../components-quick-win/on-boarding-loader';
import { useTranslator } from '../translator-provider';
import GeolocationService from './service';

const GeolocationProvider = ({ children }: any) => {

    const { container } = GeolocationService();
    const { MESSAGE_LOADER } = useTranslator();

    if (!container) {
        return (
            <OnBoardingLoader isLoading={true} message={MESSAGE_LOADER} />
        );
    }

    return (
        <>
            {children}
        </>
    );
};

export default GeolocationProvider;