import { useMemo } from 'react';
import { useAuth } from '../providers/auth-provider';

export const useUserRole = () => {
  const auth = useAuth();

  const isOpsAnalyst = useMemo(
    () => auth.user?.role === 'OPS_ANALYST',
    [auth.user?.role]
  );

  return { isOpsAnalyst };
};
