import { Typography, FlexGrid, useThemeProvider, Link } from '@albo-ui/core';
import { memo } from 'react';
import { useTranslator } from '../../../providers/translator-provider';

export type RightSideProps = {
  name?: string;
  onLogout: () => void;
};

export const RightSide = memo(({ name, onLogout }: RightSideProps) => {
  const { CLOSE_SESION } = useTranslator();
  const { theme } = useThemeProvider();
  return (
    <FlexGrid
      spacing={3}
      container
      justify='center'
      alignItems='center'
      fullHeight
    >
      <FlexGrid item>
        <Typography variant='small'>{name}</Typography>
      </FlexGrid>
      <FlexGrid item>
        <div
          style={{
            height: '23px',
            width: '2px',
            backgroundColor: theme.colors.neutral,
          }}
        />
      </FlexGrid>
      <FlexGrid item>
        <Link typography='small' color='sunset' bold onClick={onLogout}>
          {CLOSE_SESION}
        </Link>
      </FlexGrid>
    </FlexGrid>
  );
});
