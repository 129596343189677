import { CardRequestInfo } from '../../../../../../../../components-quick-win/card-request-info';
import { FormContainer } from '../../../../../../../../components-quick-win/form-container';

export const CardRequests = () => {
  return (
    <FormContainer>
      <CardRequestInfo />
    </FormContainer>
  );
};
