import {
  FlexGrid,
  RoundedIcon,
  useThemeProvider,
  Image,
  Typography,
  Form,
  useForm,
  Button,
  Alert,
  useAlert,
} from '@albo-ui/core';
import { FaMobileAlt } from 'react-icons/fa';
import logoPrimarioIndigo from '../../images/Logo_Primario_Indigo.png';
import { useTranslator } from '../../providers/translator-provider';
import { FormPhoneInput } from '../../components-quick-win/form-phone-input';
import { useSignUp } from '../../providers/sign-up-provider';
import { useHistory, useLocation } from 'react-router';
import { useValidators } from '../../utils/validators';
import { useEffect, useMemo } from 'react';
import { OnBoardingLoader } from '../../components-quick-win/on-boarding-loader';
import useLocalStorage from '../../hooks/use-local-storage';
import { LinkExpired } from '../link-expired';

export const RegisterVerifyPhone = () => {
  const { theme } = useThemeProvider();
  const [, setLoginRoute] = useLocalStorage('login-route', '');
  const validators = useValidators();
  const alertProps = useAlert();
  const openAlert = alertProps.openAlert;
  const history = useHistory();
  const location = useLocation();
  const search = location.search;
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const currentRoute = `${history.location.pathname}?token=${
    query.get('token') as string
  }`;

  const {
    savePhone,
    validateEmailToken,
    loading,
    isEmailTokenValidated,
    phone,
    phoneCode,
    phoneCodeDesc,
    phoneCountry,
    error,
  } = useSignUp();
  const formProps = useForm({
    phone: '',
    phoneCode: '+52',
    phoneCodeDesc: 'Mexico',
    phoneCountry: '484',
  });

  const {
    REGISTER_AND_VERIFY_YOUR_PHONE,
    REGISTER_AND_VERIFY_YOUR_PHONE_DESCRIPTION,
    SEND_CODE,
    EMAIL_VERIFIED,
  } = useTranslator();

  const onSendCode = async () => {
    if (formProps.hasErrors) {
      formProps.displayErrors();
    } else {
      await savePhone(
        formProps.data.phone,
        formProps.data.phoneCode,
        formProps.data.phoneCodeDesc,
        formProps.data.phoneCountry
      );
      history.push('confirm-phone');
    }
  };

  useEffect(() => {
    (async () => {
      validateEmailToken(query.get('token') as string);
    })();
  }, [query, validateEmailToken]);

  const setFormData = formProps.setData;

  useEffect(() => {
    if (phone) {
      setFormData((formData: any) => ({ ...formData, phone }));
    }
    if (phoneCode && phoneCodeDesc && phoneCountry)
      setFormData((formData: any) => ({
        ...formData,
        phoneCode,
        phoneCodeDesc,
        phoneCountry,
      }));
  }, [phone, phoneCode, phoneCodeDesc, phoneCountry, setFormData]);

  useEffect(() => {
    setLoginRoute(currentRoute);
  }, [currentRoute, setLoginRoute]);

  useEffect(() => {
    if (isEmailTokenValidated) openAlert();
  }, [isEmailTokenValidated, openAlert]);

  if (
    (!isEmailTokenValidated && isEmailTokenValidated !== undefined) ||
    error === 'AUTHORIZATION_NOT_ALLOWED'
  )
    return <LinkExpired />;

  if (loading) return <OnBoardingLoader isLoading={loading} />;

  return (
    <div style={{ height: '100vh' }}>
      <div style={{ position: 'absolute', width: '100%' }}>
        <Alert {...alertProps} description={EMAIL_VERIFIED} type='success' />
      </div>

      <FlexGrid
        container
        direction='column'
        padding={8}
        fullWidth
        fullHeight
        style={{ backgroundColor: 'white' }}
      >
        <FlexGrid
          item
          container
          alignItems='center'
          direction='column'
          spacing={10}
        >
          <FlexGrid item>
            <Image src={logoPrimarioIndigo} size='163px' />
          </FlexGrid>
          <FlexGrid
            item
            container
            direction='column'
            spacing={4}
            alignItems='center'
          >
            <FlexGrid item>
              <RoundedIcon
                icon={<FaMobileAlt size='40px' color={theme.colors.primary} />}
                size='big'
                color='manatee'
              />
            </FlexGrid>
            <FlexGrid
              item
              container
              direction='column'
              spacing={4}
              alignItems='center'
            >
              <FlexGrid
                item
                container
                direction='column'
                spacing={2}
                style={{ textAlign: 'center' }}
              >
                <FlexGrid item>
                  <Typography variant='h2'>
                    {REGISTER_AND_VERIFY_YOUR_PHONE}
                  </Typography>
                </FlexGrid>
                <FlexGrid item style={{ maxWidth: '530px' }}>
                  <Typography variant='paragraph'>
                    {REGISTER_AND_VERIFY_YOUR_PHONE_DESCRIPTION}
                  </Typography>
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item fullWidth style={{ maxWidth: '450px' }}>
                <Form {...formProps} onSubmit={onSendCode}>
                  <FormPhoneInput
                    required={true}
                    validators={[validators.selectIsEmpty, validators.isEmpty]}
                  />
                </Form>
              </FlexGrid>
              <FlexGrid item fullWidth style={{ maxWidth: '450px' }}>
                <Button onClick={onSendCode}>{SEND_CODE}</Button>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>
        </FlexGrid>
      </FlexGrid>
    </div>
  );
};
