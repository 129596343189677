export function getTimeLeft(endDate: Date) {
  const currentDate = new Date();
  var msLeft = endDate.getTime() - currentDate.getTime();
  var minutesLeft = Math.floor(msLeft / 1000 / 60);
  var secondsLeft = Math.floor((msLeft / 1000) % 60);

  return {
    value: `${minutesLeft}:${
      secondsLeft > 9 ? secondsLeft : `0${secondsLeft}`
    }`,
    msLeft,
    minutesLeft,
  };
}
