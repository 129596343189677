import { useCallback, useEffect, useReducer } from 'react';
import { makeProvider } from 'react-provider-maker';
import { initialAuthState, useAuthReducer } from './reducer';
import { useApi } from '../api-provider';
import jwt from 'jsonwebtoken';
import useLocalStorage from '../../hooks/use-local-storage';
import useInterval from '../../hooks/use-interval';
import { Api } from '../../api';
import { useTwoFactorAuth } from '../two-factor-auth-provider';
import { useTracker } from '../../providers/tracker-provider';
import { RISK_LEVEL_MEDIUM, RISK_LEVEL_HIGH } from '../../utils/constants';
import { useExternalAuthData } from '../external-auth-provider';
import { useHistory } from 'react-router';


export type Login = (email: string, password: string) => void;
export type SendLoginTwoFactorAuthCode = (phoneCode: string) => void;


const EXTRATEGIES_PHONE_RESULT = {
  USER_NOT_ALLOWED:(dispatch:  any, sendPhoneCodeResult: any) => {
    dispatch({
      type: 'USER_NOT_ALLOWED',
      payload: {
        authError: sendPhoneCodeResult.info.reason,
        lastBlockDate: sendPhoneCodeResult.info.lastBlockDate,
        lastCodeChangeDate: sendPhoneCodeResult.info.lastCodeChangeDate,
        action: sendPhoneCodeResult.info.action,
      },
    });
  },
  USER_NOT_FOUND:(dispatch:  any, sendPhoneCodeResult: any) => {
    dispatch({
      type: 'USER_NOT_FOUND',
      payload: {
        authError: sendPhoneCodeResult.info.reason,
      },
    });
  },
  USER_BLOCKED:(dispatch:  any, sendPhoneCodeResult: any) => {
    dispatch({
      type: 'USER_BLOCKED',
      payload: {
        authError: sendPhoneCodeResult.info.reason,
        lastBlockDate: sendPhoneCodeResult.info.lastBlockDate,
        lastCodeChangeDate: sendPhoneCodeResult.info.lastBlockDate,
        action: sendPhoneCodeResult.info.action,
      },
    });
  },
  PRE_LOGGED_IN:(dispatch:  any, sendPhoneCodeResult: any) => {
    dispatch({ type: 'PRE_LOGGED_IN', payload: { maskedPhone:sendPhoneCodeResult.maskedPhone } });
  }
}

const savePhone=(data:any)=>{
  localStorage.setItem('phone', JSON.stringify(data.user.phone));
  localStorage.setItem(
   'phoneCode',
   JSON.stringify(data.user.phoneCode)
 );
}



type obtainCredentialsParams = {twoFactorAuthToken?:string, leadUuid?:string, paramApi?:Api, noLead?: boolean,externalAuthData?:any}

export const { Provider: AuthProvider, useProvider: useAuth } = makeProvider(
  () => {
    const [api, setApiConfig] = useApi();
    const [state, dispatch] = useReducer(useAuthReducer, initialAuthState);
    const [authJwt, setAuthJwt] = useLocalStorage('auth_jwt', undefined);
    const { sendPhoneCode } = useTwoFactorAuth();
    const { identify: identifyOnTracker } = useTracker();
    const [container] = useLocalStorage("container", undefined)
    const history = useHistory();

    const{ queryExternalAuthData, externalAuthDataParams, isInvalid, persistExternalAuthData,clearExternalAuthData } = useExternalAuthData();
    const isEmptyLead = isInvalid === 'EMPTY_LEAD';
    const showAdditionalInfo = state?.leadData?.riskLevel === RISK_LEVEL_MEDIUM || state?.leadData?.riskLevel === RISK_LEVEL_HIGH;
    const email = state?.userData?.email;

    useEffect(() => {
      identifyOnTracker(email);
    }, [email, identifyOnTracker]);

    useInterval(async () => {
      if (authJwt) {
        const data = jwt.decode(authJwt || '') as any;
        if (data.exp * 1000 - Date.now() <= 120000) {
          const obtainCredentialsResult = await api.callService(
            'obtain-credentials',
            {}
          );
          if (obtainCredentialsResult.eventName === 'UserCredentialsObtained') {
            const jwt =
              obtainCredentialsResult?.data?.credentials?.jwt ||
              obtainCredentialsResult.sensitiveData.credentials.jwt;
            localStorage.setItem('auth_jwt', JSON.stringify(jwt));
            setApiConfig({ authorizationToken: jwt });
          }
        }
      }
    }, 30000);

    const obtainCredentials = useCallback(async (
      params:obtainCredentialsParams
      ) => {
      const obtainCredentialsResult = await (params.paramApi || api).callService(
        'obtain-credentials',
        {
          ...(params.leadUuid && {leadUuid:params.leadUuid}),
          noLead: params.noLead
        },
        { 
          ...(params.twoFactorAuthToken && {phoneCodeToken: params.twoFactorAuthToken}),
          ...(params.externalAuthData && {externalAuthData: params.externalAuthData}),
       }
      );
      if (obtainCredentialsResult.eventName === 'UserCredentialsObtained') {
        const jwt =
          obtainCredentialsResult?.data?.credentials?.jwt ||
          obtainCredentialsResult.sensitiveData.credentials.jwt;
        setAuthJwt(jwt);
      }
      return obtainCredentialsResult
    }, [ api, setAuthJwt]);


    const loginOpsAnalyst = useCallback(async (paramApi:Api) => {
      if(!queryExternalAuthData){
        dispatch({ type: 'LOGOUT'})
        history.push('/empty-lead');
      }else{
        persistExternalAuthData()
        try{
          await obtainCredentials({
            twoFactorAuthToken:undefined, leadUuid:(externalAuthDataParams as any)?.leadUuid || '', paramApi:paramApi,externalAuthData:queryExternalAuthData
            
            });
        }catch(e){
          dispatch({ type: 'LOGOUT'})
          console.log("error al obtener las credenciales",e)
          history.push('/error');
        }
      }
    },[externalAuthDataParams, history, obtainCredentials, persistExternalAuthData, queryExternalAuthData]);

 

    const login = useCallback(
      async (email: string, password: string) => {
        dispatch({ type: 'LOGIN' });
        identifyOnTracker(email);
        const loginResult = await api.callService('login', { email, password, container });
        if (loginResult.eventName === 'UserLoggedIn') {
          const data = jwt.decode(loginResult.sensitiveData.jwt) as any;
          const maskedPhone = '*'.repeat(6) + data.user.phone.phone.slice(-4);
          savePhone(data);
          const paramApi = setApiConfig({
            authorizationToken: loginResult.sensitiveData.jwt});

          if(data.user.role === 'OPS_ANALYST'){
           await loginOpsAnalyst(paramApi);
          }else{
            const sendPhoneCodeResult = await sendPhoneCode({
              paramApi,
              action: 'LOGIN',
            });
  
            const reason = sendPhoneCodeResult.info.reason as keyof typeof EXTRATEGIES_PHONE_RESULT;
            const extrategyPhoneResult = EXTRATEGIES_PHONE_RESULT[reason]  || EXTRATEGIES_PHONE_RESULT.PRE_LOGGED_IN;
            extrategyPhoneResult(dispatch, {...sendPhoneCodeResult,maskedPhone})
          }

        } else {
          dispatch({
            type: 'NOT_LOGGED_IN',
            payload: { authError: loginResult.data.reason },
          });
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [identifyOnTracker, api, container, setApiConfig, queryExternalAuthData, loginOpsAnalyst, sendPhoneCode]
    );

    const logout = useCallback(async () => {
      setAuthJwt(undefined);
      dispatch({ type: 'LOGOUT' });
      clearExternalAuthData()
      if(state.isAuth){
        await api.callService("logout",{
          userUuid: state.user?.userUuid,
        }) 
      }
     
    }, [dispatch, setAuthJwt,api,state.user?.userUuid,state.isAuth,clearExternalAuthData]);

    useEffect(() => {
      const data = jwt.decode(authJwt || '') as any;
      if (data) {
        if (data.exp * 1000 - Date.now() <= 0) {
          logout();
        }
      }
    }, [authJwt, logout]);


   

    const validatePhoneCode = useCallback(
      async (code: string) => {
        dispatch({ type: 'VALIDATE_CODE' });
        const validatePhoneCodeResult = await api.callService(
          'validate-phone-code',
          {
            code,
            action: 'LOGIN',
          }
        );

        if (validatePhoneCodeResult.eventName === 'PhoneCodeValidated') {
          const twoFactorAuthToken = validatePhoneCodeResult.data.token;
          await  obtainCredentials({twoFactorAuthToken:twoFactorAuthToken});
        } else if (
          validatePhoneCodeResult.data.info.reason === 'USER_BLOCKED'
        ) {
          dispatch({
            type: 'USER_BLOCKED',
            payload: {
              authError: validatePhoneCodeResult.data.info.reason,
              lastBlockDate: validatePhoneCodeResult.data.info.lastBlockDate,
              lastCodeChangeDate:
                validatePhoneCodeResult.data.info.lastBlockDate,
              action: validatePhoneCodeResult.data.info.action,
            },
          });
        } else {
          dispatch({
            type: 'NOT_LOGGED_IN',
            payload: { authError: 'INVALID_PHONE_CODE' },
          });
        }
      },
      [api, obtainCredentials]
    );

    const getProfile = useCallback(async (api: Api) => {
      const result={user:undefined,lead:undefined};
      try {
      const userResult =await api.callQuery('get-user-by-uuid')
      result.user = userResult.data.user;
      const leadResult = await api.callQuery('get-lead-by-uuid')
      result.lead = leadResult.data.lead;
      return result
      } catch (error) {
        console.error(error)
      }
      return result
    }, []);

    const loadCredentials = useCallback(
      async (authJwt) => {
        const data = jwt.decode(authJwt || '') as any;
        const isOpsAnalyst = data?.user?.role === 'OPS_ANALYST';
        if(isOpsAnalyst && (!data?.user?.leadUuid || data?.user?.leadUuid === '')){
          console.error("leadUuid is not defined")
          return
        }
        localStorage.setItem('auth_jwt', JSON.stringify(authJwt));
        dispatch({ type: 'LOGIN' });
        const nextApi = setApiConfig({ authorizationToken: authJwt});
        const { user, lead } = await getProfile(nextApi);
        dispatch({ type: 'LOGGED_IN', payload: { user, lead } });
      },
      [dispatch, setApiConfig, getProfile]
    );

    const reloadProfile = useCallback(async () => {
      dispatch({ type: 'RELOAD_PROFILE' });
      const { user, lead } = await getProfile(api);
      dispatch({ type: 'LOGGED_IN', payload: { user, lead } });
    }, [dispatch, getProfile, api]);

    useEffect(() => {
      if (authJwt) loadCredentials(authJwt);
      else
        dispatch({ type: 'NOT_LOGGED_IN', payload: { authError: undefined } });
    }, [authJwt, loadCredentials]);

    return {
      ...state,
      login,
      validatePhoneCode,
      logout,
      loadCredentials,
      reloadProfile,
      sendPhoneCode,
      api,
      showAdditionalInfo,
      obtainCredentials,
      isEmptyLead
    };
  }
);
