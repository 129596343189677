import {
  Drawer,
  DrawerDescription,
  DrawerTitle,
  FlexGrid,
  Typography,
  DrawerContent,
  DrawerActions,
  Button,
  useThemeProvider,
  FormItemProps,
  RadialButton,
  Form,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { FormItems } from '../form-items';
import { useCatalog } from '../../hooks/use-catalog';
import { useCallback, useEffect, useMemo } from 'react';
import { useOnboardingForm } from '../../hooks/use-onboarding-form';
import { useDefaultValidations } from '../../utils/default-validations';
import { useValidators } from '../../utils/validators';
import { OnBoardingLoader } from '../on-boarding-loader';
import { mainCountryStr } from '../../const';
import { useConfirm } from '../../components/confirm-dialog';
import { useUserRole } from '../../hooks/use-user-role';
import { useFormsFields } from '../../providers/onboarding-form-provider';

export type AddPhysicalShareholderProps = {
  uuidForm: string | undefined;
  open: boolean;
  onClose: () => void;
  totalPercentageUsed: number;
  openSnackbar?: (eventName: string) => void;
};

export const AddPhysicalShareholder = ({
  onClose,
  open,
  uuidForm,
  openSnackbar,
  totalPercentageUsed,
}: AddPhysicalShareholderProps) => {
  const {
    REQUIRED_FIELD,
    NAME_OR_DENOMINATION,
    BIRTH_PLACE,
    PARTICIPATION_PERCENTAGE,
    CANCEL,
    ADD,
    PHYSICAL_PERSON_WITH_BUSINESS_ACTIVITY,
    ADD_SHAREHOLDER,
    BIRTH_DATE,
    IS_LISTED_ON_THE_STOCK_MARKET,
    DATE_FORMAT,
    UPDATE,
    SHAREHOLDER,
    YES,
    NO,
    OR,
    CONSTITUTION,
    PERSON_TYPE,
    MORAL_PERSON,
    PHYSICAL_PERSON,
    STOCK_NAME,
    BLACKBOARD_KEY,
    PERCENTAGE_SHOULD_NOT_BE_MORE_THAN_ONE_HUNDRED,
    RFC_WITH_HOMOCLAVE,
    THIRTEEN_CHARACTER_ALPHANUMERIC_KEY,
    TWELVE_CHARACTER_ALPHANUMERIC_KEY,
    TITLE_UPDATE_CUSTOMER_INFO,
    DESCRIPTION_UPDATE_CUSTOMER_INFO,
    BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO,
  } = useTranslator();

  const { formFields ,findFields} = useFormsFields();
  
  useEffect(()=>{
    findFields('QUICK_WIN_SHAREHOLDER');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const { DialogConfirm, confirm: showConfirm } = useConfirm({
    title: TITLE_UPDATE_CUSTOMER_INFO,
    message: DESCRIPTION_UPDATE_CUSTOMER_INFO,
    textConfirm: BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO
  });

  const handleClickSave = async () => {
    const confirm = await showConfirm();
    if (!confirm) {
      return;
    }
    formProps.saveFormOps();
  }

  const { isOpsAnalyst } = useUserRole();
  const { theme } = useThemeProvider();
  const formProps = useOnboardingForm({
    defaultParams: {
      isLead: false,
    },
    onboardingFormType: 'QUICK_WIN_SHAREHOLDER',
    singleton: false,
    onboardingFormRef: uuidForm,
  });
  const setErrors = formProps.form.setErrors;
  const defaultValidations = useDefaultValidations();
  const validators = useValidators();
  const isListedOnTheStockMarket =
    formProps.form.data.general?.stockMarketListing;
  const saveOnboardingFormEvent = formProps.eventName;
  const [countries] = useCatalog('Countries');
  const getFormValue = formProps.form.getPropByName;
  const setFormValue = formProps.form.setPropByName;
  const setFormData = formProps.form.setData;
  const formData = formProps.form.data;
  const currentPercentage = getFormValue('general.percentageShares');
  const currentPersonType = getFormValue('general.type');
  const currentCountry = getFormValue('general.country');
  const isMainCountrySelected = mainCountryStr === currentCountry;
  const hasStockReferenceData =
    getFormValue('general.stockExchange') || getFormValue('general.ticker');
  const totalPercentageLimit = 100;
  const isOverPercentageLimit =
    totalPercentageLimit - totalPercentageUsed - currentPercentage < 0;
  const percentageIsMoreEqualToTwentyFive =
    currentPercentage >= 25 && currentPercentage <= 100;

  const listedOnTheStockMarketOptions = useMemo(
    () => [
      {
        name: true,
        text: YES,
        value: isListedOnTheStockMarket === true,
      },
      {
        name: false,
        text: NO,
        value: isListedOnTheStockMarket === false,
      },
    ],
    [isListedOnTheStockMarket, YES, NO]
  );

  const personTypeSelect = [
    {
      name: MORAL_PERSON,
      value: 'moral',
    },
    {
      name: PHYSICAL_PERSON,
      value: 'fisica',
    },
  ];

  const coutriesSelect = countries.map((country: any) => ({
    name: country.value,
    value: country.key,
  }));

  const shareholderInfo: FormItemProps<string>[] = formFields('QUICK_WIN_SHAREHOLDER', [
    {
      placeholder: NAME_OR_DENOMINATION,
      name: 'general.gname',
      type: 'input',
      required: true,
      ...defaultValidations.nameOrDenominationValidations(),
    },
    {
      placeholder: `${BIRTH_DATE} ${OR} ${CONSTITUTION} ${DATE_FORMAT}`,
      name: 'general.bdate',
      type: 'date',
      inputType: 'email',
      required: true,
      validators: [validators.isEmpty, validators.notValidDateFormat],
    },
    {
      placeholder: `${BIRTH_PLACE} ${OR} ${CONSTITUTION}`,
      name: 'general.country',
      type: 'select',
      required: true,
      items: coutriesSelect,
      search: true,
      validators: [validators.selectIsEmpty],
    },
    {
      placeholder: PARTICIPATION_PERCENTAGE,
      name: 'general.percentageShares',
      type: 'numberFormatInput',
      suffix: ' %',
      maxLength: 5,
      saveAs: 'number',
      required: true,
      error: currentPercentage > 100,
      errorMessage: PERCENTAGE_SHOULD_NOT_BE_MORE_THAN_ONE_HUNDRED,
      validators: [
        validators.isEmpty,
        (value: string) =>
          parseInt(value) > 100 || isOverPercentageLimit
            ? PERCENTAGE_SHOULD_NOT_BE_MORE_THAN_ONE_HUNDRED
            : undefined,
      ],
    },
  ]);

  const personTypeItem: FormItemProps<string>[] = formFields('QUICK_WIN_SHAREHOLDER', [
    {
      placeholder: PERSON_TYPE,
      name: 'general.type',
      type: 'select',
      required: true,
      items: personTypeSelect,
      validators: [validators.selectIsEmpty],
    },
  ]);

  const stockMarketInfo: FormItemProps<string>[] = formFields('QUICK_WIN_SHAREHOLDER', [
    {
      placeholder: STOCK_NAME,
      name: 'general.stockExchange',
      type: 'input',
      required: true,
      ...defaultValidations.stockNameValidations(),
    },
    {
      placeholder: BLACKBOARD_KEY,
      name: 'general.ticker',
      type: 'input',
      required: true,
      ...defaultValidations.blackboardKeyValidations(),
    },
  ]);

  const rfcInfo: FormItemProps<string>[] = formFields('QUICK_WIN_SHAREHOLDER', [
    {
      placeholder: RFC_WITH_HOMOCLAVE,
      name: 'general.taxId',
      type: 'input',
      required: true,
      toUpperCase: true,
      ...defaultValidations.rfcWithHomoclaveValidations(),
      help: {
        description: currentPersonType === 'moral' ? TWELVE_CHARACTER_ALPHANUMERIC_KEY : THIRTEEN_CHARACTER_ALPHANUMERIC_KEY,
      },
    },
  ]);

  function onSelectStockMarketOptions(selectedIndex: number) {
    const stockMarketListing = selectedIndex === 0;

    var dataWithoutStockInfo = { ...formData };
    delete dataWithoutStockInfo.general?.stockExchange;
    delete dataWithoutStockInfo.general?.ticker;

    setFormData((currentData: any) => ({
      ...(stockMarketListing ? currentData : dataWithoutStockInfo),
      general: {
        ...(stockMarketListing ? currentData : dataWithoutStockInfo).general,
        stockMarketListing,
      },
    }));
  }

  function onDrawerAddButtonClick() {
    if (formProps.form.hasErrors) {
      formProps.form.displayErrors();
    } else if (isOpsAnalyst) {
      handleClickSave();
    } else {
      formProps.moveForward();
    }
  }

  const removeStockData = useCallback(() => {
    var dataWithoutStockInfo = { ...formData };
    delete dataWithoutStockInfo.general?.stockMarketListing;
    delete dataWithoutStockInfo.general?.stockExchange;
    delete dataWithoutStockInfo.general?.ticker;

    setFormData(dataWithoutStockInfo);
  }, [formData, setFormData]);

  const removeStockReferenceData = useCallback(() => {
    var dataWithoutStockInfo = { ...formData };
    delete dataWithoutStockInfo.general?.stockExchange;
    delete dataWithoutStockInfo.general?.ticker;

    setFormData(dataWithoutStockInfo);
  }, [formData, setFormData]);

  const removeStockReferenceDataErrors = useCallback(() => {
    setErrors((currentErrors: any) => ({
      ...currentErrors,
      'general.stockExchange': undefined,
      'general.ticker': undefined,
    }));
  }, [setErrors]);

  const removePersonTypeData = useCallback(() => {
    var dataWithoutStockInfo = { ...formData };
    delete dataWithoutStockInfo.general?.type;
    delete dataWithoutStockInfo.general?.typeDesc;

    setFormData(dataWithoutStockInfo);
  }, [formData, setFormData]);

  const removePersonTypeErrors = useCallback(() => {
    setErrors((currentErrors: any) => ({
      ...currentErrors,
      'general.type': undefined,
    }));
  }, [setErrors]);

  const removeTaxIdData = useCallback(() => {
    const dataWithoutTaxId = { ...formData };
    delete dataWithoutTaxId.general?.taxId;;

    setFormData(dataWithoutTaxId);
  }, [formData, setFormData]);

  const removeTaxIdErrors = useCallback(() => {
    setErrors((currentErrors: any) => ({
      ...currentErrors,
      'general.taxId': undefined,
    }));
  }, [setErrors]);

  useEffect(() => {
    if (saveOnboardingFormEvent) {
      onClose();
      openSnackbar && openSnackbar(saveOnboardingFormEvent);
    }
  }, [saveOnboardingFormEvent, onClose, openSnackbar]);

  useEffect(() => {
    if (
      isListedOnTheStockMarket === undefined &&
      currentPersonType === 'moral'
    ) {
      setFormValue('general.stockMarketListing', false);
    }

    if (isListedOnTheStockMarket && currentPersonType === 'fisica') {
      removeStockReferenceDataErrors();
      removeStockData();
    }

    if (isListedOnTheStockMarket === false) {
      removeStockReferenceDataErrors();
      if (hasStockReferenceData) {
        removeStockData();
      }
    }
  }, [
    isListedOnTheStockMarket,
    currentPersonType,
    setFormValue,
    removeStockReferenceDataErrors,
    removeStockData,
    removeStockReferenceData,
    hasStockReferenceData,
  ]);

  useEffect(() => {
    if (currentPercentage < 25) {
      removePersonTypeErrors();
      removeStockReferenceDataErrors();

      if (currentPersonType) {
        removePersonTypeData();
        removeStockData();
      }
    } else if (!currentPersonType) {
      setFormValue('general.type', 'fisica');
    }
  }, [
    currentPercentage,
    currentPersonType,
    removeStockData,
    removeStockReferenceDataErrors,
    removePersonTypeErrors,
    removePersonTypeData,
    setFormValue,
  ]);

  useEffect(() => {
    if (!isMainCountrySelected && formData.general?.taxId !== undefined) {
      removeTaxIdData();
      removeTaxIdErrors();
    } else if (isMainCountrySelected && formData.general?.taxId === undefined) {
      setFormValue('general.taxId', '');
    }
  }, [formData, isMainCountrySelected, removeTaxIdData, removeTaxIdErrors, setFormValue])

  if (formProps.loadingForm || formProps.movingForward || formProps.saving)
    return (
      <OnBoardingLoader
        isLoading={
          formProps.loadingForm || formProps.movingForward || formProps.saving
        }
      />
    );

  return (
    <Form {...formProps.form} onSubmit={onDrawerAddButtonClick}>
      <Drawer open={open} onClose={onClose} position='right'>
        <DrawerTitle>
          {uuidForm ? `${UPDATE} ${SHAREHOLDER}` : ADD_SHAREHOLDER}
        </DrawerTitle>
        <DrawerDescription>
          <FlexGrid container fullWidth direction='column' spacing={2}>
            <FlexGrid item>{PHYSICAL_PERSON_WITH_BUSINESS_ACTIVITY}</FlexGrid>
          </FlexGrid>
        </DrawerDescription>

        <DrawerContent>
          <FlexGrid
            item
            container
            fullWidth
            spacing={4}
            direction='column'
            style={{ marginBottom: '60px' }}
          >
            <FlexGrid item container fullWidth spacing={4} direction='column'>
              <FlexGrid item>
                <Typography variant='small' color='neutral' bold>
                  <span style={{ color: theme.colors.required }}>*</span>{' '}
                  {REQUIRED_FIELD}
                </Typography>
              </FlexGrid>

              <FormItems inputs={shareholderInfo} />

              {isMainCountrySelected && !(percentageIsMoreEqualToTwentyFive && !isOverPercentageLimit) &&
                <FormItems inputs={rfcInfo} />
              }

              {isMainCountrySelected && percentageIsMoreEqualToTwentyFive && !isOverPercentageLimit && (
                <FormItems inputs={[
                  ...personTypeItem,
                  ...rfcInfo
                ]} />
              )}
              {!isMainCountrySelected && percentageIsMoreEqualToTwentyFive && !isOverPercentageLimit && (
                <FormItems inputs={[
                  ...personTypeItem,
                ]} />
              )}
            </FlexGrid>

            {percentageIsMoreEqualToTwentyFive &&
              currentPersonType === 'moral' && (
                <FlexGrid
                  item
                  container
                  fullWidth
                  spacing={4}
                  direction='column'
                >
                  <FlexGrid item>
                    <Typography variant='smallTitle'>
                      {IS_LISTED_ON_THE_STOCK_MARKET}
                    </Typography>
                  </FlexGrid>

                  <FlexGrid item container direction='row' spacing={5}>
                    {listedOnTheStockMarketOptions.map(
                      ({ value, text }, index) => (
                        <FlexGrid item>
                          <RadialButton
                            value={value}
                            onInput={() => onSelectStockMarketOptions(index)}
                            text={text}
                          />
                        </FlexGrid>
                      )
                    )}
                  </FlexGrid>

                  {isListedOnTheStockMarket && (
                    <FlexGrid item>
                      <FormItems inputs={stockMarketInfo} />
                    </FlexGrid>
                  )}
                </FlexGrid>
              )}


          </FlexGrid>
        </DrawerContent>

        <DialogConfirm />

        <DrawerActions>
          <Button onClick={onClose} buttonType='secondary' size='normal'>
            {CANCEL}
          </Button>
          <Button
            onClick={onDrawerAddButtonClick}
            size='normal'
            style={{ marginLeft: theme.spacesPx[2] }}
          >
            {uuidForm || isOpsAnalyst ? UPDATE : ADD}
          </Button>
        </DrawerActions>
      </Drawer>
    </Form>
  );
};
