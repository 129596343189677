import { useCallback, useEffect, useMemo, useState } from 'react';

export type UseItemsArrayManagerParams = {
  updateIndex?: number;
  baseData?: any;
};

export const useItemsArrayManager = (params: UseItemsArrayManagerParams) => {
  const [data, setData] = useState<any>(params.baseData || []);
  const updateIndex = params.updateIndex;
  const baseData = params.baseData;
  const updateData = useMemo(
    () => (updateIndex !== undefined ? data[updateIndex] : undefined),
    [data, updateIndex]
  );

  const cleanData = () => {
    setData([]);
  };

  const addItem = useCallback(
    (item: any) => {
      setData((data: any) => [...data, item]);
    },
    [setData]
  );

  const updateItem = useCallback(
    (newData: any, index?: number) => {
      const currentIndex = updateIndex !== undefined ? updateIndex : index;
      const currentItemData =
        currentIndex !== undefined ? data[currentIndex] : undefined;

      const updatedItem = {
        ...currentItemData,
        ...newData,
      };

      const updatedArray = [...data].map((beneficiary, index) => {
        if (index === currentIndex) return { ...updatedItem };
        return beneficiary;
      });

      setData(updatedArray);
    },
    [setData, data, updateIndex]
  );

  const deleteItem = useCallback(
    (index?: number) => {
      const arrayWithoutDeletedItem = [...data].filter(
        (item, currentIndex) => currentIndex !== index
      );

      setData(arrayWithoutDeletedItem);
    },
    [setData, data]
  );

  useEffect(() => {
    if (baseData) setData(baseData);
  }, [baseData]);

  return {
    addItem,
    updateItem,
    deleteItem,
    data,
    setData,
    cleanData,
    updateData,
  };
};
