import { FlexGrid, FormItem, Help } from '@albo-ui/core';
import { useCatalog } from '../../hooks/use-catalog';
import { useTranslator } from '../../providers/translator-provider';


export type FormPhoneInputProps = {
  baseName?: string;
  required?: boolean;
  validators?: ((value: string) => string | undefined)[];
  error?: boolean;
  disabled?: boolean;
  placeholder?: string;
  help?: Help;
};

export const FormPhoneInput = ({
  baseName,
  required,
  validators,
  error,
  disabled,
  placeholder,
  help,
}: FormPhoneInputProps) => {
  const [countries] = useCatalog('Countries');
  
  const phoneCodes = countries.map((country: any) => ({
    value: country.key,
    name: `${(country as any).value} (+${country.metadata[0].dial}) `,
  }));

  const { COUNTRY_CODE, PHONE_NUMBER, REQUIRED_FIELD, SELECT_OPTION } =
    useTranslator();

  return (
    <FlexGrid container spacing={1}>
      <FlexGrid flex='0.6 165px' item>
        <FormItem
          placeholder={COUNTRY_CODE}
          name={baseName ? `${baseName}.phoneCountry` : 'phoneCountry'}
          type='select'
          items={phoneCodes}
          required={required}
          errorMessage={SELECT_OPTION}
          error={error}
          disabled={disabled}
          validators={validators ? (validators[0] ? [validators[0]] : []) : []}
        />
      </FlexGrid>
      <FlexGrid flex='1 202px' item>
        <FormItem
          placeholder={placeholder ? placeholder : PHONE_NUMBER}
          help={help}
          type='input'
          name={baseName ? `${baseName}.phone` : 'phone'}
          required={required}
          inputType='number'
          errorMessage={REQUIRED_FIELD}
          error={error}
          maxLength={10}
          disabled={disabled}
          validators={[
            ...(validators ? (validators[1] ? [validators[1]] : []) : [])
          ]}
        />
      </FlexGrid>
    </FlexGrid>
  );
};
