import { useCallback, useEffect, useState } from 'react';
import { makeProvider } from 'react-provider-maker';
import useInterval from '../../hooks/use-interval';
import { getTimeLeft } from './helper';
import useLocalStorage from '../../hooks/use-local-storage';

export type Timer = {
  endDate: Date;
  timeLeft: { value: string; msLeft: number };
};
export type Timers = {
  [name: string]: Timer;
};

export const { Provider: TimersProvider, useProvider: useTimers } =
  makeProvider(() => {
    const [timers, setTimers] = useState<Timers>({});
    const [currentTimers, setCurrentTimers] = useLocalStorage('timers', {});

    useEffect(() => {
      const localStorageTimers: Timers = currentTimers;
      const localStorageKeys = Object.keys(localStorageTimers);

      localStorageKeys.forEach((timerKey) => {
        const timer = localStorageTimers[timerKey];
        timer.endDate = new Date(timer.endDate);
      });

      if (localStorageTimers) setTimers(localStorageTimers);
    }, [currentTimers]);

    const addTimer = useCallback(
      (timerName: string, endDate: Date) => {
        setTimers((timers) => ({
          ...timers,
          [timerName]: { endDate, timeLeft: getTimeLeft(endDate) },
        }));
      },
      [setTimers]
    );

    useInterval(() => {
      const nextTimers: Timers = {};
      const timersKeys = Object.keys(timers);

      if (timersKeys.length === 0) return;

      timersKeys.forEach((timerKey) => {
        const timer = timers[timerKey];
        const timeLeft = getTimeLeft(timer.endDate);
        if (timeLeft.msLeft > 0)
          nextTimers[timerKey] = { endDate: timer.endDate, timeLeft };
      });
      setTimers(nextTimers);
      setCurrentTimers(nextTimers);
    }, 1000);

    return { addTimer, timers };
  });
