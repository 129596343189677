import { FormContainer } from '../../../../../../../../components-quick-win/form-container';
import { LegalRepresentativeForm } from '../../../../../../../../components-quick-win/legal-representative-form';

export const LegalRepresentative = () => {
  return (
    <FormContainer>
      <LegalRepresentativeForm />
    </FormContainer>
  );
};
