import { FormItemProps } from '@albo-ui/core';
import { useCallback, useState } from 'react';
import { makeProvider } from 'react-provider-maker';
import { OnboardinFormTypes, QuickWinOnboardinFormTypes } from '../../hooks/use-onboarding-form';
import { useUserRole } from '../../hooks/use-user-role';
import { useApi } from '../api-provider';

//Dado que no tiene ninguna dependecia, se puede crear fuera del hook y no se vuelve a crear en cada render
const fieldExists = (resultList: any[], formItem: FormItemProps<any>): any | null => {
    const field = resultList?.find(definition => {
        return definition.name === formItem.name;
    });
    return field || formItem;
}

const filterValidators = (validator:any)=> validator.name !== 'isEmpty' && validator.name !== 'phoneNumberValidations' && validator.name !== 'doNotHaveMinLength';

export const { Provider: FormsProvider, useProvider: useFormsFields } = makeProvider(
    () => {
        const [formDefinition, setFormDefinition] = useState<any>(new Map());
        

        const updateFormDefinition = useCallback( (k: any, v: any) => {
            setFormDefinition(new Map(formDefinition.set(k, v)));
        },[formDefinition])

        const { isOpsAnalyst } = useUserRole();
        const [api] = useApi();

        const formFields = useCallback((formName: QuickWinOnboardinFormTypes | OnboardinFormTypes, items: FormItemProps<any>[]) => {
            if (!isOpsAnalyst) {
                return items;
            }

            const result = formDefinition.get(formName);

            const formItems = items.map((item: FormItemProps<any>) => {
                const field = fieldExists(result, item);
                    
                    return { 
                        ...item
                        ,disabled: field?.disabled
                        ,required: false
                        ,validators: field?.validators?.filter(filterValidators) || []
                   }
                });

            return formItems;
        }, [isOpsAnalyst, formDefinition]);




        const findFields = useCallback(async (formName: QuickWinOnboardinFormTypes | OnboardinFormTypes) => {
            if (!isOpsAnalyst) {
                return;
            }

            if (formDefinition[formName]) {
                return;
            }
            const result = await api.callService('get-form-definition',
                {
                    formType: formName,
                    role: 'OPS_ANALYST'
                });

            updateFormDefinition(formName, result.data.fieldsDefinition);
      
        }, [isOpsAnalyst, formDefinition, api, updateFormDefinition]);

        return { formFields, findFields };
    });
