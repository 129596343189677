type UseQueryState = {
  data?: any;
  loading: boolean;
  eventName?: string;
  sensitiveData: any;
};

export type Action =
  | { type: 'LOAD' }
  | {
      type: 'SUCCESS';
      payload: any;
      eventName: string;
      sensitiveData: any;
    };

export const useServiceReducer = (
  state: UseQueryState,
  action: Action
): UseQueryState => {
  switch (action.type) {
    case 'LOAD':
      return {
        ...state,
        data: undefined,
        eventName: undefined,
        sensitiveData: undefined,
        loading: true,
      };
    case 'SUCCESS':
      return {
        ...state,
        data: action.payload,
        loading: false,
        eventName: action.eventName,
        sensitiveData: action.sensitiveData,
      };
    default:
      return state;
  }
};
