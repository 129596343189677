import {
  CheckBox,
  FlexGrid,
  Form,
  FormItemProps,
  Typography,
  useThemeProvider,
  ActionCard,
  useDrawer,
  useDialog,
  RadialButton,
  FormItem
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { FormItems } from '../form-items';
import { useValidators } from '../../utils/validators';
import { BottomActions } from '../bottom-actions';
import { useEffect, useState } from 'react';
import { useOnboardingForm } from '../../hooks/use-onboarding-form';
import { OnBoardingLoader } from '../on-boarding-loader';
import { AddDependentDrawer } from './add-dependent-drawer';
import { useItemsArrayManager } from '../../hooks/use-items-array-manager';
import { DeletePromptDialog } from '../delete-prompt-dialog';
import { useCatalog } from '../../hooks/use-catalog';
import { useHistory } from 'react-router';
import { useFormsFields } from '../../providers/onboarding-form-provider';
import { useConfirm } from '../../components/confirm-dialog';
import { useUserRole } from '../../hooks/use-user-role';

type DependentType = {
  name: string;
};

export const AccountPepRelationForm = () => {
  const formProps = useOnboardingForm({
    defaultParams: {
      isLead: false,
      general: {
        hasEconomicDependents: false
      },
      jobDescription: {
        currentlyWorksHere: false
      },
      dependents: []
    },
    onboardingFormType: 'QUICK_WIN_PEP_RELATION',
    nextSectionUrl: 'complementary-info',
    singleton: true,
  });

  const { setPropByName } = formProps.form;
  const deleteMultiplePropsByName = formProps.form.deleteMultiplePropsByName;


  const { theme } = useThemeProvider();
  const validators = useValidators();
  const drawerProps = useDrawer();
  const deleteDialogProps = useDialog();
  const history = useHistory();

  const formData = formProps.form.data;
  const setFormErrors = formProps.form.setErrors;


  const [updateIndex, setUpdateIndex] = useState<number | undefined>(undefined);
  const [deleteIndex, setDeleteIndex] = useState<number | undefined>(undefined);

  const {
    addItem,
    data: dependentsData,
    updateItem,
    deleteItem,
    updateData,
  } = useItemsArrayManager({
    updateIndex,
    baseData: formProps.form.data.dependents,
  });
  const setFormData = formProps.form.setData;
  const setErrors = formProps.form.setErrors;

  const doesNotHaveDependents = dependentsData.length === 0;
  const [relationTypes] = useCatalog('CompanyRelationships');
  const [countryTypes] = useCatalog('ResidenceLocations');
  const {
    REQUIRED_FIELD,
    ACCOUNT_FORM_PEP_RELATION_TITLE,
    ACCOUNT_FORM_PEP_DESCRIPTION,
    ACCOUNT_FORM_PEP_HAVE_PEP_RELATION,
    ACCOUNT_FORM_PEP_HAVE_PEP_RELATION_HELP,
    ACCOUNT_FORM_PEP_PLACE_OF_RESIDENCE,
    ACCOUNT_FORM_PEP_JOB_UI_TITLE,
    ACCOUNT_FORM_PEP_JOB_UI_DESCRIPTION,
    ACCOUNT_FORM_PEP_CURRENLY_WORKS_HERE,
    ACCOUNT_FORM_PEP_HAS_DEPENDENTS_TITLE,
    ACCOUNT_FORM_PEP_HAS_DEPENDENTS_DESCRIPTION,
    ACCOUNT_FORM_PEP_MODAL_TITLE,
    DO_YOU_WANT_TO_DELETE_THIS_DEPENDENT_QUESTION,
    ACCOUNT_FORM_PEP_DEPENDECY,
    ACCOUNT_FORM_PEP_JOP_POSITION,
    ACCOUNT_FORM_PEP_ESTIMATED_SALARY,
    ACCOUNT_FORM_PEP_DATE_OF_ADMISSION,
    ACCOUNT_FORM_PEP_END_DATE,
    ACCOUNT_FORM_PEP_DEFAULT_RELANTION_DESC,
    ACCOUNT_FORM_PEP_DEFAULT_RELANTION_VALUE,
    YES,
    NO,
    MUST_BE_BELOW_ONE_HUNDRED_YEARS,
    MUST_BE_BELOW_TODAY,
    CONTINUE,
    UPDATE,
    TITLE_UPDATE_CUSTOMER_INFO,
    DESCRIPTION_UPDATE_CUSTOMER_INFO,
    BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO,
    SAVE_PROGRESS
  } = useTranslator();

  const { formFields ,findFields} = useFormsFields();
  
  useEffect(()=>{
    findFields('QUICK_WIN_PEP_RELATION');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const { DialogConfirm, confirm: showConfirm } = useConfirm({
    title: TITLE_UPDATE_CUSTOMER_INFO,
    message: DESCRIPTION_UPDATE_CUSTOMER_INFO,
    textConfirm: BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO
  });

  const handleClickSave = async () => {
    const confirm = await showConfirm();
    if (!confirm) {
      return;
    }
    formProps.saveFormOps();
  }

  const { isOpsAnalyst } = useUserRole();

  const [showCurrenlyWorksHere, setShowCurrenlyWorksHere] = useState(false);
  const [radiosSource, setRadiosSource] = useState([{ text: YES, value: false }, { text: NO, value: false }]);
  const [showDependents, setshowDependents] = useState(false);
  const [havePep, setHavePep] = useState<boolean>(false);

  useEffect(() => {
    //Actulmente trabaja aqui
    const currenlyWorksHere = formData.jobDescription?.currentlyWorksHere ? formData.jobDescription?.currentlyWorksHere : false;
    setShowCurrenlyWorksHere(currenlyWorksHere);
    if (currenlyWorksHere) {
      deleteMultiplePropsByName(['jobDescription.endDate'])
    }

    //Tiene dependientes
    const hasEconomicDependents = formData.general?.hasEconomicDependents;
    setRadiosSource([{ text: YES, value: hasEconomicDependents }, { text: NO, value: !hasEconomicDependents }])
    setshowDependents(hasEconomicDependents);

    const relationWithPep = formData.general?.havePepRelationDesc ? formData.general?.havePepRelationDesc : ACCOUNT_FORM_PEP_DEFAULT_RELANTION_DESC;
    const relationWithPepId = formData.general?.havePepRelation ? formData.general?.havePepRelation : ACCOUNT_FORM_PEP_DEFAULT_RELANTION_VALUE;
    setHavePep(relationWithPep !== ACCOUNT_FORM_PEP_DEFAULT_RELANTION_DESC);
    if (relationWithPep === ACCOUNT_FORM_PEP_DEFAULT_RELANTION_DESC) {
      setFormData((currentData: any) => ({
        general: {
          havePepRelationDesc: ACCOUNT_FORM_PEP_DEFAULT_RELANTION_DESC,
          havePepRelation: relationWithPepId
        },
      }))
      setFormErrors({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.jobDescription?.currentlyWorksHere,
  formData.general.hasEconomicDependents,
  formData.general?.havePepRelation,
  formData.general?.havePepRelationDesc
  ]);

  useEffect(() => {
    setFormData((currentData: any) => ({
      ...currentData,
      dependents: dependentsData,
    }));
  }, [dependentsData, setFormData]);

  const dependents: DependentType[] | undefined = dependentsData?.map(
    (item: any) => ({
      name: `${item.gname} ${item.lname} ${item.slname}`,
    })
  );

  const onAddDependentClick = () => {
    drawerProps.openDrawer();
  };

  const onDeleteDependentClick = (index: number) => {
    setDeleteIndex(index);
    deleteDialogProps.openDialog();
  };

  const onUpdateDependentClick = (index: number) => {
    setUpdateIndex(index);
    drawerProps.openDrawer();
  };

  const updateDependentData = (data: any) => {
    updateItem(data);
    drawerProps.onClose();
    setUpdateIndex(undefined);
  };

  const saveDependent = (data: any) => {
    addItem(data);
    drawerProps.onClose();
  };

  const closeDeletePrompt = () => {
    setDeleteIndex(undefined);
    deleteDialogProps.onClose();
  };

  const deleteDependent = () => {
    deleteItem(deleteIndex);
    deleteDialogProps.onClose();
  };



  if (formProps.saving || formProps.movingForward || formProps.loadingForm) {
    return (
      <OnBoardingLoader
        isLoading={
          formProps.saving || formProps.movingForward || formProps.loadingForm
        }
      />
    );
  }

  //SET CATALOGS COMPONENTS
  const relationTypeSelect = relationTypes.map((type: any) => ({
    name: type.value,
    value: type.key,
  }));

  const countrySelect = countryTypes.map((type: any) => ({
    name: type.value,
    value: type.key,
  }));

  const jobInputs: FormItemProps<string>[] = formFields('QUICK_WIN_PEP_RELATION', [
    //Relacion de la empresa con un pep
    {
      placeholder: ACCOUNT_FORM_PEP_DEPENDECY,
      name: 'jobDescription.dependency',
      type: 'input',
      required: true,
      validators: [validators.isEmpty],
    },
    //lugar de residencia
    {
      placeholder: ACCOUNT_FORM_PEP_JOP_POSITION,
      name: 'jobDescription.jobPosition',
      type: 'input',
      required: true,
      validators: [validators.isEmpty],
    },
    //salario estimado
    {
      placeholder: ACCOUNT_FORM_PEP_ESTIMATED_SALARY,
      name: 'jobDescription.estimatedSalary',
      type: 'numberFormatInput',
      required: true,
      prefix: '$ ',
      decimalScale: 2,
      saveAs: 'number',
      validators: [validators.isEmpty],
    }
  ]);

  const validateDate = (selectedDate: string) => {
    const endDate = selectedDate ? new Date(selectedDate) : new Date();
    const currentDate = new Date();

    const endDateYears = endDate.getFullYear();
    const currentDateYears = currentDate.getFullYear();
    const age = currentDateYears - endDateYears;

    if (age > 100) return MUST_BE_BELOW_ONE_HUNDRED_YEARS;

    if (endDate > currentDate) return MUST_BE_BELOW_TODAY;

    return undefined;
  };

  const onCurrenlyWorksHereChange = (value: boolean) => {
    setShowCurrenlyWorksHere(value);
    setPropByName('jobDescription.currentlyWorksHere', value);
    if (value) {
      setPropByName('jobDescription.endDate', undefined)
      setErrors((currentErrors: any) => ({
        ...currentErrors,
        'jobDescription.endDate': undefined,
      }));
    } else {
      setPropByName('dependents', [])
      setPropByName('general.has_economic_dependents', false)
      setPropByName('general.hasEconomicDependents', false)
      setPropByName('jobDescription.currentlyWorksHere', value)

    }
  }

  const setHasDependents = (index: number) => {
    const exist = index === 0;
    radiosSource.map((item, i) => item.value = (i === index));
    setRadiosSource([...radiosSource]);
    setPropByName('general.hasEconomicDependents', exist);
    setFormErrors({});
    if (!exist) {
      setPropByName('dependents', []);
    }
  };

  function onBack() {
    history.push('company-organization-info');
  }



  return (
    <>
      <DeletePromptDialog
        open={deleteDialogProps.open}
        description={DO_YOU_WANT_TO_DELETE_THIS_DEPENDENT_QUESTION}
        onConfirm={deleteDependent}
        onClose={closeDeletePrompt}
      />

      {drawerProps.open && (
        <AddDependentDrawer
          {...drawerProps}
          onSave={saveDependent}
          onUpdate={updateDependentData}
          updateData={updateData}
        />
      )}

      {/* Pantalla principal */}
      <Form {...formProps.form} onSubmit={isOpsAnalyst ? handleClickSave : formProps.moveForward}>
        <FlexGrid
          container
          fullHeight
          fullWidth
          direction='column'
          spacing={3}
          style={{ paddingBottom: '100px' }}
        >
          <FlexGrid item container spacing={1} direction='column'>
            <FlexGrid item>
              <Typography variant='h2'>{ACCOUNT_FORM_PEP_RELATION_TITLE}</Typography>
            </FlexGrid>
            <FlexGrid item>
              <Typography variant='small'>{ACCOUNT_FORM_PEP_DESCRIPTION}</Typography>
            </FlexGrid>

            <FlexGrid item spacing={0}>
              <Typography variant='small' color='neutral' bold>
                <span style={{ color: theme.colors.required }}>*</span>{' '}
                {REQUIRED_FIELD}
              </Typography>
            </FlexGrid>

            <FlexGrid item container direction='row' fullWidth spacing={4}>
              <FlexGrid item fullWidth>
                <FormItem
                  placeholder={ACCOUNT_FORM_PEP_HAVE_PEP_RELATION}
                  name='general.havePepRelation'
                  type='select'
                  required={true}
                  validators={[validators.selectIsEmpty]}
                  help={
                    { description: ACCOUNT_FORM_PEP_HAVE_PEP_RELATION_HELP }
                  }
                  items={relationTypeSelect}
                  search={true}
                />
              </FlexGrid>
              <FlexGrid item fullWidth>
                {havePep ?
                  <FormItem
                    placeholder={ACCOUNT_FORM_PEP_PLACE_OF_RESIDENCE}
                    name='general.placeOfResidence'
                    type='select'
                    required={true}
                    items={countrySelect}
                    search={true}
                    validators={[validators.selectIsEmpty]}
                  />
                  : <></>
                }
              </FlexGrid>
            </FlexGrid>

          </FlexGrid>
          {
            havePep ?
              <>
                <FlexGrid item container spacing={1} direction='column'>
                  <FlexGrid item>
                    <Typography variant='smallTitle'>{ACCOUNT_FORM_PEP_JOB_UI_TITLE}</Typography>
                  </FlexGrid>

                  <FlexGrid item>
                    <Typography variant='small'>{ACCOUNT_FORM_PEP_JOB_UI_DESCRIPTION}</Typography>
                  </FlexGrid>

                  <FlexGrid item container fullWidth>
                    <FormItems inputs={jobInputs} />
                  </FlexGrid>

                </FlexGrid>


                <FlexGrid item container fullWidth direction='column'>
                  <FlexGrid item container direction='column' spacing={1}>
                    <FlexGrid item>
                      <CheckBox
                        onInput={onCurrenlyWorksHereChange}
                        value={showCurrenlyWorksHere ? showCurrenlyWorksHere : false}
                        text={ACCOUNT_FORM_PEP_CURRENLY_WORKS_HERE}
                      />
                    </FlexGrid>
                  </FlexGrid>
                </FlexGrid>

                <FlexGrid item container fullWidth>

                  <FlexGrid item container direction='row' fullWidth spacing={4}>
                    <FlexGrid item fullWidth>
                      <FormItem
                        placeholder={ACCOUNT_FORM_PEP_DATE_OF_ADMISSION}
                        name='jobDescription.dateOfAdmission'
                        type='date'
                        inputType='email'
                        required={true}
                        validators={[
                          validators.isEmpty,
                          validators.notValidDateFormat,
                          (value: string) => {
                            return validateDate(value);
                          }
                        ]}
                      />
                    </FlexGrid>
                    <FlexGrid item fullWidth>
                      {
                        !showCurrenlyWorksHere ?
                          <FormItem
                            placeholder={ACCOUNT_FORM_PEP_END_DATE}
                            name='jobDescription.endDate'
                            type='date'
                            inputType='email'
                            required={true}
                            validators={[
                              validators.isEmpty,
                              validators.notValidDateFormat,
                              (value: string) => {
                                return validateDate(value);
                              }
                            ]}
                          />
                          :
                          <></>
                      }
                    </FlexGrid>
                  </FlexGrid>

                </FlexGrid>

                {showCurrenlyWorksHere ?
                  <>
                    <FlexGrid item container spacing={1} direction='column'>
                      <FlexGrid item>
                        <Typography variant='smallTitle'>{ACCOUNT_FORM_PEP_HAS_DEPENDENTS_TITLE}</Typography>
                      </FlexGrid>

                      <FlexGrid item>
                        <Typography variant='small'>{ACCOUNT_FORM_PEP_HAS_DEPENDENTS_DESCRIPTION}</Typography>
                      </FlexGrid>

                    </FlexGrid>

                    <FlexGrid item container fullWidth direction='column'>
                      <FlexGrid item container direction='row' spacing={2}>
                        {radiosSource.map(({ value, text }, index) => (
                          <FlexGrid item key={index}>
                            <RadialButton
                              key={`additional-income-${index}`}
                              value={value}
                              onInput={() => setHasDependents(index)}
                              text={text}
                            />
                          </FlexGrid>
                        ))}
                      </FlexGrid>
                    </FlexGrid>
                    <FlexGrid item container direction='column' spacing={2}>
                      {showDependents &&
                        <FlexGrid item>
                          <ActionCard
                            title={ACCOUNT_FORM_PEP_MODAL_TITLE}
                            onClick={onAddDependentClick}
                            link
                            style={{ height: '80px' }}
                          />
                        </FlexGrid>
                      }
                      {
                        showDependents &&
                        dependents &&
                        dependents.map(({ name }, index) => (
                          <FlexGrid item key={index}>
                            <ActionCard
                              title={name}
                              style={{ height: '80px' }}
                              status='default'
                              onDelete={() => onDeleteDependentClick(index)}
                              onUpdate={() => onUpdateDependentClick(index)}
                            />
                          </FlexGrid>
                        ))
                      }

                    </FlexGrid>
                  </>
                  : <></>
                }
              </>
              : <></>
          }

        </FlexGrid>

        <DialogConfirm />

        <BottomActions
          saveProgressButton={!isOpsAnalyst}
          saveButtonText={SAVE_PROGRESS}
          continueButton
          continueButtonText={isOpsAnalyst ? UPDATE : CONTINUE}
          backButton
          onBack={onBack}
          onSaveProgress={formProps.saveForm}
          onContinue={isOpsAnalyst ? handleClickSave : formProps.moveForward}
          disableContinueButton={(doesNotHaveDependents && showDependents)}
        />

      </Form>
    </>
  );
};
