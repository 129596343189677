import { useCallback, useReducer } from 'react';
import { CallServiceOptions } from '../../api';
import { useApi } from '../../providers/api-provider';
import { useServiceReducer } from './reducer';

export type ServiceOptions = {} & CallServiceOptions;

export type ServiceResultTuple = [
  string | undefined,
  any,
  boolean,
  (loadParams?: any) => Promise<void>
];

export type ServiceParams = {
  name: string;
  params?: any;
  options?: ServiceOptions;
};

export const useService = (
  { name, params, options }: ServiceParams,
  deps: any[]
): ServiceResultTuple => {
  const [api] = useApi();
  const [state, dispatch] = useReducer(useServiceReducer, {
    loading: false,
    data: undefined,
    sensitiveData: undefined,
  });

  const load = useCallback(async (loadParams?: any) => {
    dispatch({ type: 'LOAD' });
    const result = await api.callService(name, loadParams || params, options);
    dispatch({
      type: 'SUCCESS',
      payload: result.data,
      sensitiveData: result.sensitiveData,
      eventName: result.eventName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return state.sensitiveData
    ? [
        state.eventName,
        { ...state.data, sensitiveData: state.sensitiveData },
        state.loading,
        load,
      ]
    : [state.eventName, state.data, state.loading, load];
};
