import {
  Drawer,
  DrawerDescription,
  DrawerTitle,
  FlexGrid,
  RadialButton,
  Typography,
  DrawerContent,
  Form,
  DrawerActions,
  Button,
  FormItemProps,
  useThemeProvider,
  Card,
  Description,
} from '@albo-ui/core';
import { useEffect, useMemo } from 'react';
import { useOnboardingForm } from '../../hooks/use-onboarding-form';
import { useQuery } from '../../hooks/user-query';
import { useAuth } from '../../providers/auth-provider';
import { useTranslator } from '../../providers/translator-provider';
import { useDefaultValidations } from '../../utils/default-validations';
import { useValidators } from '../../utils/validators';
import { FormItems } from '../form-items';
import { FaInfoCircle } from 'react-icons/fa';
import { useFormsFields } from '../../providers/onboarding-form-provider';

export type AddAdminDrawerProps = {
  uuidForm: string | undefined;
  open: boolean;
  onClose: () => void;
};

export const AddAdminDrawer = ({
  onClose,
  open,
  uuidForm,
}: AddAdminDrawerProps) => {
  const defaultValidations = useDefaultValidations();
  const validators = useValidators();
  const { theme } = useThemeProvider();
  const {
    leadData: { onboardingForms },
  } = useAuth();
  const formProps = useOnboardingForm({
    defaultParams: {
      role: '1',
      roleDesc: 'Administrador',
      general: { phoneCode: '+52', phoneCountry:'484', phoneCodeDesc: 'Mexico' },
      identificationFile: {
        type: 'ine',
      },
    },
    onboardingFormType: 'ADMIN',
    singleton: false,
    onboardingFormRef: uuidForm,
  });
  const { formFields ,findFields} = useFormsFields();
  
  useEffect(()=>{
    findFields('ADMIN');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const {
    ADD_ADMIN,
    NAME,
    PATERNAL_LASTNAME,
    MATERNAL_LASTNAME,
    EMAIL,
    PLATFORM_ROLE,
    ADD_ADMIN_DESCRIPTION,
    CANCEL,
    OFICIAL_IDENTIFICATION_FRONT,
    OFICIAL_IDENTIFICATION_BACK,
    UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
    SELECT_IDENTIFICATION_TYPE,
    INE,
    PASSPORT,
    SAVE,
    ADMIN,
    COMPLETE_YOUR_PENDING_INFO_ADMIN,
  } = useTranslator();
  const identificationFileType = formProps.form.data.identificationFile.type;
  const setErrors = formProps.form.setErrors;
  const { setPropByName } = formProps.form;

  const [queryFormData] = useQuery(
    {
      name: 'get-onboarding-forms-by-uuids',
      params: {
        onboardingFormsUuids: [uuidForm],
      },
    },
    [onboardingForms]
  );

  const isLead =
    queryFormData && queryFormData?.onboardingForms.length !== 0
      ? queryFormData?.onboardingForms[0].formData.isLead
      : false;

  const oficialDocuments = useMemo(
    () => [
      {
        name: 'ine',
        text: INE,
        value: identificationFileType === 'ine',
      },
      {
        name: 'passport',
        text: PASSPORT,
        value: identificationFileType === 'passport',
      },
    ],
    [identificationFileType, PASSPORT, INE]
  );

  const drawerInputs: FormItemProps<string>[] = formFields('ADMIN', [
    {
      placeholder: NAME,
      name: 'general.gname',
      type: 'input',
      required: true,
      disabled: isLead,
      ...defaultValidations.nameValidations(),
    },
    {
      placeholder: PATERNAL_LASTNAME,
      name: 'general.lname',
      type: 'input',
      required: true,
      disabled: isLead,
      ...defaultValidations.paternalLastNameValidations(),
    },
    {
      placeholder: MATERNAL_LASTNAME,
      name: 'general.slname',
      disabled: isLead,
      type: 'input',
      ...defaultValidations.maternalLastNameValidations(),
    },
    {
      placeholder: EMAIL,
      name: 'general.email',
      type: 'input',
      required: true,
      disabled: isLead,
      ...defaultValidations.emailValidations(),
    },
  ]);

  const oficialDocumentIne: FormItemProps<string>[] = formFields('ADMIN',[
    {
      placeholder: OFICIAL_IDENTIFICATION_FRONT,
      name: 'identificationFile.data.front',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
      help: {
        description: UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: OFICIAL_IDENTIFICATION_BACK,
      name: 'identificationFile.data.back',
      required: true,
      type: 'fileInput',
      validators: [validators.isEmpty],
    },
    {
      name: 'general',
      type: 'phone',
      required: true,
      disabled: isLead,
      ...defaultValidations.phoneNumberValidations(),
    },
    {
      placeholder: PLATFORM_ROLE,
      name: 'role',
      type: 'select',
      items: [{ name: 'Administrador', value: '1' }],
      disabled: true,
    },
  ]);

  const oficialDocumentPassport: FormItemProps<string>[] = formFields('ADMIN',[
    {
      placeholder: OFICIAL_IDENTIFICATION_FRONT,
      name: 'identificationFile.data.front',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
      help: {
        description: UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
      },
    },
    {
      name: 'general',
      type: 'phone',
      required: true,
      disabled: isLead,
      ...defaultValidations.phoneNumberValidations(),
    },
    {
      placeholder: PLATFORM_ROLE,
      name: 'role',
      type: 'select',
      items: [{ name: 'Administrador', value: '1' }],
      disabled: true,
    },
  ]);

  function onSelectOficialDocument(selectedIndex: number) {
    setPropByName('identificationFile', {
      data: undefined,
      type: selectedIndex === 0 ? 'ine' : 'passport',
    });
  }

  const onDrawerAddButtonClick = () => {
    if (formProps.form.hasErrors) {
      formProps.form.displayErrors();
    } else {
      formProps.moveForward();
    }
  };

  const saveOnboardingFormEvent = formProps.eventName;
  useEffect(() => {
    if (saveOnboardingFormEvent) onClose();
  }, [saveOnboardingFormEvent, onClose]);

  const adminInfo = (
    <>
      {COMPLETE_YOUR_PENDING_INFO_ADMIN}{' '}
      <span className='f-bold' style={{ textTransform: 'lowercase' }}>
        {ADMIN}.
      </span>
      .
    </>
  );

  useEffect(() => {
    if (identificationFileType !== 'ine') {
      setErrors((currentErrors: any) => ({
        ...currentErrors,
        'identificationFile.data.back': undefined,
      }));
    }
  }, [identificationFileType, setErrors]);

  const isLeadInfo = (
    <FlexGrid item>
      <Card
        fullWidth
        alignItems='center'
        style={{
          borderColor: theme.colors.focus,
          height: '55px',
        }}
      >
        <Description
          titleColor='focus'
          icon={<FaInfoCircle color={theme.colors.focus} size='17px' />}
        >
          {adminInfo}
        </Description>
      </Card>
    </FlexGrid>
  );

  return (
    <Drawer open={open} onClose={onClose} position='right'>
      <DrawerTitle>{ADD_ADMIN}</DrawerTitle>
      <DrawerDescription>
        <FlexGrid container fullWidth direction='column' spacing={2}>
          <FlexGrid item>{ADD_ADMIN_DESCRIPTION}</FlexGrid>
          {isLead && isLeadInfo}
        </FlexGrid>
      </DrawerDescription>
      <DrawerContent>
        <Form {...formProps.form}>
          <FlexGrid item container fullWidth spacing={4} direction='column'>
            <FormItems inputs={drawerInputs} />
            <FlexGrid item container fullWidth spacing={4} direction='column'>
              <FlexGrid item>
                <Typography variant='smallTitle'>
                  {SELECT_IDENTIFICATION_TYPE}
                </Typography>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                {oficialDocuments.map(({ value, text }, index) => (
                  <FlexGrid item>
                    <RadialButton
                      value={value}
                      onInput={() => onSelectOficialDocument(index)}
                      text={text}
                    />
                  </FlexGrid>
                ))}
              </FlexGrid>
              <FlexGrid item>
                <FormItems
                  inputs={
                    identificationFileType === 'ine'
                      ? oficialDocumentIne
                      : oficialDocumentPassport
                  }
                />
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>
        </Form>
      </DrawerContent>
      <DrawerActions>
        <Button onClick={onClose} buttonType='secondary' size='normal'>
          {CANCEL}
        </Button>
        <Button
          onClick={onDrawerAddButtonClick}
          size='normal'
          style={{ marginLeft: theme.spacesPx[2] }}
        >
          {SAVE}
        </Button>
      </DrawerActions>
    </Drawer>
  );
};
