
import { ReactNode, useCallback, useState } from 'react';
import { makeProvider } from 'react-provider-maker';
import { useEffect } from 'react';
import { useFirebase } from '../../providers/firebase-provider';
import { useAuth } from '../auth-provider';
import { MultisessionDialog } from '../../components/multisession-dialog';

export type DialogMultisessionProps = {
  children: ReactNode;
};

export const {
  Provider: MultiSessionProvider,
  useProvider: useMultisessionProvider,
} = makeProvider(() => {
  const {isAuth,error,logout} =useAuth()

  const [visible, setVisible] = useState<boolean>(false);
  

  const { loadSAFirebase, subscribeMultisessionEvent,firebaseisInitialized } = useFirebase()

  
  const show = useCallback(() => {
    setVisible(true)
  }, []);

  const close = useCallback(() => {
    setVisible(false)
    logout()
  }, [logout]);

  const load =useCallback(async()=>{
    if(firebaseisInitialized.current){
      return;
    }
    await loadSAFirebase();
       
    subscribeMultisessionEvent(() => {
              show();
      });
       
    
  },[ loadSAFirebase, show, subscribeMultisessionEvent,firebaseisInitialized])

  useEffect(() => {
    if(isAuth){
      load()
    }
  }, [isAuth, load])

  useEffect(() => {
    if(error === 'USER_ALREADY_LOGGED'){
      show()
    }
  }, [error,show])
 
  return {
    visible,
    close,
    show,
  };
});

export const DialogMultiSessionProvider = ({
  children,
}: DialogMultisessionProps) => {
  const { visible, close } = useMultisessionProvider();
  
  return (
    <>
      {children}
      <MultisessionDialog
        open={visible}
        onClose={close}
      />
    </>
  );
};
