import {
  DefaultComponentProps,
  FlexGrid,
  useThemeProvider,
  Card,
} from '@albo-ui/core';
import { IconContainer } from './styled';
import { FaTrash } from 'react-icons/fa';
import { ReactNode } from 'react';

export type ListItemCardProps = {
  children: ReactNode;
  onDelete: () => void;
} & DefaultComponentProps;

export function ListItemCard({
  style,
  onDelete,
  className,
  children,
}: ListItemCardProps) {
  const { theme } = useThemeProvider();

  return (
    <Card
      className={className}
      style={style}
      fullWidth
      flexDirection='column'
      justifyContent='center'
      height='auto'
    >
      <FlexGrid
        spacing={3}
        fullWidth
      >
        <FlexGrid container item spacing={3} justify='space-between'
        alignItems='center'>
          <FlexGrid container fullWidth item>{children}</FlexGrid>
          <FlexGrid item>
            <IconContainer theme={theme} style={{margin: 0}}>
              <FaTrash
                color={theme.colors.sunset}
                size='15px'
                onClick={onDelete}
              />
            </IconContainer>
          </FlexGrid>
        </FlexGrid>
      </FlexGrid>
    </Card>
  );
}
