import { useCallback, useState } from 'react';
import { makeProvider } from 'react-provider-maker';

export const { Provider: OnHomeCBSProvider, useProvider: useOnHomeCBS } =
  makeProvider(() => {
    const [state, setState] = useState<any>([]);
    const [showDialog, setShowDialog] = useState(true);

    // CB = Callback

    const addCB = useCallback(
      (CB: () => void) => {
        setState((currentState: any) => {
          currentState.push(CB);
          return [...currentState];
        });
      },
      [setState]
    );

    const removeCB = useCallback(
      (item) => {
        setState((currentState: any) => {
          return currentState.filter(
            (currentItem: any) => currentItem !== item
          );
        });
      },
      [setState]
    );
    return { addCB, removeCB, state, showDialog, setShowDialog };
  });
