import {
  useThemeProvider,
  WizardStepper,
  WizardStepperProps,
} from '@albo-ui/core';
import { Container, IconContainer } from './styled';
import { FaHome } from 'react-icons/fa';

export type OnBoardingWizardStepperProps = {
  onHomeClick: () => void;
} & WizardStepperProps;

export const OnBoardingWizardStepper = ({
  items,
  onHomeClick,
  sectionActive,
  sectionsDone,
  className,
  style,
}: OnBoardingWizardStepperProps) => {
  const { theme } = useThemeProvider();

  return (
    <Container theme={theme} style={style} className={className}>
      <IconContainer onClick={onHomeClick}>
        <FaHome size='20px' color={theme.colors.primary} />
      </IconContainer>
      <WizardStepper
        items={items}
        sectionActive={sectionActive}
        sectionsDone={sectionsDone}
      />
    </Container>
  );
};
