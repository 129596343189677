import axios, { AxiosInstance } from 'axios';
import { baseUrl } from '../environments/environment';
export type Options = {
  authorizationToken?: string;
};

export type CallServiceOptions = {
  phoneCodeToken?: string;
  signedUrlToken?: string;
};

export type ServiceResultData = {
  response: 'BAD' | 'OK';
  eventName: string;
  data: any;
  sensitiveData: any;
};

export type QueryResultData = {
  response: 'BAD' | 'OK';
  data: any;
};

export class Api {
  http: AxiosInstance;
  constructor(options: Options) {
    this.http = axios.create({
      baseURL: baseUrl,
      headers: {
        ...(options.authorizationToken && {
          Authorization: `Bearer ${options.authorizationToken}`}),
      },
    });
  }

  async callService(
    serviceName: string,
    data: any = {},
    options?: CallServiceOptions,
    httpOptions?: { headers: any }
  ): Promise<ServiceResultData> {
    const { data: resultData } = await this.http.post(
      `services/${serviceName}`,
      {
        ...options,
        data,
      },
      { ...httpOptions }
    );
    return resultData;
  }

  async callQuery(
    queryName: string,
    data: any = {},
    options?: CallServiceOptions
  ): Promise<QueryResultData> {
    const { data: resultData } = await this.http.post(`queries/${queryName}`, {
      ...options,
      data: data || {},
    });
    return resultData;
  }

  async uploadFile(url: string, file: Blob) {
    const { data: resultData } = await axios({
      method: 'put',
      url: url,
      data: await file.arrayBuffer(),
      headers: { 'Content-Type': `${file.type}` },
    });
    return resultData;
  }
}
