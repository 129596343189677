import { FlexGrid, Image, Typography } from '@albo-ui/core';
import personComputer from '../../../images/Person_Computer.svg';
import { useTranslator } from '../../../providers/translator-provider';

export type CreatingAccountProps = {
  email?: string;
};

export const CreatingAccount = ({ email }: CreatingAccountProps) => {
  const { YOUR_ACCOUNT_IS_BEING_CREATED, CREATING_ACCOUNT_DESCRIPTION } =
    useTranslator();
  return (
    <FlexGrid
      container
      direction='column'
      spacing={6}
      alignItems='center'
      style={{ maxWidth: '530px' }}
    >
      <FlexGrid item>
        <Image src={personComputer} size='212px' />
      </FlexGrid>
      <FlexGrid
        item
        container
        spacing={1}
        direction='column'
        alignItems='center'
        style={{ textAlign: 'center' }}
      >
        <FlexGrid item>
          <Typography variant='smallTitle'>
            {YOUR_ACCOUNT_IS_BEING_CREATED}
          </Typography>
        </FlexGrid>
        <FlexGrid item>
          <Typography variant='paragraph'>
            {CREATING_ACCOUNT_DESCRIPTION}
          </Typography>
        </FlexGrid>
        <FlexGrid item>
          <Typography variant='paragraph' bold>
            {email}
          </Typography>
        </FlexGrid>
      </FlexGrid>
    </FlexGrid>
  );
};
