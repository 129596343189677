import {
  ActionCard,
  Alert,
  Breadcrum,
  Button,
  Card,
  CheckBox,
  CodeInput,
  Drawer,
  FileInput,
  Form,
  Link,
  MenuItem,
  RadialButton,
  RoundedIcon,
  Select,
  Skeleton,
  Snackbar,
  Stepper,
  Tabs,
  TextField,
  Typography,
  WizardStepper,
  FlexGrid,
  useTextfield,
  useForm,
  SelectItem,
  useSelect,
  useFileInput,
  useRadialButton,
  useCheckBox,
  CheckBoxCard,
  WizardStepperItem,
  Step,
  BreadcrumItem,
  useSnackbar,
  useAlert,
  useDialog,
  useDrawer,
  DrawerTitle,
  DrawerContent,
  DrawerActions,
  useTabs,
} from '@albo-ui/core';
import { Divider } from './style';
import {
  FaReceipt,
  FaPen,
  FaUser,
  FaBriefcase,
  FaUserFriends,
  FaCreditCard,
} from 'react-icons/fa';
import { ExitPromptDialog } from '../../components-quick-win/exit-prompt-dialog';
import { DeletePromptDialog } from '../../components-quick-win/delete-prompt-dialog';
import { UserAlreadyExistDialog } from '../../components-quick-win/user-already-exist-dialog';

export const Playground = () => {
  const textFieldProps = useTextfield();
  const formProps = useForm({});
  const selectProps = useSelect();
  const selectSearchProps = useSelect();
  const fileInputProps = useFileInput();
  const raidalButtonProps = useRadialButton();
  const checkboxProps = useCheckBox();
  const snackbarProps = useSnackbar();
  const successAlertProps = useAlert();
  const warningAlertProps = useAlert();
  const errorAlertProps = useAlert();
  const exitPromptDialogProps = useDialog();
  const deleteDialogProps = useDialog();
  const userAlreadyExistDialogProps = useDialog();
  const rightDrawerProps = useDrawer();
  const leftDrawerProps = useDrawer();
  const { tabsProps } = useTabs();

  const receipt = (
    <RoundedIcon
      icon={<FaReceipt size='13px' color='white' />}
      color='oliva'
      size='normal'
    />
  );

  const steps: WizardStepperItem[] = [
    {
      color: 'oliva',
      icon: <FaUser size='13px' color='white' />,
      name: 'ADMIN',
    },
    {
      color: 'purple',
      icon: <FaPen size='13px' color='white' />,
      name: 'AGREEMENT',
    },
    {
      color: 'gold',
      icon: <FaBriefcase size='13px' color='white' />,
      name: 'BUSINESS_DATA_ACCOUNT',
    },
    {
      color: 'sunset',
      icon: <FaUserFriends size='13px' color='white' />,
      name: 'BENEFICIARIES',
    },
    {
      color: 'brightBlue',
      icon: <FaCreditCard size='13px' color='white' />,
      name: 'CARDS',
    },
  ];

  const stepperItems: Step[] = [
    {
      name: 'Information',
    },
    {
      name: 'Validation',
    },
    {
      name: 'Agreement',
    },
    {
      name: 'Account created',
    },
    {
      name: 'Welcome',
    },
  ];

  const breadCrumItems: BreadcrumItem[] = [
    {
      name: 'Home',
      nameKey: 'home',
    },
    {
      name: 'Administrador',
      nameKey: 'admin',
    },
  ];

  const breadCrumItems1: BreadcrumItem[] = [
    {
      name: 'Home',
      nameKey: 'home',
    },
  ];

  const breadCrumItems2: BreadcrumItem[] = [
    {
      name: 'Home',
      nameKey: 'home',
    },
    {
      name: 'Section 1',
      nameKey: 'admin',
    },
    {
      name: 'Section 2',
      nameKey: 'admin',
    },
    {
      name: 'Section 3',
      nameKey: 'admin',
    },
  ];

  const selectItems: SelectItem<string>[] = [
    {
      name: 'Option 1',
      value: 'op1',
    },
    {
      name: 'Option 2',
      value: 'op2',
    },
    {
      name: 'Option 3',
      value: 'op1',
    },
    {
      name: 'Option 4',
      value: 'op4',
    },
    {
      name: 'Option 5',
      value: 'op5',
    },
    {
      name: 'Option 6',
      value: 'op6',
    },
  ];

  return (
    <>
      <Form {...formProps}>
        <FlexGrid
          container
          fullHeight
          fullWidth
          spacing={3}
          direction='column'
          style={{
            backgroundColor: 'white',
            height: '100vh',
            overflowY: 'auto',
          }}
          padding={5}
        >
          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                TextField
              </Typography>
            </FlexGrid>
            <FlexGrid
              item
              container
              spacing={3}
              style={{ marginLeft: '50px' }}
              wrap
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Default</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <TextField {...textFieldProps} placeholder='Placeholder' />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Required</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <TextField
                    {...textFieldProps}
                    placeholder='Placeholder'
                    required
                  />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Help</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <TextField
                    {...textFieldProps}
                    placeholder='Placeholder'
                    help={{
                      description:
                        'Este es un mensaje super largo informativo para el usuario',
                    }}
                  />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Error</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <TextField
                    {...textFieldProps}
                    placeholder='Placeholder'
                    required
                    error
                    errorMessage={'Este es un mensaje de error'}
                  />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Help/Error</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <TextField
                    {...textFieldProps}
                    placeholder='Placeholder'
                    error
                    errorMessage='Este es un mensaje de error'
                    required
                    help={{
                      description:
                        'Este es un mensaje super largo informativo para el usuario',
                    }}
                  />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Disabled</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <TextField
                    {...textFieldProps}
                    placeholder='Placeholder'
                    value='Value'
                    disabled
                  />
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>

          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                Select
              </Typography>
            </FlexGrid>
            <FlexGrid
              item
              container
              spacing={3}
              style={{ marginLeft: '50px' }}
              wrap
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Default</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Select
                    {...selectProps}
                    items={selectItems}
                    placeholder='Placeholder'
                  />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Search</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Select
                    {...selectSearchProps}
                    items={selectItems}
                    placeholder='Placeholder'
                    search
                  />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Required</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Select
                    {...selectProps}
                    items={selectItems}
                    placeholder='Placeholder'
                    required
                  />
                </FlexGrid>
              </FlexGrid>

              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Help</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Select
                    {...selectProps}
                    items={selectItems}
                    placeholder='Placeholder'
                    required
                    help={{
                      description:
                        'Este es un mensaje super largo informativo para el usuario',
                    }}
                  />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Error</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Select
                    {...selectProps}
                    items={selectItems}
                    placeholder='Placeholder'
                    required
                    error
                    errorMessage='Este es un mensaje de error'
                  />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Help/Error</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Select
                    {...selectProps}
                    items={selectItems}
                    placeholder='Placeholder'
                    required
                    error
                    errorMessage='Este es un mensaje de error'
                    help={{
                      description:
                        'Este es un mensaje super largo informativo para el usuario',
                    }}
                  />
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>

          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                FileInput
              </Typography>
            </FlexGrid>
            <FlexGrid
              item
              container
              spacing={3}
              style={{ marginLeft: '50px' }}
              wrap
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Default</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <FileInput
                    {...fileInputProps}
                    placeholder='Placeholder'
                    size='normal'
                  />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Required</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <FileInput
                    {...fileInputProps}
                    placeholder='Placeholder'
                    required
                    size='normal'
                  />
                </FlexGrid>
              </FlexGrid>

              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Help</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <FileInput
                    {...fileInputProps}
                    placeholder='Placeholder'
                    size='normal'
                    help={{
                      description:
                        'Este es un mensaje super largo informativo para el usuario',
                    }}
                  />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Error</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <FileInput
                    {...fileInputProps}
                    placeholder='Placeholder'
                    required
                    size='normal'
                    error
                    errorMessage='Este es un mensaje de error'
                  />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Help/Error</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <FileInput
                    {...fileInputProps}
                    placeholder='Placeholder'
                    required
                    size='normal'
                    error
                    errorMessage='Este es un mensaje de error'
                    help={{
                      description:
                        'Este es un mensaje super largo informativo para el usuario',
                    }}
                  />
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>

          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                CodeInput
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={3}
              style={{ marginLeft: '50px' }}
              wrap
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Default</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <CodeInput onInput={() => {}} size={4} />
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>

          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                Button
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={3}
              style={{ marginLeft: '50px' }}
              wrap
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Default</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Button onClick={() => {}} size='normal'>
                    Button text
                  </Button>
                </FlexGrid>
              </FlexGrid>

              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Secondary</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Button
                    onClick={() => {}}
                    size='normal'
                    buttonType='secondary'
                  >
                    Button text
                  </Button>
                </FlexGrid>
              </FlexGrid>

              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Disabled</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Button onClick={() => {}} size='normal' disabled>
                    Button text
                  </Button>
                </FlexGrid>
              </FlexGrid>

              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Default - small</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Button onClick={() => {}} size='small'>
                    Button text
                  </Button>
                </FlexGrid>
              </FlexGrid>

              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    Secondary - small
                  </Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Button
                    onClick={() => {}}
                    size='small'
                    buttonType='secondary'
                  >
                    Button text
                  </Button>
                </FlexGrid>
              </FlexGrid>

              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    Secondary - small - disabled
                  </Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Button
                    onClick={() => {}}
                    size='small'
                    buttonType='secondary'
                    disabled
                  >
                    Button text
                  </Button>
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>

          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                RadialButton
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={3}
              style={{ marginLeft: '50px' }}
              wrap
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Default</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <RadialButton
                    {...raidalButtonProps}
                    text='Lorem ipsum amet'
                  />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Disabled</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <RadialButton
                    {...raidalButtonProps}
                    text='Lorem ipsum amet'
                    disabled
                  />
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>

          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                Link
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={3}
              style={{ marginLeft: '50px' }}
              wrap
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Default</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Link>+Agregar accionista</Link>
                </FlexGrid>
              </FlexGrid>

              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    Default - underlined
                  </Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Link underlined>+Agregar accionista</Link>
                </FlexGrid>
              </FlexGrid>

              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Bold</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Link bold>+Agregar accionista</Link>
                </FlexGrid>
              </FlexGrid>

              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    Bold - underlined
                  </Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Link bold underlined>
                    +Agregar accionista
                  </Link>
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>
          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                Checkbox
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={3}
              style={{ marginLeft: '50px' }}
              wrap
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Default</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <CheckBox
                    {...checkboxProps}
                    text='Lorem ipsum dolor sit amet, ullamco laboris '
                  />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    Default with card
                  </Typography>
                </FlexGrid>
                <FlexGrid item>
                  <CheckBox
                    {...checkboxProps}
                    text='Lorem ipsum dolor sit amet, ullamco laboris '
                    style={{ maxWidth: '500px' }}
                  >
                    <CheckBoxCard>Lorem ipsum dolor sit amet</CheckBoxCard>
                  </CheckBox>
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>

          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                Card
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={3}
              style={{ marginLeft: '50px' }}
              wrap
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Default</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Card width='500px' height='150px'>
                    {' '}
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Porro tenetur neque, sed numquam deleniti non, impedit earum
                    consequuntur magni voluptate vitae? Exercitationem harum
                    similique, ut deserunt illum assumenda recusandae? Quam.{' '}
                  </Card>
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>
          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                MenuItem
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={3}
              style={{ marginLeft: '50px' }}
              wrap
              direction='column'
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Default</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <MenuItem
                    title='Titulo'
                    description='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed dodolor sit amet, consectetur adipiscing'
                    icon={receipt}
                    onClick={() => {}}
                    status='default'
                    style={{ maxWidth: '700px' }}
                  />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Done</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <MenuItem
                    title='Titulo'
                    description='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed dodolor sit amet, consectetur adipiscing'
                    icon={receipt}
                    onClick={() => {}}
                    status='done'
                    style={{ maxWidth: '700px' }}
                  />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Pending</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <MenuItem
                    title='Titulo'
                    description='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed dodolor sit amet, consectetur adipiscing'
                    icon={receipt}
                    onClick={() => {}}
                    status='pending'
                    style={{ maxWidth: '700px' }}
                  />
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>
          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                ActionCard
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={3}
              style={{ marginLeft: '50px' }}
              wrap
              direction='column'
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Add</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <ActionCard
                    title='Agregar beneficiario'
                    onClick={() => {}}
                    style={{ maxWidth: '700px' }}
                    link
                  />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Edit/delete</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <ActionCard
                    title='Suzclem Adriana Ochoa Casillas'
                    status='default'
                    style={{ maxWidth: '700px' }}
                  />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    Edit/delete - with description
                  </Typography>
                </FlexGrid>
                <FlexGrid item>
                  <ActionCard
                    title='Suzclem Adriana Ochoa Casillas'
                    status='default'
                    style={{ maxWidth: '700px' }}
                    description='Porcentaje de acciones: 25%'
                  />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    Edit/delete - with children
                  </Typography>
                </FlexGrid>
                <FlexGrid item>
                  <ActionCard
                    title='Suzclem Adriana Ochoa Casillas'
                    status='default'
                    style={{ maxWidth: '700px', height: 'auto' }}
                  >
                    <RadialButton
                      {...raidalButtonProps}
                      style={{ paddingTop: '24px' }}
                      text='Esta persona será el administrador'
                    />
                  </ActionCard>
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    Edit/delete - pending
                  </Typography>
                </FlexGrid>
                <FlexGrid item>
                  <ActionCard
                    title='Suzclem Adriana Ochoa Casillas'
                    status='pending'
                    style={{ maxWidth: '700px' }}
                    description='Información pendiente'
                  />
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>
          <FlexGrid item>
            <Divider />
          </FlexGrid>
          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                WizardStepper
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={3}
              style={{ marginLeft: '50px' }}
              wrap
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Default</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <WizardStepper
                    items={steps}
                    sectionActive={3}
                    sectionsDone={[true, true, true, false, false]}
                  />
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>

          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                Stepper
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={3}
              style={{ marginLeft: '50px' }}
              wrap
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Default</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Stepper active={0} status='default' steps={stepperItems} />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    Default - second position
                  </Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Stepper active={1} status='default' steps={stepperItems} />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Done</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Stepper active={0} status='done' steps={stepperItems} />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Pending</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Stepper active={0} status='pending' steps={stepperItems} />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Validation</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Stepper
                    active={0}
                    status='validation'
                    steps={stepperItems}
                  />
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>

          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                Breadcrum
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={6}
              style={{ marginLeft: '50px' }}
              wrap
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    Default - only one item
                  </Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Breadcrum items={breadCrumItems1} />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    Default - two items
                  </Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Breadcrum items={breadCrumItems} />
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Multiple items</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Breadcrum items={breadCrumItems2} />
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>

          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                Snackbar
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={6}
              style={{ marginLeft: '50px' }}
              wrap
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Default</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Button
                    onClick={() => {
                      snackbarProps.openSnackbar();
                    }}
                    size='normal'
                  >
                    Open snackbar
                  </Button>
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>
          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                Alert
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={6}
              style={{ marginLeft: '50px' }}
              wrap
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Success</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Button
                    onClick={() => {
                      successAlertProps.openAlert();
                    }}
                    size='normal'
                  >
                    Open alert success
                  </Button>
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Warning</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Button
                    onClick={() => {
                      warningAlertProps.openAlert();
                    }}
                    size='normal'
                  >
                    Open alert warning
                  </Button>
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Error</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Button
                    onClick={() => {
                      errorAlertProps.openAlert();
                    }}
                    size='normal'
                  >
                    Open alert error
                  </Button>
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>

          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                Dialog
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={6}
              style={{ marginLeft: '50px' }}
              wrap
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Success</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Button
                    onClick={() => {
                      exitPromptDialogProps.openDialog();
                    }}
                    size='normal'
                  >
                    Open exit dialog
                  </Button>
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Warning</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Button
                    onClick={() => {
                      deleteDialogProps.openDialog();
                    }}
                    size='normal'
                  >
                    Open delete dialog
                  </Button>
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Error</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Button
                    onClick={() => {
                      userAlreadyExistDialogProps.openDialog();
                    }}
                    size='large'
                  >
                    Open user already exists dialog
                  </Button>
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>

          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                Typography
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={6}
              style={{ marginLeft: '50px' }}
              wrap
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>variant: H1</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Typography variant='h1'>Some text</Typography>
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>variant: H2</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Typography variant='h2'>Some text</Typography>
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    variant: Paragraph
                  </Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Typography variant='paragraph'>Some text</Typography>
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>
          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                Typography
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={6}
              style={{ marginLeft: '50px' }}
              wrap
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>variant: H1</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Typography variant='h1'>Some text</Typography>
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>variant: H2</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Typography variant='h2'>Some text</Typography>
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    variant: Paragraph
                  </Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Typography variant='paragraph'>Some text</Typography>
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>

          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2}>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                Drawer
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={6}
              style={{ marginLeft: '50px' }}
              wrap
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Position: right</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Button
                    onClick={() => {
                      rightDrawerProps.openDrawer();
                    }}
                    size='large'
                  >
                    This opens a right side drawer!
                  </Button>
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>position: left</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Button
                    onClick={() => {
                      leftDrawerProps.openDrawer();
                    }}
                    size='large'
                  >
                    This opens a left side drawer!
                  </Button>
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>

          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2} fullWidth>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                Tabs
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={6}
              style={{ marginLeft: '50px' }}
              wrap
              fullWidth
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>Default</Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Tabs
                    {...tabsProps}
                    tabNames={['Physical person', 'Moral person']}
                  >
                    <FlexGrid item container direction='column' spacing={2}>
                      First tab content
                    </FlexGrid>
                    <FlexGrid item container direction='column' spacing={2}>
                      Second tab content
                    </FlexGrid>
                  </Tabs>
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>
          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2} fullWidth>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                Skeleton
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={6}
              style={{ marginLeft: '50px' }}
              wrap
              fullWidth
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    Can be used to indicate a component with info is loading
                  </Typography>
                </FlexGrid>
                <FlexGrid item container spacing={2}>
                  <FlexGrid item>
                    <Skeleton width='250px' height='150px' />
                  </FlexGrid>
                  <FlexGrid item>
                    <Skeleton width='250px' height='150px' />
                  </FlexGrid>
                  <FlexGrid item>
                    <Skeleton width='250px' height='150px' />
                  </FlexGrid>
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>
          <FlexGrid item>
            <Divider />
          </FlexGrid>

          <FlexGrid item container direction='column' spacing={2} fullWidth>
            <FlexGrid item>
              <Typography variant='h2' color='primary'>
                RoundedIcon
              </Typography>
            </FlexGrid>

            <FlexGrid
              item
              container
              spacing={6}
              style={{ marginLeft: '50px' }}
              wrap
              fullWidth
            >
              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    Rounded circle with a given icon
                  </Typography>
                </FlexGrid>
                <FlexGrid item container spacing={2}>
                  <FlexGrid item>
                    <RoundedIcon
                      icon={<FaReceipt size='13px' color='white' />}
                      color='oliva'
                      size='normal'
                    />
                  </FlexGrid>
                  <FlexGrid item>
                    <RoundedIcon
                      icon={<FaUser size='13px' color='white' />}
                      color='primary'
                      size='normal'
                    />
                  </FlexGrid>
                  <FlexGrid item>
                    <RoundedIcon
                      icon={<FaPen size='13px' color='white' />}
                      color='gold'
                      size='normal'
                    />
                  </FlexGrid>
                </FlexGrid>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>
        </FlexGrid>
        <Snackbar
          {...snackbarProps}
          message='Correo enviado. sigue las instrucciones para recuperar tu contraseña'
          action='Entendido'
        />
      </Form>

      <ExitPromptDialog {...exitPromptDialogProps} onConfirm={() => {}} />
      <DeletePromptDialog {...deleteDialogProps} onConfirm={() => {}} />
      <UserAlreadyExistDialog
        {...userAlreadyExistDialogProps}
        onConfirm={() => {}}
      />

      <Drawer {...rightDrawerProps} position='right'>
        <DrawerTitle>Drawer Title</DrawerTitle>
        <DrawerContent>Drawer content</DrawerContent>
        <DrawerActions>
          <Typography variant='smallTitle' style={{ marginRight: '20px' }}>
            Actions
          </Typography>
          <Button onClick={() => {}} size='normal'>
            Dummy Button
          </Button>{' '}
        </DrawerActions>
      </Drawer>
      <Drawer {...leftDrawerProps} position='left'>
        <DrawerTitle>Drawer Title</DrawerTitle>
        <DrawerContent>Drawer content</DrawerContent>
        <DrawerActions>
          <Typography variant='smallTitle' style={{ marginRight: '20px' }}>
            Actions
          </Typography>
          <Button onClick={() => {}} size='normal'>
            Dummy Button
          </Button>
        </DrawerActions>
      </Drawer>

      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
        <Alert {...successAlertProps} description='Success!!' type='success' />
        <Alert {...warningAlertProps} description='Warning' type='warning' />
        <Alert {...errorAlertProps} description='Error...' type='error' />
      </div>
    </>
  );
};
