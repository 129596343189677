import {
  CheckBox,
  FlexGrid,
  Form,
  FormItemProps,
  RadialButton,
  Typography,
  useThemeProvider,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { FormItems } from '../form-items';
import { useValidators } from '../../utils/validators';
import { useDefaultValidations } from '../../utils/default-validations';
import { BottomActions } from '../bottom-actions';
import { useHistory } from 'react-router';
import { useOnboardingForm } from '../../hooks/use-onboarding-form';
import { OnBoardingLoader } from '../on-boarding-loader';
import { useOnHomeCBS } from '../../providers/on-home-cbs-provider';
import { useMemo, useEffect } from 'react';
import { usePmStatusQuickWin } from '../../hooks/use-pm-status-quick-win';
import { useAuth } from '../../providers/auth-provider';
import _ from 'lodash';
import { useCatalog } from '../../hooks/use-catalog';
import { useConfirm } from '../../components/confirm-dialog';
import { useUserRole } from '../../hooks/use-user-role';
import { useFormsFields } from '../../providers/onboarding-form-provider';


export const AccountConfigForm = () => {
  const formProps = useOnboardingForm({
    defaultParams: {
      admin: {
        general: {
          phone: { phoneCode: '+52', phoneCountry:'484', phoneCodeDesc: 'Mexico' },
        },
        role: '1',
        roleDesc: 'Administrador',
        identificationFile: {
          type: 'ine',
        },
      },
    },
    nextSectionUrl: '/home/menu',
    onboardingFormType: 'QUICK_WIN_ACCOUNT_CONFIG',
    singleton: true,
  });
  const { theme } = useThemeProvider();
  const validators = useValidators();
  const defaultValidators = useDefaultValidations();
  const history = useHistory();
  const pmStatus = usePmStatusQuickWin();
  const accountConfigStatus = pmStatus.accountConfigStatus !== 'done';
  const homeCBS = useOnHomeCBS();
  const defaultValidations = useDefaultValidations();
  const { leadData } = useAuth();
  const currentLeadData = leadData.general;
  const currentData = formProps.form.data.admin.general;
  const isLeadData = _.isEqual(currentLeadData, currentData);
  const checkBoxIsChecked = useMemo(() => isLeadData, [isLeadData]);
  homeCBS.setShowDialog(accountConfigStatus);
  const [societyTypes] = useCatalog('EntityTypes');
  const [mainUseTypes] = useCatalog('AccountPurposes');
  const [sourceResourcesTypes] = useCatalog('SourceOfAccountResources');
  const [targetResourcesTypes] = useCatalog('ResourcesDestinations');
  const [monthlyOperationsTypes] = useCatalog('TransferFrequencies');
  const [monthlyAmountTypes] = useCatalog('TransferAmounts');
  const [monthlyAccountsTypes] = useCatalog('TransferCounterparties');
  const { isOpsAnalyst } = useUserRole();


  const {
    ACCOUNT_CONFIG,
    ACCOUNT_ADMIN_DATA,
    ACCOUNT_CONFIG_DESCRIPTION,
    SOURCE_ACCOUNT_RESOURCES,
    EXPECTED_RESOURCES_USAGE,
    REQUIRED_FIELD,
    ACCOUNT_NICKNAME,
    ACCOUNT_NICKNAME_HELP_DESCRIPTION,
    NAME,
    PATERNAL_LASTNAME,
    MATERNAL_LASTNAME,
    EMAIL,
    INE,
    PASSPORT,
    OFICIAL_IDENTIFICATION_FRONT,
    OFICIAL_IDENTIFICATION_BACK,
    UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
    OFICIAL_IDENTIFICATION,
    ACCOUNT_ADMIN_DESCRIPTION,
    I_WILL_BE_THE_PRINCIPAL_ACCOUNT_ADMIN,
    ACCOUNT_SOCIETY_TYPE,
    ACCOUNT_CONFIG_TARGET_ACCOUNT_RESOURCES,
    ACCOUNT_CONFIG_SECTION_TITLE_ACCOUNT_DEPOSIT,
    ACCOUNT_CONFIG_SECTION_FIELD_MONTHY_MONEY_IN,
    ACCOUNT_CONFIG_SECTION_FIELD_MONTHY_MONEY_IN_AMOUNT,
    ACCOUNT_CONFIG_SECTION_FIELD_MONTHY_MONEY_IN_ACCOUNTS,
    ACCOUNT_CONFIG_SECTION_TITLE_ACCOUNT_TRANSFER,
    ACCOUNT_CONFIG_SECTION_FIELD_MONTHY_MONEY_OUT,
    ACCOUNT_CONFIG_SECTION_FIELD_MONTHY_MONEY_OUT_AMOUNT,
    ACCOUNT_CONFIG_SECTION_FIELD_MONTHY_MONEY_OUT_ACCOUNTS,
    FINISH,
    UPDATE,
    TITLE_UPDATE_CUSTOMER_INFO,
    DESCRIPTION_UPDATE_CUSTOMER_INFO,
    BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO,
    SAVE_PROGRESS
  } = useTranslator();
  const identificationFileType =
    formProps.form.data.admin.identificationFile.type;
  const { setPropByName } = formProps.form;
  const setErrors = formProps.form.setErrors;

  const oficialDocuments = useMemo(
    () => [
      {
        name: 'ine',
        text: INE,
        value: identificationFileType === 'ine',
      },
      {
        name: 'passport',
        text: PASSPORT,
        value: identificationFileType === 'passport',
      },
    ],
    [identificationFileType, PASSPORT, INE]
  );

  const { DialogConfirm, confirm: showConfirm } = useConfirm({
    title: TITLE_UPDATE_CUSTOMER_INFO,
    message: DESCRIPTION_UPDATE_CUSTOMER_INFO,
    textConfirm: BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO
  })

  const handleClickSave = async () => {
    const confirm = await showConfirm();
    if (!confirm) {
      return;
    }
    formProps.saveFormOps();
  }

  const { formFields, findFields } = useFormsFields();
  
  useEffect(()=>{
    findFields('QUICK_WIN_ACCOUNT_CONFIG');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
 
  const oficialDocumentIne: FormItemProps<string>[] = formFields('QUICK_WIN_ACCOUNT_CONFIG',[
    {
      placeholder: OFICIAL_IDENTIFICATION_FRONT,
      name: 'admin.identificationFile.data.front',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
      help: {
        description: UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: OFICIAL_IDENTIFICATION_BACK,
      name: 'admin.identificationFile.data.back',
      required: true,
      type: 'fileInput',
      validators: [validators.isEmpty],
    },
  ]);

  const oficialDocumentPassport: FormItemProps<string>[] = formFields('QUICK_WIN_ACCOUNT_CONFIG',[
    {
      placeholder: OFICIAL_IDENTIFICATION_FRONT,
      name: 'admin.identificationFile.data.front',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
      help: {
        description: UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
      },
    },
  ]);

  const societyTypesSelect = societyTypes.map((type: any) => ({
    name: type.value,
    value: type.key,
  }));

  const mainUseSelect = mainUseTypes.map((type: any) => ({
    name: type.value,
    value: type.key,
  }));

  const sourceResourcesSelect = sourceResourcesTypes.map((type: any) => ({
    name: type.value,
    value: type.key,
  }));

  const targetResourcesSelect = targetResourcesTypes.map((type: any) => ({
    name: type.value,
    value: type.key,
  }));

  const monthlyOperations = monthlyOperationsTypes.map((type: any) => ({
    name: type.value,
    value: type.key,
  }));

  const monthlyAmount = monthlyAmountTypes.map((type: any) => ({
    name: type.value,
    value: type.key,
  }));

  const monthlyAccounts = monthlyAccountsTypes.map((type: any) => ({
    name: type.value,
    value: type.key,
  }));



  const inputs: FormItemProps<string>[] = formFields('QUICK_WIN_ACCOUNT_CONFIG',[
    //Alias de la cuenta
    {
      placeholder: ACCOUNT_NICKNAME,
      name: 'accountName',
      type: 'input',
      required: true,
      ...defaultValidators.accountNickNameValidations(),
      help: {
        description: ACCOUNT_NICKNAME_HELP_DESCRIPTION,
      },
    },
    //Tipo de sociedad
    {
      placeholder: ACCOUNT_SOCIETY_TYPE,
      name: 'societyType',
      type: 'select',
      required: true,
      items: societyTypesSelect,
      search: true,
      validators: [validators.selectIsEmpty],
    },
    //Uso principal de esta cuenta
    {
      placeholder: EXPECTED_RESOURCES_USAGE,
      name: 'mainUseThisAccount',
      required: true,
      type: 'select',
      search: true,
      items: mainUseSelect,
      validators: [validators.selectIsEmpty],
    },
    //Origen de los recursos de la cuenta
    {
      placeholder: SOURCE_ACCOUNT_RESOURCES,
      name: 'sourceOfAccountResources',
      required: true,
      type: 'select',
      search: true,
      items: sourceResourcesSelect,
      validators: [validators.selectIsEmpty]
    },
    // Destino principal del dinero de la cuenta
    {
      placeholder: ACCOUNT_CONFIG_TARGET_ACCOUNT_RESOURCES,
      name: 'targetOfAccountResources',
      required: true,
      type: 'select',
      search: true,
      items: targetResourcesSelect,
      validators: [validators.selectIsEmpty],
    },
  ]);

  const inputsAccountDeposits: FormItemProps<string>[] = formFields('QUICK_WIN_ACCOUNT_CONFIG',[
    //Depositos estimados al mes
    {
      placeholder: ACCOUNT_CONFIG_SECTION_FIELD_MONTHY_MONEY_IN,
      name: 'moneyIn.approximateMonthlyOperations',
      type: 'select',
      required: true,
      items: monthlyOperations,
      search: true,
      validators: [validators.selectIsEmpty],
    },
    //Monto mensual estimado
    {
      placeholder: ACCOUNT_CONFIG_SECTION_FIELD_MONTHY_MONEY_IN_AMOUNT,
      name: 'moneyIn.approximateMonthlyAmount',
      type: 'select',
      required: true,
      items: monthlyAmount,
      search: true,
      validators: [validators.selectIsEmpty],
    },
    //Numero de cuentas money in
    {
      placeholder: ACCOUNT_CONFIG_SECTION_FIELD_MONTHY_MONEY_IN_ACCOUNTS,
      name: 'moneyIn.numberAccountsFromReceive',
      type: 'select',
      required: true,
      items: monthlyAccounts,
      search: true,
      validators: [validators.selectIsEmpty],
    }
  ]);

  const inputsAccountTransfers: FormItemProps<string>[] = formFields('QUICK_WIN_ACCOUNT_CONFIG',[
    //Transferencias estimadas al mes
    {
      placeholder: ACCOUNT_CONFIG_SECTION_FIELD_MONTHY_MONEY_OUT,
      name: 'transfersOtherAccounts.approximateMonthlyOperations',
      type: 'select',
      required: true,
      items: monthlyOperations,
      search: true,
      validators: [validators.selectIsEmpty],
    },
    //Monto mensual estimado
    {
      placeholder: ACCOUNT_CONFIG_SECTION_FIELD_MONTHY_MONEY_OUT_AMOUNT,
      name: 'transfersOtherAccounts.approximateMonthlyAmount',
      type: 'select',
      required: true,
      items: monthlyAmount,
      search: true,
      validators: [validators.selectIsEmpty],
    },
    //Cantidad de cuentas a las que envías
    {
      placeholder: ACCOUNT_CONFIG_SECTION_FIELD_MONTHY_MONEY_OUT_ACCOUNTS,
      name: 'transfersOtherAccounts.numberAccountsToSend',
      type: 'select',
      required: true,
      items: monthlyAccounts,
      search: true,
      validators: [validators.selectIsEmpty],
    }
  ]);

  const adminInputs: FormItemProps<string>[] = formFields('QUICK_WIN_ACCOUNT_CONFIG',[
    {
      placeholder: NAME,
      name: 'admin.general.gname',
      type: 'input',
      required: true,
      disabled: checkBoxIsChecked,
      ...defaultValidations.nameValidations(),
    },
    {
      placeholder: PATERNAL_LASTNAME,
      name: 'admin.general.lname',
      type: 'input',
      required: true,
      disabled: checkBoxIsChecked,
      ...defaultValidations.paternalLastNameValidations(),
    },
    {
      placeholder: MATERNAL_LASTNAME,
      name: 'admin.general.slname',
      type: 'input',
      disabled: checkBoxIsChecked,
      ...defaultValidations.maternalLastNameValidations(),
    },
    {
      placeholder: EMAIL,
      name: 'admin.general.email',
      type: 'input',
      required: true,
      disabled: checkBoxIsChecked,
      ...defaultValidations.emailValidations(),
    },
    {
      name: 'admin.general.phone',
      type: 'phone',
      required: true,
      disabled: checkBoxIsChecked,
      ...defaultValidations.phoneNumberValidations(),
    },
  ]);

  

  function onBack() {
    history.push('shareholders');
  }

  function onSelectOficialDocument(selectedIndex: number) {
    setPropByName('admin.identificationFile', {
      data: undefined,
      type: selectedIndex === 0 ? 'ine' : 'passport',
    });
  }
  const setFormData = formProps.form.setData;
  const onInputData = formProps.form.onInput;
  const generalLeadData = leadData.general;

  function onCheckBoxInput() {
    const isNotLeadData = !isLeadData;
    setFormData((currentData: any) => ({
      ...currentData,
      admin: {
        ...currentData.admin,
        identificationFile: {
          type: 'ine',
        },
      },
    }));

    if (isNotLeadData) {
      setFormData((currentData: any) => ({
        ...currentData,
        admin: {
          ...currentData.admin,
          general: {
            phone: { phoneCode: '+52', phoneCountry:'484', phoneCodeDesc: 'Mexico' },
          },
        },
      }));

      onInputData((currentData: any) => ({
        ...currentData,
        admin: {
          ...currentData.admin,
          role: '1',
          roleDesc: 'Administrador',
          general: {
            ...currentData.admin.general,
            ...generalLeadData,
          },
        },
      }));
    } else {
      setFormData((currentData: any) => ({
        ...currentData,
        admin: {
          general: {
            phone: { phoneCode: '+52', phoneCountry:'484', phoneCodeDesc: 'Mexico' },
          },
          role: '1',
          roleDesc: 'Administrador',
          identificationFile: {
            type: 'ine',
          },
        },
      }));
    }
  }

  useEffect(() => {
    if (identificationFileType !== 'ine') {
      setErrors((currentErrors: any) => ({
        ...currentErrors,
        'admin.identificationFile.data.back': undefined,
      }));
    }
  }, [identificationFileType, setErrors]);

  if (formProps.saving || formProps.movingForward || formProps.loadingForm)
    return (
      <OnBoardingLoader
        isLoading={
          formProps.saving || formProps.movingForward || formProps.loadingForm
        }
      />
    );

  return (
    <Form {...formProps.form} onSubmit={formProps.moveForward}>
      <FlexGrid
        container
        fullHeight
        fullWidth
        direction='column'
        spacing={3}
        style={{ marginBottom: '100px' }}
      >
        <FlexGrid item container direction='column' spacing={1}>
          <FlexGrid item>
            <Typography variant='h2'>{ACCOUNT_CONFIG}</Typography>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='small'>
              {ACCOUNT_CONFIG_DESCRIPTION}
            </Typography>
          </FlexGrid>
        </FlexGrid>
        <FlexGrid item>
          <Typography variant='small' color='neutral' bold>
            <span style={{ color: theme.colors.required }}>*</span>{' '}
            {REQUIRED_FIELD}
          </Typography>
        </FlexGrid>
        <FlexGrid item container fullWidth>
          <FormItems inputs={inputs} />
        </FlexGrid>

        <FlexGrid item container direction='column' spacing={1}>
          <FlexGrid item>
            <Typography variant='h2'>{ACCOUNT_CONFIG_SECTION_TITLE_ACCOUNT_DEPOSIT}</Typography>
          </FlexGrid>
        </FlexGrid>
        <FlexGrid item container fullWidth>
          <FormItems inputs={inputsAccountDeposits} />
        </FlexGrid>

        <FlexGrid item container direction='column' spacing={1}>
          <FlexGrid item>
            <Typography variant='h2'>{ACCOUNT_CONFIG_SECTION_TITLE_ACCOUNT_TRANSFER}</Typography>
          </FlexGrid>
        </FlexGrid>
        <FlexGrid item container fullWidth>
          <FormItems inputs={inputsAccountTransfers} />
        </FlexGrid>

        <FlexGrid item container fullWidth spacing={4} direction='column'>
          <FlexGrid item container direction='column' spacing={1}>
            <FlexGrid item>
              <Typography variant='h2'>{ACCOUNT_ADMIN_DATA}</Typography>
            </FlexGrid>
            <FlexGrid item>
              <Typography variant='small'>
                {ACCOUNT_ADMIN_DESCRIPTION}
              </Typography>
            </FlexGrid>
            <FlexGrid item>
              <CheckBox
                onInput={onCheckBoxInput}
                value={checkBoxIsChecked}
                text={I_WILL_BE_THE_PRINCIPAL_ACCOUNT_ADMIN}
              />
            </FlexGrid>
          </FlexGrid>
          <FormItems inputs={adminInputs} />
        </FlexGrid>
        <FlexGrid item container fullWidth spacing={4} direction='column'>
          <FlexGrid item>
            <Typography variant='smallTitle'>
              {OFICIAL_IDENTIFICATION}
            </Typography>
          </FlexGrid>
          <FlexGrid item container direction='row' spacing={2}>
            {oficialDocuments.map(({ value, text }, index) => (
              <FlexGrid item key={index}>
                <RadialButton
                  value={value}
                  onInput={() => onSelectOficialDocument(index)}
                  text={text}
                />
              </FlexGrid>
            ))}
          </FlexGrid>
          <FlexGrid item>
            <FormItems
              inputs={
                identificationFileType === 'ine'
                  ? oficialDocumentIne
                  : oficialDocumentPassport
              }
            />
          </FlexGrid>
        </FlexGrid>

        <DialogConfirm />

        <BottomActions
          backButton
          continueButton
          continueButtonText={isOpsAnalyst ? UPDATE : FINISH}
          saveProgressButton={accountConfigStatus && !isOpsAnalyst}
          saveButtonText={SAVE_PROGRESS}
          onSaveProgress={formProps.saveForm}
          onContinue={isOpsAnalyst ? handleClickSave : formProps.moveForward}
          onBack={onBack}
        />
      </FlexGrid>
    </Form>
  );
};
