import {
  Card,
  Description,
  FlexGrid,
  Form,
  FormItemProps,
  Typography,
  useThemeProvider,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { FaInfoCircle } from 'react-icons/fa';
import { FormItems } from '../../components-quick-win/form-items';
import { useCatalog } from '../../hooks/use-catalog';
import { useValidators } from '../../utils/validators';
import { BottomActions } from '../bottom-actions';
import { useDefaultValidations } from '../../utils/default-validations';
import { useOnboardingForm } from '../../hooks/use-onboarding-form';
import { OnBoardingLoader } from '../on-boarding-loader';
import { useEffect } from 'react';
import { usePfaeStatus } from '../../hooks/use-pfae-status';
import { useOnHomeCBS } from '../../providers/on-home-cbs-provider';
import { useConfirm } from '../../components/confirm-dialog';
import { useUserRole } from '../../hooks/use-user-role';
import { useFormsFields } from '../../providers/onboarding-form-provider';

export const AdminForm = () => {
  const formProps = useOnboardingForm({
    defaultParams: {
      general: { phone: { phoneCode: '+52', phoneCountry: '484', phoneCodeDesc: 'Mexico' } },
      identificationFile: {
        type: 'ine',
      },
    },
    onboardingFormType: 'QUICK_WIN_PERSONAL_INFO',
    nextSectionUrl: 'company-info-and-account',
    singleton: true,
  });
  const setErrors = formProps.form.setErrors;
  const { theme } = useThemeProvider();
  const [countries] = useCatalog('Countries');
  const [genders] = useCatalog('Genders');
  const validators = useValidators();
  const defaultValidations = useDefaultValidations();
  const pfaeStatus = usePfaeStatus();
  const adminStatus = pfaeStatus.adminStatus !== 'done';
  const homeCBS = useOnHomeCBS();
  homeCBS.setShowDialog(adminStatus);

  const countriesSelect = countries.map((country: any) => ({
    value: country.key,
    name: country.value,
  }));

  const gendersSelect = genders.map((gender: any) => ({
    value: gender.key,
    name: gender.value,
  }));

  useEffect(()=>{
    findFields('QUICK_WIN_PERSONAL_INFO');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const {
    ADMIN_FORM_CARD_DESCRIPTION,
    PERSONAL_INFO,
    ADMIN_FORM_CARD_SIGN_CONTRACT_TEXT,
    REMEMBER,
    ADMIN_FORM_FORM_TEXT,
    REQUIRED_FIELD,
    NAME,
    PATERNAL_LASTNAME,
    MATERNAL_LASTNAME,
    EMAIL,
    BIRTH_COUNTRY,
    BIRTH_DATE,
    CURP,
    CURP_HELP_DESCRIPTION,
    GENDER,
    OFICIAL_IDENTIFICATION_FRONT,
    UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
    OFICIAL_IDENTIFICATION_BACK,
    OFICIAL_IDENTIFICATION_SELFIE,
    OFICIAL_IDENTIFICATION_SELFIE_HELP,
    EMAIL_YOU_REGISTERED_IN_ALBO,
    UPLOAD_INE,
    EMAIL_YOU_REGISTERED_IN_ALBO_DESCRIPTION,
    DATE_FORMAT,
    UPDATE,
    CONTINUE,
    TITLE_UPDATE_CUSTOMER_INFO,
    DESCRIPTION_UPDATE_CUSTOMER_INFO,
    BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO,
    SAVE_PROGRESS
  } = useTranslator();

  const { formFields, findFields } = useFormsFields();
  const { DialogConfirm, confirm: showConfirm } = useConfirm({
    title: TITLE_UPDATE_CUSTOMER_INFO,
    message: DESCRIPTION_UPDATE_CUSTOMER_INFO,
    textConfirm: BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO
  });

  const handleClickSave = async () => {
    const confirm = await showConfirm();
    if (!confirm) {
      return;
    }
    formProps.saveFormOps();
  }

  const { isOpsAnalyst } = useUserRole();

  const formData = formProps.form.data;
  const identificationFileType = formData.identificationFile.type;
  const emailRegisteredInAlbo = formData.retailAccountEmail;

  const oficialDocumentIne: FormItemProps<string>[] = formFields('QUICK_WIN_PERSONAL_INFO', [
    {
      placeholder: OFICIAL_IDENTIFICATION_FRONT,
      name: 'identificationFile.data.front',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
      help: {
        description: UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: OFICIAL_IDENTIFICATION_BACK,
      name: 'identificationFile.data.back',
      required: true,
      type: 'fileInput',
      validators: [validators.isEmpty],
    },
    {
      placeholder: OFICIAL_IDENTIFICATION_SELFIE,
      name: 'identificationFile.data.selfie',
      required: true,
      type: 'fileInput',
      validators: [validators.isEmpty],
      help: {
        description: OFICIAL_IDENTIFICATION_SELFIE_HELP,
      },
    },
  ]);

  const oficialDocumentPassport: FormItemProps<string>[] = formFields('QUICK_WIN_PERSONAL_INFO', [
    {
      placeholder: OFICIAL_IDENTIFICATION_FRONT,
      name: 'identificationFile.data.front',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
      help: {
        description: UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
      },
    },
  ]);

  const inputs: FormItemProps<string>[] = formFields('QUICK_WIN_PERSONAL_INFO', [
    {
      placeholder: NAME,
      name: 'general.gname',
      type: 'input',
      required: true,
      disabled: true,
      ...defaultValidations.nameValidations(),
    },
    {
      placeholder: PATERNAL_LASTNAME,
      name: 'general.lname',
      type: 'input',
      required: true,
      disabled: true,
      ...defaultValidations.paternalLastNameValidations(),
    },
    {
      placeholder: MATERNAL_LASTNAME,
      name: 'general.slname',
      type: 'input',
      disabled: true,
      ...defaultValidations.maternalLastNameValidations(),
    },
    {
      placeholder: EMAIL,
      name: 'general.email',
      type: 'input',
      required: true,
      disabled: true,
      ...defaultValidations.emailValidations(),
    },
    {
      name: 'general.phone',
      type: 'phone',
      required: true,
      disabled: true,
      ...defaultValidations.phoneNumberValidations(),
    },
    {
      placeholder: BIRTH_COUNTRY,
      name: 'general.bcountry',
      type: 'select',
      inputType: 'text',
      items: countriesSelect,
      search: true,
      required: true,
      validators: [validators.selectIsEmpty],
    },
    {
      placeholder: `${BIRTH_DATE} ${DATE_FORMAT}`,
      name: 'general.bdate',
      type: 'date',
      inputType: 'email',
      required: true,
      validators: [validators.isEmpty, validators.notValidDateFormat],
    },
    {
      placeholder: GENDER,
      name: 'general.gender',
      type: 'select',
      inputType: 'text',
      items: gendersSelect,
      required: true,
      validators: [validators.selectIsEmpty],
    },
    {
      placeholder: CURP,
      name: 'general.populationid',
      type: 'input',
      inputType: 'alphanumeric',
      required: true,
      toUpperCase: true,
      ...defaultValidations.curpValidations(),
      help: {
        description: CURP_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: EMAIL_YOU_REGISTERED_IN_ALBO,
      name: 'retailAccountEmail',
      type: 'input',
      help: { description: EMAIL_YOU_REGISTERED_IN_ALBO_DESCRIPTION },
      inputType: 'email',
      maxLength: 100,
      validators:
        emailRegisteredInAlbo?.length > 0 ? [validators.notValidEmail] : [],
    },
  ]);

  useEffect(() => {
    if (identificationFileType !== 'ine') {
      setErrors((currentErrors: any) => ({
        ...currentErrors,
        'identificationFile.data.back': undefined,
      }));
    }
  }, [identificationFileType, setErrors]);

  if (formProps.loadingForm || formProps.saving || formProps.movingForward)
    return (
      <OnBoardingLoader
        isLoading={
          formProps.loadingForm || formProps.saving || formProps.movingForward
        }
      />
    );

  return (
    <FlexGrid container fullHeight fullWidth direction='column' spacing={3}>
      <FlexGrid item container direction='column' spacing={1}>
        <FlexGrid item>
          <Typography variant='h2'>{PERSONAL_INFO}</Typography>
        </FlexGrid>
        <FlexGrid item>
          <Typography variant='small'>{ADMIN_FORM_FORM_TEXT}</Typography>
        </FlexGrid>
      </FlexGrid>
      <FlexGrid item>
        <Card
          fullWidth
          alignItems='center'
          style={{
            borderColor: theme.colors.focus,

            padding: `${theme.spacesPx[3]} ${theme.spacesPx[3]}`,
          }}
        >
          <Description
            title={REMEMBER}
            titleColor='focus'
            icon={<FaInfoCircle color={theme.colors.focus} size='17px' />}
          >
            {ADMIN_FORM_CARD_DESCRIPTION}{' '}
            <span className='f-bold'>{ADMIN_FORM_CARD_SIGN_CONTRACT_TEXT}</span>
          </Description>
        </Card>
      </FlexGrid>
      <FlexGrid item>
        <Typography variant='small' color='neutral' bold>
          <span style={{ color: theme.colors.required }}>*</span>{' '}
          {REQUIRED_FIELD}
        </Typography>
      </FlexGrid>
      <FlexGrid item container fullWidth style={{ paddingBottom: '200px' }}>
        <Form {...formProps.form} onSubmit={isOpsAnalyst ? handleClickSave : formProps.moveForward}>
          <FlexGrid item container fullWidth spacing={4} direction='column'>
            <FormItems inputs={inputs} />
            <FlexGrid item container fullWidth spacing={4} direction='column'>
              <FlexGrid item>
                <Typography variant='smallTitle'>{UPLOAD_INE}</Typography>
              </FlexGrid>
              <FlexGrid item>
                <FormItems
                  inputs={
                    identificationFileType === 'ine'
                      ? oficialDocumentIne
                      : oficialDocumentPassport
                  }
                />
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>
        </Form>
      </FlexGrid>

      <DialogConfirm />

      <BottomActions
        saveProgressButton={adminStatus && !isOpsAnalyst}
        saveButtonText={SAVE_PROGRESS}
        continueButton
        continueButtonText={isOpsAnalyst ? UPDATE : CONTINUE}
        onSaveProgress={formProps.saveForm}
        onContinue={isOpsAnalyst ? handleClickSave : formProps.moveForward}
      />
    </FlexGrid>
  );
};
