import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { AccountConfig } from './routes/account-config';
import { Admin } from './routes/admin';
import { Admins } from './routes/admins';
import { Beneficiaries } from './routes/beneficiaries';
import { CardRequests } from './routes/cards-request';
import { CompanyInfo } from './routes/company-info';
import { Contract } from './routes/contract';
import { LegalRepresentatives } from './routes/legal-representatives';
import { AddShareHolders } from './routes/shareholders';
import { CompanyInfoAndAccount } from './routes/company-info-and-account';
import { CardRequestForm } from '../../../../../../components-quick-win/card-request-form';
import { LegalRepresentative } from './routes/legal-representative';
import { useAuth } from '../../../../../../providers/auth-provider';
import { ShareHoldersInfo } from './routes/shareholders-info';
import { SoleOwnerInfo } from './routes/sole-owner-info';

const PFAECardRequestForm = () => <CardRequestForm personType='PFAE' />;
const PMCardRequestForm = () => <CardRequestForm personType='PM' />;

export const RecordInfoRouter = () => {
  const { url } = useRouteMatch();
  const { user } = useAuth();
  const leadType = user?.leadType;

  return leadType === 'PFAE' ? (
    <Switch>
      <Route exact path={`${url}/personal-info`} component={Admin} />
      <Route exact path={`${url}/beneficiaries`} component={Beneficiaries} />
      <Route
        exact
        path={`${url}/cards-request`}
        component={PFAECardRequestForm}
      />
      <Route
        exact
        path={`${url}/cards-request-info`}
        component={CardRequests}
      />
      <Route
        path={`${url}/company-info-and-account`}
        component={CompanyInfoAndAccount}
      />
      <Route
        path={`${url}/contract`}
        component={() => <Contract personType='PFAE' />}
      />
      <Route
        path={`${url}/legal-representatives`}
        component={LegalRepresentatives}
      />
      <Redirect to={`/onboarding/home`} />
    </Switch>
  ) : (
    <Switch>
      <Route
        path={`${url}/legal-representative`}
        component={LegalRepresentative}
      />
      <Route exact path={`${url}/account-config`} component={AccountConfig} />
      <Route exact path={`${url}/shareholders`} component={AddShareHolders} />
      <Route
        exact
        path={`${url}/shareholders-info`}
        component={ShareHoldersInfo}
      />
      <Route
        exact
        path={`${url}/cards-request`}
        component={PMCardRequestForm}
      />
      <Route
        exact
        path={`${url}/cards-request-info`}
        component={CardRequests}
      />
      <Route exact path={`${url}/company-info`} component={CompanyInfo} />
      <Route exact path={`${url}/admins`} component={Admins} />
      <Route exact path={`${url}/sole-owner-info`} component={SoleOwnerInfo} />
      <Route
        exact
        path={`${url}/contract`}
        component={() => <Contract personType='PM' />}
      />
      <Route
        exact
        path={`${url}/legal-representatives`}
        component={LegalRepresentatives}
      />
      <Redirect to={`/onboarding/home`} />
    </Switch>
  );
};
