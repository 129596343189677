import { FlexGrid } from '@albo-ui/core';
import { CreatingAccount } from '../../../../../../components-quick-win/status-process-lib/creating-account';

export const AccountCreation = () => {
  return (
    <FlexGrid
      container
      alignItems='center'
      justify='center'
      fullHeight
      fullWidth
    >
      <FlexGrid item>
        <CreatingAccount />
      </FlexGrid>
    </FlexGrid>
  );
};
