import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { useAuth } from '../../providers/auth-provider';
import { Home } from './routes/home';
import { Welcome } from './routes/welcome';

export const OnboardingRouter = () => {
  const { url } = useRouteMatch();
  const { user } = useAuth();

  return user?.status === 'starting' || user?.status === 'signing-up' ? (
    <Switch>
      <Route path={`${url}/welcome`} component={Welcome} />
      <Redirect to={`${url}/welcome`} />
    </Switch>
  ) : (
    <Switch>
      <Route path={`${url}/home`} component={Home} />
      <Route path={`${url}/home/menu`} component={Home} />
      <Redirect to={`${url}/home`} />
    </Switch>
  );
};
