import { useCallback, useState } from 'react';
import { makeProvider } from 'react-provider-maker';
import { useApi } from '../api-provider';
import { useAuth } from '../auth-provider';
import { useTracker } from '../../providers/tracker-provider';
export type SignUpParams = {
  general: {
    gname: string;
    lname: string;
    slname: string;
    email: string;
    phone: string;
    phoneCode: string;
    phoneCodeDesc: string;
    phoneCountry: string;
  };
  type: 'PFAE' | 'PM';
  password: string;
};

export const { Provider: SignUpProvider, useProvider: useSignUp } =
  makeProvider(() => {
    const [api] = useApi();
    const { identify: identifyOnTracker } = useTracker();
    const { loadCredentials } = useAuth();
    const [state, setState] = useState<{
      loading?: boolean | undefined;
      isSignedUp?: boolean;
      isEmailTokenValidated?: boolean;
      emailToken?: string;
      phoneCodeToken?: string;
      phone?: string;
      phoneCode?: string;
      phoneCodeDesc?: string;
      phoneCountry?: string;
      sendAttempts?: number;
      error?:
        | 'INVALID_PHONE_CODE'
        | 'USER_NOT_SIGNED_UP'
        | 'USER_NOT_ALLOWED'
        | 'AUTHORIZATION_NOT_ALLOWED';
      lastCodeChangeDate?: string;
    }>({});
    const currentEmailToken = state.emailToken;

    const signUp = useCallback(
      async (signUpParams: SignUpParams) => {
        identifyOnTracker(signUpParams.general.email);
        setState({ loading: true });
        const signUpResult = await api.callService('sign-up', signUpParams);
        if (signUpResult.eventName === 'UserSignedUp') {
          setState({ isSignedUp: true, loading: false });
        }
        setState({ loading: false });
      },
      [api, setState, identifyOnTracker]
    );

    const validateEmailToken = useCallback(
      async (token: string) => {
        setState({ loading: true });

        const signUpResult = await api
          .callService('validate-email-token', {}, { signedUrlToken: token })
          .catch(() => {});

        if (signUpResult && signUpResult.eventName === 'EmailTokenValidated') {
          setState({
            isSignedUp: true,
            loading: false,
            emailToken: token,
            isEmailTokenValidated: true,
            phone: signUpResult.data.user.phone.phone,
            phoneCode: signUpResult.data.user.phone.phoneCode,
            phoneCodeDesc: signUpResult.data.user.phone.phoneCodeDesc,
            phoneCountry: signUpResult.data.user.phone.phoneCountry,
          });
        } else
          setState((currentState) => ({
            ...currentState,
            loading: false,
            isEmailTokenValidated: false,
          }));
      },
      [api, setState]
    );

    const sendPhoneCode = useCallback(
      async (token?: string) => {
        const sendPhoneCodeResult = await api.callService(
          'require-auth-sms-code',
          {
            action: 'PRELEAD',
          },
          { signedUrlToken: token }
        );

        if (sendPhoneCodeResult.data.info.reason === 'USER_NOT_ALLOWED') {
          setState((currentState) => ({
            ...currentState,
            lastCodeChangeDate:
              sendPhoneCodeResult.data.info.lastCodeChangeDate,
            error: sendPhoneCodeResult.data.info.reason,
          }));
        }

        if (
          sendPhoneCodeResult.data.info.reason === 'AUTHORIZATION_NOT_ALLOWED'
        ) {
          setState((currentState) => ({
            ...currentState,
            error: sendPhoneCodeResult.data.info.reason,
          }));
        }

        setState((currentState) => ({
          ...currentState,
          sendAttempts: sendPhoneCodeResult.data.info.sendAttempts,
        }));

        return sendPhoneCodeResult.data;
      },
      [api]
    );

    const savePhone = useCallback(
      async (
        phone,
        phoneCode,
        phoneCodeDesc,
        phoneCountry,
        jwt?: string | null
      ) => {
        setState((currentState) => ({ ...currentState, loading: true }));

        try {
          await api.callService(
            'save-phone',
            {
              phone: { phoneCode, phoneCodeDesc, phoneCountry, phone },
            },
            { signedUrlToken: jwt ? jwt : currentEmailToken }
          );
        } catch (e) {
          setState((currentState) => ({
            ...currentState,
            error: 'AUTHORIZATION_NOT_ALLOWED',
          }));
        }

        sendPhoneCode(currentEmailToken);
        setState((currentState) => ({
          ...currentState,
          loading: false,
        }));
      },
      [api, sendPhoneCode, currentEmailToken]
    );

    const validatePhoneCode = useCallback(
      async (code: string) => {
        setState((currentState) => ({ ...currentState, loading: true }));
        var validatePhoneCodeResult;

        try {
          validatePhoneCodeResult = await api.callService(
            'validate-phone-code',
            {
              code,
              action: 'PRELEAD',
            },
            { signedUrlToken: currentEmailToken }
          );
        } catch (e) {
          setState((currentState) => ({
            ...currentState,
            error: 'AUTHORIZATION_NOT_ALLOWED',
          }));
        }

        if (validatePhoneCodeResult?.eventName === 'PhoneCodeValidated') {
          const phoneCodeToken = validatePhoneCodeResult.data.token;
          const obtainCredentialsResult = await api.callService(
            'obtain-credentials',
            {},
            { phoneCodeToken }
          );
          const jwt = obtainCredentialsResult.sensitiveData.credentials.jwt;
          loadCredentials(jwt);
          setState({
            loading: false,
          });
        }

        if (validatePhoneCodeResult?.eventName === 'PhoneCodeNotValidated') {
          setState((currentState) => ({
            ...currentState,
            loading: false,
            error: 'INVALID_PHONE_CODE',
          }));
        }

        setState((currentState) => ({ ...currentState, loading: false }));
      },
      [api, loadCredentials, currentEmailToken]
    );

    return {
      ...state,
      signUp,
      savePhone,
      validateEmailToken,
      validatePhoneCode,
      sendPhoneCode,
      api,
    };
  });
