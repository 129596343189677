export type AuthError =
  | 'USER_NOT_FOUND'
  | 'INVALID_CREDENTIALS'
  | 'USER_BLOCKED'
  | 'INVALID_PHONE_CODE'
  | 'USER_NOT_ALLOWED'
  | 'USER_ALREADY_LOGGED';

export type ActionType =
  | 'LOGIN'
  | 'PRELEAD'
  | 'SIGN_CONTRACT'
  | 'COMPLETE_LEGAL_REPRESENTATIVE_ACCOUNT'
  | undefined;

export type UseAuthState = {
  isAuth?: boolean;
  isPreLoggedIn?: boolean;
  loading?: boolean;
  userData?: any;
  leadData?: any;
  error?: AuthError;
  maskedPhone?: string;
  reloadingProfile?: boolean;
  authError?: AuthError;
  lastBlockDate?: string;
  lastCodeChangeDate?: string;
  action?: ActionType;
  user?: {
    userUuid: string;
    leadUuid: string;
    role: string;
    leadType: 'PFAE' | 'PM';
    status:
      | 'signing-up'
      | 'starting'
      | 'info'
      | 'revision'
      | 'request-extrainfo'
      | 'pending-extrainfo'
      | 'validating'
      | 'documents-required'
      | 'signing'
      | 'creating-account'
      | 'done'
      | 'rejected';
    name: string;
  };
};

export const initialAuthState: UseAuthState = { loading: true };

export type Action =
  | { type: 'LOGIN' }
  | { type: 'RELOAD_PROFILE' }
  | { type: 'LOGOUT' }
  | { type: 'NOT_LOGGED_IN'; payload: { authError?: AuthError } }
  | {
      type: 'USER_BLOCKED';
      payload: {
        authError?: AuthError;
        lastBlockDate?: string;
        lastCodeChangeDate?: string;
        action?: string;
      };
    }
  | {
      type: 'USER_NOT_ALLOWED';
      payload: {
        authError?: AuthError;
        lastBlockDate?: string;
        lastCodeChangeDate?: string;
        action?: string;
      };
    }
  | { type: 'PRE_LOGGED_IN'; payload: { maskedPhone: string } }
  | { type: 'VALIDATE_CODE' }
  | { type: 'LOGGED_IN'; payload: { user: any; lead: any } }
  | { type: 'USER_NOT_FOUND'; payload: { authError?: AuthError } };

export const useAuthReducer = (
  state: UseAuthState,
  action: Action
): UseAuthState => {
  switch (action.type) {
    case 'RELOAD_PROFILE':
      return { ...state, reloadingProfile: true };
    case 'LOGIN':
      return { loading: true };
    case 'LOGOUT':
      return { loading: false };
    case 'PRE_LOGGED_IN':
      return {
        ...state,
        isPreLoggedIn: true,
        loading: false,
        maskedPhone: action.payload.maskedPhone,
      };
    case 'VALIDATE_CODE':
      return {
        isPreLoggedIn: true,
        loading: true,
      };
    case 'LOGGED_IN':
      const lead = action.payload.lead;
      const user = action.payload.user;
      return {
        isAuth: true,
        isPreLoggedIn: false,
        reloadingProfile: false,
        user: {
          leadUuid: lead?.uuid,
          userUuid: user.uuid,
          leadType: lead?.type,
          name: lead?.general.gname,
          role: user.role,
          status: lead?.status,
        },
        leadData: action.payload.lead,
        userData: action.payload.user,
      };
    case 'NOT_LOGGED_IN':
      return { ...state, loading: false, error: action.payload.authError };
    case 'USER_BLOCKED':
      return {
        ...state,
        loading: false,
        error: action.payload.authError,
        lastBlockDate: action.payload.lastBlockDate,
        lastCodeChangeDate: action.payload.lastCodeChangeDate,
        action: action.payload.action as ActionType,
      };
    case 'USER_NOT_ALLOWED':
      return {
        ...state,
        loading: false,
        error: action.payload.authError,
        lastBlockDate: action.payload.lastBlockDate,
        lastCodeChangeDate: action.payload.lastCodeChangeDate,
        action: action.payload.action as ActionType,
      };
    case 'USER_NOT_FOUND':
      return {
        ...state,
        loading: false,
        error: action.payload.authError,
      };
    default:
      return state;
  }
};
