import {
  FlexGrid,
  Form,
  FormItemProps,
  RadialButton,
  Typography,
  useThemeProvider,
} from '@albo-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useConfirm } from '../../components/confirm-dialog';
import { useOnboardingForm } from '../../hooks/use-onboarding-form';
import { usePmStatusQuickWinAdditionalInfo } from '../../hooks/use-pm-status-quick-win-additional-info';
import { useUserRole } from '../../hooks/use-user-role';
import { useAuth } from '../../providers/auth-provider';
import { useFormsFields } from '../../providers/onboarding-form-provider';
import { useTranslator } from '../../providers/translator-provider';
import { RISK_LEVEL_HIGH } from '../../utils/constants';
import { useValidators } from '../../utils/validators';
import { BottomActions } from '../bottom-actions';
import { FormContainer } from '../form-container';
import { FormItems } from '../form-items';
import { OnBoardingLoader } from '../on-boarding-loader';

export const ComplementaryInfoForm = () => {
  const { leadData } = useAuth();
  const formProps = useOnboardingForm({
    defaultParams: {
      isLead: false,
      general: {
        haveAdditionalIncome: false,
      },
    },
    onboardingFormType: 'QUICK_WIN_ADDITIONAL_INFORMATION',
    nextSectionUrl: leadData?.riskLevel === RISK_LEVEL_HIGH ? 'additional-info' : '/home/menu',
    singleton: true,
  });

  const {
    ADDITIONAL_INFORMATION,
    ENTER_ADDITIONAL_INFO_TO_RECORD,
    REQUIRED_FIELDS,
    BUSINESS_DATA,
    YEARS_CARRYING,
    MONEYIN_MONTHLY,
    ANOTHER_SOURCE_INCOME,
    OTHER_SOURCE,
    CONTINUE,
    FINISH,
    UPDATE,
    TITLE_UPDATE_CUSTOMER_INFO,
    DESCRIPTION_UPDATE_CUSTOMER_INFO,
    BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO,
    SAVE_PROGRESS
  } = useTranslator();

  const { formFields ,findFields} = useFormsFields();
  
  useEffect(()=>{
    findFields('QUICK_WIN_ADDITIONAL_INFORMATION');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const { DialogConfirm, confirm: showConfirm } = useConfirm({
    title: TITLE_UPDATE_CUSTOMER_INFO,
    message: DESCRIPTION_UPDATE_CUSTOMER_INFO,
    textConfirm: BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO
  });

  const handleClickSave = async () => {
    const confirm = await showConfirm();
    if (!confirm) {
      return;
    }
    formProps.saveFormOps();
  }

  const { isOpsAnalyst } = useUserRole();


  const { theme } = useThemeProvider();
  const validators = useValidators();
  const history = useHistory();

  const formData = useMemo(() => formProps.form.data, [formProps.form.data]);;
  const setFormData = formProps.form.setData;

  const { setPropByName } = formProps.form;
  const { complementaryInfoStatus } = usePmStatusQuickWinAdditionalInfo();

  const haveAdditionalIncome = formData.general?.haveAdditionalIncome;
  const [showAdditional, setShowAdditional] = useState(false);

  const dataCompanyInput: FormItemProps<string>[] = formFields('QUICK_WIN_ADDITIONAL_INFORMATION', [
    {
      placeholder: YEARS_CARRYING,
      name: 'general.activityYears',
      type: 'input',
      inputType: 'number',
      required: true,
      maxLength: 3,
      saveAs: 'number',
      validators: [validators.isEmpty],
    },
    {
      placeholder: MONEYIN_MONTHLY,
      name: 'general.monthlyIncome',
      type: 'numberFormatInput',
      required: true,
      prefix: '$ ',
      decimalScale: 2,
      saveAs: 'float',
    },
  ]);

  const sourceInput: FormItemProps<string>[] = formFields('QUICK_WIN_ADDITIONAL_INFORMATION', [
    {
      placeholder: OTHER_SOURCE,
      name: 'general.additionalIncome',
      type: 'input',
      required: true,
      maxLength: 80,
      pattern: 'specialTextPattern',
      validators: [validators.isEmpty],
    },
  ]);

  const moveForward = () => {
    formProps.moveForward();
  };

  const [radiosSource, setRadiosSource] = useState<
    { text: string; value: any }[]
  >([
    { text: 'Sí', value: false },
    { text: 'No', value: false },
  ]);

  const removeAdditionalIncome = useCallback(() => {
    var currentData = { ...formData }
    delete currentData.general.additionalIncome;
    currentData.general.haveAdditionalIncome = false;
    setFormData(currentData);

  }, [formData, setFormData]);

  const updateValuesRadios = useCallback((index: number) => {
    const updateValuesRadios = [...radiosSource];
    updateValuesRadios.map((item, i) => (item.value = i === index));
    setRadiosSource(updateValuesRadios);
  }, [radiosSource]);

  const setAdditionalIncome = useCallback((index: number) => {
    const hasOtherIncome = index === 0;
    setPropByName('general.haveAdditionalIncome', hasOtherIncome);
    updateValuesRadios(index);
    setShowAdditional(hasOtherIncome);
    if (!hasOtherIncome) {
      if (formData.general.additionalIncome) {
        removeAdditionalIncome();
      }
    }
  }, [formData.general.additionalIncome, setPropByName, removeAdditionalIncome, updateValuesRadios]);

  const onBack = () => {
    history.push('pep-info');
  };

  const setErrors = formProps.form.setErrors;

  useEffect(() => {
    if (!haveAdditionalIncome) {
      setErrors((currentErrors: any) => ({
        ...currentErrors,
        'general.additionalIncome': undefined,
      }));
      setAdditionalIncome(1);
    } else {
      setAdditionalIncome(0);
    }
    setShowAdditional(haveAdditionalIncome);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [haveAdditionalIncome, setErrors]);

  if (formProps.saving || formProps.movingForward || formProps.loadingForm)
    return (
      <OnBoardingLoader
        isLoading={
          formProps.saving || formProps.movingForward || formProps.loadingForm
        }
      />
    );

  return (
    <FormContainer>
      <Form {...formProps.form} onSubmit={isOpsAnalyst ? handleClickSave : moveForward}>
        <FlexGrid
          container
          fullHeight
          fullWidth
          direction='column'
          spacing={3}
          style={{ paddingBottom: '100px' }}
        >
          <FlexGrid item container direction='column' spacing={1} fullWidth>
            <FlexGrid item>
              <Typography variant='h2'>{ADDITIONAL_INFORMATION}</Typography>
            </FlexGrid>
            <FlexGrid item>
              <Typography variant='small'>
                {ENTER_ADDITIONAL_INFO_TO_RECORD}
              </Typography>
            </FlexGrid>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='smallTitle'>{BUSINESS_DATA}</Typography>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='small' color='neutral' bold>
              <span style={{ color: theme.colors.required }}>*</span>{' '}
              {REQUIRED_FIELDS}
            </Typography>
          </FlexGrid>
          <FlexGrid item container fullWidth>
            <FormItems inputs={dataCompanyInput} />
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='smallTitle'>
              {ANOTHER_SOURCE_INCOME}
            </Typography>
          </FlexGrid>
          <FlexGrid item container direction='row' spacing={2}>
            {radiosSource.map(({ value, text }, index) => (
              <FlexGrid item key={index}>
                <RadialButton
                  key={`additional-income-${index}`}
                  value={value}
                  onInput={() => setAdditionalIncome(index)}
                  text={text}
                />
              </FlexGrid>
            ))}
          </FlexGrid>
          {showAdditional && (
            <FlexGrid item container fullWidth>
              <FormItems inputs={sourceInput} />
            </FlexGrid>
          )}
        </FlexGrid>

        <DialogConfirm />

        <BottomActions
          backButton
          continueButton
          saveProgressButton={complementaryInfoStatus !== 'done' && !isOpsAnalyst}
          saveButtonText={SAVE_PROGRESS}
          onSaveProgress={formProps.saveForm}
          onContinue={isOpsAnalyst ? handleClickSave : moveForward}
          continueButtonText={isOpsAnalyst
            ? UPDATE
            : leadData?.riskLevel === RISK_LEVEL_HIGH
              ? CONTINUE
              : FINISH}
          onBack={onBack}
        />
      </Form>
    </FormContainer>
  );
};
