import { Button, FlexGrid, Typography, useThemeProvider } from '@albo-ui/core';
import { FaCheckCircle } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { useTranslator } from '../../providers/translator-provider';

export const PasswordChanged = () => {
  const { theme } = useThemeProvider();
  const history = useHistory();
  const {
    PASSWORD_UPDATED_SUCCESSSFULLY,
    YOU_WILL_RECEIVE_AN_EMAIL_WITH_THE_CONFIRMATION,
    LOG_IN,
  } = useTranslator();

  function onClick() {
    history.push('/login');
  }

  return (
    <FlexGrid
      container
      fullWidth
      fullHeight
      direction='column'
      spacing={5}
      alignItems='center'
      justify='center'
      style={{ height: '100vh' }}
    >
      <FlexGrid item>
        <FaCheckCircle size='55px' color={theme.colors.vivere} />
      </FlexGrid>
      <FlexGrid item style={{ maxWidth: '400px', textAlign: 'center' }}>
        <Typography variant='smallTitle' color='charcoal'>
          {PASSWORD_UPDATED_SUCCESSSFULLY}{' '}
          {YOU_WILL_RECEIVE_AN_EMAIL_WITH_THE_CONFIRMATION}
        </Typography>
      </FlexGrid>
      <FlexGrid item>
        <Button onClick={onClick} size='large'>
          {LOG_IN}
        </Button>
      </FlexGrid>
    </FlexGrid>
  );
};
