import styled from 'styled-components';

type IconContainerrops = {
  theme: any;
};

export const IconContainer = styled.div<IconContainerrops>`
  margin-top: ${({ theme }) => theme.spacesPx[2]};
  &:hover {
    cursor: pointer;
  }
`;
