import {
  ActionCard,
  Drawer,
  DrawerDescription,
  DrawerTitle,
  FlexGrid,
  FormItemProps,
  Typography,
  useDrawer,
  useThemeProvider,
  DrawerContent,
  Form,
  DrawerActions,
  Button,
  Skeleton,
  useDialog,
  Card,
  Description,
  useSnackbar,
} from '@albo-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useAddressInputs } from '../../common';
import { useCatalog } from '../../hooks/use-catalog';
import { useOnboardingForm } from '../../hooks/use-onboarding-form';
import { useQuery } from '../../hooks/user-query';
import { useApi } from '../../providers/api-provider';
import { useAuth } from '../../providers/auth-provider';
import { useTranslator } from '../../providers/translator-provider';
import { useDefaultValidations } from '../../utils/default-validations';
import { useValidators } from '../../utils/validators';
import { BottomActions } from '../bottom-actions';
import { DeletePromptDialog } from '../delete-prompt-dialog';
import { FormItems } from '../form-items';
import { OnBoardingLoader } from '../on-boarding-loader';
import { FaInfoCircle } from 'react-icons/fa';
import { useOnHomeCBS } from '../../providers/on-home-cbs-provider';
import { InfoDialog } from '../info-dialog';
import { mainCountry } from '../../const';
import { useSnackbarNotifier } from '../../hooks/use-snackbar-notifier';
import { useConfirm } from '../../components/confirm-dialog';
import { useUserRole } from '../../hooks/use-user-role';
import { useFormsFields } from '../../providers/onboarding-form-provider';

export const BeneficiariesForm = () => {
  const {
    ADMINS,
    REQUIRED_FIELD,
    ADD_BENEFICIARY_DESCRIPTION,
    ADDRESS,
    BIRTH_DATE,
    BENEFICIARY_RELATIONSHIP,
    NAME,
    PATERNAL_LASTNAME,
    MATERNAL_LASTNAME,
    BENEFICIARIES_DESCRIPTION,
    CANCEL,
    SAVE,
    BENEFICIARIES,
    ADD_BENEFICIARY,
    ASSIGNED_PERCENTAGE,
    EXCEEDS_TOTAL_PERCENTAGE,
    DELETE_BENEFICIARY_DESCRIPTION,
    REMAINING_PERCENTAGE_TO_ASSIGNED,
    PERCENTAGE_TO_BE_ASSIGNED,
    DO_YOU_WANT_TO_DELETE_THIS_BENEFICIARY_QUESTION,
    BENEFICIARY_ADDED,
    YOU_HAVE,
    LEFT_TO_ASSIGN_TO_THE_OTHERS_BENEFICIARIES,
    CHANGE_YOUR_BENEFICIARIES_PERCENTAGES_DESCRIPTION,
    CHANGE_YOUR_BENEFICIARIES_PERCENTAGES,
    BENEFICIARY_UPDATED,
    DATE_FORMAT,
    PERSONAL_DATA,
    BENEFICIARY,
    DELETE_LAST_BENEFICIARY_DESCRIPTION,
    BENEFICIARY_DELETED_SUCCESSFULLY,
    UPDATE,
    TITLE_UPDATE_CUSTOMER_INFO,
    DESCRIPTION_UPDATE_CUSTOMER_INFO,
    BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO,
  } = useTranslator();

  const { formFields ,findFields} = useFormsFields();
  
  useEffect(()=>{
    findFields('QUICK_WIN_BENEFICIARY');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const { DialogConfirm, confirm: showConfirm } = useConfirm({
    title: TITLE_UPDATE_CUSTOMER_INFO,
    message: DESCRIPTION_UPDATE_CUSTOMER_INFO,
    textConfirm: BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO
  });

  const { isOpsAnalyst } = useUserRole();

  const { theme } = useThemeProvider();
  const [uuidUpdate, setUuidUpdate] = useState<undefined | string>(undefined);
  const [uuidDelete, setUuidDelete] = useState<undefined | string>(undefined);
  const currentPercentage = useRef<number>(0);
  const currentPercentageChecked = useRef<boolean>(false);
  const [showDialogInfo, setShowDialogInfo] = useState(false);
  const formProps = useOnboardingForm({
    defaultParams: {
      general: {
        phone: { phoneCode: '+52', phoneCountry:'484', phoneCodeDesc: 'Mexico' },
      },
      address: {
        country: mainCountry,
        countryDesc: 'Mexico',
      },
    },
    onboardingFormType: 'QUICK_WIN_BENEFICIARY',
    singleton: false,
    onboardingFormRef: uuidUpdate,
  });
  const drawerProps = useDrawer();
  const validators = useValidators();
  const defaultValidators = useDefaultValidations();
  const [relationship] = useCatalog('Relationships');
  const history = useHistory();
  const defaultValidations = useDefaultValidations();
  const [api] = useApi();
  const [loading, setLoading] = useState(false);
  const deleteDialogProps = useDialog();
  const deleteLastBeneficiaryDialogProps = useDialog();
  const infoDialogProps = useDialog();
  const changePercentagesProps = useDialog();
  const snackBarProps = useSnackbar();
  const percentage = formProps.form.data.assignedPercentage;
  const homeCBS = useOnHomeCBS();
  const eventName = formProps.eventName;
  const movingForward = formProps.movingForward;
  const loadingForm = formProps.loadingForm;
  const openInfoDialog = infoDialogProps.openDialog;
  const [showSnackbar, closeSnackbar] = useSnackbarNotifier();
  homeCBS.setShowDialog(false);
  const {
    leadData: { onboardingForms },
  } = useAuth();
  const totalPercentage = 100;

  const beneficiariesUuids = onboardingForms
    .filter(({ formType }: any) => formType === 'QUICK_WIN_BENEFICIARY')
    .map(({ uuid }: any) => uuid);

  const [queryFormData, loadingBeneficiaries, reloadForm] = useQuery(
    {
      name: 'get-onboarding-forms-by-uuids',
      params: {
        onboardingFormsUuids: beneficiariesUuids,
      },
      options: {
        manualLoad: beneficiariesUuids.length === 0,
      },
    },
    [onboardingForms]
  );

  const relationshipSelect = relationship.map((relation: any) => ({
    value: relation.key,
    name: relation.value,
  }));

  const beneficiaries:
    | {
        name: string;
        percentage: string;
        uuid: string;
      }[]
    | undefined = queryFormData
    ? queryFormData.onboardingForms.map((data: any) => ({
        name: `${data.formData.general.gname} ${data.formData.general.lname} ${data.formData.general.slname}`,
        percentage: `${data.formData.assignedPercentage}%`,
        uuid: data.uuid,
      }))
    : undefined;

  const thereIsOnlyOneBeneficiary = beneficiaries?.length === 1;
  const thereIsAtLeastOneBeneficiary =
    beneficiaries && beneficiaries?.length > 0;

  const totalPercentageUsed = beneficiaries
    ? beneficiaries
        ?.map(({ percentage }) => parseInt(percentage))
        .reduce((total, current) => total + current, 0)
    : 0;

  const addedBeneficiaryDescription = `${YOU_HAVE} ${
    totalPercentage - totalPercentageUsed
  }% ${LEFT_TO_ASSIGN_TO_THE_OTHERS_BENEFICIARIES}`;
  
  const beneficiaryInputs: FormItemProps<string>[] = formFields('QUICK_WIN_BENEFICIARY', [
    {
      placeholder: NAME,
      name: 'general.gname',
      type: 'input',
      required: true,
      ...defaultValidations.nameValidations(),
    },
    {
      placeholder: PATERNAL_LASTNAME,
      name: 'general.lname',
      type: 'input',
      required: true,
      ...defaultValidations.paternalLastNameValidations(),
    },
    {
      placeholder: MATERNAL_LASTNAME,
      name: 'general.slname',
      type: 'input',
      ...defaultValidations.maternalLastNameValidations(),
    },
    {
      name: 'general.phone',
      type: 'phone',
      required: true,
      ...defaultValidators.phoneNumberValidations(),
    },
    {
      placeholder: `${BIRTH_DATE} ${DATE_FORMAT}`,
      name: 'general.bdate',
      type: 'date',
      inputType: 'email',
      required: true,
      validators: [validators.isEmpty, validators.notValidDateFormat],
    },
    {
      placeholder: BENEFICIARY_RELATIONSHIP,
      name: 'kindred',
      type: 'select',
      items: relationshipSelect,
      required: true,
      search: true,
      validators: [validators.selectIsEmpty],
    },
  ]);

  const percentageInfo: FormItemProps<string>[] = formFields('QUICK_WIN_BENEFICIARY', [
    {
      placeholder: PERCENTAGE_TO_BE_ASSIGNED,
      name: 'assignedPercentage',
      type: 'input',
      inputType: 'number',
      saveAs: 'number',
      required: true,
      maxLength: 3,
      description: `Porcentaje restante disponible: ${
        totalPercentage -
        totalPercentageUsed +
        (uuidUpdate ? currentPercentage.current : 0)
      }%`,
      validators: [
        validators.isEmpty,
        (value: string) => {
          return totalPercentage -
            totalPercentageUsed +
            (uuidUpdate ? currentPercentage.current : 0) -
            parseInt(value) <
            0
            ? EXCEEDS_TOTAL_PERCENTAGE
            : undefined;
        },
      ],
    },
  ]);

  const address = useAddressInputs({
    setFormData: formProps.form.setData,
    postalCode: formProps.form.data.address.zip,
    formData: formProps.form.data,
  });

  const clearFormData = () => {
    formProps.form.setData({
      general: {
        phone: { phoneCode: '+52', phoneCountry:'484', phoneCodeDesc: 'Mexico' },
      },
      address: {
        country: mainCountry,
        countryDesc: 'Mexico',
      },
    });
  };

  const onAddBeneficiaryClick = () => {
    if (totalPercentage - totalPercentageUsed !== 0) {
      formProps.form.displayErrors(false);
      formProps.form.setData({
        general: {
          phone: { phoneCode: '+52', phoneCountry:'484', phoneCodeDesc: 'Mexico' },
        },
        address: {
          country: mainCountry,
          countryDesc: 'Mexico',
        },
      });
      drawerProps.openDrawer();
      setUuidUpdate(undefined);
      currentPercentage.current = 0;
      currentPercentageChecked.current = false;
      closeSnackbar('');
    } else {
      changePercentagesProps.openDialog();
    }
  };

  const onDrawerClose = () => {
    setUuidUpdate(undefined);
    currentPercentage.current = 0;
    currentPercentageChecked.current = false;
    drawerProps.onClose();
    clearFormData();
  };

  const handleClickSave = async () => {
    const confirm = await showConfirm();
    if (!confirm) {
      return;
    }
    onDrawerSave();
  }

  function onDrawerSave() {
    if (!formProps.form.hasErrors) {
      setUuidUpdate(undefined);
      formProps.moveForward();
      setShowDialogInfo(true);
      drawerProps.onClose();
      clearFormData();
      currentPercentage.current = 0;
      currentPercentageChecked.current = false;
    }
  }

  function onBack() {
    history.push('company-info-and-account');
  }

  function onContinue() {
    history.push('/home/menu');
  }

  async function onDeleteBeneficiary(uuid: string) {
    setUuidDelete(uuid);

    if (thereIsOnlyOneBeneficiary) {
      deleteLastBeneficiaryDialogProps.openDialog();
    } else {
      deleteDialogProps.openDialog();
    }
  }

  async function deleteBeneficiary() {
    setLoading(true);
    closeSnackbar('')
    await api
      .callService('delete-onboarding-form', {
        uuid: uuidDelete,
      })
      .then(() => {
        reloadForm();
        deleteDialogProps.onClose();
        deleteLastBeneficiaryDialogProps.onClose();
        showSnackbar(BENEFICIARY_DELETED_SUCCESSFULLY);
        setUuidDelete(undefined);
      });
    setLoading(false);
  }

  function onDialogClose() {
    setUuidDelete(undefined);
    deleteDialogProps.onClose();
    deleteLastBeneficiaryDialogProps.onClose();
  }

  function onDialogBeneficiaryCreatedClose() {
    infoDialogProps.onClose();
    setShowDialogInfo(false);
  }

  function onAddMoreThanPercentageLeftDialogClose() {
    changePercentagesProps.onClose();
  }

  async function onUpdateBeneficiary(uuid: string) {
    setUuidUpdate(uuid);
    snackBarProps.onClose();
    closeSnackbar('');
    drawerProps.openDrawer();
  }

  useEffect(() => {
    if (
      (eventName === 'OnboardingFormCreated' ||
        eventName === 'OnboardingFormUpdated') &&
      !movingForward &&
      !loadingForm
    )
      openInfoDialog();
  }, [eventName, movingForward, loadingForm, openInfoDialog, uuidUpdate]);

  useEffect(() => {
    if (
      !loadingForm &&
      percentage &&
      uuidUpdate &&
      !currentPercentageChecked.current
    ) {
      currentPercentage.current = percentage;
      currentPercentageChecked.current = true;
    }
  }, [loadingForm, percentage, uuidUpdate]);

  if (loading || loadingForm || movingForward)
    return (
      <OnBoardingLoader isLoading={loading || loadingForm || movingForward} />
    );

  return (
    <>
      <DeletePromptDialog
        open={deleteDialogProps.open}
        title={DO_YOU_WANT_TO_DELETE_THIS_BENEFICIARY_QUESTION}
        description={DELETE_BENEFICIARY_DESCRIPTION}
        onConfirm={deleteBeneficiary}
        onClose={onDialogClose}
      />
      <DeletePromptDialog
        open={deleteLastBeneficiaryDialogProps.open}
        title={DO_YOU_WANT_TO_DELETE_THIS_BENEFICIARY_QUESTION}
        description={DELETE_LAST_BENEFICIARY_DESCRIPTION}
        onConfirm={deleteBeneficiary}
        onClose={onDialogClose}
      />
      <InfoDialog
        open={infoDialogProps.open && showDialogInfo}
        title={
          eventName === 'OnboardingFormCreated'
            ? BENEFICIARY_ADDED
            : BENEFICIARY_UPDATED
        }
        description={addedBeneficiaryDescription}
        onClose={onDialogBeneficiaryCreatedClose}
      />

      <InfoDialog
        open={changePercentagesProps.open}
        title={CHANGE_YOUR_BENEFICIARIES_PERCENTAGES}
        description={CHANGE_YOUR_BENEFICIARIES_PERCENTAGES_DESCRIPTION}
        onClose={onAddMoreThanPercentageLeftDialogClose}
      />

      <Form {...formProps.form} onSubmit={onDrawerSave}>
        <Drawer
          open={drawerProps.open}
          position='right'
          onClose={onDrawerClose}
        >
          <DrawerTitle>
            {uuidUpdate ? `${UPDATE} ${BENEFICIARY}` : ADD_BENEFICIARY}
          </DrawerTitle>
          <DrawerDescription>
            <FlexGrid container fullWidth direction='column' spacing={2}>
              <FlexGrid item>{ADD_BENEFICIARY_DESCRIPTION}</FlexGrid>
              <FlexGrid item>
                <Typography variant='small' color='neutral'>
                  <span style={{ color: theme.colors.required }}>*</span>{' '}
                  {REQUIRED_FIELD}
                </Typography>
              </FlexGrid>
            </FlexGrid>
          </DrawerDescription>
          <DrawerContent>
            <FlexGrid item container fullWidth spacing={9} direction='column'>
              <FlexGrid item container fullWidth spacing={4} direction='column'>
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    {PERCENTAGE_TO_BE_ASSIGNED}
                  </Typography>
                </FlexGrid>
                <FormItems inputs={percentageInfo} />
                <FlexGrid item>
                  <Typography variant='smallTitle'>{PERSONAL_DATA}</Typography>
                </FlexGrid>
                <FormItems inputs={beneficiaryInputs} />
              </FlexGrid>
              <FlexGrid item container fullWidth spacing={4} direction='column'>
                <FlexGrid item>
                  <Typography variant='smallTitle'>{ADDRESS}</Typography>
                </FlexGrid>
                <FormItems inputs={address} />
              </FlexGrid>
            </FlexGrid>
          </DrawerContent>

          <DialogConfirm />

          <DrawerActions>
            <Button
              onClick={onDrawerClose}
              buttonType='secondary'
              size='normal'
            >
              {CANCEL}
            </Button>
            <Button
              onClick={isOpsAnalyst ? handleClickSave : onDrawerSave} /* TODO: check detailed behavior */
              size='normal'
              style={{ marginLeft: theme.spacesPx[2] }}
            >
              {uuidUpdate ? UPDATE : SAVE}
            </Button>
          </DrawerActions>
        </Drawer>
      </Form>
      <FlexGrid
        container
        direction='column'
        spacing={2}
        style={{ marginBottom: '150px' }}
      >
        <FlexGrid item container spacing={1} direction='column'>
          <FlexGrid item>
            <Typography variant='h2'>{BENEFICIARIES}</Typography>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='small'>{BENEFICIARIES_DESCRIPTION}</Typography>
          </FlexGrid>
        </FlexGrid>
        <FlexGrid item>
          <Typography variant='smallTitle'>{ADMINS}</Typography>
        </FlexGrid>
        <FlexGrid item container direction='column' spacing={2}>
          <FlexGrid item>
            <Card
              fullWidth
              alignItems='center'
              style={{
                borderColor: theme.colors.focus,
                padding: `0 ${theme.spacesPx[3]}`,
                minHeight: '70px',
                height: 'auto',
              }}
            >
              <Description
                titleColor='focus'
                icon={<FaInfoCircle color={theme.colors.focus} size='17px' />}
              >
                <Typography variant='small'>
                  {REMAINING_PERCENTAGE_TO_ASSIGNED}
                </Typography>
                <Typography variant='small'>
                  {` ${totalPercentage - totalPercentageUsed}%`}
                </Typography>
              </Description>
            </Card>
          </FlexGrid>
          <FlexGrid item>
            <ActionCard
              title={ADD_BENEFICIARY}
              onClick={onAddBeneficiaryClick}
              link
              style={{ height: '80px' }}
            />
          </FlexGrid>
          {beneficiaries &&
            beneficiaries.map(({ name, percentage, uuid }, index) => (
              <FlexGrid item key={index}>
                <ActionCard
                  title={name}
                  style={{ height: '80px' }}
                  status='default'
                  description={`${ASSIGNED_PERCENTAGE}: ${percentage}`}
                  onDelete={() => onDeleteBeneficiary(uuid)}
                  onUpdate={() => onUpdateBeneficiary(uuid)}
                />
              </FlexGrid>
            ))}
          {loadingBeneficiaries && (
            <>
              {beneficiariesUuids.map(() => (
                <Skeleton width='100%' height='80px' />
              ))}
            </>
          )}
        </FlexGrid>
      </FlexGrid>
      <BottomActions
        backButton
        continueButton
        disableContinueButton={
          thereIsAtLeastOneBeneficiary && totalPercentageUsed !== 100
        }
        onContinue={onContinue}
        onBack={onBack}
      />
    </>
  );
};
