import {
  Button,
  Card,
  FlexGrid,
  MenuItem,
  Typography,
  useThemeProvider,
} from '@albo-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { usePfaeStatus } from '../../hooks/use-pfae-status';
import { useUserRole } from '../../hooks/use-user-role';
import { useApi } from '../../providers/api-provider';
import { useAuth } from '../../providers/auth-provider';
import { useTranslator } from '../../providers/translator-provider';
import { OnBoardingLoader } from '../on-boarding-loader';
import { GetMenuItems } from './const';
import { useExternalAuthData } from '../../providers/external-auth-provider';

export const OnBoardingMenuPFAE = () => {
  const { items } = GetMenuItems();
  const [api] = useApi();
  const history = useHistory();
  const {
    ON_BOARDING_MENU_TITLE,
    ON_BOARDING_MENU_TEXT,
    SEND_VALIDATION,
    CONFIRM,
    FINISH
  } = useTranslator();
  const { theme } = useThemeProvider();
  const pfaeStatus = usePfaeStatus();
  const { isOpsAnalyst } = useUserRole();
  const { clearLeadFromAuthData } = useExternalAuthData()


  const {
    leadData: { uuid, interactionStatus },
    user,
    obtainCredentials
  } = useAuth();
  const buttonEnabled = isOpsAnalyst 
    || (interactionStatus === 'pending-user-approval' && pfaeStatus.allFormsIsCompleted)
    || pfaeStatus.readyToSend;

  const { reloadProfile, reloadingProfile } = useAuth();
  const [loading, setloading] = useState(false);

  function onCardClick(url: string) {
    history.push(`record-info/${url}`);
  }
  async function onSendValidationClick() {
    setloading(true);
    await api.callService('send-to-validation', {
      leadUuid: uuid,
      type: 'revision'
    });
    reloadProfile();
    setloading(false);
  }

  async function onHandleClick() {
    if (isOpsAnalyst) {
      sendForUserApproval();
      return;
    }

    if (!isOpsAnalyst && interactionStatus === 'pending-user-approval') {
      onConfirmChanges();
      return;
    }

    onSendValidationClick();
  }
  async function sendForUserApproval() {
    setloading(true);
    await obtainCredentials({ noLead: true });
    clearLeadFromAuthData();
    reloadProfile();
    setloading(false);
  }

  async function onConfirmChanges() {
    setloading(true);
    await api.callService('lead-approves-changes', {
      leadUuid: uuid,
      userUuid: user?.userUuid
    });
    await onSendValidationClick();
    reloadProfile();
    setloading(false);
  }

  const bottonButtonText = isOpsAnalyst ?
    FINISH :
    interactionStatus === 'pending-user-approval'
      ? CONFIRM
      : SEND_VALIDATION;

  if (loading || reloadingProfile)
    return <OnBoardingLoader isLoading={loading || reloadingProfile} />;

  return (
    <FlexGrid
      container
      fullHeight
      fullWidth
      direction='column'
      alignItems='center'
      spacing={4}
      style={{ overflowY: 'auto', paddingTop: theme.spacesPx[4] }}
    >
      <FlexGrid item container direction='column' style={{ maxWidth: '633px' }}>
        <FlexGrid item container direction='column' spacing={1}>
          <FlexGrid item>
            <Typography variant='smallTitle'>
              {ON_BOARDING_MENU_TITLE}
            </Typography>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='small'>{ON_BOARDING_MENU_TEXT}</Typography>
          </FlexGrid>
        </FlexGrid>
      </FlexGrid>
      <FlexGrid item container direction='column'>
        <FlexGrid item container direction='column' spacing={2}>
          {items.map(({ icon, title, description, url, status }, index) => (
            <FlexGrid item key={index}>
              <MenuItem
                key={index}
                title={title}
                description={description}
                icon={icon}
                status={status as keyof typeof theme.statusCardColors}
                onClick={() => {
                  onCardClick(url);
                }}
              />
            </FlexGrid>
          ))}
        </FlexGrid>
      </FlexGrid>
      <FlexGrid container item fullWidth style={{ marginTop: 'auto' }}>
        <Card
          fullWidth
          style={{ borderColor: theme.colors.manatee, borderRadius: 0 }}
          alignItems='center'
          justifyContent='flex-end'
        >
          <Button
            onClick={onHandleClick}
            size='large'
            disabled={!buttonEnabled}
          >
            {bottonButtonText}
          </Button>
        </Card>
      </FlexGrid>
    </FlexGrid>
  );
};
