import { useThemeProvider } from '@albo-ui/core';
import { useAuth } from '../providers/auth-provider';

export type QuickWinOnboardinFormTypes =
  | 'QUICK_WIN_COMPANY_ORGANIZATION'
  | 'QUICK_WIN_PEP_RELATION'
  | 'QUICK_WIN_ADDITIONAL_INFORMATION'
  | 'QUICK_WIN_COMPLEMENTARY_INFORMATION';

export const usePmStatusQuickWinAdditionalInfo = () => {
  const { theme } = useThemeProvider();
  type status = keyof typeof theme.statusCardColors;
  const {
    leadData: { onboardingForms },
  } = useAuth();

  const companyOrganizationForm = onboardingForms.find(
    ({ formType }: any) => formType === 'QUICK_WIN_COMPANY_ORGANIZATION'
  );
  const companyOrganizationStatus: status =
    companyOrganizationForm?.status === 'pending-user-approval'
      ? 'pendinguserapproval'
      : !companyOrganizationForm
        ? 'default'
        : companyOrganizationForm?.isCompleted
          ? 'done'
          : 'pending';

  const pepForm = onboardingForms.find(
    ({ formType }: any) => formType === 'QUICK_WIN_PEP_RELATION'
  );
  const pepStatus: status =
    pepForm?.status === 'pending-user-approval'
      ? 'pendinguserapproval'
      : companyOrganizationStatus === 'pending' || companyOrganizationStatus === 'default'
        ? 'disabled'
        : companyOrganizationStatus === 'done' &&
          !pepForm
          ? 'default'
          : pepForm?.isCompleted
            ? 'done'
            : 'pending';

  const complementaryInfoForm = onboardingForms.find(
    ({ formType }: any) => formType === 'QUICK_WIN_ADDITIONAL_INFORMATION'
  );
  const complementaryInfoStatus: status =
    complementaryInfoForm?.status === 'pending-user-approval'
      ? 'pendinguserapproval'
      : pepStatus === 'pending' ||
        pepStatus === 'disabled' ||
        pepStatus === 'default'
        ? 'disabled'
        : pepStatus === 'done' &&
          !complementaryInfoForm
          ? 'default'
          : complementaryInfoForm?.isCompleted
            ? 'done'
            : 'pending';

  const additionalInfoForm = onboardingForms.find(
    ({ formType }: any) => formType === 'QUICK_WIN_COMPLEMENTARY_INFORMATION'
  );
  const additionalInfoStatus: status =
    additionalInfoForm?.status === 'pending-user-approval'
      ? 'pendinguserapproval'
      : complementaryInfoStatus === 'pending' ||
        complementaryInfoStatus === 'disabled' ||
        complementaryInfoStatus === 'default'
        ? 'disabled'
        : complementaryInfoStatus === 'done' &&
          !additionalInfoForm
          ? 'default'
          : additionalInfoForm?.isCompleted
            ? 'done'
            : 'pending';

  const readyToSend = companyOrganizationStatus === 'done' &&
    pepStatus === 'done' &&
    complementaryInfoStatus === 'done';

  const readyToSendExtraInfo = readyToSend && additionalInfoStatus === 'done';

  return {
    companyOrganizationStatus,
    pepStatus,
    complementaryInfoStatus,
    additionalInfoStatus,
    readyToSend,
    readyToSendExtraInfo
  };
};
