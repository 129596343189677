export const adviceOfPrivacyUrl = 'https://equipos.albo.mx/privacidad/';
export const landingPageUrl = 'https://equipos.albo.mx/';
export const alboForBusinessUrl = 'https://afb-web.albo.live/login';
export const mainCountryStr =  "484"
// eslint-disable-next-line no-new-wrappers
export const mainCountry:any =  new String(mainCountryStr);
mainCountry._def = true
export const canadaCountryCode = '124'
export const unitedStatesCountryCode = '840'
export const cookieUsePolicyUrl = 'https://equipos.albo.mx/politicas/'
