import {
  FlexGrid,
  Card,
  WizardStepperItem,
  useThemeProvider,
  Breadcrum,
  BreadcrumItem,
  useDialog,
} from '@albo-ui/core';
import { OnBoardingWizardStepper } from '../../../../../../components-quick-win/on-boarding-wizard-stepper';
import { RecordInfoRouter } from './router';
import {
  FaUser,
  // FaPen,
  FaBriefcase,
  FaUserFriends,
  FaUserTie,
  FaFileAlt,
  FaFileInvoiceDollar,
} from 'react-icons/fa';
import { useTranslator } from '../../../../../../providers/translator-provider';
import { useHistory } from 'react-router';
import { FormContainer } from '../../../../../../components-quick-win/form-container';
import { useAuth } from '../../../../../../providers/auth-provider';
import { useOnHomeCBS } from '../../../../../../providers/on-home-cbs-provider';
import { ExitPromptDialog } from '../../../../../../components-quick-win/exit-prompt-dialog';

export const RecordInfo = () => {
  const { theme } = useThemeProvider();
  const history = useHistory();
  const { user } = useAuth();
  const leadType = user?.leadType;
  const pfaeActives = [false, false, false, false, false];
  const pmActives = [false, false, false, false, false, false, false];
  const homeCBS = useOnHomeCBS();
  const dialogProps = useDialog();

  const {
    // AGREEMENT,
    BUSINESS_DATA_ACCOUNT,
    BENEFICIARIES,
    CARDS,
    HOME,
    LEGAL_REPRESENTATIVE,
    // CONFIRM_AGREEMENT_DATA,
    ACCOUNT_CONFIG,
    SHAREHOLDERS,
    BUSINESS_DATA,
    // PLATFORM_ADMINS,
    COMPANY_INFO_ACCOUNT,
    PERSONAL_INFO,
  } = useTranslator();

  const sectionName = {
    'legal-representative': LEGAL_REPRESENTATIVE,
    // contract: CONFIRM_AGREEMENT_DATA,
    shareholders: SHAREHOLDERS,
    'shareholders-info': SHAREHOLDERS,
    'company-info': BUSINESS_DATA,
    'company-info-and-account': COMPANY_INFO_ACCOUNT,
    'account-config': ACCOUNT_CONFIG,
    // admins: PLATFORM_ADMINS,
    // 'cards-request-info': CARDS,
    'cards-request': CARDS,
    'personal-info': PERSONAL_INFO,
    beneficiaries: BENEFICIARIES,
    'sole-owner-info': SHAREHOLDERS,
    default: '',
  };

  const sectionNumber = {
    'legal-representative': 1,
    // contract: 1,
    shareholders: 2,
    'shareholders-info': 1,
    'company-info': 0,
    'company-info-and-account': 1,
    'account-config': 3,
    // admins: 5,
    // 'cards-request-info': user?.leadType === 'PFAE' ? 4 : 6,
    'cards-request': user?.leadType === 'PFAE' ? 3 : 4,
    'personal-info': 0,
    beneficiaries: 2,
    'sole-owner-info': 2,
    default: 0,
  };

  const currentPath = `${history.location.pathname.split('/').pop()}`;

  function onHomeClick() {
    if (homeCBS.showDialog) dialogProps.openDialog();
    else history.push('/home/menu');
  }

  function onConfirmSave() {
    homeCBS.state.forEach((CB: () => any) => CB && CB());
    history.push('/home/menu');
  }

  const breadCrumItems: BreadcrumItem[] = [
    {
      name: HOME,
      nameKey: 'home',
      to: '/home/menu',
      onClick: () => {
        onHomeClick();
      },
    },
    {
      name: sectionName[currentPath as keyof typeof sectionName],
      nameKey: 'none',
    },
  ];

  const stepsPFAE: WizardStepperItem[] = [
    {
      icon: <FaUser size='13px' color='white' />,
      name: PERSONAL_INFO,
      onClick: () => {
        history.push('personal-info');
      },
    },
    // {
    //   color: 'purple',
    //   icon: <FaPen size='13px' color='white' />,
    //   name: AGREEMENT,
    //   onClick: () => {
    //     history.push('contract');
    //   },
    // },
    {
      icon: <FaBriefcase size='13px' color='white' />,
      name: BUSINESS_DATA_ACCOUNT,
      onClick: () => {
        history.push('company-info-and-account');
      },
    },
    {
      icon: <FaUserFriends size='13px' color='white' />,
      name: BENEFICIARIES,
      onClick: () => {
        history.push('beneficiaries');
      },
    }
  ];

  const stepsPM: WizardStepperItem[] = [
    {
      icon: <FaFileAlt size='13px' color='white' />,
      name: BUSINESS_DATA,
      onClick: () => {
        history.push('company-info');
      },
    },
    {
      icon: <FaUserTie size='13px' color='white' />,
      name: LEGAL_REPRESENTATIVE,
      onClick: () => {
        history.push('legal-representative');
      },
    },
    {
      icon: <FaUserFriends size='13px' color='white' />,
      name: SHAREHOLDERS,
      onClick: () => {
        history.push('shareholders');
      },
    },
    // {
    //   color: 'purple',
    //   icon: <FaPen size='13px' color='white' />,
    //   name: AGREEMENT,
    //   onClick: () => {
    //     history.push('contract');
    //   },
    // },
    {
      icon: <FaFileInvoiceDollar size='13px' color='white' />,
      name: ACCOUNT_CONFIG,
      onClick: () => {
        history.push('account-config');
      },
    }
   
  ];

  return (
    <FlexGrid container fullWidth fullHeight>
      <ExitPromptDialog {...dialogProps} onConfirm={onConfirmSave} />
      <FlexGrid
        item
        style={{ padding: `${theme.spacesPx[4]} ${theme.spacesPx[2]}` }}
      >
        <OnBoardingWizardStepper
          onHomeClick={onHomeClick}
          items={leadType === 'PFAE' ? stepsPFAE : stepsPM}
          sectionActive={sectionNumber[currentPath as keyof typeof sectionName]}
          sectionsDone={
            leadType === 'PFAE'
              ? pfaeActives.map(
                  (value, index) =>
                    index <
                    sectionNumber[currentPath as keyof typeof sectionName]
                )
              : pmActives.map(
                  (value, index) =>
                    index <
                    sectionNumber[currentPath as keyof typeof sectionName]
                )
          }
        />
      </FlexGrid>
      <FlexGrid
        item
        container
        fullHeight
        fullWidth
        style={{ position: 'relative' }}
      >
        <Card
          fullWidth
          fullHeight
          alignItems='flex-start'
          justifyContent='center'
          style={{
            borderColor: theme.colors.manatee,
            borderRadius: 0,
            overflowY: 'auto',
            borderRight: 'none',
            borderBottom: 'none',
          }}
        >
          <FlexGrid
            container
            fullHeight
            fullWidth
            direction='column'
            spacing={2}
            alignItems='center'
          >
            <FlexGrid item style={{ zIndex: 20000 }}>
              <FormContainer>
                <Breadcrum
                  primaryColor={
                    currentPath === 'cards-request-info' ? 'white' : 'primary'
                  }
                  secondaryColor={
                    currentPath === 'cards-request-info'
                      ? 'lightNeutral'
                      : 'neutral'
                  }
                  items={breadCrumItems}
                />
              </FormContainer>
            </FlexGrid>
            <FlexGrid
              item
              container
              justify='center'
              style={{ paddingBottom: theme.spacesPx[4] }}
            >
              <RecordInfoRouter />
            </FlexGrid>
          </FlexGrid>
        </Card>
      </FlexGrid>
    </FlexGrid>
  );
};
