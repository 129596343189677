import { AdminForm } from '../../../../../../../../components-quick-win/admin-form';
import { FormContainer } from '../../../../../../../../components-quick-win/form-container';

export const Admin = () => {
  return (
    <FormContainer>
      <AdminForm />
    </FormContainer>
  );
};
