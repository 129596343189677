import {
  FlexGrid,
  Typography,
  Form,
  useForm,
  Button,
  useSnackbar,
  Snackbar,
  FormItemProps,
  Link,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { useHistory } from 'react-router';
import { useValidators } from '../../utils/validators';
import { useEffect } from 'react';
import { FormItems } from '../../components-quick-win/form-items';
import { usePasswordRecovery } from '../../providers/password-recovery-provider';
import { OnBoardingLoader } from '../../components-quick-win/on-boarding-loader';

export const ResetPassword = () => {
  const validators = useValidators();
  const snackbarProps = useSnackbar();
  const openSnackbar = snackbarProps.openSnackbar;
  const history = useHistory();
  const formProps = useForm({ email: '' });
  const { sendRecoverPasswordEmail, emailSent, loading, error } =
    usePasswordRecovery();

  const {
    EMAIL,
    SEND,
    RESTORE_PASSWORD,
    RESTORE_PASSWORD_DESCRIPTION,
    COME_BACK_TO_LOGIN,
    EMAIL_SENT_FOLLOW_INSTRUCTIONS,
    UNDERSTOOD,
    ERROR_SENDING_EMAIL_MESSAGE,
  } = useTranslator();

  const onSendEmail = async () => {
    if (formProps.hasErrors) {
      formProps.displayErrors();
    } else {
      sendRecoverPasswordEmail(formProps.data.email);
      formProps.setData({ email: '' });
    }
  };

  const onGoBackLogin = () => {
    history.push('login');
  };

  const inputs: FormItemProps<string>[] = [
    {
      placeholder: EMAIL,
      name: 'email',
      type: 'input',
      inputType: 'email',
      required: true,
      inputSize: '100%',
      validators: [validators.isEmpty, validators.notValidEmail],
    },
  ];

  useEffect(() => {
    if (emailSent === true) openSnackbar();
  }, [emailSent, openSnackbar]);

  useEffect(() => {
    if (error) openSnackbar();
  }, [error, openSnackbar]);

  if (loading) return <OnBoardingLoader isLoading={loading} />;

  return (
    <div style={{ height: '100vh' }}>
      <Snackbar
        open={snackbarProps.open}
        onClose={snackbarProps.onClose}
        message={
          error === 'USER_NOT_FOUND'
            ? ERROR_SENDING_EMAIL_MESSAGE
            : EMAIL_SENT_FOLLOW_INSTRUCTIONS
        }
        action={UNDERSTOOD}
      />

      <FlexGrid
        container
        direction='column'
        padding={8}
        fullWidth
        fullHeight
        justify='center'
        style={{ backgroundColor: 'white' }}
      >
        <FlexGrid
          item
          container
          alignItems='center'
          direction='column'
          spacing={10}
        >
          <FlexGrid
            item
            container
            direction='column'
            spacing={6}
            alignItems='center'
          >
            <FlexGrid
              item
              container
              direction='column'
              spacing={2}
              style={{ textAlign: 'center' }}
            >
              <FlexGrid item>
                <Typography variant='h2'>{RESTORE_PASSWORD}</Typography>
              </FlexGrid>
              <FlexGrid item style={{ maxWidth: '530px' }}>
                <Typography variant='paragraph'>
                  {RESTORE_PASSWORD_DESCRIPTION}
                </Typography>
              </FlexGrid>
            </FlexGrid>

            <FlexGrid item fullWidth style={{ maxWidth: '450px' }}>
              <Form {...formProps} onSubmit={onSendEmail}>
                <FormItems inputs={inputs} inputSize='100%' />
              </Form>
            </FlexGrid>

            <FlexGrid
              item
              container
              direction='column'
              spacing={3}
              fullWidth
              style={{ maxWidth: '450px' }}
            >
              <FlexGrid item>
                <Button onClick={onSendEmail}>{SEND}</Button>
              </FlexGrid>
              <FlexGrid item style={{ textAlign: 'center' }}>
                <Link typography='smallTitle' onClick={onGoBackLogin}>
                  {COME_BACK_TO_LOGIN}
                </Link>
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>
        </FlexGrid>
      </FlexGrid>
    </div>
  );
};
