import { useCallback, useState } from 'react';
import { makeProvider } from 'react-provider-maker';
import { Api, Options } from '../../api';

type UseApiResultTuple = [Api, (options: Options) => Api];

export const { Provider: ApiProvider, useProvider: useApi } = makeProvider(
  (): UseApiResultTuple => {
    const [api, setApi] = useState(() => new Api({}));

    const configApi = useCallback(
      (options: Options) => {
        const nextApi = new Api(options);
        setApi(nextApi);
        return nextApi;
      },
      [setApi]
    );

    return [api, configApi];
  }
);
