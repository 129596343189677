import {
  Drawer,
  DrawerTitle,
  FlexGrid,
  Typography,
  DrawerContent,
  DrawerActions,
  Button,
  CheckBox,
  useThemeProvider,
  FormItemProps,
  RadialButton,
  Form,
  CheckBoxCard,
  useForm,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { FormItems } from '../form-items';
import { useAddressInputs } from '../../common';
import { useCatalog } from '../../hooks/use-catalog';
import { useCallback, useEffect, useMemo } from 'react';
import { useDefaultValidations } from '../../utils/default-validations';
import { useValidators } from '../../utils/validators';
import { mainCountry } from '../../const';
import { useFormsFields } from '../../providers/onboarding-form-provider';

export type AddMoralShareholderProps = {
  updateData?: any;
  open: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
  businessName: string;
};

export const AddMoralShareholder = ({
  onClose,
  open,
  updateData,
  onSave,
  businessName,
}: AddMoralShareholderProps) => {
  const {
    REQUIRED_FIELD,
    ADD_SHAREHOLDER_OF,
    ADDRESS,
    NAME,
    PATERNAL_LASTNAME,
    MATERNAL_LASTNAME,
    OFICIAL_IDENTIFICATION_FRONT,
    EMAIL,
    ADD,
    SHAREHOLDER_INFO,
    UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
    CURP,
    CURP_HELP_DESCRIPTION,
    RFC_WITH_HOMOCLAVE,
    TWELVE_CHARACTER_ALPHANUMERIC_KEY,
    LEGAL_REPRESENTATIVE_OR_FAMILIAR_PEP,
    GENDER,
    BIRTH_DATE,
    BIRTH_COUNTRY,
    SELECT_IDENTIFICATION_TYPE,
    NATIONALITY,
    PERCENTAGE_OF_SHARES_ASSIGNED,
    OFICIAL_IDENTIFICATION_BACK,
    INE,
    PASSPORT,
    RELATIONSHIP,
    OCCUPATION,
    LEGAL_REPRESENTATIVE_IS_PEP_HELP_DESCRIPTION,
    CHECK_THE_OPTION_THAT_APPLIES,
    MAIN_LEGAL_REPRESENTATIVE_IS_PEP,
    PEP_IS_FAMILY,
    PEP_IS_FAMILY_AND_MAIN_LEGAL_REPRESENTATIVE,
    ENTER_PEP_INFO,
    ENTER_FAMILIAR_PEP_INFO,
    DEPENDENCY_NAME,
    POSITION_HELD,
    ANTIQUITI,
    BACK,
    DATE_FORMAT,
  } = useTranslator();
  const { theme } = useThemeProvider();
  const formProps = useForm<any>(
    updateData
      ? updateData
      : {
          general: {
            phoneCode: '+52', phoneCountry:'484',
            phoneCodeDesc: 'Mexico',
          },
          address: {
            country: mainCountry,
            countryDesc: 'Mexico',
          },
          identificationFile: {
            type: 'ine',
          },
        }
  );
  const pep = formProps.data.pep;
  const pepKindred = formProps.data.pepKindred;
  const setErrors = formProps.setErrors;
  const { setPropByName } = formProps;
  const [countries] = useCatalog('Countries');
  const [activities] = useCatalog('ShareholderActivities');
  const [genders] = useCatalog('Genders');
  const [antiquities] = useCatalog('Antiquities');
  const [relationship] = useCatalog('Relationships');
  const defaultValidations = useDefaultValidations();
  const validators = useValidators();
  
  const identificationFileType = formProps.data.identificationFile.type;

  const { formFields ,findFields} = useFormsFields();
  
  useEffect(()=>{
    findFields('QUICK_WIN_SHAREHOLDER');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const oficialDocuments = useMemo(
    () => [
      {
        name: 'ine',
        text: INE,
        value: identificationFileType === 'ine',
      },
      {
        name: 'passport',
        text: PASSPORT,
        value: identificationFileType === 'passport',
      },
    ],
    [identificationFileType, PASSPORT, INE]
  );

  const countriesSelect = countries.map((country: any) => ({
    name: country.value,
    value: country.key,
  }));

  const activitiesSelect = activities.map((activity: any) => ({
    name: activity.value,
    value: activity.key,
  }));

  const gendersSelect = genders.map((gender: any) => ({
    name: gender.value,
    value: gender.key,
  }));

  const anitquitiesSelect = antiquities.map((antiquity: any) => ({
    value: antiquity.key,
    name: antiquity.value,
  }));

  const relationshipSelect = relationship.map((relation: any) => ({
    value: relation.key,
    name: relation.value,
  }));

  const shareholderInfo: FormItemProps<string>[] = formFields('QUICK_WIN_SHAREHOLDER', [
    {
      placeholder: NAME,
      name: 'general.gname',
      type: 'input',
      required: true,
      ...defaultValidations.nameValidations(),
    },
    {
      placeholder: PATERNAL_LASTNAME,
      name: 'general.lname',
      type: 'input',
      required: true,
      ...defaultValidations.paternalLastNameValidations(),
    },
    {
      placeholder: MATERNAL_LASTNAME,
      name: 'general.slname',
      type: 'input',
      ...defaultValidations.maternalLastNameValidations(),
    },
    {
      placeholder: GENDER,
      name: 'general.gender',
      type: 'select',
      items: gendersSelect,
      required: true,
      validators: [validators.selectIsEmpty],
    },
    {
      placeholder: `${BIRTH_DATE} ${DATE_FORMAT}`,
      name: 'general.bdate',
      type: 'date',
      inputType: 'email',
      required: true,
      validators: [validators.isEmpty, validators.notValidDateFormat],
    },
    {
      placeholder: BIRTH_COUNTRY,
      name: 'general.bcountry',
      type: 'select',
      inputType: 'text',
      items: countriesSelect,
      search: true,
      required: true,
      validators: [validators.selectIsEmpty],
    },
    {
      placeholder: NATIONALITY,
      name: 'general.nationality',
      type: 'input',
      required: true,
      ...defaultValidations.nationalityValidations(),
    },
    {
      placeholder: OCCUPATION,
      name: 'general.occupation',
      type: 'select',
      items: activitiesSelect,
      required: true,
      validators: [validators.selectIsEmpty],
    },
    {
      placeholder: CURP,
      name: 'general.populationid',
      type: 'input',
      inputType: 'alphanumeric',
      required: true,
      toUpperCase: true,
      ...defaultValidations.curpValidations(),
      help: {
        description: CURP_HELP_DESCRIPTION,
      },
    },
    {
      name: 'general',
      type: 'phone',
      required: true,
      ...defaultValidations.phoneNumberValidations(),
    },
    {
      placeholder: EMAIL,
      name: 'general.email',
      type: 'input',
      required: true,
      ...defaultValidations.emailValidations(),
    },
    {
      placeholder: PERCENTAGE_OF_SHARES_ASSIGNED,
      name: 'percentageShares',
      type: 'input',
      inputType: 'number',
      saveAs: 'number',
      maxLength: 3,
      required: true,
      validators: [validators.isEmpty, validators.notMinimumPercentaje],
    },
  ]);

  const oficialDocumentIne: FormItemProps<string>[] = formFields('QUICK_WIN_SHAREHOLDER',[
    {
      placeholder: OFICIAL_IDENTIFICATION_FRONT,
      name: 'identificationFile.data.front',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
      help: {
        description: UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: OFICIAL_IDENTIFICATION_BACK,
      name: 'identificationFile.data.back',
      required: true,
      type: 'fileInput',
      validators: [validators.isEmpty],
    },
    {
      placeholder: RFC_WITH_HOMOCLAVE,
      name: 'general.taxid',
      required: true,
      type: 'input',
      toUpperCase: true,
      ...defaultValidations.rfcWithHomoclaveValidations(),
      help: {
        description: TWELVE_CHARACTER_ALPHANUMERIC_KEY,
      },
    },
  ]);

  const oficialDocumentPassport: FormItemProps<string>[] = formFields('QUICK_WIN_SHAREHOLDER',[
    {
      placeholder: OFICIAL_IDENTIFICATION_FRONT,
      name: 'identificationFile.data.front',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
      help: {
        description: UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: RFC_WITH_HOMOCLAVE,
      name: 'general.taxid',
      required: true,
      type: 'input',
      toUpperCase: true,
      ...defaultValidations.rfcWithHomoclaveValidations(),
      help: {
        description: TWELVE_CHARACTER_ALPHANUMERIC_KEY,
      },
    },
  ]);

  const address: FormItemProps<string>[] = useAddressInputs({
    setFormData: formProps.setData,
    postalCode: formProps.data.address.zip,
    formData: formProps.data
  });

  const pepFormSchema: FormItemProps<string>[] = formFields('QUICK_WIN_SHAREHOLDER',[
    {
      placeholder: DEPENDENCY_NAME,
      name: 'pep.dependencyName',
      type: 'input',
    },
    {
      placeholder: POSITION_HELD,
      name: 'pep.description',
      type: 'input',
    },
    {
      placeholder: ANTIQUITI,
      name: 'pep.antiquity',
      type: 'select',
      items: anitquitiesSelect,
    },
  ]);

  const pepKindredFormSchema: FormItemProps<string>[] =formFields('QUICK_WIN_SHAREHOLDER', [
    {
      placeholder: RELATIONSHIP,
      name: 'pepKindred.relationship',
      type: 'select',
      items: relationshipSelect,
    },
    {
      placeholder: DEPENDENCY_NAME,
      name: 'pepKindred.dependencyName',
      type: 'input',
    },
    {
      placeholder: POSITION_HELD,
      name: 'pepKindred.description',
      type: 'input',
    },
    {
      placeholder: ANTIQUITI,
      name: 'pepKindred.antiquity',
      type: 'select',
      items: anitquitiesSelect,
    },
  ]);

  function onSelectOficialDocument(selectedIndex: number) {
    setPropByName('identificationFile', {
      data: undefined,
      type: selectedIndex === 0 ? 'ine' : 'passport',
    });
  }

  function onDrawerAddButtonClick() {
    if (formProps.hasErrors) {
      formProps.displayErrors();
    } else {
      onSave(formProps.data);
      onClose();
    }
  }

  const switchPep = useCallback(() => {
    if (pep || pepKindred) {
      setPropByName('pep', undefined);
      setPropByName('pepKindred', undefined);
    } else {
      setPropByName('pep', {});
      setPropByName('pepKindred', undefined);
    }
  }, [setPropByName, pep, pepKindred]);

  const setPep = useCallback(() => {
    setPropByName('pep', pep || {});
    setPropByName('pepKindred', undefined);
  }, [setPropByName, pep]);

  const setPepKindred = useCallback(() => {
    setPropByName('pep', undefined);
    setPropByName('pepKindred', pepKindred || {});
  }, [setPropByName, pepKindred]);

  const setBothPep = useCallback(() => {
    setPropByName('pep', pep || {});
    setPropByName('pepKindred', pepKindred || {});
  }, [setPropByName, pepKindred, pep]);

  const pepFormActive = !pepKindred && pep;

  useEffect(() => {
    if (identificationFileType !== 'ine') {
      setErrors((currentErrors: any) => ({
        ...currentErrors,
        'identificationFile.data.back': undefined,
      }));
    }
  }, [identificationFileType, setErrors]);

  return (
    <Form {...formProps} onSubmit={onDrawerAddButtonClick}>
      <Drawer open={open} onClose={onClose} position='right'>
        <DrawerTitle>
          {ADD_SHAREHOLDER_OF} {businessName}
        </DrawerTitle>

        <DrawerContent>
          <FlexGrid item container fullWidth spacing={4} direction='column'>
            <FlexGrid item container fullWidth spacing={2} direction='column'>
              <FlexGrid item>
                <Typography variant='smallTitle'>{SHAREHOLDER_INFO}</Typography>
              </FlexGrid>
              <FlexGrid item>
                <Typography variant='small' color='neutral'>
                  <span style={{ color: theme.colors.required }}>*</span>{' '}
                  {REQUIRED_FIELD}
                </Typography>
              </FlexGrid>

              <FormItems inputs={shareholderInfo} />
            </FlexGrid>
            <FlexGrid item container fullWidth spacing={4} direction='column'>
              <FlexGrid item>
                <Typography variant='smallTitle'>
                  {SELECT_IDENTIFICATION_TYPE}
                </Typography>
              </FlexGrid>
              <FlexGrid item container direction='column' spacing={2}>
                {oficialDocuments.map(({ value, text }, index) => (
                  <FlexGrid item>
                    <RadialButton
                      value={value}
                      onInput={() => onSelectOficialDocument(index)}
                      text={text}
                    />
                  </FlexGrid>
                ))}
              </FlexGrid>
              <FlexGrid item>
                <FormItems
                  inputs={
                    identificationFileType === 'ine'
                      ? oficialDocumentIne
                      : oficialDocumentPassport
                  }
                />
              </FlexGrid>
            </FlexGrid>
            <FlexGrid item container fullWidth spacing={4} direction='column'>
              <FlexGrid item>
                <Typography variant='smallTitle'>{ADDRESS}</Typography>
              </FlexGrid>
              <FormItems inputs={address} />
            </FlexGrid>
            <FlexGrid item style={{ marginBottom: '150px' }}>
              <CheckBox
                value={pep || pepKindred}
                onInput={switchPep}
                text={LEGAL_REPRESENTATIVE_OR_FAMILIAR_PEP}
                help={{
                  description: LEGAL_REPRESENTATIVE_IS_PEP_HELP_DESCRIPTION,
                }}
              >
                <CheckBoxCard>
                  <FlexGrid
                    container
                    fullHeight
                    fullWidth
                    direction='column'
                    spacing={3}
                  >
                    <FlexGrid item>
                      <Typography variant='smallTitle'>
                        {CHECK_THE_OPTION_THAT_APPLIES}
                      </Typography>
                    </FlexGrid>
                    <FlexGrid container item direction='column' spacing={2}>
                      <RadialButton
                        onInput={setPep}
                        value={pepFormActive}
                        text={MAIN_LEGAL_REPRESENTATIVE_IS_PEP}
                      />
                      <RadialButton
                        onInput={setPepKindred}
                        value={!pep && pepKindred}
                        text={PEP_IS_FAMILY}
                      />
                      <RadialButton
                        onInput={setBothPep}
                        value={pep && pepKindred}
                        text={PEP_IS_FAMILY_AND_MAIN_LEGAL_REPRESENTATIVE}
                      />
                    </FlexGrid>

                    {pep && (
                      <>
                        <FlexGrid item>
                          <Typography variant='smallTitle'>
                            {ENTER_PEP_INFO}
                          </Typography>
                        </FlexGrid>
                        <FlexGrid item container fullWidth>
                          <FormItems inputs={pepFormSchema} />
                        </FlexGrid>{' '}
                      </>
                    )}
                    {pepKindred && (
                      <>
                        <FlexGrid item>
                          <Typography variant='smallTitle'>
                            {ENTER_FAMILIAR_PEP_INFO}
                          </Typography>
                        </FlexGrid>
                        <FlexGrid item container fullWidth>
                          <FormItems inputs={pepKindredFormSchema} />
                        </FlexGrid>
                      </>
                    )}
                  </FlexGrid>
                </CheckBoxCard>
              </CheckBox>
            </FlexGrid>
          </FlexGrid>
        </DrawerContent>

        <DrawerActions>
          <Button onClick={onClose} buttonType='secondary' size='normal'>
            {BACK}
          </Button>
          <Button
            onClick={onDrawerAddButtonClick}
            size='normal'
            style={{ marginLeft: theme.spacesPx[2] }}
          >
            {ADD}
          </Button>
        </DrawerActions>
      </Drawer>
    </Form>
  );
};
