import { FormContainer } from '../../../../../../../../components-quick-win/form-container';
import { AccountConfigForm } from '../../../../../../../../components-quick-win/account-config-form';

export const AccountConfig = () => {
  return (
    <FormContainer>
      <AccountConfigForm />
    </FormContainer>
  );
};
