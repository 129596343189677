import {
  FaBriefcase,
  FaRegCreditCard,
  FaLock,
  FaFolderOpen,
  FaEnvelope,
  FaMobileAlt,
} from 'react-icons/fa';
import { useThemeProvider } from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { ReactNode } from 'react';

export type TopCard = { icon: ReactNode; text: string; images?: string[] }[];
export type RightSideList = {
  icon: ReactNode;
  title: string;
  list: string[];
};

export const GetMainItems = (): {
  topCards: TopCard;
  bottomItems: {
    icon: ReactNode;
    text: string;
    color: keyof typeof theme.colors;
    width: string;
  }[];
} => {
  const { theme } = useThemeProvider();
  const {
    ON_BOARDING_INTRO_PFAE_TOP_FIRST_ITEM_TEXT,
    ON_BOARDING_INTRO_PFAE_TOP_SECOND_ITEM_TEXT,
    ON_BOARDING_INTRO_PFAE_TOP_THIRD_ITEM_TEXT,
    ON_BOARDING_INTRO_BOTTOM_FIRST_ITEM_TEXT,
    ON_BOARDING_INTRO_BOTTOM_SECOND_ITEM_TEXT,
    ON_BOARDING_INTRO_BOTTOM_THIRD_ITEM_TEXT,
  } = useTranslator();

  const briefCase = <FaBriefcase size='40px' color={theme.colors.indigo} />;
  const phone = <FaMobileAlt size='40px' color={theme.colors.indigo} />;
  const creditCard = (
    <FaRegCreditCard size='40px' color={theme.colors.indigo} />
  );
  const lock = <FaLock size='13px' color={theme.colors.white} />;
  const folder = <FaFolderOpen size='13px' color={theme.colors.white} />;
  const email = <FaEnvelope size='13px' color={theme.colors.white} />;

  return {
    topCards: [
      {
        icon: phone,
        text: ON_BOARDING_INTRO_PFAE_TOP_FIRST_ITEM_TEXT,
      },
      {
        icon: briefCase,
        text: ON_BOARDING_INTRO_PFAE_TOP_SECOND_ITEM_TEXT,
      },
      {
        icon: creditCard,
        text: ON_BOARDING_INTRO_PFAE_TOP_THIRD_ITEM_TEXT,
      },
    ],
    bottomItems: [
      {
        icon: lock,
        text: ON_BOARDING_INTRO_BOTTOM_FIRST_ITEM_TEXT,
        color: 'vivere',
        width: '211px',
      },
      {
        icon: folder,
        text: ON_BOARDING_INTRO_BOTTOM_SECOND_ITEM_TEXT,
        color: 'bolt',
        width: '269px',
      },
      {
        icon: email,
        text: ON_BOARDING_INTRO_BOTTOM_THIRD_ITEM_TEXT,
        color: 'primary',
        width: '191px',
      },
    ],
  };
};

export const GetRightSideList = (): { list: string[] } => {
  const {
    ON_BOARDING_INTRO_PFAE_RIGHT_SIDE_LIST_FIRST_ITEM,
    ON_BOARDING_INTRO_PFAE_RIGHT_SIDE_LIST_SECOND_ITEM,
    ON_BOARDING_INTRO_PFAE_RIGHT_SIDE_LIST_THIRD_ITEM,
    ON_BOARDING_INTRO_PFAE_RIGHT_SIDE_LIST_FOURTH_ITEM,
  } = useTranslator();

  return {
    list: [
      ON_BOARDING_INTRO_PFAE_RIGHT_SIDE_LIST_FIRST_ITEM,
      ON_BOARDING_INTRO_PFAE_RIGHT_SIDE_LIST_SECOND_ITEM,
      ON_BOARDING_INTRO_PFAE_RIGHT_SIDE_LIST_THIRD_ITEM,
      ON_BOARDING_INTRO_PFAE_RIGHT_SIDE_LIST_FOURTH_ITEM,
    ],
  };
};
