import { FlexGrid } from '@albo-ui/core';
import { useEffect, useState } from 'react';
import { OnBoardingIntroPFAE } from '../../../../components-quick-win/on-boarding-intro-pfae';
import { OnBoardingIntroPM } from '../../../../components-quick-win/on-boarding-intro-pm';
import { OnBoardingLoader } from '../../../../components-quick-win/on-boarding-loader';
import { useService } from '../../../../hooks/use-service';
import { useApi } from '../../../../providers/api-provider';
import { useAuth } from '../../../../providers/auth-provider';
import GeolocationProvider from '../../../../providers/geolocation-provider';
import { useSnackbarNotifier } from '../../../../hooks/use-snackbar-notifier';
import { useTranslator } from '../../../../providers/translator-provider';


export const Welcome = () => {
  const {
    user,
    reloadProfile,
    loading: authLoading,
    reloadingProfile,
  } = useAuth();
  const [api] = useApi();
  const [, , startDocumetationLoading, startDocumentation] = useService(
    {
      name: 'start-documentation',
    },
    []
  );
  const [show] = useSnackbarNotifier()
  const [loadingForms, setLoadingForms] = useState(false);
  const {DESCRIPTION_LANDING_ERROR}= useTranslator();

  const loading = authLoading || startDocumetationLoading || loadingForms;

  async function onPFAEContinueClick() {
   
    try {
      await startDocumentation();
      //"DocumentationNotStarted"
      setLoadingForms(true);
  
  
      let hasOnboardingForms = false;
      let tryCount = 0;
      // Keep fetching lead data until array of forms have data
      do {
        
        if (tryCount > 20) {
          throw new Error('Timeout waiting for onboarding forms');
        }
        const { data } = await api.callQuery('get-lead-by-uuid');
        if (data?.lead?.onboardingForms?.length > 0) hasOnboardingForms = true;
        else {
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }
        tryCount++;
      } while (!hasOnboardingForms);
  
      setLoadingForms(false);
  
      await reloadProfile();
    } catch (error) {
      console.log(error);
      setLoadingForms(false);
      show(DESCRIPTION_LANDING_ERROR)
    }
   
  }

  async function onPMContinueClick() {
    await startDocumentation();
    await reloadProfile();
  }

  useEffect(() => {
    if (user?.status === 'signing-up') {
      reloadProfile();
    }
  }, [user?.status, reloadProfile]);

  if (loading || reloadingProfile)
    return <OnBoardingLoader isLoading={loading || reloadingProfile} />;

  return (
    <GeolocationProvider>
      <FlexGrid container fullHeight fullWidth>
        {user?.leadType === 'PFAE' ? (
          <OnBoardingIntroPFAE onClick={onPFAEContinueClick} />
        ) : (
          <OnBoardingIntroPM onClick={onPMContinueClick} />
        )}
      </FlexGrid>
    </GeolocationProvider>
  );
};
