import styled from 'styled-components';

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px -2px 4px #edf0f4;
  height: 100px;
  background-color: white;
  margin-top: auto;
  border-left: none;
`;
