import { useCallback, useState } from 'react';
import { makeProvider } from 'react-provider-maker';
import { useApi } from '../api-provider';

export type LeadTypes = 'PFAE' | 'PM';
export type Phone = {
  phoneCode: string;
  phoneCodeDesc: string;
  phone: string;
};
export type OnboardingVersion = 'QUICK_WIN' | 'V1';

export type SubmitMoreInfoParams = {
  general: {
    gname: string;
    lname: string;
    slname?: string;
    email: string;
    phone: Phone;
  };
  type: LeadTypes;
  employeesQuantity: string;
  employeesQuantityDesc: string;
  reasonForBeingInterestedInAlbo: string;
  onboardingVersion: OnboardingVersion;
};

export const {
  Provider: SubmitMoreInfoProvider,
  useProvider: useSubmitMoreInfo,
} = makeProvider(() => {
  const [api] = useApi();
  const [state, setState] = useState<{
    loading?: boolean | undefined;
    eventName?: 'MoreInfoSubmited';
  }>({});

  const submitMoreInfo = useCallback(
    async (submitMoreInfoParams: SubmitMoreInfoParams) => {
      setState({ loading: true });
      const submitMoreInfoResult = await api.callService(
        'submit-more-info',
        submitMoreInfoParams
      );
      if (submitMoreInfoResult.eventName === 'MoreInfoSubmited') {
        setState({ loading: false, eventName: 'MoreInfoSubmited' });
      }
      setState((currentState) => ({ ...currentState, loading: false }));
    },
    [api, setState]
  );

  return {
    ...state,
    submitMoreInfo,
    api,
  };
});
