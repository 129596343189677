import { FlexGrid } from '@albo-ui/core';
import { Documents } from '../../../../../../components-quick-win/status-process-lib/documents';
import { useAuth } from '../../../../../../providers/auth-provider';
export const Validation = () => {
  const { user } = useAuth();
  return (
    <FlexGrid
      container
      alignItems='center'
      justify='center'
      fullHeight
      fullWidth
    >
      <FlexGrid item>
        <Documents
          status={
            user?.status === 'validating' ? 'pending' : 'documents-required'
          }
        />
      </FlexGrid>
    </FlexGrid>
  );
};
