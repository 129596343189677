import styled from 'styled-components';

type ContainerProps = {
  theme: any;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacesPx[4]};
  width: fit-content;
  align-items: center;
`;

export const IconContainer = styled.div`
  width: fit-content;
  height: auto;
  &:hover {
    cursor: pointer;
  }
`;
