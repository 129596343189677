import { useTranslator } from '../../../providers/translator-provider';
import laptopBlueCircle from '../../../images/Laptop_Blue_Circle.png';
import { alboForBusinessUrl } from '../../../const';
import { Button, FlexGrid, Image, Typography } from '@albo-ui/core';

export const AccountCreated = () => {
  const {
    YOUR_ALBO_ACCOUNT_HAS_BEEN_CREATED,
    WELCOME_WE_SENT_TO_YOUR_MAIL_IMPORTANT_INFORMATION,
    GO_TO_MY_ACCOUNT,
  } = useTranslator();

  const onClickGoToMyAccount = () => {
    window.open(alboForBusinessUrl, '_self');
  };

  return (
    <FlexGrid
      container
      spacing={6}
      alignItems='flex-end'
      style={{
        width: '100%',
        height: '100%',
        minWidth: '850px',
        minHeight: '400px',
        position: 'relative',
        margin: 'auto',
      }}
      fullWidth
      fullHeight
      direction='column'
    >
      <FlexGrid
        item
        container
        direction='column'
        spacing={1}
        style={{ maxWidth: '330px' }}
      >
        <FlexGrid item>
          <Typography variant='h1' color='white'>
            {YOUR_ALBO_ACCOUNT_HAS_BEEN_CREATED}
          </Typography>
        </FlexGrid>
        <FlexGrid item>
          <Typography variant='paragraph' color='white'>
            {WELCOME_WE_SENT_TO_YOUR_MAIL_IMPORTANT_INFORMATION}
          </Typography>
        </FlexGrid>
      </FlexGrid>
      <FlexGrid item>
        <Button onClick={onClickGoToMyAccount} color='white' size='large'>
          <Typography variant='smallTitle' color='primary'>
            {GO_TO_MY_ACCOUNT}
          </Typography>
        </Button>
      </FlexGrid>
      <Image
        src={laptopBlueCircle}
        size='642px'
        style={{ position: 'absolute', left: 0, bottom: 0 }}
      />
    </FlexGrid>
  );
};
