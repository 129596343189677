import { Button, FlexGrid, Image, Typography } from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import portrait from '../../images/Portrait_Solid.svg';
import logo  from '../../images/Logo_Primario_Indigo.png'
import { inextUrl } from '../../environments/environment';
export const InextMessageEmptyLead = () => {
  const {
    TITLE_LANDING_INEXT_REDIRECT,
    BUTTON_LANDING_INEXT_REDIRECT,
  } = useTranslator();

  const redirectInext = () => {
    window.location.href = inextUrl;
  }

  

  return (
    <FlexGrid
      container
      direction='column'
      spacing={8}
      alignItems='center'
      justify='center'
      fullHeight
      fullWidth
      padding={8}
    >
      <FlexGrid item>
        <Image src={logo} size='200px' />
      </FlexGrid>
      <FlexGrid item>
        <Image src={portrait} size='60px' />
      </FlexGrid>
      <FlexGrid
        item
        container
        spacing={1}
        direction='column'
        alignItems='center'
        style={{ textAlign: 'center' }}
      >
        <FlexGrid item>
          <Typography variant='h2'>
            {TITLE_LANDING_INEXT_REDIRECT}
          </Typography>
        </FlexGrid>
        <FlexGrid item  style={{ maxWidth: '520px', padding:"20px" }}>
          <Typography variant='paragraph'>
          Para actualizar la información de un prospecto, primero <b> ingresa a iNext</b>, selecciona <b>"Editar"</b> e <b>inicia sesión</b> en onboarding.
          </Typography>
        </FlexGrid>
      </FlexGrid>

      <FlexGrid
        item
        container
        spacing={1}
        direction='column'
        alignItems='center'
        style={{ textAlign: 'center' }}
      >
        
        <FlexGrid item>
          <Button color='indigo' size='large' onClick={redirectInext}>
            {BUTTON_LANDING_INEXT_REDIRECT}
            </Button>
          
        </FlexGrid>
      </FlexGrid>
    </FlexGrid>
  );
};
