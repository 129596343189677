import { Link, Snackbar, Typography } from '@albo-ui/core';

import { ReactNode, useCallback, useState } from 'react';
import { makeProvider } from 'react-provider-maker';
import { useTranslator } from '../translator-provider';
import { useLocalStorage } from '../../hooks/use-local-storage';
import { cookieUsePolicyUrl } from '../../const';

export type CookieUsePolicyNotificationProviderSnackBarProps = {
  children: ReactNode;
};

export const {
  Provider: CookieUsePolicyNotificationProvider,
  useProvider: useCookieUsePolicyNotification,
} = makeProvider(() => {
  const [text, setText] = useState<string | ReactNode | undefined>(undefined);
  const [cookiesAccepted, setCookiesAccepted] = useLocalStorage<
    boolean | undefined
  >('OBCookiesAccepted', undefined);
  const cookiesNotAccepted = !cookiesAccepted;
  const { COOKIE_USE_POLICY, WHEN_USING_THE_PLATFORM_YOU_ACCEPT_OUR } =
    useTranslator();

  const show = useCallback(() => {
    setText(
      <Typography variant='paragraph' color='white'>
        {WHEN_USING_THE_PLATFORM_YOU_ACCEPT_OUR}{' '}
        <Link
          typography='paragraph'
          color='white'
          href={cookieUsePolicyUrl}
          underlined
        >
          {COOKIE_USE_POLICY}
        </Link>
      </Typography>
    );
  }, [setText, COOKIE_USE_POLICY, WHEN_USING_THE_PLATFORM_YOU_ACCEPT_OUR]);

  const close = useCallback(() => {
    setText(undefined);
    setCookiesAccepted(true);
  }, [setText, setCookiesAccepted]);

  return {
    text,
    close,
    show,
    cookiesNotAccepted,
    cookiesAccepted,
    setCookiesAccepted,
  };
});

export const CookieUsePolicyNotificationProviderSnackBar = ({
  children,
}: CookieUsePolicyNotificationProviderSnackBarProps) => {
  const { text, close } = useCookieUsePolicyNotification();
  const { ACCEPT } = useTranslator();

  return (
    <>
      {children}
      <Snackbar
        open={text !== undefined}
        onClose={close}
        message={text || ''}
        action={ACCEPT}
      />
    </>
  );
};
