import { FlexGrid } from '@albo-ui/core';
import { LoginForm } from '../../components-quick-win/login-form';
import GeolocationProvider from '../../providers/geolocation-provider';

export const Login = () => {
  return (
    <GeolocationProvider>
    <FlexGrid
      fullWidth
      fullHeight
      container
      padding={8}
      justify='center'
      style={{ minHeight: '100vh', height: 'auto', backgroundColor: 'white' }}
    >
      <FlexGrid item style={{ width: '380px' }}>
        <LoginForm />
      </FlexGrid>
    </FlexGrid>
    </GeolocationProvider>
  );
};
