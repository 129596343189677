import { FlexGrid, useThemeProvider } from '@albo-ui/core';
import { AccountCreated } from '../../../../../../components-quick-win/status-process-lib/account-created';

export const AccountReady = () => {
  const { theme } = useThemeProvider();
  return (
    <FlexGrid
      container
      fullWidth
      fullHeight
      style={{
        backgroundColor: theme.colors.primary,
        display: 'flex',
        alignItems: 'center',
      }}
      justify='center'
    >
      <FlexGrid item>
        <AccountCreated />
      </FlexGrid>
    </FlexGrid>
  );
};
