
import { useCallback, useState } from 'react';
import { ConfirmationDialog } from '../ConfirmationDialog';

type ConfirmProps = {
    title:string, message:string,
    style?: "warning" | "error" | "success" | "info";
    textConfirm?: string;
    textCancel?: string;
}
type PromiseProps = {resolve:(value:unknown) => void | Promise<boolean> | Promise<void> } | null;

export const useConfirm = ({title, message,textCancel,textConfirm}:ConfirmProps) => {
    const [promise, setPromise] = useState<PromiseProps>(null);

    const confirm = useCallback(() => new Promise((resolve, reject) => {
        setPromise({ resolve });
    }), []);

    const handleClose = () => {
        setPromise(null);
    };

    const handleConfirm = () => {
        promise?.resolve(true);
        handleClose();
    };

    const handleCancel = () => {
        promise?.resolve(false);
        handleClose();
    };
    const DialogConfirm = (): JSX.Element => {
        return <>
            <ConfirmationDialog
                onConfirm={handleConfirm}
                onClose={handleCancel}
                open={promise !== null}
                title={title}
                message={message}
                textCancel={textCancel}
                textConfirm={textConfirm}
            />
        </>
    }
        ;
    return { DialogConfirm, confirm };
};
