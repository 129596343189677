import { FlexGrid, Image, Typography } from '@albo-ui/core';
import { useTranslator } from '../../../providers/translator-provider';
import contract from '../../../images/Contract.svg';

export type ContractProps = {
  status: 'done' | 'pending';
};

export const ContractStatusInfo = ({ status }: ContractProps) => {
  const { SIGN_YOUR_CONTRACT, SIGN_YOUR_CONTRACT_DESCRIPTION } =
    useTranslator();

  return (
    <FlexGrid
      container
      direction='column'
      spacing={3}
      alignItems='center'
      style={{ maxWidth: '530px' }}
    >
      <FlexGrid item>
        <Image src={contract} size='123px' />
      </FlexGrid>
      <FlexGrid
        item
        container
        spacing={1}
        direction='column'
        alignItems='center'
        style={{ textAlign: 'center' }}
      >
        <FlexGrid item>
          <Typography variant='smallTitle'>{SIGN_YOUR_CONTRACT}</Typography>
        </FlexGrid>
        <FlexGrid item>
          <Typography variant='paragraph'>
            {SIGN_YOUR_CONTRACT_DESCRIPTION}
          </Typography>
        </FlexGrid>
      </FlexGrid>
    </FlexGrid>
  );
};
