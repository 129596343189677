import {
  Button,
  DefaultComponentProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FlexGrid,
  Typography,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';

export type UserAlreadyExistDialogProps = {
  onConfirm: () => void;
  onClose: () => void;
  open: boolean;
} & DefaultComponentProps;

export const UserAlreadyExistDialog = ({
  onConfirm,
  onClose,
  open,
  className,
  style,
}: UserAlreadyExistDialogProps) => {
  const {
    USER_ALREADY_EXISTS_DIALOG_TEXT,
    USER_ALREADY_EXISTS_DIALOG_BUTTON_TEXT_CANCEL,
    USER_ALREADY_EXISTS_DIALOG_BUTTON_TEXT_CONFIRM,
    USER_ALREADY_EXISTS_DIALOG_TITLE,
  } = useTranslator();

  return (
    <Dialog className={className} style={style} onClose={onClose} open={open}>
      <DialogTitle>
        <Typography variant='smallTitle' color='sunset'>
          {USER_ALREADY_EXISTS_DIALOG_TITLE}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='paragraph'>
          {USER_ALREADY_EXISTS_DIALOG_TEXT}{' '}
        </Typography>
      </DialogContent>
      <DialogActions>
        <FlexGrid
          container
          fullHeight
          fullWidth
          justify='space-between'
          alignItems='flex-end'
          style={{ marginTop: '20px' }}
        >
          <FlexGrid item>
            <Button onClick={onClose} buttonType='secondary' size='normal'>
              {USER_ALREADY_EXISTS_DIALOG_BUTTON_TEXT_CANCEL}
            </Button>
          </FlexGrid>
          <FlexGrid item>
            <Button onClick={onConfirm} size='normal'>
              {USER_ALREADY_EXISTS_DIALOG_BUTTON_TEXT_CONFIRM}
            </Button>
          </FlexGrid>
        </FlexGrid>
      </DialogActions>
    </Dialog>
  );
};
