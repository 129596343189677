import {
  CheckBox,
  FlexGrid,
  Form,
  FormItem,
  FormItemProps,
  Typography,
  useThemeProvider,
  RadialButton,
  useFormCheckBox,
} from '@albo-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useAddressInputs } from '../../common';
import { useOnboardingForm } from '../../hooks/use-onboarding-form';
import { usePfaeStatus } from '../../hooks/use-pfae-status';
import { usePmStatusQuickWin } from '../../hooks/use-pm-status-quick-win';
import { useQuery } from '../../hooks/user-query';
import { useAuth } from '../../providers/auth-provider';
import { useOnHomeCBS } from '../../providers/on-home-cbs-provider';
import { useTranslator } from '../../providers/translator-provider';
import { useDefaultValidations } from '../../utils/default-validations';
import { useValidators } from '../../utils/validators';
import { BottomActions } from '../bottom-actions';
import { FormContainer } from '../form-container';
import { FormItems } from '../form-items';
import { OnBoardingLoader } from '../on-boarding-loader';
import { mainCountry } from '../../const';
import { useConfirm } from '../../components/confirm-dialog';
import { useUserRole } from '../../hooks/use-user-role';
import { useFormsFields } from '../../providers/onboarding-form-provider';

export type CardRequestFormProps = {
  personType: 'PFAE' | 'PM';
};
const defaultParams = {
  requiredCards: 'si',
  quantity: 0,
  general: { phone: { phoneCode: '+52', phoneCountry:'484', phoneCodeDesc: 'Mexico' } },
  address: {
    country: mainCountry + '',
    countryDesc: 'Mexico',
  },
  isInvoicing: false,
}

export const CardRequestForm = ({ personType }: CardRequestFormProps) => {
  const [total, setTotal] = useState(0);
  const formProps = useOnboardingForm({
    defaultParams: defaultParams,
    onboardingFormType: 'QUICK_WIN_CARD_REQUEST',
    nextSectionUrl: '/home/menu',
    singleton: true,
  });
  const { theme } = useThemeProvider();
  const validators = useValidators();
  const history = useHistory();
  const defaultValidators = useDefaultValidations();
  const pfaeStatus = usePfaeStatus();
  const pmStatus = usePmStatusQuickWin();
  const homeCBS = useOnHomeCBS();
  const showSaveButton = personType === 'PFAE' ? pfaeStatus.readyToSend : pmStatus.readyToSend;
  homeCBS.setShowDialog(showSaveButton);
  const {
    leadData: { onboardingForms },
  } = useAuth();

  const companyUuid = onboardingForms.find(
    ({ formType }: any) =>
      formType ===
      (personType === 'PFAE'
        ? 'QUICK_WIN_COMPANY_INFO_ACCOUNT'
        : 'QUICK_WIN_COMPANY_INFO')
  ).uuid;

  const [queryFormData, loadingForm] = useQuery(
    {
      name: 'get-onboarding-forms-by-uuids',
      params: {
        onboardingFormsUuids: [companyUuid],
      },
    },
    [companyUuid]
  );

  const setFormData = formProps.form.setData;
  const setFormErrors = formProps.form.setErrors;
  const quantity = formProps.form.data.quantity;
  const notificationAddress =
    queryFormData?.onboardingForms[0]?.formData?.notificationAddress;
  const { setPropByName } = formProps.form;
  const requiredCards = formProps.form.data.requiredCards;
  const checkBox = useFormCheckBox('isInvoicing', formProps.form);
  const checkBoxValue = checkBox.value;
  const toggleCheckBox = checkBox.onInput;
  const mayInvoicing = notificationAddress?.country === mainCountry + '';

  const {
    FINISH,
    EMAIL,
    CARDS_FOR_YOUR_COLLABORATORS,
    REQUIRED_FIELD,
    DELIVERY_ADDRESS,
    DATA_OF_WHO_RECEIVES,
    FULL_NAME_OF_WHO_RECEIVES,
    NUMBER_OF_CARDS_REQUIRED,
    USE_SAME_ADDRESS_OF_MY_BUSINESS,
    USE_MY_FISCAL_ADDRESS,
    TOTAL_GUARANTEE_DEPOSIT,
    CURRENCY,
    CARDS_REQUEST_FORM_DESCRIPTION,
    CARDS_REQUEST_FORM_FOOTER,
    YES, OTHER_MOMENT,
    DO_YOU_WANT_ALBO_CARDS_FOR_YOUR_COLLABORATORS,
    UPDATE,
    TITLE_UPDATE_CUSTOMER_INFO,
    DESCRIPTION_UPDATE_CUSTOMER_INFO,
    BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO,
    SAVE_PROGRESS
  } = useTranslator();

  const { formFields ,findFields} = useFormsFields();
  
  useEffect(()=>{
    findFields('QUICK_WIN_CARD_REQUEST');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const { DialogConfirm, confirm: showConfirm } = useConfirm({
    title: TITLE_UPDATE_CUSTOMER_INFO,
    message: DESCRIPTION_UPDATE_CUSTOMER_INFO,
    textConfirm: BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO
  });

  const handleClickSave = async () => {
    const confirm = await showConfirm();
    if (!confirm) {
      return;
    }
    formProps.saveFormOps();
  }

  const { isOpsAnalyst } = useUserRole();

  const checkBoxText = {
    PFAE: USE_SAME_ADDRESS_OF_MY_BUSINESS,
    PM: USE_MY_FISCAL_ADDRESS,
  };

  const radioOptions = useMemo(
    () => [
      {
        name: 'si',
        text: YES,
        value: requiredCards === 'si',
      },
      {
        name: 'no',
        text: OTHER_MOMENT,
        value: requiredCards === 'no',
      },
    ],
    [requiredCards, YES, OTHER_MOMENT]
  );

  function onSelectRadioOption(selectedIndex: number) {
    setPropByName('requiredCards', selectedIndex === 0 ? 'si' : 'no');
    if (selectedIndex === 0) {
      setFormData(defaultParams);
    } else {
      setFormData({requiredCards: 'no'})
      setFormErrors({});
    }
  }

  const personWhoReceivesData: FormItemProps<string>[] = formFields('QUICK_WIN_CARD_REQUEST',[
    {
      placeholder: EMAIL,
      name: 'general.email',
      type: 'input',
      required: true,
      ...defaultValidators.emailValidations(),
    },
    {
      placeholder: FULL_NAME_OF_WHO_RECEIVES,
      name: 'general.name',
      type: 'input',
      required: true,
      ...defaultValidators.fullNameOfWhoReceivesValidations(),
    },
    {
      name: 'general.phone',
      type: 'phone',
      required: true,
      ...defaultValidators.phoneNumberValidations(),
    },
  ]);

  const addressInputs: FormItemProps<string>[] = formFields('QUICK_WIN_CARD_REQUEST', useAddressInputs({
    setFormData: formProps.form.setData,
    postalCode: requiredCards === 'si' ? formProps.form.data.address.zip : '',
    disabled: checkBoxValue,
    formData: formProps.form.data,
    disableCountryInput: true,
  }));

  function onBack() {
    personType === 'PFAE'
      ? history.push('beneficiaries')
      : history.push('account-config');
  }

  function onFinish() {
    formProps.moveForward();
  }

  function onCheckBoxInput(value: boolean) {
    toggleCheckBox();
    if (value) {
      setFormData((currentData: any) => {
        return {
          ...currentData,
          isInvoicing: value,
          address: {
            ...notificationAddress,
          },
        };
      });
    } else {
      setFormData((currentData: any) => ({
        ...currentData,
        isInvoicing: value,
        address: {
          country: mainCountry + '',
          countryDesc: 'Mexico',
        },
      }));
    }
  }

  useEffect(() => {
    if (requiredCards === 'si') {
      setFormData(defaultParams);
    } else {
      setFormData({requiredCards: 'no'});
      setFormErrors({});
    }
  }, [requiredCards, setFormData, setFormErrors]);

  useEffect(() => {
    if (checkBoxValue) {
      setFormData((currentData: any) => {
        return {
          ...currentData,
          isInvoicing: checkBoxValue,
          address: {
            ...notificationAddress,
          },
        };
      });
    } else {
      setFormData((currentData: any) => ({
        ...currentData,
        isInvoicing: checkBoxValue,
      }));
    }
  }, [notificationAddress, checkBoxValue, setFormData]);

  useEffect(() => {
    if (quantity) setTotal(quantity * 58);
    else setTotal(0);
  }, [quantity, setTotal]);

  if (
    formProps.loadingForm ||
    formProps.movingForward ||
    formProps.saving ||
    loadingForm
  )
    return (
      <OnBoardingLoader
        isLoading={
          formProps.loadingForm ||
          formProps.movingForward ||
          formProps.saving ||
          loadingForm
        }
      />
    );

  return (
    <Form {...formProps.form} onSubmit={isOpsAnalyst ? handleClickSave : onFinish}>
      <FormContainer>
        <FlexGrid container fullHeight fullWidth direction='column' spacing={3}>
          <FlexGrid item container direction='column' spacing={1}>
            <FlexGrid item>
              <Typography variant='h2'>
                {CARDS_FOR_YOUR_COLLABORATORS}
              </Typography>
            </FlexGrid>

            <FlexGrid item>
              <Typography variant='paragraph'>
                {CARDS_REQUEST_FORM_DESCRIPTION}
              </Typography>
            </FlexGrid>
          </FlexGrid>

          <FlexGrid item container fullWidth spacing={4} direction='column'>
              <FlexGrid item>
                <Typography variant='smallTitle'>
                  {DO_YOU_WANT_ALBO_CARDS_FOR_YOUR_COLLABORATORS}
                </Typography>
              </FlexGrid>
              <FlexGrid item container direction='row' spacing={2}>
                {radioOptions.map(({ value, text }, index) => (
                  <FlexGrid item>
                    <RadialButton
                      value={value}
                      onInput={() => onSelectRadioOption(index)}
                      text={text}
                    />
                  </FlexGrid>
                ))}
              </FlexGrid>
            </FlexGrid>

          {requiredCards === 'si' && (
            <>
              <FlexGrid item>
                <Typography variant='small' color='neutral' bold>
                  <span style={{ color: theme.colors.required }}>*</span>{' '}
                  {REQUIRED_FIELD}
                </Typography>
              </FlexGrid>
              <FlexGrid item container wrap fullWidth spacing={4}>
                <FlexGrid item style={{ width: '45%' }}>
                  <FormItem
                    name='quantity'
                    placeholder={NUMBER_OF_CARDS_REQUIRED}
                    type='input'
                    inputType='number'
                    saveAs='number'
                    required={true}
                    maxLength={4}
                    validators={[validators.isEmpty]}
                  />
                </FlexGrid>
                <FlexGrid
                  item
                  container
                  direction='column'
                  spacing={1}
                  style={{ width: '45%' }}
                >
                  <FlexGrid item>
                    <Typography variant='small' color='neutral'>
                      {TOTAL_GUARANTEE_DEPOSIT}
                    </Typography>
                  </FlexGrid>
                  <FlexGrid item>
                    <Typography variant='small' color='charcoal' bold>
                      $ {total} {CURRENCY}
                    </Typography>
                  </FlexGrid>
                </FlexGrid>
              </FlexGrid>
              <FlexGrid item>
                <Typography variant='smallTitle'>{DELIVERY_ADDRESS}</Typography>
              </FlexGrid>
              {
                mayInvoicing && 
                <FlexGrid item>
                  <CheckBox
                    onInput={onCheckBoxInput}
                    value={checkBoxValue}
                    text={checkBoxText[personType]}
                  />
                </FlexGrid>
              }
              <FlexGrid item container fullWidth>
                <FormItems inputs={addressInputs} />
              </FlexGrid>
              <FlexGrid item>
                <Typography variant='smallTitle'>{DATA_OF_WHO_RECEIVES}</Typography>
              </FlexGrid>
              <FlexGrid item container fullWidth>
                <FormItems inputs={personWhoReceivesData} />
              </FlexGrid>
              <FlexGrid item container style={{ marginBottom: '100px' }}>
                <Typography variant='paragraph'>
                  {CARDS_REQUEST_FORM_FOOTER}
                </Typography>
              </FlexGrid>
            </>
          )}
          </FlexGrid>
      </FormContainer>

      <DialogConfirm />

      <BottomActions
        backButton
        saveProgressButton={showSaveButton && !isOpsAnalyst}
        saveButtonText={SAVE_PROGRESS}
        continueButton
        continueButtonText={isOpsAnalyst ? UPDATE : FINISH}
        onSaveProgress={formProps.saveForm}
        onContinue={isOpsAnalyst ? handleClickSave : onFinish}
        onBack={onBack}
      />
    </Form>
  );
};
