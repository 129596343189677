const MORE_25 = 25;

export const iterateShareholders = (shareholders:[]|any, isFormData: boolean) => {
    let isMoreOrEqual25 = false;
    let prioritys = [false, false, false];

    if (shareholders && shareholders?.length !== 0) {
        const size = shareholders?.length;

        for (var i = 0; i < size; i++) {
            const element: any = shareholders[i];
            let percentageShares, type, stockMarketListing;

            if (isFormData) {
                let item = element.formData?.general;
                ({ percentageShares, type, stockMarketListing } = item);
            } else {
                ({ percentage: percentageShares, type, beListed: stockMarketListing } = element);
            }
            isMoreOrEqual25 = percentageShares && Number(percentageShares) >= MORE_25;

            if (isMoreOrEqual25) {
                const isPM = type && type.toLowerCase() === 'moral';
                const isPFAE = type && type.toLowerCase() === 'fisica';

                prioritys = setPriority(isPFAE, 0, prioritys);
                prioritys = setPriority(isPM && stockMarketListing, 1, prioritys);
                prioritys = setPriority(isPM && !stockMarketListing, 2, prioritys);
            } else {
                prioritys = setPriority(true, 2, prioritys);
            }
        }
    }
    return prioritys;
}

const setPriority = (value: boolean, index: number, prioritys: boolean[]) => {
    const current = prioritys[index];
    if (!current && value) {
        prioritys[index] = value;
    }
    return prioritys;
}