import styled from 'styled-components';

export const Point = styled.div`
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 50%;
  margin-top: 9px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
`;
