import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { CompanyOrganization } from './routes/company-organization';
import { AccountPepRelation } from './routes/account-pep-relation';
import { ComplementaryInfo } from './routes/complementary-info';
import { AdditionalInfo } from './routes/additional-info';


export const AdditionalRecordInfoRouter = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${url}/company-organization-info`} component={CompanyOrganization}/>
      <Route exact path={`${url}/pep-info`} component={AccountPepRelation}/>
      <Route exact path={`${url}/complementary-info`} component={ComplementaryInfo}/>
      <Route exact path={`${url}/additional-info`} component={AdditionalInfo}/>
      <Redirect to={`/onboarding/home/menu-extra-info`} />
    </Switch>
  )
};
