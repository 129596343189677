import { CompanyAdminsForm } from '../../../../../../../../components-quick-win/company-admins-form';
import { FormContainer } from '../../../../../../../../components-quick-win/form-container';

export const Admins = () => {
  return (
    <FormContainer>
      <CompanyAdminsForm />
    </FormContainer>
  );
};
