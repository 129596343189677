import {
  Drawer,
  DrawerDescription,
  DrawerTitle,
  FlexGrid,
  Typography,
  DrawerContent,
  DrawerActions,
  Button,
  useThemeProvider,
  FormItemProps,
  Form,
  useForm,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { FormItems } from '../form-items';
import { useDefaultValidations } from '../../utils/default-validations';
import { useValidators } from '../../utils/validators';
import { useCatalog } from '../../hooks/use-catalog';
import { useFormsFields } from '../../providers/onboarding-form-provider';
import { useEffect } from 'react';

export type AddDependentDrawerProps = {
  open: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
  onUpdate: (data: any) => void;
  updateData?: any;
};

export const AddDependentDrawer = ({
  onClose,
  open,
  updateData,
  onSave,
  onUpdate,
}: AddDependentDrawerProps) => {
  const {
    REQUIRED_FIELD,
    PATERNAL_LASTNAME,
    MATERNAL_LASTNAME,
    CANCEL,
    ADD,
    BIRTH_DATE,
    DATE_FORMAT,
    NAME,
    UPDATE,
    MUST_BE_BELOW_ONE_HUNDRED_YEARS,
    ACCOUNT_FORM_PEP_MODAL_TITLE,
    ACCOUNT_FORM_PEP_MODAL_TITLE_UPDATE,
    ACCOUNT_FORM_PEP_MODAL_DESCRIPTION,
    ACCOUNT_FORM_PEP_MODAL_FORM_TITLE,
    ACCOUNT_FORM_PEP_MODAL_FORM_RELATION_TYPE
  } = useTranslator();
  const { theme } = useThemeProvider();
  const formProps = useForm(updateData || {});
  const formData = formProps.data;
  const defaultValidations = useDefaultValidations();
  const validators = useValidators();
  const [relationship] = useCatalog('Relationships');
  
  
  const { formFields ,findFields} = useFormsFields();
  useEffect(()=>{
    findFields('QUICK_WIN_PEP_RELATION');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const validateAge = (selectedDate: string) => {
    const endDate = selectedDate ? new Date(selectedDate) : new Date();
    const currentDate = new Date();

    const endDateYears = endDate.getFullYear();
    const currentDateYears = currentDate.getFullYear();
    const age = currentDateYears - endDateYears;

    if (age > 100) return MUST_BE_BELOW_ONE_HUNDRED_YEARS;
    return undefined;
  };

  const relationshipSelect = relationship.map((relation: any) => ({
    value: relation.key,
    name: relation.value,
  }));

  const dependentInfo: FormItemProps<string>[] = formFields('QUICK_WIN_PEP_RELATION',[
    {
      placeholder: NAME,
      name: 'gname',
      type: 'input',
      required: true,
      ...defaultValidations.nameValidations(),
    },
    {
      placeholder: PATERNAL_LASTNAME,
      name: 'lname',
      type: 'input',
      required: true,
      ...defaultValidations.paternalLastNameValidations(),
    },
    {
      placeholder: MATERNAL_LASTNAME,
      name: 'slname',
      type: 'input',
      ...defaultValidations.maternalLastNameValidations(),
    },
    {
      placeholder: `${BIRTH_DATE} ${DATE_FORMAT}`,
      name: 'bdate',
      type: 'date',
      inputType: 'email',
      required: true,
      validators: [
        validators.isEmpty,
        validators.notValidDateFormat,
        (value: string) => {
          return validateAge(value);
        },
      ],
    },
    {
      placeholder: ACCOUNT_FORM_PEP_MODAL_FORM_RELATION_TYPE,
      name: 'relation',
      type: 'select',
      items: relationshipSelect,
      required: true,
      search: true,
      validators: [validators.selectIsEmpty],
    }
  ]);

  const onSaveDependent = () => {
    if (formProps.hasErrors) formProps.displayErrors();
    else {
      onSave && onSave(formData);
    }
  };

  const onUpdateDependent = () => {
    if (formProps.hasErrors) formProps.displayErrors();
    else {
      onUpdate && onUpdate(formData);
    }
  };

  return (
    <Form
      {...formProps}
      onSubmit={updateData ? onUpdateDependent : onSaveDependent}
    >
      <Drawer open={open} onClose={onClose} position='right'>
        <DrawerTitle>{updateData ? ACCOUNT_FORM_PEP_MODAL_TITLE_UPDATE : ACCOUNT_FORM_PEP_MODAL_TITLE}</DrawerTitle>
        <DrawerDescription>
          <FlexGrid container fullWidth direction='column' spacing={2}>
            <FlexGrid item>{ACCOUNT_FORM_PEP_MODAL_DESCRIPTION}</FlexGrid>
          </FlexGrid>
        </DrawerDescription>

        <DrawerContent>
          <FlexGrid item container fullWidth spacing={4} direction='column'>
            <FlexGrid item container fullWidth spacing={2} direction='column'>
              <FlexGrid item>
                <Typography variant='smallTitle'>{ACCOUNT_FORM_PEP_MODAL_FORM_TITLE}</Typography>
              </FlexGrid>

              <FlexGrid item>
                <Typography variant='small' color='neutral'>
                  <span style={{ color: theme.colors.required }}>*</span>{' '}
                  {REQUIRED_FIELD}
                </Typography>
              </FlexGrid>

              <FormItems inputs={dependentInfo} />
            </FlexGrid>
          </FlexGrid>
        </DrawerContent>

        <DrawerActions>
          <Button onClick={onClose} buttonType='secondary' size='normal'>
            {CANCEL}
          </Button>
          <Button
            onClick={updateData ? onUpdateDependent : onSaveDependent}
            size='normal'
            style={{ marginLeft: theme.spacesPx[2] }}
          >
            {updateData ? UPDATE : ADD}
          </Button>
        </DrawerActions>
      </Drawer>
    </Form>
  );
};
