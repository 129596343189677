import { Loader } from '@albo-ui/core';
import loader from '../../images/loading.gif';

export type OnBoardingLoaderProps = {
  isLoading?: boolean;
  message?:string;
};

export const OnBoardingLoader = ({ isLoading, message }: OnBoardingLoaderProps) => {
  return <Loader open={isLoading} src={loader} message={message} />;
};
