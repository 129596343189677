import {
  Alert,
  Card,
  FlexGrid,
  useAlert,
  useThemeProvider,
} from '@albo-ui/core';
import { useEffect } from 'react';
import { SideNav } from '../../../../components-quick-win/side-nav';
import { usePfaeStatus } from '../../../../hooks/use-pfae-status';
import { usePmStatusQuickWin } from '../../../../hooks/use-pm-status-quick-win';
import { useAuth } from '../../../../providers/auth-provider';
import GeolocationProvider from '../../../../providers/geolocation-provider';
import { useTranslator } from '../../../../providers/translator-provider';
import { HomeRouter } from './router';
import { Rejected } from '../../../../components-quick-win/status-process-lib/rejected'
import { SimpleAlertProvider, SimpleAlertProviderSnackBar } from '../../../../providers/simple-alert-provider';

export const Home = () => {
  const { theme } = useThemeProvider();
  const { user, showAdditionalInfo } = useAuth();
  const pfaeStatus = usePfaeStatus();
  const pmStatus = usePmStatusQuickWin();
  const alertProps = useAlert();
  const openAlert = alertProps.openAlert;
  const closeAlert = alertProps.onClose;
  const { COMPLETE_DOCUMENTATION_TEXT } = useTranslator();

  const statusDefault: any = {
    info: 0,
    revision: 1,
    'pending-user-approval': 1,
    'request-extrainfo': 1,
    signing: 2,
    'creating-account': 3,
    done: 4,
  };

  const statusPFAEDefault: any = {
    info: 0,
    validating: 1,
    'pending-user-approval': 1,
    signing: 2,
    'creating-account': 3,
    done: 4,
  };

  const statusRiskLevel: any = {
    info: 0,
    revision: 1,
    'request-extrainfo': 1,
    'pending-extrainfo': 2,
    validating: 3,
    'documents-required': 3,
    signing: 4,
    'creating-account': 5,
    done: 6,
  };

  const sideNavStatesDefault: any = {
    info: pfaeStatus.readyToSend || pmStatus.readyToSend ? 'done' : 'pending',
    revision: 'pending',
    'pending-user-approval': 'pending',
    'request-extrainfo': 'pending',
    'pending-extrainfo': 'pending',
    validating: 'pending',
    signing: 'pending',
    'creating-account': 'pending',
    done: 'done',
    'documents-required': 'pending',
  };

  const getActiveStep = () => {
    return showAdditionalInfo
      ? statusRiskLevel[user?.status ? user?.status : 'info']
      : user?.leadType === 'PFAE'
        ? statusPFAEDefault[user?.status ? user?.status : 'info']
        : statusDefault[user?.status ? user?.status : 'info'];
  };

  const active = getActiveStep();
  const pfaeReadyToSend = pfaeStatus.readyToSend;
  const pmReadyToSend = pmStatus.readyToSend;

  useEffect(() => {
    if ((pfaeReadyToSend || pmReadyToSend) && user?.status === 'info')
      openAlert();
    else closeAlert();
  }, [pfaeReadyToSend, pmReadyToSend, openAlert, closeAlert, user?.status]);

  return (
    <GeolocationProvider>

      <FlexGrid container fullWidth fullHeight>
        {user?.status === 'rejected' ? (
          <FlexGrid
            item
            container
            fullHeight
            fullWidth
            justify='center'
            alignItems='center'
          >
            <FlexGrid item>
              <Rejected />
            </FlexGrid>
          </FlexGrid>
        ) : (
          <>
            <SimpleAlertProvider>
              <SimpleAlertProviderSnackBar>
                <FlexGrid item>
                  <SideNav
                    active={active}
                    status={
                      sideNavStatesDefault[user?.status ? user?.status : 'pending']
                    }
                  />
                </FlexGrid>

                <Card
                  fullWidth
                  fullHeight
                  style={{
                    borderColor: theme.colors.manatee,
                    borderRight: 'none',
                    borderBottom: 'none',
                    backgroundColor: 'white',
                    padding: 0,
                  }}
                >
                  <HomeRouter />
                </Card>


                <div style={{ position: 'absolute', width: '100%' }}>
                  <Alert
                    {...alertProps}
                    description={COMPLETE_DOCUMENTATION_TEXT}
                    type='success'
                  />
                </div>

              </SimpleAlertProviderSnackBar>
            </SimpleAlertProvider>
          </>
        )}
      </FlexGrid>
    </GeolocationProvider>

  );
};
