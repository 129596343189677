import {
  Button,
  FlexGrid,
  Image,
  Typography,
  useThemeProvider,
  Link,
  FormItemProps,
  Form,
  useForm,
  Snackbar,
  useSnackbar,
  useDialog,
} from '@albo-ui/core';
import logoPrimarioIndigo from '../../images/Logo_Primario_Indigo.png';
import { useAuth } from '../../providers/auth-provider';
import { useTranslator } from '../../providers/translator-provider';
import { useValidators } from '../../utils/validators';
import { FormItems } from '../form-items';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { OnBoardingLoader } from '../../components-quick-win/on-boarding-loader';
import { useTimers } from '../../providers/use-timer';
import { FiEyeOff, FiEye } from 'react-icons/fi';
import { TemporalBlockDialog } from '../../components-quick-win/temporal-block-dialog';
import { useTwoFactorAuth } from '../../providers/two-factor-auth-provider';
import { TemporalBlockSignContractDialog } from '../../components-quick-win/temporal-block-sign-contract-dialog';
import { useCookieUsePolicyNotification } from '../../providers/cookie-use-policy-notification-provider';
import useLocalStorage from '../../hooks/use-local-storage';

const timerName = 'login';
const EyeSlashIcon = <FiEyeOff size='20px' />;
const EyeIcon = <FiEye size='20px' />;
const temporalBlockTime = 10;

export const LoginForm = () => {
  const formProps = useForm({ email: '', password: '' });
  const { addTimer } = useTimers();
  const history = useHistory();
  const { theme } = useThemeProvider();
  const snackbarProps = useSnackbar();
  const openSnackbar = snackbarProps.openSnackbar;
  const temporalBlockDialogProps = useDialog();
  const temporalBlockSignContractDialogProps = useDialog();
  const validators = useValidators();
  const { login, loading, action, error } = useAuth();
  const { error: smsError, lastBlockDate } = useTwoFactorAuth();
  const [hidePassword, setHidePassword] = useState(true);
  const [showDialog, setShowDialog] = useState(true);
  const { show, cookiesNotAccepted, cookiesAccepted, setCookiesAccepted } =
    useCookieUsePolicyNotification();
  const [container, setContainer] = useLocalStorage('container', undefined);
  const endBlockDate = lastBlockDate ? new Date(lastBlockDate) : new Date();
  endBlockDate.setMinutes(endBlockDate.getMinutes() + temporalBlockTime);
  const {
    ENTER,
    EMAIL,
    FORGET_MY_PASSWORD,
    PASSWORD,
    LOGIN_FORM__TITLE,
    INCORRECT_EMAIL_OR_PASSWORD_TRY_AGAIN,
    UNDERSTOOD,
  } = useTranslator();

  function onPasswordIconClick() {
    setHidePassword(!hidePassword);
  }

  const inputs: FormItemProps<string>[] = [
    {
      placeholder: EMAIL,
      name: 'email',
      type: 'input',
      inputType: 'email',
      pattern: 'password',
      required: true,
      inputSize: '100%',
      validators: [validators.isEmpty, validators.notValidEmail],
    },
    {
      placeholder: PASSWORD,
      name: 'password',
      type: 'input',
      pattern: 'password',
      inputType: hidePassword ? 'password' : 'default',
      required: true,
      inputSize: '100%',
      icon: hidePassword ? EyeSlashIcon : EyeIcon,
      onClickIcon: onPasswordIconClick,
      validators: [validators.isEmpty],
    },
  ];

  async function onClickButton() {
    if (formProps.hasErrors) formProps.displayErrors();
    else {
      await login(formProps.data.email, formProps.data.password);
    }
  }

  function onClickForgetPassword() {
    history.push('reset-password');
  }

  function onCloseDialog() {
    temporalBlockDialogProps.onClose();
    temporalBlockSignContractDialogProps.onClose();
    setShowDialog(!showDialog);
  }

  useEffect(() => {
    if (error === 'USER_NOT_FOUND' || error === 'INVALID_CREDENTIALS')
      openSnackbar();
  }, [error, openSnackbar]);

  useEffect(() => {
    const currentCookiesAcceptedValue = cookiesAccepted;
    const currentContainer = container;
    var endDate = new Date();

    localStorage.clear();
    setCookiesAccepted(currentCookiesAcceptedValue);
    setContainer(currentContainer);
    addTimer(timerName, endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTimer, cookiesAccepted, setCookiesAccepted]);

  const openTemporalBlockDialog = temporalBlockDialogProps.openDialog;
  const openTemporalBlockSignContractDialogProps =
    temporalBlockSignContractDialogProps.openDialog;

  useEffect(() => {
    if (smsError === 'USER_BLOCKED' && action === 'LOGIN' && showDialog) {
      openTemporalBlockDialog();
    }
    if (
      smsError === 'USER_BLOCKED' &&
      action === 'SIGN_CONTRACT' &&
      showDialog
    ) {
      openTemporalBlockSignContractDialogProps();
    }
  }, [
    smsError,
    action,
    openTemporalBlockDialog,
    showDialog,
    openTemporalBlockSignContractDialogProps,
  ]);

  useEffect(() => {
    if (cookiesNotAccepted) show();
  }, [show, cookiesNotAccepted]);

  if (loading) return <OnBoardingLoader isLoading={loading} />;

  return (
    <>
      <TemporalBlockDialog
        open={temporalBlockDialogProps.open}
        onClose={onCloseDialog}
        lastDate={endBlockDate}
      />
      <TemporalBlockSignContractDialog
        open={temporalBlockSignContractDialogProps.open}
        onClose={onCloseDialog}
        lastDate={endBlockDate}
      />
      <Snackbar
        open={snackbarProps.open}
        message={INCORRECT_EMAIL_OR_PASSWORD_TRY_AGAIN}
        action={UNDERSTOOD}
        onClose={snackbarProps.onClose}
      />
      <FlexGrid
        container
        fullHeight
        fullWidth
        direction='column'
        spacing={10}
        alignItems='center'
      >
        <FlexGrid item>
          <Image src={logoPrimarioIndigo} size='163px' />
        </FlexGrid>
        <FlexGrid container spacing={6} direction='column' fullWidth fullHeight>
          <FlexGrid
            item
            style={{ padding: `0 ${theme.spacesPx[2]}`, textAlign: 'center' }}
          >
            <Typography variant='h2' color='indigo'>
              {LOGIN_FORM__TITLE}
            </Typography>
          </FlexGrid>
          <Form {...formProps} onSubmit={onClickButton}>
            <FlexGrid item container direction='column' spacing={3}>
              <FormItems inputs={inputs} />
            </FlexGrid>
          </Form>
          <FlexGrid item container direction='column' spacing={3}>
            <FlexGrid item>
              <Button
                onClick={onClickButton}
                size='fullWidth'
                disabled={cookiesNotAccepted}
              >
                {ENTER}
              </Button>
            </FlexGrid>
            <FlexGrid item style={{ textAlign: 'center' }}>
              <Link typography='smallTitle' onClick={onClickForgetPassword}>
                {FORGET_MY_PASSWORD}
              </Link>
            </FlexGrid>
          </FlexGrid>
        </FlexGrid>
      </FlexGrid>
    </>
  );
};
