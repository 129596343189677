import {
  ActionCard,
  Drawer,
  DrawerTitle,
  FlexGrid,
  Typography,
  DrawerContent,
  DrawerActions,
  Button,
  useThemeProvider,
  Form,
  FormItemProps,
  useDrawer,
} from '@albo-ui/core';
import { useEffect, useState } from 'react';
import { useOnboardingFormShareHolderPM } from '../../hooks/use-onboarding-form-shareholder-pm';
import { useQuery } from '../../hooks/user-query';
import { useAuth } from '../../providers/auth-provider';
import { useTranslator } from '../../providers/translator-provider';
import { useDefaultValidations } from '../../utils/default-validations';
import { useValidators } from '../../utils/validators';
import { FormItems } from '../form-items';
import { AddMoralShareholder } from './add-moral-shareholder';
import { useFormsFields } from '../../providers/onboarding-form-provider';

export type MoralShareholderProps = {
  open: boolean;
  onClose: () => void;
  uuidForm?: string;
};

export const MoralShareholder = ({
  onClose,
  open,
  uuidForm,
}: MoralShareholderProps) => {
  const {
    REQUIRED_FIELD,
    CANCEL,
    MORAL_SHAREHOLDER_SOCIAL_REASON,
    PENDING_INFO,
    ADD_SHAREHOLDER,
    BUSINESS_SOCIAL_REASON,
    ADD_MORAL_SHAREHOLDER,
    ENTER_BUSINESS_SHAREHOLDERS,
    PERCENTAGE_OF_SHARES,
    SAVE,
  } = useTranslator();
  const { theme } = useThemeProvider();
  const defaultValidations = useDefaultValidations();
  const validators = useValidators();
  const [emailUpdate, setEmailUpdate] = useState('');
  const { formFields } = useFormsFields();
  const {
    addShareholder,
    deleteShareholder,
    updateShareholder,
    save,
    formPropsShareholder,
    data,
    updateData,
    setData,
    eventName,
  } = useOnboardingFormShareHolderPM({
    onboardingFormType: 'SHAREHOLDER_PM',
    onboardingFormRef: '',
    emailRef: emailUpdate,
  });
  const addMoralShareholderDrawerProps = useDrawer();
  const {
    leadData: { onboardingForms },
  } = useAuth();

  const totalPercentageUsed =
    data.shareholders.length > 0
      ? data.shareholders
          ?.map(({ percentageShares }: any) => parseInt(percentageShares))
          .reduce((total: any, current: any) => total + current, 0)
      : 0;

  const moralShareholdersDrawer = data.shareholders.map((data: any) => ({
    name: `${data.general.gname} ${data.general.lname} ${data.general.slname}`,
    percentage: data.percentageShares,
    status: 'done',
    email: data.general.email,
  }));

  const shareholderPMuuid = onboardingForms.find(
    ({ formType, uuid }: any) =>
      formType === 'SHAREHOLDER_PM' && uuid === uuidForm
  )?.uuid;

  const [queryFormData] = useQuery(
    {
      name: 'get-onboarding-forms-by-uuids',
      params: {
        onboardingFormsUuids: [shareholderPMuuid],
      },
    },
    [onboardingForms]
  );

  const shareholder: FormItemProps<string>[] =formFields('QUICK_WIN_SHAREHOLDER', [
    {
      placeholder: BUSINESS_SOCIAL_REASON,
      name: 'socialReason',
      type: 'input',
      required: true,
      ...defaultValidations.socialReasonValidations(),
    },

    {
      placeholder: PERCENTAGE_OF_SHARES,
      name: 'percentageShares',
      required: true,
      type: 'input',
      inputType: 'number',
      maxLength: 3,
      saveAs: 'number',
      validators: [validators.isEmpty, validators.notMinimumPercentaje],
    },
  ]);

  function onAddShareholder() {
    addMoralShareholderDrawerProps.openDrawer();
  }

  function onAddShareholderPM() {
    if (formPropsShareholder.hasErrors) {
      formPropsShareholder.displayErrors();
    } else {
      save();
    }
  }

  useEffect(() => {
    if (eventName) onClose();
  }, [eventName, onClose]);

  useEffect(() => {
    if (uuidForm && queryFormData) {
      setData(queryFormData.onboardingForms[0].formData);
    }
  }, [queryFormData, uuidForm, setData]);

  return (
    <>
      <Form {...formPropsShareholder}>
        <Drawer open onClose={onClose} position='right'>
          <DrawerTitle>{ADD_MORAL_SHAREHOLDER}</DrawerTitle>
          <DrawerContent>
            <FlexGrid item container fullWidth spacing={4} direction='column'>
              <FlexGrid item container fullWidth spacing={2} direction='column'>
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    {MORAL_SHAREHOLDER_SOCIAL_REASON}
                  </Typography>
                </FlexGrid>
                <FlexGrid item>
                  <Typography variant='small' color='neutral'>
                    <span style={{ color: theme.colors.required }}>*</span>{' '}
                    {REQUIRED_FIELD}
                  </Typography>
                </FlexGrid>
                <FormItems inputs={shareholder} />
              </FlexGrid>
              <FlexGrid item container fullWidth spacing={4} direction='column'>
                <FlexGrid item>
                  <Typography variant='smallTitle'>
                    {ENTER_BUSINESS_SHAREHOLDERS}
                  </Typography>
                </FlexGrid>
              </FlexGrid>

              <FlexGrid item container direction='column' spacing={2}>
                <FlexGrid item>
                  <ActionCard
                    title={ADD_SHAREHOLDER}
                    onClick={onAddShareholder}
                    link
                    disabled={
                      !formPropsShareholder.data.socialReason ||
                      totalPercentageUsed === 100
                    }
                    style={{ height: '80px' }}
                  />
                </FlexGrid>
                {moralShareholdersDrawer &&
                  moralShareholdersDrawer.map((data: any, index: any) => (
                    <FlexGrid item>
                      <ActionCard
                        key={index}
                        title={data.name}
                        style={{ height: '80px' }}
                        onDelete={() => deleteShareholder(data.email)}
                        onUpdate={() => {
                          setEmailUpdate(data.email);
                          addMoralShareholderDrawerProps.openDrawer();
                        }}
                        status={
                          data.status === 'pending' ? 'pending' : 'default'
                        }
                        description={
                          data.status === 'done'
                            ? `${PERCENTAGE_OF_SHARES}: ${data.percentage}%`
                            : PENDING_INFO
                        }
                      />
                    </FlexGrid>
                  ))}
              </FlexGrid>
            </FlexGrid>
          </DrawerContent>

          <DrawerActions>
            <Button onClick={onClose} buttonType='secondary' size='normal'>
              {CANCEL}
            </Button>
            <Button
              onClick={onAddShareholderPM}
              size='normal'
              style={{ marginLeft: theme.spacesPx[2] }}
              disabled={data.shareholders.length === 0}
            >
              {SAVE}
            </Button>
          </DrawerActions>
        </Drawer>
      </Form>
      {addMoralShareholderDrawerProps.open && (
        <AddMoralShareholder
          open={addMoralShareholderDrawerProps.open}
          onClose={() => {
            addMoralShareholderDrawerProps.onClose();
            setEmailUpdate('');
          }}
          businessName={formPropsShareholder.data.socialReason}
          onSave={updateData ? updateShareholder : addShareholder}
          updateData={updateData}
        />
      )}
    </>
  );
};
