import { ConfirmContract } from '../../../../../../../../components-quick-win/confirm-contract';
import { FormContainer } from '../../../../../../../../components-quick-win/form-container';

export type ContractProps = {
  personType: 'PM' | 'PFAE';
};

export const Contract = ({ personType }: ContractProps) => {
  return (
    <FormContainer>
      <ConfirmContract personType={personType} />
    </FormContainer>
  );
};
