import { FlexGrid } from '@albo-ui/core';
import { Documents } from '../../../../../../components-quick-win/status-process-lib/documents';
import { useAuth } from '../../../../../../providers/auth-provider';
export const Revision = () => {
  const { user, leadData } = useAuth();
  const status = user?.status;

  return (
    <FlexGrid
      container
      alignItems='center'
      justify='center'
      fullHeight
      fullWidth
    >
      <FlexGrid item>
        <Documents status={status ? (status as any) : 'request-extrainfo'}
          interactionStatus={leadData?.interactionStatus ? (leadData?.interactionStatus as any) : ''} />
      </FlexGrid>
    </FlexGrid>
  );
};
