import {
  ActionCard,
  FlexGrid,
  Skeleton,
  Typography,
  useDialog,
  useDrawer,
  useThemeProvider,
} from '@albo-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from '../../hooks/user-query';
import { useApi } from '../../providers/api-provider';
import { useAuth } from '../../providers/auth-provider';
import { useTranslator } from '../../providers/translator-provider';
import { BottomActions } from '../bottom-actions';
import { DeletePromptDialog } from '../delete-prompt-dialog';
import { OnBoardingLoader } from '../on-boarding-loader';
import { AddAdminDrawer } from './add-admin-drawer';

export const CompanyAdminsForm = () => {
  const {
    ADMINS,
    PLATFORM_ADMINS,
    COMPANY_ADMINS_TEXT,
    ADD_ADMIN,
    PENDING_INFO,
    SHORT_LEGAL_REPRESENTATIVE,
    THIS_PERSON_IS_ALSO_LEGAL_REPRESENTATIVE_DESCRIPTION,
    COMPLETE_YOUR_INFO_THIS_CARD_CAN_NOT_BE_DELETED,
    DELETE_ADMIN_DIALOG_DESCRIPTION,
  } = useTranslator();
  const { theme } = useThemeProvider();
  const history = useHistory();
  const [uuidUpdate, setUuidUpdate] = useState<undefined | string>(undefined);
  const [uuidDelete, setUuidDelete] = useState<undefined | string>(undefined);
  const [loading, setLoading] = useState(false);
  const [api] = useApi();
  const deleteDialogProps = useDialog();
  const {
    leadData: { onboardingForms },
  } = useAuth();
  const drawerProps = useDrawer();
  const adminsUuids = onboardingForms
    .filter(({ formType }: any) => formType === 'ADMIN')
    .map(({ uuid }: any) => uuid);

  const legalRepresentativesUuids = onboardingForms
    .filter(({ formType }: any) => formType === 'LEGAL_REPRESENTATIVE')
    .map(({ uuid }: any) => uuid);

  const [adminsData, loadingForm, reloadForm] = useQuery(
    {
      name: 'get-onboarding-forms-by-uuids',
      params: {
        onboardingFormsUuids: adminsUuids,
      },
    },
    [onboardingForms]
  );

  const [legalRepresentativesData, loadingFormLegalRepresentatives] = useQuery(
    {
      name: 'get-onboarding-forms-by-uuids',
      params: {
        onboardingFormsUuids: legalRepresentativesUuids,
      },
    },
    [onboardingForms]
  );

  const adminsLegalRepresentatives =
    adminsData?.onboardingForms && legalRepresentativesData?.onboardingForms
      ? [
          ...adminsData.onboardingForms,
          ...legalRepresentativesData.onboardingForms.filter(
            ({ formData: { isLead } }: any) => !isLead
          ),
        ]
      : [];

  const admins: {
    name: string;
    status: keyof typeof theme.statusColors;
    formType: string;
    isLead: boolean;
    uuid: string;
  }[] = adminsLegalRepresentatives.map((data: any) => ({
    name: `${data.formData.general.gname} ${data.formData.general.lname} ${data.formData.general.slname}`,
    status: data.isCompleted ? 'default' : 'pending',
    formType: data.formType,
    isLead: data.formData.isLead,
    uuid: data.uuid,
  }));

  const onAddAdminClick = () => {
    drawerProps.openDrawer();
    setUuidUpdate(undefined);
  };

  function onDelete(uuid: string) {
    setUuidDelete(uuid);
    deleteDialogProps.openDialog();
  }

  function onUpdate(uuid: string) {
    setUuidUpdate(uuid);
    drawerProps.openDrawer();
  }

  async function deleteAdmin() {
    setLoading(true);
    await api.callService('delete-onboarding-form', {
      uuid: uuidDelete,
    });
    reloadForm();
    deleteDialogProps.onClose();
    setLoading(false);
  }

  function onDialogClose() {
    setUuidDelete(undefined);
    deleteDialogProps.onClose();
  }

  function onContinue() {
    history.push('cards-request-info');
  }

  function onBack() {
    history.push('account-config');
  }

  const hasPendingData = adminsLegalRepresentatives.find(
    ({ status }) => status === 'pending'
  );

  if (loadingForm || loadingFormLegalRepresentatives || loading)
    <OnBoardingLoader
      isLoading={loadingForm || loadingFormLegalRepresentatives || loading}
    />;

  return (
    <>
      <DeletePromptDialog
        open={deleteDialogProps.open}
        onConfirm={deleteAdmin}
        onClose={onDialogClose}
        description={DELETE_ADMIN_DIALOG_DESCRIPTION}
      />

      {drawerProps.open && (
        <AddAdminDrawer {...drawerProps} uuidForm={uuidUpdate} />
      )}
      <FlexGrid
        container
        direction='column'
        spacing={2}
        style={{ marginBottom: '150px' }}
      >
        <FlexGrid item container spacing={1} direction='column'>
          <FlexGrid item>
            <Typography variant='h2'>{PLATFORM_ADMINS}</Typography>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='small'>{COMPANY_ADMINS_TEXT}</Typography>
          </FlexGrid>
        </FlexGrid>
        <FlexGrid item>
          <Typography variant='smallTitle'>{ADMINS}</Typography>
        </FlexGrid>
        <FlexGrid item container direction='column' spacing={2}>
          <FlexGrid item>
            <ActionCard
              title={ADD_ADMIN}
              onClick={onAddAdminClick}
              link
              style={{ height: '80px' }}
            />
          </FlexGrid>
          {admins &&
            admins.map(({ name, status, formType, isLead, uuid }, index) => (
              <FlexGrid item>
                {isLead}
                <ActionCard
                  key={index}
                  title={name}
                  status={status}
                  onDelete={() => onDelete(uuid)}
                  onUpdate={() => onUpdate(uuid)}
                  description={status === 'pending' ? PENDING_INFO : undefined}
                  help={
                    isLead
                      ? {
                          description:
                            COMPLETE_YOUR_INFO_THIS_CARD_CAN_NOT_BE_DELETED,
                        }
                      : formType === 'LEGAL_REPRESENTATIVE'
                      ? {
                          title: SHORT_LEGAL_REPRESENTATIVE,
                          description:
                            THIS_PERSON_IS_ALSO_LEGAL_REPRESENTATIVE_DESCRIPTION,
                        }
                      : undefined
                  }
                  style={{ height: '100px' }}
                />
              </FlexGrid>
            ))}

          {loadingForm && (
            <>
              {adminsUuids.map(() => (
                <Skeleton width='100%' height='100px' />
              ))}
              {legalRepresentativesUuids.map(() => (
                <Skeleton width='100%' height='100px' />
              ))}
            </>
          )}
        </FlexGrid>

        <BottomActions
          backButton
          continueButton
          onContinue={onContinue}
          onBack={onBack}
          disableContinueButton={hasPendingData}
        />
      </FlexGrid>
    </>
  );
};
