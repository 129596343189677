import { FlexGrid, useThemeProvider, Image, Typography } from '@albo-ui/core';
import logoPrimarioBlanco from '../../images/Logo_Primario_Blanco.svg';
import pantallaLaptop from '../../images/Pantalla_Laptop.png';
import { useTranslator } from '../../providers/translator-provider';
import {
  ListItem,
  StyledTypography,
  TextContainer,
  BottomImage,
  BottomGradient,
} from './styled';

export const LandingWelcome = () => {
  const { theme } = useThemeProvider();
  const {
    LANDING_WELCOME_LIST_FIRST_ITEM,
    LANDING_WELCOME_LIST_SECOND_ITEM,
    LANDING_WELCOME_LIST_THIRD_ITEM,
    LANDING_WELCOME_TEXT,
    LANDING_WELCOME_TITLE,
  } = useTranslator();

  return (
    <FlexGrid
      container
      style={{
        backgroundColor: theme.colors.primary,
        position: 'relative',
        overflow: 'hidden',
      }}
      padding={7}
      spacing={8}
      direction='column'
      fullWidth
      fullHeight
    >
      <FlexGrid item>
        <Image src={logoPrimarioBlanco} size='25%' />
      </FlexGrid>
      <FlexGrid item container spacing={4} direction='column'>
        <FlexGrid item>
          <Typography
            variant='h1'
            color='white'
            style={{ lineHeight: theme.spacesPx[6] }}
          >
            {LANDING_WELCOME_TITLE}
          </Typography>
        </FlexGrid>
        <FlexGrid item>
          <TextContainer theme={theme}>{LANDING_WELCOME_TEXT}</TextContainer>
        </FlexGrid>
        <FlexGrid item>
          <ListItem>
            <StyledTypography variant='smallTitle' color='white'>
              {LANDING_WELCOME_LIST_FIRST_ITEM}
            </StyledTypography>
          </ListItem>
          <ListItem>
            <StyledTypography variant='smallTitle' color='white'>
              {LANDING_WELCOME_LIST_SECOND_ITEM}
            </StyledTypography>
          </ListItem>
          <ListItem>
            <StyledTypography variant='smallTitle' color='white'>
              {LANDING_WELCOME_LIST_THIRD_ITEM}
            </StyledTypography>
          </ListItem>
        </FlexGrid>
      </FlexGrid>
      <BottomImage src={pantallaLaptop} size='400px' theme={theme} />
      <BottomGradient theme={theme} />
    </FlexGrid>
  );
};
