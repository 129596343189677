import { Alert } from '@albo-ui/core';

import { ReactNode, useCallback, useState } from 'react';
import { makeProvider } from 'react-provider-maker';

export type SimpleAlertProviderProps = {
  children: ReactNode;
};

export type SimpleAlertType = "success" | "warning" | "error" 
export const {
  Provider: SimpleAlertProvider,
  useProvider: useSimpleAlertNotification,
} = makeProvider(() => {
  const [text, setText] = useState<string | undefined>(undefined);
  const [type, setType] = useState<SimpleAlertType>("success");

  const show = useCallback(
    (text: string,type?:SimpleAlertType) => {
      if(type){
        setType(type)
      }
      setText(text);
      
    },
    [setText]
  );

  const close = useCallback(() => {
    setText(undefined);
  }, [setText]);

 

  return {
    text,
    type,
    close,
    show,
  };
});

export const SimpleAlertProviderSnackBar = ({
  children,
}: SimpleAlertProviderProps) => {
  const { text,type, close } = useSimpleAlertNotification();
  
  return (
    <>
      {children}
      <div style={{ position: 'absolute', width: '100%' }}>
        <Alert  open={text !== undefined}
        onClose={close} description={text || ''} type={type} />
      </div>
      
    </>
  );
};
