import { FlexGrid } from '@albo-ui/core';
import { ContractStatusInfo } from '../../../../../../components-quick-win/status-process-lib/contract';

export const ContractStatus = () => {
  return (
    <FlexGrid
      container
      alignItems='center'
      justify='center'
      fullHeight
      fullWidth
    >
      <FlexGrid item>
        <ContractStatusInfo status='pending' />
      </FlexGrid>
    </FlexGrid>
  );
};
