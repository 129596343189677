import { Snackbar } from '@albo-ui/core';

import { ReactNode, useCallback, useState } from 'react';
import { makeProvider } from 'react-provider-maker';
import { useHistory } from 'react-router';
import { useTranslator } from '../translator-provider';

export type SimpleNotificationProviderSnackBarProps = {
  children: ReactNode;
};

export const {
  Provider: SimpleNotificationProvider,
  useProvider: useSimpleNotification,
} = makeProvider(() => {
  const history = useHistory()
  const [text, setText] = useState<string | undefined>(undefined);

  const show = useCallback(
    (text: string) => {
      setText(text);
    },
    [setText]
  );

  const close = useCallback(() => {
    setText(undefined);
  }, [setText]);

  // Cleans up text if url changes
  history.listen(() => {
    close()
  })

  return {
    text,
    close,
    show,
  };
});

export const SimpleNotificationProviderSnackBar = ({
  children,
}: SimpleNotificationProviderSnackBarProps) => {
  const { text, close } = useSimpleNotification();
  const { UNDERSTOOD } = useTranslator();

  return (
    <>
      {children}
      <Snackbar
        open={text !== undefined}
        onClose={close}
        message={text || ''}
        action={UNDERSTOOD}
      />
    </>
  );
};
