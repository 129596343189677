import { useCallback, useState } from 'react';
import { makeProvider } from 'react-provider-maker';
import { useApi } from '../api-provider';
export type SignUpFromMoreInfoParams = {
  email: string;
  password: string;
};

export const {
  Provider: SignUpFromMoreInfoProvider,
  useProvider: useSignUpFromMoreInfo,
} = makeProvider(() => {
  const [api] = useApi();
  const [state, setState] = useState<{
    loading?: boolean | undefined;
    isSignedUp?: boolean;
    isEmailTokenValidated?: boolean;
  }>({});

  const signUpFromMoreInfo = useCallback(
    async (signUpFromMoreInfoParams: SignUpFromMoreInfoParams) => {
      setState({ loading: true });
      const signUpFromMoreInfoResult = await api.callService(
        'sign-up-from-more-info',
        signUpFromMoreInfoParams
      );
      if (signUpFromMoreInfoResult.eventName === 'UserSignedUp') {
        setState({ isSignedUp: true, loading: false });
      }
      setState({ loading: false });
    },
    [api, setState]
  );

  return {
    ...state,
    signUpFromMoreInfo,
    api,
  };
});
