import {
  Button,
  DefaultComponentProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FlexGrid,
  Link,
  Typography,
} from '@albo-ui/core';
import { getTimeLeft } from '../../providers/use-timer/helper';
import { useTranslator } from '../../providers/translator-provider';

export type TemporalBlockSignContractDialogProps = {
  onClose: () => void;
  open: boolean;
  lastDate: Date;
} & DefaultComponentProps;

export const TemporalBlockSignContractDialog = ({
  onClose,
  open,
  className,
  style,
  lastDate,
}: TemporalBlockSignContractDialogProps) => {
  const {
    ACCEPT,
    TEMPORAL_BLOCK,
    SIGN_CONTRACT_TEMPORAL_BLOCK_DESCRIPTION_FIRST_PART,
    TEMPORAL_BLOCK_INFO_DESCRIPTION_SECOND_PART,
    BUSINESS_HELP_EMAIL,
    IF_THE_PROBLEM_PERSISTS_CONTACT_US_IN,
  } = useTranslator();

  const minutesLeft = getTimeLeft(lastDate).minutesLeft;
  const minutesText = minutesLeft === 1 ? 'minuto' : 'minutos';

  return (
    <Dialog className={className} style={style} onClose={onClose} open={open}>
      <DialogTitle>{TEMPORAL_BLOCK}</DialogTitle>
      <DialogContent>
        <FlexGrid container direction='column' spacing={3}>
          <FlexGrid item>
            <Typography variant='paragraph'>
              {SIGN_CONTRACT_TEMPORAL_BLOCK_DESCRIPTION_FIRST_PART}{' '}
              <span className='f-bold'>
                {minutesLeft < 1 ? 1 : minutesLeft} {minutesText}
              </span>{' '}
              {TEMPORAL_BLOCK_INFO_DESCRIPTION_SECOND_PART}{' '}
            </Typography>
          </FlexGrid>
          <FlexGrid item container direction='column' spacing={1}>
            <FlexGrid item>{IF_THE_PROBLEM_PERSISTS_CONTACT_US_IN}</FlexGrid>
            <FlexGrid item>
              <Link underlined href={`mailto:${BUSINESS_HELP_EMAIL}`}>
                {BUSINESS_HELP_EMAIL}
              </Link>
            </FlexGrid>
          </FlexGrid>
        </FlexGrid>
      </DialogContent>
      <DialogActions>
        <FlexGrid
          container
          fullHeight
          fullWidth
          justify='space-between'
          alignItems='flex-end'
          style={{ marginTop: '20px' }}
        >
          <FlexGrid item style={{ marginLeft: 'auto' }}>
            <Button onClick={onClose} size='normal'>
              {ACCEPT}
            </Button>
          </FlexGrid>
        </FlexGrid>
      </DialogActions>
    </Dialog>
  );
};
