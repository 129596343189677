import { Logo } from '../../../components-quick-win/logo';
import { Typography, FlexGrid } from '@albo-ui/core';
import { memo } from 'react';

export const LeftSide = memo(() => {
  return (
    <FlexGrid spacing={2} container justify='center' alignItems='center'>
      <FlexGrid item>
        <Logo />
      </FlexGrid>
      <FlexGrid item>
        <Typography variant='smallTitle'>Perfil de la empresa</Typography>
      </FlexGrid>
    </FlexGrid>
  );
});
