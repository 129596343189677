import { Layout } from './layout';
import { OnboardingRouter } from './router';

export const Onboarding = () => {
  return (
    <Layout>
      <OnboardingRouter />
    </Layout>
  );
};
