import { mainCountryStr } from '../../const';

export const countryDefault = {
    country: mainCountryStr,
    countryDesc: 'Mexico',
}

export const fromDefault = {
    hasForeignClient: false,
    hasPointOfSale: false,
    mainClients: [],
    mainProviders: [],
    hasSharesInOtherCompany: false,
    isPartOfGroupCompany: false,
}

export const groupCompanyInfoForm = {
    ...countryDefault,
    denomination: '',
    socialObject: '',
    participationPercentage: 0,
}

export const groupCompanyAddressForm = {
    ...countryDefault,
    extnum: '',
    locality: '',
    state: '',
    street: '',
    town: '',
    zip: ''
}