import {
  Button,
  DefaultComponentProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FlexGrid,
  Link,
  Typography,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';

export type MultisessionDialogProps = {
  onClose: () => void;
  open: boolean;
} & DefaultComponentProps;

export const MultisessionDialog = ({
  onClose,
  open,
  className,
  style,
}: MultisessionDialogProps) => {
  const {
    ACCEPT,
    MULTISESSION_DIALOG_TITLE,
    MULTISESSION_DIALOG_DESCRIPTION_1,
    MULTISESSION_DIALOG_DESCRIPTION_2,
    HELP_EMAIL
  } = useTranslator();

  return (
    <Dialog className={className} style={style} onClose={onClose} open={open}>
      <DialogTitle>{MULTISESSION_DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <FlexGrid container direction='column' spacing={3}>
          <FlexGrid item container direction='column' spacing={1}>
            <FlexGrid item>
            <Typography variant='paragraph'>
                  {MULTISESSION_DIALOG_DESCRIPTION_1}
                  {" "}
                  <Link underlined href={`mailto:${HELP_EMAIL}`}>
                        {HELP_EMAIL}
                  </Link>
                  {" "}
                  {MULTISESSION_DIALOG_DESCRIPTION_2}
            </Typography>
            </FlexGrid>
          </FlexGrid>
        </FlexGrid>
      </DialogContent>
      <DialogActions>
        <FlexGrid
          container
          fullHeight
          fullWidth
          justify='space-between'
          alignItems='flex-end'
          style={{ marginTop: '20px' }}
        >
          <FlexGrid item style={{ marginLeft: 'auto' }}>
            <Button onClick={onClose} size='normal'>
              {ACCEPT}
            </Button>
          </FlexGrid>
        </FlexGrid>
      </DialogActions>
    </Dialog>
  );
};
