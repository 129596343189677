
export const decodeParamsFromUrl = () => {
    const paramsBase64 = window.location.search.split('p=')[1];
    if (paramsBase64) {
        try {
            const paramsDecoded = atob(paramsBase64);
            const params = JSON.parse(paramsDecoded);
            return params;
        } catch (error) {
            console.log('error decode params', error);
        }
    }
    return undefined;
}
