import {
  Button,
  FlexGrid,
  RoundedIcon,
  Typography,
  useThemeProvider,
  Image,
} from '@albo-ui/core';
import { FaUnlink } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { useTranslator } from '../../providers/translator-provider';
import logoPrimarioIndigo from '../../images/Logo_Primario_Indigo.png';

export type LinkExpiredProps = {
  defaultLink?: boolean;
  description?: string;
};

export const LinkExpired = ({ defaultLink, description }: LinkExpiredProps) => {
  const { theme } = useThemeProvider();
  const history = useHistory();
  const {
    LINK_EXPIRED,
    MAKE_YOUR_REGISTRATION_AGAIN_TO_RECEIVE_VERIFICATION_EMAIL,
    GO_TO_SIGN_UP,
  } = useTranslator();

  function onClick() {
    history.push('/sign-up');
  }

  return (
    <FlexGrid
      container
      fullWidth
      fullHeight
      direction='column'
      spacing={6}
      alignItems='center'
      justify='center'
      style={{ height: '100vh' }}
    >
      <FlexGrid item>
        <Image src={logoPrimarioIndigo} size='163px' />
      </FlexGrid>
      <FlexGrid item>
        <RoundedIcon
          icon={<FaUnlink size='30px' color={theme.colors.primary} />}
          size='big'
          color='iconLightGrayBackground'
        />
      </FlexGrid>
      <FlexGrid
        item
        container
        direction='column'
        spacing={2}
        style={{ textAlign: 'center' }}
      >
        <FlexGrid item>
          <Typography variant='h2' color='charcoal'>
            {LINK_EXPIRED}
          </Typography>
        </FlexGrid>
        {!defaultLink && (
          <FlexGrid item style={{ textAlign: 'center' }}>
            <Typography variant='paragraph' color='charcoal'>
              {MAKE_YOUR_REGISTRATION_AGAIN_TO_RECEIVE_VERIFICATION_EMAIL}
            </Typography>
          </FlexGrid>
        )}

        {description && (
          <FlexGrid item style={{ textAlign: 'center', width: '450px' }}>
            <Typography variant='paragraph' color='charcoal'>
              {description}
            </Typography>
          </FlexGrid>
        )}
      </FlexGrid>

      {!defaultLink && (
        <FlexGrid item>
          <Button onClick={onClick} style={{ width: '380px' }}>
            {GO_TO_SIGN_UP}
          </Button>
        </FlexGrid>
      )}
    </FlexGrid>
  );
};
