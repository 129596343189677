import {
  Button,
  DefaultComponentProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FlexGrid,
  Typography,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';

export type DeletePromptDialogProps = {
  onConfirm: () => void;
  onClose: () => void;
  open: boolean;
  title?: string;
  description?: string;
} & DefaultComponentProps;

export const DeletePromptDialog = ({
  onConfirm,
  onClose,
  open,
  className,
  style,
  description,
  title,
}: DeletePromptDialogProps) => {
  const {
    DELETE_PROMPT_DIALOG_TEXT,
    CANCEL,
    DELETE,
    DELETE_PROMPT_DIALOG_TITLE,
  } = useTranslator();

  return (
    <Dialog className={className} style={style} onClose={onClose} open={open}>
      <DialogTitle>{title ? title : DELETE_PROMPT_DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <Typography variant='paragraph'>
          {description ? description : DELETE_PROMPT_DIALOG_TEXT}
        </Typography>
      </DialogContent>
      <DialogActions>
        <FlexGrid
          container
          fullHeight
          fullWidth
          justify='space-between'
          alignItems='flex-end'
          style={{ marginTop: '20px' }}
        >
          <FlexGrid item>
            <Button onClick={onClose} buttonType='secondary' size='normal'>
              {CANCEL}
            </Button>
          </FlexGrid>
          <FlexGrid item>
            <Button
              onClick={onConfirm}
              size='normal'
              buttonType='warning'
              color='sunset'
            >
              {DELETE}
            </Button>
          </FlexGrid>
        </FlexGrid>
      </DialogActions>
    </Dialog>
  );
};
