import {
  Form,
  Drawer,
  FlexGrid,
  DrawerTitle,
  DrawerDescription,
  Typography,
  DrawerContent,
  DrawerActions,
  useThemeProvider,
  FormItemProps,
  CheckBox,
  Button,
  RadialButton,
  useFormCheckBox,
} from '@albo-ui/core';
import { useEffect, useMemo } from 'react';
import { FormItems } from '../../components-quick-win/form-items';
import { useTranslator } from '../../providers/translator-provider';
import { useDefaultValidations } from '../../utils/default-validations';
import { useOnboardingForm } from '../../hooks/use-onboarding-form';
import { OnBoardingLoader } from '../on-boarding-loader';
import { useValidators } from '../../utils/validators';
import { useAuth } from '../../providers/auth-provider';
import { useConfirm } from '../../components/confirm-dialog';
import { useUserRole } from '../../hooks/use-user-role';
import { useFormsFields } from '../../providers/onboarding-form-provider';

export type AddLegalRepresentativeDrawerProps = {
  uuidForm: string | undefined;
  open: boolean;
  onClose: () => void;
  position: 'left' | 'right';
  reloadForm: () => Promise<void>;
  hasLead?: boolean;
  openSnackbar?: (eventName: string) => void;
};

export const AddLegalRepresentativeDrawer = ({
  uuidForm,
  open,
  position,
  onClose,
  reloadForm,
  hasLead,
  openSnackbar,
}: AddLegalRepresentativeDrawerProps) => {
  const { theme } = useThemeProvider();
  const defaultValidations = useDefaultValidations();
  const validators = useValidators();
  const { reloadProfile, reloadingProfile } = useAuth();
  const formProps = useOnboardingForm({
    defaultParams: {
      isLead: false,
      isPrincipal: false,
      general: {
        phone: {
          phoneCode: '+52', phoneCountry: '484',
          phoneCodeDesc: 'Mexico',
        },
      },
      identificationFile: {
        type: 'ine',
      },
    },
    onboardingFormType: 'QUICK_WIN_LEGAL_REPRESENTATIVE',
    singleton: false,
    onboardingFormRef: uuidForm,
  });
  const setErrors = formProps.form.setErrors;
  const myInfoCheckBox = useFormCheckBox('isLead', formProps.form);
  const { leadData } = useAuth();
  const { movingForward, eventName } = formProps;
  const { setPropByName } = formProps.form;
  const setFormData = formProps.form.setData;
  const onFormInput = formProps.form.onInput;
  const checkBoxValue = myInfoCheckBox.value;
  const saveOnboardingFormEvent = formProps.eventName;
  const rfc = formProps.form.data?.general?.taxid

  const {
    LEGAL_REPRESENTATIVE,
    REQUIRED_FIELD,
    NAME,
    PATERNAL_LASTNAME,
    MATERNAL_LASTNAME,
    EMAIL,
    LEGAL_REPRESENTATIVE_DESCRIPTION_DRAWER,
    RFC_WITH_HOMOCLAVE,
    CANCEL,
    SAVE,
    WHAT_ID_WILL_YOU_USE,
    INE,
    PASSPORT,
    OFICIAL_IDENTIFICATION_FRONT,
    UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
    OFICIAL_IDENTIFICATION_BACK,
    I_AM_LEGAL_REPRESENTATIVE,
    SELFIE_OF_YOUR_FACE,
    SELFIE_OF_YOUR_FACE_HELP_DESCRIPTION,
    POWER_OF_ATTORNEY,
    POWER_OF_ATTORNEY_HELP_DESCRIPTION,
    UPDATE,
    TITLE_UPDATE_CUSTOMER_INFO,
    DESCRIPTION_UPDATE_CUSTOMER_INFO,
    BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO,
  } = useTranslator();

  const { formFields, findFields } = useFormsFields();
  const { DialogConfirm, confirm: showConfirm } = useConfirm({
    title: TITLE_UPDATE_CUSTOMER_INFO,
    message: DESCRIPTION_UPDATE_CUSTOMER_INFO,
    textConfirm: BUTTON_CONFIRM_UPDATE_CUSTOMER_INFO
  });

  const handleClickSave = async () => {
    const confirm = await showConfirm();
    if (!confirm) {
      return;
    }
    formProps.saveFormOps();
  }

  const { isOpsAnalyst } = useUserRole();

  useEffect(() => {
    findFields('QUICK_WIN_LEGAL_REPRESENTATIVE')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const identificationFileType = formProps.form.data.identificationFile.type;

  const oficialDocuments = useMemo(
    () => [
      {
        name: 'ine',
        text: INE,
        value: identificationFileType === 'ine',
      },
      {
        name: 'passport',
        text: PASSPORT,
        value: identificationFileType === 'passport',
      },
    ],
    [identificationFileType, PASSPORT, INE]
  );

  const oficialDocumentIne: FormItemProps<string>[] = formFields('QUICK_WIN_LEGAL_REPRESENTATIVE', [
    {
      placeholder: OFICIAL_IDENTIFICATION_FRONT,
      name: 'identificationFile.data.front',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
      help: {
        description: UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: OFICIAL_IDENTIFICATION_BACK,
      name: 'identificationFile.data.back',
      required: true,
      type: 'fileInput',
      validators: [validators.isEmpty],
    },
  ]);

  const oficialDocumentPassport: FormItemProps<string>[] = formFields('QUICK_WIN_LEGAL_REPRESENTATIVE', [
    {
      placeholder: OFICIAL_IDENTIFICATION_FRONT,
      name: 'identificationFile.data.front',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
      help: {
        description: UPLOAD_OFICIAL_IDENTIFICATION_HELP_DESCRIPTION,
      },
    },
  ]);

  const inputs: FormItemProps<string>[] = formFields('QUICK_WIN_LEGAL_REPRESENTATIVE', [
    {
      placeholder: NAME,
      name: 'general.gname',
      type: 'input',
      required: true,
      disabled: myInfoCheckBox.value,
      ...defaultValidations.nameValidations(),
    },
    {
      placeholder: PATERNAL_LASTNAME,
      name: 'general.lname',
      type: 'input',
      required: true,
      disabled: myInfoCheckBox.value,
      ...defaultValidations.paternalLastNameValidations(),
    },
    {
      placeholder: MATERNAL_LASTNAME,
      name: 'general.slname',
      type: 'input',
      disabled: myInfoCheckBox.value,
      ...defaultValidations.maternalLastNameValidations(),
    },
    {
      placeholder: RFC_WITH_HOMOCLAVE,
      name: 'general.taxid',
      type: 'input',
      toUpperCase: true,
      inputType: 'alphanumeric',
      maxLength: 13,
      validators:
        rfc?.length > 0
          ? [
            validators.notAlphanumeric,
            (value: string) => validators.doNotHaveMinLength(value, 12),
            validators.notValidRfcFormat,
          ]
          : [],
    },
    {
      name: 'general.phone',
      type: 'phone',
      required: true,
      disabled: myInfoCheckBox.value,
      ...defaultValidations.phoneNumberValidations(),
    },
    {
      placeholder: EMAIL,
      name: 'general.email',
      type: 'input',
      required: true,
      disabled: myInfoCheckBox.value,
      ...defaultValidations.emailValidations(),
    },
  ]);

  const selfieAndPowerOfAttorney: FormItemProps<string>[] = formFields('QUICK_WIN_LEGAL_REPRESENTATIVE', [
    {
      placeholder: SELFIE_OF_YOUR_FACE,
      name: 'selfie',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
      help: {
        description: SELFIE_OF_YOUR_FACE_HELP_DESCRIPTION,
      },
    },
    {
      placeholder: POWER_OF_ATTORNEY,
      name: 'powerOfNotaryFile',
      type: 'fileInput',
      required: false,
      fileExtensions: ['.pdf'],
      validators: [validators.isEmpty],
      help: {
        description: POWER_OF_ATTORNEY_HELP_DESCRIPTION,
      },
    },
  ]);

  function onDrawerAddButtonClick() {
    if (isOpsAnalyst) {
      handleClickSave();
    } else {
      formProps.moveForward();
    }
  }

  function onSelectOficialDocument(selectedIndex: number) {
    setPropByName('identificationFile', {
      data: undefined,
      type: selectedIndex === 0 ? 'ine' : 'passport',
    });
  }

  useEffect(() => {
    if (saveOnboardingFormEvent) {
      onClose();
      openSnackbar && openSnackbar(saveOnboardingFormEvent);
    }
  }, [saveOnboardingFormEvent, onClose, openSnackbar]);

  useEffect(() => {
    if (!movingForward && eventName) {
      reloadProfile();
      reloadForm();
      onClose();
    }
  }, [movingForward, eventName, onClose, reloadForm, reloadProfile]);

  useEffect(() => {
    if (checkBoxValue) {
      onFormInput((current: any) => ({
        ...current,
        general: {
          ...current.general,
          ...leadData.general,
        },
      }));
    } else {
      setFormData((currentData: any) => ({
        ...currentData,
        general: { phone: { phoneCode: '+52', phoneCountry: '484', phoneCodeDesc: 'Mexico' } },
      }));
    }
  }, [checkBoxValue, onFormInput, setFormData, leadData]);

  useEffect(() => {
    if (identificationFileType !== 'ine') {
      setErrors((currentErrors: any) => ({
        ...currentErrors,
        'identificationFile.data.back': undefined,
      }));
    }
  }, [identificationFileType, setErrors]);

  if (
    formProps.loadingForm ||
    formProps.movingForward ||
    formProps.saving ||
    reloadingProfile
  )
    return (
      <OnBoardingLoader
        isLoading={
          formProps.loadingForm ||
          formProps.movingForward ||
          formProps.saving ||
          reloadingProfile
        }
      />
    );

  return (
    <Form {...formProps.form} onSubmit={onDrawerAddButtonClick}>
      <Drawer open={open} position={position} onClose={onClose}>
        <DrawerTitle>{LEGAL_REPRESENTATIVE} </DrawerTitle>
        <DrawerDescription>
          <FlexGrid container fullWidth direction='column' spacing={2}>
            <FlexGrid item>{LEGAL_REPRESENTATIVE_DESCRIPTION_DRAWER}</FlexGrid>

            {!hasLead && (
              <FlexGrid item>
                <CheckBox {...myInfoCheckBox} text={I_AM_LEGAL_REPRESENTATIVE} />
              </FlexGrid>
            )}

            <FlexGrid item>
              <Typography variant='small' color='neutral'>
                <span style={{ color: theme.colors.required }}>*</span>{' '}
                {REQUIRED_FIELD}
              </Typography>
            </FlexGrid>
          </FlexGrid>
        </DrawerDescription>
        <DrawerContent>
          <FlexGrid
            item
            container
            fullWidth
            spacing={9}
            direction='column'
            style={{ marginBottom: '150px' }}
          >
            <FlexGrid item container fullWidth spacing={4} direction='column'>
              <FormItems inputs={inputs} />
            </FlexGrid>

            <FlexGrid item container fullWidth spacing={4} direction='column'>
              <FlexGrid item>
                <Typography variant='smallTitle'>
                  {WHAT_ID_WILL_YOU_USE}
                </Typography>
              </FlexGrid>
              <FlexGrid item container direction='row' spacing={2}>
                {oficialDocuments.map(({ value, text }, index) => (
                  <FlexGrid item>
                    <RadialButton
                      value={value}
                      onInput={() => onSelectOficialDocument(index)}
                      text={text}
                    />
                  </FlexGrid>
                ))}
              </FlexGrid>
              <FlexGrid item>
                <FormItems
                  inputs={
                    identificationFileType === 'ine'
                      ? oficialDocumentIne
                      : oficialDocumentPassport
                  }
                />
              </FlexGrid>
              <FlexGrid item>
                <FormItems inputs={selfieAndPowerOfAttorney} />
              </FlexGrid>
            </FlexGrid>
          </FlexGrid>
        </DrawerContent>

        <DialogConfirm />

        <DrawerActions>
          <Button onClick={onClose} buttonType='secondary' size='normal'>
            {CANCEL}
          </Button>
          <Button
            onClick={onDrawerAddButtonClick}
            size='normal'
            style={{ marginLeft: theme.spacesPx[2] }}
          >
            {isOpsAnalyst ? UPDATE : SAVE}
          </Button>
        </DrawerActions>
      </Drawer>
    </Form>
  );
};
