import { useEffect, useRef } from 'react';
import { useApi } from '../providers/api-provider';
import useLocalStorage from './use-local-storage';
export type CatalogType =
  | 'Antiquities'
  | 'Relationships'
  | 'Countries'
  | 'PhoneCodes'
  | 'ShareholderActivities'
  | 'EconomicActivities'
  | 'NumberOfEmployees'
  | 'SourceOfAccountResources'
  | 'ResourcesDestinations'
  | 'AdminActivities'
  | 'AdminAreas'
  | 'AdminRoles'
  | 'PFAEActivities'
  | 'Genders'
  | 'States'
  | 'AccountTransactionalities'
  | 'zip'
  | 'EntityTypes'
  | 'AccountPurposes'
  | 'SourceOfAccountResources'
  | 'ResourcesDestinations'
  | 'TransferAmounts'
  | 'TransferCounterparties'
  | 'TransferFrequencies'
  | 'CompanyRelationships'
  | 'ResidenceLocations';

const CACHE_EXPIRATION = 1.728e8;

function timeSince(sinceDate: number) {
  return sinceDate - Date.now();
}

const catalogsLoading: any = {};
export type UseCatalogOptions = {
  delay?: number;
  disable?: boolean;
};
export const useCatalog = (
  name: CatalogType,
  key?: string,
  catalogOptions: UseCatalogOptions = {}
) => {
  const { delay, disable } = catalogOptions;
  const [api] = useApi();
  const [catalogData, setCatalogData] = useLocalStorage(`catalogs_${name}`, {
    lastCacheUpdateTime: 0,
    value: [],
  });
  const timeOutRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    (async () => {
      if (
        (!catalogData.lastCacheUpdateTime ||
          timeSince(catalogData.lastCacheUpdateTime) > CACHE_EXPIRATION) &&
        !catalogsLoading[name]
      ) {
        catalogsLoading[name] = true;

        const loadCatalog = async () => {
          const catalogResult = await api.callQuery('get-catalog-by-name', {
            catalogName: name,
            key,
          });
          const catalog = catalogResult.data.catalogItems;
          setCatalogData({
            lastCacheUpdateTime: Date.now(),
            value: catalog,
          });
          catalogsLoading[name] = false;
        };

        if (!disable) {
          if (delay) {
            clearTimeout(timeOutRef.current as any);
            timeOutRef.current = setTimeout(loadCatalog, delay);
          } else {
            await loadCatalog();
          }
        }
      }
    })();
  }, [
    api,
    catalogData.lastCacheUpdateTime,
    name,
    setCatalogData,
    key,
    disable,
    delay,
  ]);

  return [[...(catalogData.value ? catalogData.value : ([] as any[]))]];
};
