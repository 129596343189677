import { useCallback, useEffect, useReducer } from 'react';
import { useApi } from '../../providers/api-provider';
import { useQueryReducer } from './reducer';

export type QueryOptions = {
  manualLoad: boolean;
};

export type QueryResultTuple = [any, boolean, () => Promise<void>];

export type QueryParams = { name: string; params: any; options?: QueryOptions };

export const useQuery = (
  { name, params, options }: QueryParams,
  deps: any[]
): QueryResultTuple => {
  const [api] = useApi();
  const [state, dispatch] = useReducer(useQueryReducer, {
    loading: false,
    data: undefined,
  });

  const load = useCallback(async () => {
    dispatch({ type: 'LOAD' });
    const result = await api.callQuery(name, params);
    dispatch({ type: 'SUCCESS', payload: result.data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, dispatch]);

  useEffect(() => {
    if (!options?.manualLoad) {
      load();
    }
  }, [options?.manualLoad, load]);

  return [state.data, state.loading, load];
};
