export function setImmutablePropByString(
  propName: string,
  obj: any,
  value: any
) {
  const nestedProperties = propName.split('.');
  let currentProp = obj;
  for (let i = 0; i < nestedProperties.length; i++) {
    const propName = nestedProperties[i];
    const isLast = i === nestedProperties.length - 1;
    const nextValue = isLast ? value : { ...currentProp[propName] };
    currentProp[propName] = nextValue;
    currentProp = currentProp[propName];
  }
  return { ...obj };
}

export function getPropByString(propName: string, obj: any) {
  const nestedProperties = propName.split('.');
  let currentProp = obj;
  for (let i = 0; i < nestedProperties.length; i++) {
    const propName = nestedProperties[i];
    const isLast = i === nestedProperties.length - 1;
    if (isLast) {
      return currentProp ? currentProp[propName] : undefined;
    }

    currentProp = currentProp ? currentProp[propName] : undefined;
  }
}
