import {
  Button,
  DefaultComponentProps,
  Dialog,
  DialogContent,
  DialogTitle,
  FlexGrid,
  Link,
  Typography,
  Image,
  useThemeProvider,
} from '@albo-ui/core';
import corporateCard from '../../images/Corporate_Card.png';
import { useTranslator } from '../../providers/translator-provider';
import { FaCheck } from 'react-icons/fa';
import ReactLoading from 'react-loading';

export type CorporateCardDialogProps = {
  onConfirm: () => void;
  onClose: () => void;
  open: boolean;
  showSuccess?: boolean;
  isLoading?: boolean;
} & DefaultComponentProps;

export const CorporateCardDialog = ({
  onConfirm,
  onClose,
  open,
  className,
  style,
  showSuccess,
  isLoading,
}: CorporateCardDialogProps) => {
  const { theme } = useThemeProvider();
  const {
    CORPORATE_CARD,
    ADD_ME_TO_WAIT_LIST,
    JOIN_WAIT_LIST,
    NEXT_TIME,
    EXIT,
    YOU_HAVE_BEEN_ADDED_TO_WAIT_LIST,
  } = useTranslator();
  return (
    <Dialog
      className={className}
      style={{ height: '644px', ...style }}
      onClose={onClose}
      open={open}
    >
      <DialogTitle style={{ width: '100%', textAlign: 'center' }}>
        <Typography variant='h2' color='primary'>
          {CORPORATE_CARD}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FlexGrid
          container
          fullHeight
          fullWidth
          direction='column'
          spacing={5}
          alignItems='center'
          justify='center'
        >
          <FlexGrid item>
            <Image src={corporateCard} size='207px' />
          </FlexGrid>
          {isLoading && (
            <FlexGrid
              item
              container
              fullWidth
              fullHeight
              alignItems='center'
              justify='center'
            >
              <ReactLoading
                type='spin'
                color={theme.colors.primary}
                height='50px'
                width='50px'
              />
            </FlexGrid>
          )}
          {isLoading ? null : showSuccess ? (
            <>
              <FlexGrid
                item
                container
                direction='column'
                spacing={2}
                justify='center'
                alignItems='center'
              >
                <FlexGrid item>
                  <FaCheck color={theme.colors.oliva} size='20px' />
                </FlexGrid>
                <FlexGrid
                  item
                  style={{ maxWidth: '342px', textAlign: 'center' }}
                >
                  <Typography variant='paragraph' bold>
                    {YOU_HAVE_BEEN_ADDED_TO_WAIT_LIST}
                  </Typography>
                </FlexGrid>
              </FlexGrid>

              <FlexGrid item>
                <Button
                  onClick={onClose}
                  buttonType='secondary'
                  size='large'
                  style={{ backgroundColor: 'white' }}
                >
                  <Typography variant='smallTitle' color='primary'>
                    {EXIT}
                  </Typography>
                </Button>
              </FlexGrid>
            </>
          ) : (
            <>
              <FlexGrid item style={{ maxWidth: '280px', textAlign: 'center' }}>
                <Typography variant='paragraph' bold>
                  {JOIN_WAIT_LIST}
                </Typography>
              </FlexGrid>
              <FlexGrid item>
                <Button
                  onClick={onConfirm}
                  buttonType='secondary'
                  size='large'
                  style={{ backgroundColor: 'white' }}
                >
                  <Typography variant='smallTitle' color='primary'>
                    {ADD_ME_TO_WAIT_LIST}
                  </Typography>
                </Button>
              </FlexGrid>
              <FlexGrid item>
                <Link underlined bold onClick={onClose}>
                  {NEXT_TIME}
                </Link>
              </FlexGrid>
            </>
          )}
        </FlexGrid>
      </DialogContent>
    </Dialog>
  );
};
