import { FlexGrid } from '@albo-ui/core';
import { SignUpFromMoreInfoForm } from '../../components-quick-win/sign-up-from-more-info-form';

export const SignUpFromMoreInfo = () => {
  return (
    <FlexGrid
      fullWidth
      fullHeight
      container
      padding={8}
      justify='center'
      style={{ height: '100vh', backgroundColor: 'white' }}
    >
      <FlexGrid item>
        <SignUpFromMoreInfoForm />
      </FlexGrid>
    </FlexGrid>
  );
};
