import { useTranslator } from '../../providers/translator-provider';
import {
  FlexGrid,
  Typography,
  useThemeProvider,
  Image,
  Button,
  Link,
  useDialog,
} from '@albo-ui/core';
import { Point, Line } from './styled';
import tarjetasAlbo from '../../images/Tarjetas_Albo.png';
import { useHistory } from 'react-router';
import { BottomActions } from '../bottom-actions';
import { CorporateCardDialog } from '../corporate-card-dialog';
import { useApi } from '../../providers/api-provider';
import { useAuth } from '../../providers/auth-provider';
import { useState } from 'react';
import { useOnHomeCBS } from '../../providers/on-home-cbs-provider';

export const CardRequestInfo = () => {
  const { theme } = useThemeProvider();
  const history = useHistory();
  const dialogProps = useDialog();
  const [api] = useApi();
  const { reloadProfile } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const homeCBS = useOnHomeCBS();
  homeCBS.setShowDialog(false);
  const {
    CARD_REQUEST_INFO_DESCRIPTION_BOLD_TEXT,
    CARD_REQUEST_INFO_DESCRIPTION_FIRST_PART,
    CARD_REQUEST_INFO_DESCRIPTION_SECOND_PART,
    CARD_REQUEST_INFO_LIST_FIRST_ITEM,
    CARD_REQUEST_INFO_LIST_SECOND_ITEM,
    CARD_REQUEST_INFO_LIST_THIRD_ITEM,
    CARD_REQUEST_INFO_LIST_FOURTH_ITEM,
    CARD_REQUEST_INFO_TITLE,
    REQUEST_CARDS,
    CORPORATE_CARD,
    COMING_SOON,
    SKIP_AND_FINISH,
    MORE_INFO,
  } = useTranslator();

  const list = [
    CARD_REQUEST_INFO_LIST_FIRST_ITEM,
    CARD_REQUEST_INFO_LIST_SECOND_ITEM,
    CARD_REQUEST_INFO_LIST_THIRD_ITEM,
    CARD_REQUEST_INFO_LIST_FOURTH_ITEM,
  ];

  function onRequestCardsClick() {
    history.push('cards-request');
  }

  function onBack() {
    history.push('beneficiaries');
  }

  function onContinue() {
    history.push('/home/menu');
  }

  function onMoreInfoClick() {
    dialogProps.openDialog();
  }

  async function onConfirmDialog() {
    setIsLoading(true);
    await api.callService('interested-in-corporate-card').then(() => {
      reloadProfile();
      setSuccess(true);
    });
    setIsLoading(false);
  }

  function onCloseDialog() {
    dialogProps.onClose();
    setSuccess(false);
  }

  return (
    <>
      <div
        className='background'
        style={{
          backgroundColor: theme.colors.primary,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
      <CorporateCardDialog
        onClose={onCloseDialog}
        onConfirm={onConfirmDialog}
        open={dialogProps.open}
        showSuccess={success}
        isLoading={isLoading}
      />
      <FlexGrid
        container
        style={{
          position: 'relative',
          marginBottom: '70px',
        }}
        spacing={6}
        fullWidth
        fullHeight
      >
        <FlexGrid
          container
          spacing={6}
          fullHeight
          fullWidth
          direction='column'
          style={{ marginTop: theme.spacesPx[6] }}
        >
          <FlexGrid item style={{ maxWidth: '800px' }}>
            <Typography variant='h2' color='white'>
              {CARD_REQUEST_INFO_TITLE}
            </Typography>
          </FlexGrid>
          <FlexGrid
            container
            item
            direction='column'
            spacing={2}
            style={{ maxWidth: '370px' }}
          >
            <FlexGrid item container direction='column' spacing={2}>
              {list.map((text) => (
                <FlexGrid item container spacing={1}>
                  <FlexGrid item>
                    <Point />
                  </FlexGrid>
                  <FlexGrid item>
                    <Typography
                      variant='smallTitle'
                      color='white'
                      style={{ fontWeight: 400 }}
                    >
                      {text}
                    </Typography>
                  </FlexGrid>
                </FlexGrid>
              ))}
            </FlexGrid>
            <FlexGrid item>
              <Typography
                variant='smallTitle'
                color='white'
                style={{ fontWeight: 400 }}
              >
                {CARD_REQUEST_INFO_DESCRIPTION_FIRST_PART}{' '}
              </Typography>
              <Typography variant='smallTitle' color='white'>
                {CARD_REQUEST_INFO_DESCRIPTION_BOLD_TEXT}{' '}
              </Typography>
              <Typography
                variant='smallTitle'
                color='white'
                style={{ fontWeight: 400 }}
              >
                {CARD_REQUEST_INFO_DESCRIPTION_SECOND_PART}
              </Typography>
            </FlexGrid>
          </FlexGrid>
        </FlexGrid>
        <FlexGrid
          container
          spacing={5}
          fullHeight
          fullWidth
          direction='column'
          alignItems='center'
        >
          <FlexGrid item>
            <Image src={tarjetasAlbo} size='224px' />
          </FlexGrid>
          <FlexGrid item fullWidth>
            <Button
              onClick={onRequestCardsClick}
              buttonType='secondary'
              color='white'
            >
              <Typography variant='smallTitle' color='primary'>
                {REQUEST_CARDS}
              </Typography>
            </Button>
          </FlexGrid>
          <FlexGrid item fullWidth>
            <Line style={{ backgroundColor: theme.colors.manatee }} />
          </FlexGrid>
          <FlexGrid
            item
            container
            direction='column'
            spacing={2}
            alignItems='center'
          >
            <FlexGrid
              item
              container
              direction='column'
              spacing={1}
              alignItems='center'
            >
              <FlexGrid item>
                <Typography variant='h2' color='white'>
                  {CORPORATE_CARD}
                </Typography>
              </FlexGrid>
              <FlexGrid item>
                <Typography variant='smallTitle' color='manatee'>
                  {COMING_SOON}
                </Typography>
              </FlexGrid>
            </FlexGrid>
            <FlexGrid item>
              <Link
                color='white'
                underlined
                typography='small'
                bold
                onClick={onMoreInfoClick}
              >
                {MORE_INFO}
              </Link>
            </FlexGrid>
          </FlexGrid>
        </FlexGrid>
      </FlexGrid>
      <BottomActions backButton onBack={onBack} style={{ boxShadow: 'none' }}>
        <Button buttonType='secondary' onClick={onContinue} size='normal'>
          {SKIP_AND_FINISH}
        </Button>
      </BottomActions>
    </>
  );
};
