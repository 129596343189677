import {
  FaUserTie,
  FaUsers,
  FaFileAlt,
  FaFileInvoiceDollar
} from 'react-icons/fa';
import { RoundedIcon, useThemeProvider } from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { ReactNode } from 'react';
import { usePmStatusQuickWinAdditionalInfo } from '../../hooks/use-pm-status-quick-win-additional-info';
import { useAuth } from '../../providers/auth-provider';
import { RISK_LEVEL_HIGH } from '../../utils/constants';

export type TopCard = {
  icon: ReactNode;
  title: string;
  description: string;
  url: string;
  status: string;
}[];

export const GetMenuItems = (): {
  items: TopCard;
} => {
  const { theme } = useThemeProvider();
  const {
    companyOrganizationStatus,
    pepStatus,
    additionalInfoStatus,
    complementaryInfoStatus
  } = usePmStatusQuickWinAdditionalInfo();
  const { leadData } = useAuth();

  const {
    ON_BOARDING_MENU_PM_ORGANIZATION_BUSINESS_DATA_CARD_DESCRIPTION,
    ORGANIZATION_BUSINESS_DATA,
    ON_BOARDING_MENU_PM_PEP_DATA_CARD_DESCRIPTION,
    PEP_DATA,
    ON_BOARDING_MENU_PM_ADDITIONAL_INFORMATION_COMPANY,
    ON_BOARDING_MENU_PM_ADDITIONAL_INFORMATION_COMPANY_CARD_DESCRIPTION,
    ON_BOARDING_MENU_PM_ADDITIONAL_INFORMATION_COMPANY_FINISH_CARD_DESCRIPTION,
    ON_BOARDING_MENU_PM_EXTRA_INFO_DATA_CARD_DESCRIPTION,
    ON_BOARDING_MENU_PM_COMPLEMENTARY_INFORMATION_COMPANY
  } = useTranslator();

  const organizationBusiness = (
    <RoundedIcon
      icon={<FaUsers size='13px' color={theme.colors.charcoal} />}
      size='normal'
    />
  );

  const pep = (
    <RoundedIcon
      icon={<FaUserTie size='13px' color={theme.colors.charcoal} />}
      size='normal'
    />
  );

  const complementaryInfo = (
    <RoundedIcon
      icon={<FaFileInvoiceDollar size='13px' color={theme.colors.charcoal} />}
      size='normal'
    />
  );

  const additionalInfo = (
    <RoundedIcon
      icon={<FaFileAlt size='13px' color={theme.colors.charcoal} />}
      size='normal'
    />
  );


  return leadData?.riskLevel === RISK_LEVEL_HIGH ? {
    items: [
      {
        icon: organizationBusiness,
        title: ORGANIZATION_BUSINESS_DATA,
        description: ON_BOARDING_MENU_PM_ORGANIZATION_BUSINESS_DATA_CARD_DESCRIPTION,
        url: 'company-organization-info',
        status: companyOrganizationStatus,
      },
      {
        icon: pep,
        title: PEP_DATA,
        description: ON_BOARDING_MENU_PM_PEP_DATA_CARD_DESCRIPTION,
        url: 'pep-info',
        status: pepStatus,
      },
      {
        icon: complementaryInfo,
        title: ON_BOARDING_MENU_PM_ADDITIONAL_INFORMATION_COMPANY,
        description: ON_BOARDING_MENU_PM_ADDITIONAL_INFORMATION_COMPANY_CARD_DESCRIPTION,
        url: 'complementary-info',
        status: complementaryInfoStatus,
      },
      {
        icon: additionalInfo,
        title: ON_BOARDING_MENU_PM_COMPLEMENTARY_INFORMATION_COMPANY,
        description: ON_BOARDING_MENU_PM_EXTRA_INFO_DATA_CARD_DESCRIPTION,
        url: 'additional-info',
        status: additionalInfoStatus,
      },
    ],
  } : {
    items: [
      {
        icon: organizationBusiness,
        title: ORGANIZATION_BUSINESS_DATA,
        description: ON_BOARDING_MENU_PM_ORGANIZATION_BUSINESS_DATA_CARD_DESCRIPTION,
        url: 'company-organization-info',
        status: companyOrganizationStatus,
      },
      {
        icon: pep,
        title: PEP_DATA,
        description: ON_BOARDING_MENU_PM_PEP_DATA_CARD_DESCRIPTION,
        url: 'pep-info',
        status: pepStatus,
      },
      {
        icon: complementaryInfo,
        title: ON_BOARDING_MENU_PM_ADDITIONAL_INFORMATION_COMPANY,
        description: ON_BOARDING_MENU_PM_ADDITIONAL_INFORMATION_COMPANY_FINISH_CARD_DESCRIPTION,
        url: 'complementary-info',
        status: complementaryInfoStatus,
      },
    ],
  };
};
