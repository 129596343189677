import { FormItemProps, Link, useThemeProvider } from '@albo-ui/core';
import { useEffect, useRef, useState, useCallback } from 'react';
import { useCatalog } from '../hooks/use-catalog';
import { useTranslator } from '../providers/translator-provider';
import { useDefaultValidations } from '../utils/default-validations';
import { useValidators } from '../utils/validators';
import ReactLoading from 'react-loading';
import { useApi } from '../providers/api-provider';
import { mainCountry, mainCountryStr } from '../const';
import { usePreviousValue } from '../hooks/use-previus-value';

export type AddressInputsProps = {
  setFormData: any;
  postalCode: string;
  formData: any;
  disabled?: boolean;
  propName?: string;
  disableCountryInput?: boolean;
  filterCountries?: any[]
};

export const useAddressInputs = ({
  setFormData,
  disabled,
  postalCode,
  propName,
  formData,
  disableCountryInput,
  filterCountries
}: AddressInputsProps): FormItemProps<string>[] => {
  const defaultValidations = useDefaultValidations();
  const validators = useValidators();
  const [countries] = useCatalog('Countries');
  const [states] = useCatalog('States');
  const [api] = useApi();
  const { theme } = useThemeProvider();
  const timeOutRef = useRef<undefined | NodeJS.Timeout>();
  const [loading, setLoading] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const currentCountryDesc = getPropByString(
    `${propName || 'address'}.countryDesc`,
    formData
  );
  const currentCountryCode = getPropByString(
    `${propName || 'address'}.country`,
    formData
  );
  const countryCodePrevValue = usePreviousValue(currentCountryCode);
  const mainCountryIsSelected = currentCountryCode === mainCountryStr;
  const mainCountryNotSelected = !mainCountryIsSelected;
  const [neighborhoods, setNeighborhoods] = useState<
    [] | { value: string; name: string }[]
  >([]);
  const {
    COUNTRY,
    POSTAL_CODE,
    NEIGHBORHOOD,
    STREET,
    STREET_ADDRESS,
    APARTAMENT_NUMBER,
    POSTAL_CODE_HELP_DESCRIPTION,
    TOWN_MUNICIPALITY,
    INVALID_POSTAL_CODE_CONTACT_CUSTOMER_SERVICE,
    REFERENCE,
    TOWN_STATE,
    NAME_WITHOUT_ABBREVIATION,
  } = useTranslator();

  const countriesSelect = countries.map((country: any) => ({
    value: country.key,
    name: country.value,
  }));

  const countriesFilter = filterCountries &&
    countriesSelect.filter(country => filterCountries.includes(country.value));

  const statesSelect = states.map((state: any) => ({
    value: state.key,
    name: state.value,
  }));

  const findPostalCode = useCallback(
    async (postalCode: string) => {
      const catalogResult = await api.callQuery('get-catalog-by-name', {
        catalogName: 'zip',
        key: postalCode,
      });
      const data = catalogResult.data.catalogItems;

      return data;
    },
    [api]
  );

  const clearAddress = useCallback(
    (cleanZip?: boolean) => {
      setFormData((currentData: any) => ({
        ...currentData,
        [`${propName || 'address'}`]: {
          country: currentCountryCode,
          countryDesc: currentCountryDesc,
          ...(mainCountryIsSelected && {
            ...currentData[`${propName || 'address'}`],
            ...(cleanZip && { zip: '' }),
            state: undefined,
            stateDesc: undefined,
            town: undefined,
            locality: undefined,
            localityDesc: undefined,
          }),
        },
      }));
      setNeighborhoods([]);
    },
    [
      propName,
      setFormData,
      mainCountryIsSelected,
      currentCountryCode,
      currentCountryDesc,
    ]
  );

  useEffect(() => {
    clearTimeout(timeOutRef.current as any);
    if (postalCode?.length === 5 && mainCountryIsSelected) {
      setLoading(true);
      timeOutRef.current = setTimeout(
        async () => {
          const data = await findPostalCode(postalCode);

          if (data.length > 0) {
            setPostalCodeError(false);
            const metadata = data[0]?.metadata[0];

            setFormData((currentData: any) => ({
              ...currentData,
              [`${propName || 'address'}`]: {
                ...currentData[`${propName || 'address'}`],
                state: metadata.state_key,
                stateDesc: metadata.state,
                town: metadata.municipality,
              },
            }));

            const neighborhoodsSelect =
              data.length > 0
                ? data.map((address: any) => ({
                    value: address.metadata[0].id_township_mun,
                    name: address.metadata[0].township,
                  }))
                : [];

            setNeighborhoods(neighborhoodsSelect);
          } else {
            setPostalCodeError(true);
            clearAddress();
          }
          setLoading(false);
        },
        disabled ? 0 : 2000
      );
    } else {
      setLoading(false);
    }
  }, [
    postalCode,
    findPostalCode,
    setFormData,
    propName,
    disabled,
    clearAddress,
    mainCountryIsSelected,
  ]);

  useEffect(() => {
    const previusIsDefault =
      countryCodePrevValue?._def === (mainCountry as any)._def;
    if (
      countryCodePrevValue !== undefined &&
      countryCodePrevValue !== currentCountryCode &&
      !previusIsDefault &&
      !disabled
    ) {
      clearAddress(true);
    }
    setPostalCodeError(false);
  }, [currentCountryCode, clearAddress, countryCodePrevValue, disabled]);

  return [
    {
      placeholder: COUNTRY,
      name: `${propName || 'address'}.country`,
      type: 'select',
      items: filterCountries ? countriesFilter : countriesSelect,
      required: true,
      search: true,
      disabled: disabled || disableCountryInput,
      validators: [validators.selectIsEmpty],
    },
    {
      placeholder: POSTAL_CODE,
      name: `${propName || 'address'}.zip`,
      type: 'input',
      required: true,
      error: postalCodeError,
      errorMessage: (
        <>
          {INVALID_POSTAL_CODE_CONTACT_CUSTOMER_SERVICE}{' '}
          <Link
            typography='small'
            color='sunset'
            underlined
            href='https://www.correosdemexico.gob.mx/SSLServicios/ConsultaCP/Descarga.aspx'
            bold
          >
            aquí
          </Link>{' '}
        </>
      ),
      icon: loading && (
        <ReactLoading
          type='spin'
          color={theme.colors.primary}
          height='20px'
          width='20px'
        />
      ),
      disabled,
      ...defaultValidations.postalCodeValidations(mainCountryNotSelected),
      ...(mainCountryIsSelected && {
        help: {
          description: POSTAL_CODE_HELP_DESCRIPTION,
        },
      }),
    },
    {
      placeholder: TOWN_STATE,
      name: `${propName || 'address'}.state`,
      type: mainCountryIsSelected ? 'select' : 'input',
      search: true,
      items: statesSelect,
      required: true,
      disabled: disabled || mainCountryIsSelected,
      ...defaultValidations.cityStateValidations(),
      ...(mainCountryNotSelected && {
        help: {
          description: NAME_WITHOUT_ABBREVIATION,
        },
      }),
    },
    {
      placeholder: TOWN_MUNICIPALITY,
      name: `${propName || 'address'}.town`,
      type: 'input',
      required: true,
      disabled: disabled || mainCountryIsSelected,
      ...defaultValidations.municipalityValidations(),
      ...(mainCountryNotSelected && {
        help: {
          description: NAME_WITHOUT_ABBREVIATION,
        },
      }),
    },
    {
      placeholder: NEIGHBORHOOD,
      name: `${propName || 'address'}.locality`,
      type: mainCountryIsSelected ? 'select' : 'input',
      search: true,
      items: neighborhoods,
      required: true,
      disabled,
      validators: [validators.selectIsEmpty],
    },
    {
      placeholder: STREET,
      name: `${propName || 'address'}.street`,
      type: 'input',
      required: true,
      disabled,
      ...defaultValidations.streetValidations(),
    },
    {
      placeholder: STREET_ADDRESS,
      name: `${propName || 'address'}.extnum`,
      type: 'input',
      required: true,
      inputType: 'alphanumeric',
      disabled,
      ...defaultValidations.streetAddressValidations(),
    },
    {
      placeholder: APARTAMENT_NUMBER,
      name: `${propName || 'address'}.intnum`,
      inputType: 'alphanumeric',
      type: 'input',
      disabled,
      ...defaultValidations.apartamentNumberValidations(),
    },
    {
      placeholder: REFERENCE,
      name: `${propName || 'address'}.reference`,
      type: 'input',
      disabled,
      ...defaultValidations.referenceValidations(),
    },
  ];
};

export const GetGeneralInfoInputs = (): FormItemProps<string>[] => {
  const defaultValidations = useDefaultValidations();
  const {
    NAME,
    PATERNAL_LASTNAME,
    MATERNAL_LASTNAME,
    COUNTRY_CODE,
    PHONE_NUMBER,
  } = useTranslator();

  return [
    {
      placeholder: NAME,
      name: 'general.gname',
      type: 'input',
      required: true,
      ...defaultValidations.nameValidations(),
    },
    {
      placeholder: PATERNAL_LASTNAME,
      name: 'general.lname',
      type: 'input',
      required: true,
      ...defaultValidations.paternalLastNameValidations(),
    },
    {
      placeholder: MATERNAL_LASTNAME,
      name: 'general.slname',
      type: 'input',
      ...defaultValidations.maternalLastNameValidations(),
    },
    {
      placeholder: COUNTRY_CODE,
      name: 'general.zip',
      type: 'input',
      required: true,
      ...defaultValidations.countryValidations(),
    },
    {
      placeholder: PHONE_NUMBER,
      name: 'phone',
      type: 'input',
      required: true,
      ...defaultValidations.phoneNumberValidations(),
    },
  ];
};

export function setImmutablePropByString(
  propName: string,
  obj: any,
  value: any
) {
  const nestedProperties = propName.split('.');
  let currentProp = obj;
  for (let i = 0; i < nestedProperties.length; i++) {
    const propName = nestedProperties[i];
    const isLast = i === nestedProperties.length - 1;
    const nextValue = isLast ? value : { ...currentProp[propName] };
    currentProp[propName] = nextValue;
    currentProp = currentProp[propName];
  }
  return { ...obj };
}

export function getPropByString(propName: string, obj: any) {
  const nestedProperties = propName.split('.');
  let currentProp = obj;
  for (let i = 0; i < nestedProperties.length; i++) {
    const propName = nestedProperties[i];
    const isLast = i === nestedProperties.length - 1;
    if (isLast) {
      return currentProp ? currentProp[propName] : undefined;
    }

    currentProp = currentProp ? currentProp[propName] : undefined;
  }
}
