
import {  useEffect, useMemo } from 'react';

import { useConfirm } from '../../components/confirm-dialog';
import { useAuth } from '../auth-provider';
import { useApi } from '../api-provider';
import { useExternalAuthData } from './external-auth-provider';
import { useTranslator } from '../translator-provider';
import { ExternalAuthErrorsProviderProps } from './types';
import { useHistory } from 'react-router';


interface ConfirmDialog {
    [key: string]: (confirm: ()=> Promise<unknown>) =>Promise<void>;
  }

  
export const ExternalAuthErrorsProvider=({children}:ExternalAuthErrorsProviderProps)=>{
    const {isInvalid,queryExternalAuthData,forceDecodeParams,clearExternalAuthData} = useExternalAuthData();
    const {logout,obtainCredentials} = useAuth();
    const [, setApiConfig] = useApi();
    const history = useHistory();

    const {
        TITLE_DIALOG_WARNING_AGENT,
        DESCRIPTION_DIALOG_WARNING_AGENT,
        BUTTON_DIALOG_WARNING_AGENT,
        BUTTON_CLOSE_DIALOG_WARNING_AGENT,
      } = useTranslator();
    
    const { DialogConfirm,confirm:showConfirm} = useConfirm({
        title: TITLE_DIALOG_WARNING_AGENT,
        message: DESCRIPTION_DIALOG_WARNING_AGENT,
        textCancel: BUTTON_CLOSE_DIALOG_WARNING_AGENT,
        textConfirm: BUTTON_DIALOG_WARNING_AGENT,
    });

    const handleErrors: ConfirmDialog =useMemo(() => ({
        NEW_LEAD: async (confirm) => {

          if(localStorage.getItem('auth_jwt')){
            const newExternalAuthDataParams = forceDecodeParams()
            const paramApi = setApiConfig({
                authorizationToken: JSON.parse(localStorage.getItem('auth_jwt') || '')
              });
            await obtainCredentials({leadUuid:(newExternalAuthDataParams as any).leadUuid, paramApi,externalAuthData: queryExternalAuthData}) 
          }
        },
        EMPTY_LEAD: async (confirm) => { 
            history.push('/empty-lead');
        },
        ERROR: async (confirm) => { 
           logout().then(()=>
           {
                history.push('/error');
           }).catch(()=>{
              history.push('/error');
           })
           clearExternalAuthData()
            
         },
        WARNING:  async (confirm) => {  
            const isConfirm = await confirm()
            if(isConfirm){
                const newExternalAuthDataParams = forceDecodeParams()
                const paramApi = setApiConfig({
                    authorizationToken: JSON.parse(localStorage.getItem('auth_jwt') || '')
                  });
                await obtainCredentials({leadUuid:(newExternalAuthDataParams as any).leadUuid, paramApi,externalAuthData: queryExternalAuthData})   
            }
         },
      }),[history, logout, clearExternalAuthData, forceDecodeParams, setApiConfig, obtainCredentials,queryExternalAuthData]);


    useEffect(() => {
        if (isInvalid) {
            const handle = handleErrors[isInvalid];
            if(handle){
              
              handle(showConfirm)
            }
            
          }
    }, [isInvalid,showConfirm,handleErrors]);

    
    return <>
         <DialogConfirm/>
        {children}
    </>
}