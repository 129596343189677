import {
  FlexGrid,
  Step,
  Stepper,
  Typography,
  useThemeProvider,
  Image,
  StatusColor,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import logo from '../../images/Logo_Primario_Indigo.png';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../providers/auth-provider';

export type SideNavProps = {
  active: number;
  status: StatusColor;
};

export const SideNav = memo(({ active, status }: SideNavProps) => {
  const { theme } = useThemeProvider();
  const [steps, setSteps] = useState<Step[]>();
  const { user, showAdditionalInfo } = useAuth();

  const {
    INFORMATION,
    REVISION,
    EXTRA_INFO,
    VALIDATION,
    AGREEMENT,
    ACCOUNT_CREATED,
    LOGIN_YOUR_ACCOUNT,
    NEED_HELP,
    BUSINESS_HELP_EMAIL,
    POWERED_BY,
  } = useTranslator();

  const stepsDefault = useMemo(() => [
    {
      name: INFORMATION,
    },
    {
      name: REVISION,
    },
    {
      name: AGREEMENT,
    },
    {
      name: ACCOUNT_CREATED,
    },
    {
      name: LOGIN_YOUR_ACCOUNT,
    },
  ], [INFORMATION, REVISION, AGREEMENT, ACCOUNT_CREATED, LOGIN_YOUR_ACCOUNT]);

  const stepsPFAEDefault = useMemo(() => [
    {
      name: INFORMATION,
    },
    {
      name: VALIDATION,
    },
    {
      name: AGREEMENT,
    },
    {
      name: ACCOUNT_CREATED,
    },
    {
      name: LOGIN_YOUR_ACCOUNT,
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [INFORMATION, REVISION, AGREEMENT, ACCOUNT_CREATED, LOGIN_YOUR_ACCOUNT]);

  const stepsExtraInfo = useMemo(() =>
    [
      {
        name: INFORMATION,
      },
      {
        name: REVISION,
      },
      {
        name: EXTRA_INFO,
      },
      {
        name: VALIDATION,
      },
      {
        name: AGREEMENT,
      },
      {
        name: ACCOUNT_CREATED,
      },
      {
        name: LOGIN_YOUR_ACCOUNT,
      },
    ], [INFORMATION, REVISION, EXTRA_INFO, VALIDATION, AGREEMENT, ACCOUNT_CREATED, LOGIN_YOUR_ACCOUNT]
  );

  const getSteps = useCallback((): Step[] => {
    return showAdditionalInfo ? stepsExtraInfo : user?.leadType === 'PFAE' ? stepsPFAEDefault : stepsDefault;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepsExtraInfo, stepsDefault, showAdditionalInfo])

  useEffect(() => {
    setSteps(getSteps())
  }, [user, setSteps, getSteps])

  return (
    <FlexGrid
      container
      direction='column'
      padding={4}
      style={{ backgroundColor: theme.colors.lightGray, width: '360px' }}
      alignItems='center'
      fullHeight
      justify='space-between'
    >
      <FlexGrid item>
        {steps &&
          <Stepper active={active} status={status} steps={steps} />
        }
      </FlexGrid>
      <FlexGrid
        item
        container
        direction='column'
        spacing={5}
        alignItems='flex-start'
        fullWidth
      >
        <FlexGrid item container direction='column'>
          <FlexGrid item>
            <Typography variant='paragraph' bold>
              {NEED_HELP}
            </Typography>
          </FlexGrid>
          <FlexGrid item>
            <Typography variant='paragraph'>{BUSINESS_HELP_EMAIL}</Typography>
          </FlexGrid>
        </FlexGrid>
        <FlexGrid container item alignItems='flex-end'>
          <FlexGrid item>
            <Typography variant='small'>{POWERED_BY}</Typography>
          </FlexGrid>
          <FlexGrid item>
            <Image src={logo} size='38px' />
          </FlexGrid>
        </FlexGrid>
      </FlexGrid>
    </FlexGrid>
  );
});
