import { useEffect } from 'react';
import { useOnHomeCBS } from '../providers/on-home-cbs-provider';

export const useOnHome = (cb: () => void) => {
  const { addCB, removeCB } = useOnHomeCBS();

  useEffect(() => {
    addCB(cb);
    return () => {
      removeCB(cb);
    };
  }, [cb, removeCB, addCB]);
};
