import {
  FlexGrid,
  Card,
  WizardStepperItem,
  useThemeProvider,
  Breadcrum,
  BreadcrumItem,
  useDialog,
} from '@albo-ui/core';
import { OnBoardingWizardStepper } from '../../../../../../components-quick-win/on-boarding-wizard-stepper';
import { AdditionalRecordInfoRouter } from './router';
import {
  FaUsers,
  FaUserTie,
  FaFileAlt,
  FaFileInvoiceDollar
} from 'react-icons/fa';
import { useTranslator } from '../../../../../../providers/translator-provider';
import { useHistory } from 'react-router';
import { FormContainer } from '../../../../../../components-quick-win/form-container';
import { useOnHomeCBS } from '../../../../../../providers/on-home-cbs-provider';
import { ExitPromptDialog } from '../../../../../../components-quick-win/exit-prompt-dialog';
import { useAuth } from '../../../../../../providers/auth-provider';
import { RISK_LEVEL_HIGH } from '../../../../../../utils/constants';

export const AdditionalRecordInfo = () => {
  const { theme } = useThemeProvider();
  const history = useHistory();
  const pmActives = [false, false, false];
  const homeCBS = useOnHomeCBS();
  const dialogProps = useDialog();
  const { leadData } = useAuth();

  const {
    ORGANIZATION_BUSINESS_DATA,
    PEP_DATA_SHORT,
    ON_BOARDING_MENU_PM_COMPLEMENTARY_INFORMATION_COMPANY,
    ON_BOARDING_MENU_PM_ADDITIONAL_INFORMATION_COMPANY,
    HOME,
  } = useTranslator();

  const sectionName = {
    'company-organization-info': ORGANIZATION_BUSINESS_DATA,
    'pep-info': PEP_DATA_SHORT,
    'complementary-info': ON_BOARDING_MENU_PM_ADDITIONAL_INFORMATION_COMPANY,
    'additional-info': ON_BOARDING_MENU_PM_COMPLEMENTARY_INFORMATION_COMPANY,
    default: '',
  };

  const sectionNumber = {
    'company-organization-info': 0,
    'pep-info': 1,
    'complementary-info': 2,
    'additional-info': 3,
    default: 0,
  };

  const currentPath = `${history.location.pathname.split('/').pop()}`;

  function onHomeClick() {
    if (homeCBS.showDialog) {
      dialogProps.openDialog();
    }
    else {
      history.push('menu-extra-info');
    }
  }

  function onConfirmSave() {
    homeCBS.state.forEach((CB: () => any) => CB && CB());
    history.push('menu-extra-info');
  }

  const breadCrumItems: BreadcrumItem[] = [
    {
      name: HOME,
      nameKey: 'home',
      to: '/home/menu-extra-info',
      onClick: () => {
        onHomeClick();
      },
    },
    {
      name: sectionName[currentPath as keyof typeof sectionName],
      nameKey: 'none',
    },
  ];

  const stepsPM: WizardStepperItem[] = [
    {
      icon: <FaUsers size='13px' color='white' />,
      name: ORGANIZATION_BUSINESS_DATA,
      onClick: () => {
        history.push('company-organization-info');
      },
    },
    {
      icon: <FaUserTie size='13px' color='white' />,
      name: PEP_DATA_SHORT,
      onClick: () => {
        history.push('pep-info');
      },
    },
    {
      icon: <FaFileInvoiceDollar size='13px' color='white' />,
      name: ON_BOARDING_MENU_PM_ADDITIONAL_INFORMATION_COMPANY,
      onClick: () => {
        history.push('complementary-info');
      },
    }
  ];


  const stepsExtraInfoPM: WizardStepperItem[] = [
    {
      icon: <FaUsers size='13px' color='white' />,
      name: ORGANIZATION_BUSINESS_DATA,
      onClick: () => {
        history.push('company-organization-info');
      },
    },
    {
      icon: <FaUserTie size='13px' color='white' />,
      name: PEP_DATA_SHORT,
      onClick: () => {
        history.push('pep-info');
      },
    },
    {
      icon: <FaFileInvoiceDollar size='13px' color='white' />,
      name: ON_BOARDING_MENU_PM_ADDITIONAL_INFORMATION_COMPANY,
      onClick: () => {
        history.push('complementary-info');
      },
    },
    {
      icon: <FaFileAlt size='13px' color='white' />,
      name: ON_BOARDING_MENU_PM_COMPLEMENTARY_INFORMATION_COMPANY,
      onClick: () => {
        history.push('additional-info');
      },
    }
  ];



  return (
    <FlexGrid container fullWidth fullHeight>
      <ExitPromptDialog {...dialogProps} onConfirm={onConfirmSave} />
      <FlexGrid
        item
        style={{ padding: `${theme.spacesPx[4]} ${theme.spacesPx[2]}` }}
      >
        <OnBoardingWizardStepper
          onHomeClick={onHomeClick}
          items={leadData.riskLevel === RISK_LEVEL_HIGH ? stepsExtraInfoPM : stepsPM}
          sectionActive={sectionNumber[currentPath as keyof typeof sectionName]}
          sectionsDone={pmActives.map(
            (value, index) =>
              index <
              sectionNumber[currentPath as keyof typeof sectionName]
          )
          }
        />
      </FlexGrid>
      <FlexGrid
        item
        container
        fullHeight
        fullWidth
        style={{ position: 'relative' }}
      >
        <Card
          fullWidth
          fullHeight
          alignItems='flex-start'
          justifyContent='center'
          style={{
            borderColor: theme.colors.manatee,
            borderRadius: 0,
            overflowY: 'auto',
            borderRight: 'none',
            borderBottom: 'none',
          }}
        >
          <FlexGrid
            container
            fullHeight
            fullWidth
            direction='column'
            spacing={2}
            alignItems='center'
          >
            <FlexGrid item style={{ zIndex: 20000 }}>
              <FormContainer>
                <Breadcrum
                  primaryColor={
                    currentPath === 'cards-request-info' ? 'white' : 'primary'
                  }
                  secondaryColor={
                    currentPath === 'cards-request-info'
                      ? 'lightNeutral'
                      : 'neutral'
                  }
                  items={breadCrumItems}
                />
              </FormContainer>
            </FlexGrid>
            <FlexGrid
              item
              container
              justify='center'
              style={{ paddingBottom: theme.spacesPx[4] }}
            >
              <AdditionalRecordInfoRouter />
            </FlexGrid>
          </FlexGrid>
        </Card>
      </FlexGrid>
    </FlexGrid>
  );
};
