import {
  FaUserAlt,
  // FaPen,
  FaUserFriends,
  
  FaBriefcase,
} from 'react-icons/fa';
import { RoundedIcon, useThemeProvider } from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { ReactNode } from 'react';
import { usePfaeStatus } from '../../hooks/use-pfae-status';

export type TopCard = {
  icon: ReactNode;
  title: string;
  description: string;
  url: string;
  status: string;
}[];

export const GetMenuItems = (): {
  items: TopCard;
} => {
  const { theme } = useThemeProvider();
  const {
    adminStatus,
    companyInfoAccountStatus,
    beneficiariesStatus,
  } = usePfaeStatus();

  const {
    ON_BOARDING_MENU_PFAE_BENEFICIARIES_CARD_DESCRIPTION,
    BENEFICIARIES,
    ON_BOARDING_MENU_PFAE_BUSINESS_DATA_ACCOUNT_CARD_DESCRIPTION,
    BUSINESS_DATA_ACCOUNT,
    PERSONAL_INFO,
    PERSONAL_INFO_DESCRIPTION,
  } = useTranslator();

  const user = (
    <RoundedIcon
      icon={<FaUserAlt size='13px' color={theme.colors.charcoal} />}
      size='normal'
    />
  );

  const briefCase = (
    <RoundedIcon
      icon={<FaBriefcase size='13px' color={theme.colors.charcoal} />}
      size='normal'
    />
  );

  const userFriends = (
    <RoundedIcon
      icon={<FaUserFriends size='13px' color={theme.colors.charcoal} />}
      size='normal'
    />
  );


  return {
    items: [
      {
        icon: user,
        title: PERSONAL_INFO,
        description: PERSONAL_INFO_DESCRIPTION,
        url: 'personal-info',
        status: adminStatus,
      },
      {
        icon: briefCase,
        title: BUSINESS_DATA_ACCOUNT,
        description:
          ON_BOARDING_MENU_PFAE_BUSINESS_DATA_ACCOUNT_CARD_DESCRIPTION,
        url: 'company-info-and-account',
        status: companyInfoAccountStatus,
      },
      {
        icon: userFriends,
        title: BENEFICIARIES,
        description: ON_BOARDING_MENU_PFAE_BENEFICIARIES_CARD_DESCRIPTION,
        url: 'beneficiaries',
        status: beneficiariesStatus,
      }
    ],
  };
};
