import {
  FlexGrid,
  Form,
  FormItemProps,
  Typography,
  useForm,
  useThemeProvider,
} from '@albo-ui/core';
import { useTranslator } from '../../../../../../providers/translator-provider';
import { useValidators } from '../../../../../../utils/validators';
import { OnBoardingLoader } from '../../../../../../components-quick-win/on-boarding-loader';
import { FormItems } from '../../../../../../components-quick-win/form-items';
import { BottomActions } from '../../../../../../components-quick-win/bottom-actions';
import { FormContainer } from '../../../../../../components-quick-win/form-container';
import { DetailBox } from './styled';
import { useAuth } from '../../../../../../providers/auth-provider';

export const UploadDocuments = () => {
  const formProps = useForm({});
  const { theme } = useThemeProvider();
  const validators = useValidators();
  const { user } = useAuth();

  const {
    UPLOAD_DOCUMENTS_AGAIN,
    UPLOAD_OF_DOCUMENTS,
    UPLOAD_OF_DOCUMENTS_DESCRIPTION,
    REQUIRED_FIELD,
    SEND_VALIDATION,
    DETAIL,
    UPLOAD_REQUIRED_DOCUMENTS,
  } = useTranslator();

  const inputs: FormItemProps<string>[] = [
    {
      placeholder: 'Documento',
      name: 'firstFile',
      type: 'fileInput',
      required: true,
      validators: [validators.isEmpty],
    },
  ];

  return (
    <FlexGrid container fullHeight fullWidth direction='column' spacing={3}>
      <OnBoardingLoader isLoading={false} />
      <FlexGrid
        item
        container
        justify='center'
        style={{
          paddingBottom: theme.spacesPx[4],
          marginTop: theme.spacesPx[8],
        }}
      >
        <FormContainer>
          <FlexGrid container direction='column' spacing={3}>
            <FlexGrid item container direction='column' spacing={1}>
              <FlexGrid item>
                <Typography variant='h2'>{UPLOAD_OF_DOCUMENTS}</Typography>
              </FlexGrid>
              <FlexGrid item>
                <Typography variant='small'>
                  {UPLOAD_OF_DOCUMENTS_DESCRIPTION}
                </Typography>
              </FlexGrid>
            </FlexGrid>

            <FlexGrid item>
              <Typography variant='small' color='neutral' bold>
                <span style={{ color: theme.colors.required }}>*</span>{' '}
                {REQUIRED_FIELD}
              </Typography>
            </FlexGrid>
            <FlexGrid item container direction='column' spacing={1}>
              <FlexGrid item>
                <Typography variant='smallTitle'>{DETAIL}</Typography>
              </FlexGrid>
              <FlexGrid item>
                <DetailBox style={{ padding: theme.spacesPx[2] }}>
                  <Typography variant='paragraph'>
                    {' '}
                    Aqui el usuario podra ver el detalle e informacion sobre los
                    documentos que se tienen que volver a subir. Esta
                    informacion la subira la persona de Ops encarga el proceso
                    de validacion de documentos.
                  </Typography>
                </DetailBox>
              </FlexGrid>
            </FlexGrid>
            <FlexGrid
              item
              container
              fullWidth
              style={{ paddingBottom: '200px' }}
            >
              <Form {...formProps} onSubmit={() => {}}>
                <FlexGrid
                  item
                  container
                  fullWidth
                  spacing={4}
                  direction='column'
                >
                  <FlexGrid item>
                    <Typography variant='smallTitle'>
                      {UPLOAD_DOCUMENTS_AGAIN} {user?.name}
                    </Typography>
                  </FlexGrid>

                  <FormItems inputSize='380px' inputs={inputs} />

                  <FlexGrid item>
                    <Typography variant='smallTitle'>
                      {UPLOAD_REQUIRED_DOCUMENTS}
                    </Typography>
                  </FlexGrid>
                  <FormItems inputSize='380px' inputs={inputs} />
                </FlexGrid>
              </Form>
            </FlexGrid>
          </FlexGrid>
        </FormContainer>
      </FlexGrid>
      <BottomActions
        saveProgressButton
        continueButton
        continueButtonText={SEND_VALIDATION}
        onSaveProgress={() => {}}
        onContinue={() => {}}
      />
    </FlexGrid>
  );
};
