import { useForm } from '@albo-ui/core';
import { useAuth } from '../providers/auth-provider';
import { useQuery } from '../hooks/user-query';
import { useCallback, useEffect, useState } from 'react';
import { useService } from './use-service';
export type OnboardinFormTypes = 'SHAREHOLDER_PM';

export type useOnboardingFormShareHolderPMParams = {
  onboardingFormType: OnboardinFormTypes;
  onboardingFormRef?: string;
  emailRef?: string;
};

export const useOnboardingFormShareHolderPM = (
  params: useOnboardingFormShareHolderPMParams
) => {
  const [data, setData] = useState<any>({ shareholders: [] });
  const socialReason = data.socialReason;
  const percentageShares = data.percentageShares;
  const formPropsShareholder = useForm({
    socialReason,
    percentageShares,
  });
  const shareHolderData = formPropsShareholder.data;
  const shareHolderSetData = formPropsShareholder.setData;

  useEffect(() => {
    shareHolderSetData({
      socialReason,
      percentageShares,
    });
  }, [socialReason, percentageShares, shareHolderSetData]);

  const [{ movingForward, saving }] = useState({
    saving: false,
    movingForward: false,
  });
  const updateData = params.emailRef
    ? data.shareholders.find(
        ({ general: { email } }: any) => email === params.emailRef
      )
    : undefined;
  const { leadData, reloadProfile } = useAuth();
  const onboardingFormsRef = leadData.onboardingForms;

  const onboardingFormRef = onboardingFormsRef.find(
    ({ formType }: any) => formType === params.onboardingFormType
  );

  const [queryFormData, loadingForm] = useQuery(
    {
      name: 'get-onboarding-forms-by-uuids',
      params: {
        onboardingFormsUuids: [onboardingFormRef?.uuid],
      },
      options: { manualLoad: !onboardingFormRef },
    },
    [onboardingFormRef?.uuid]
  );

  const queryOnboardingForm = queryFormData?.onboardingForms
    ? queryFormData?.onboardingForms[0]
    : undefined;

  const [eventName, , savingForm, onSave] = useService(
    {
      name: 'save-onboarding-form',
      params: {
        uuid: queryOnboardingForm?.uuid,
        formType: params.onboardingFormType,
        formData: data,
      },
    },
    [data, params.onboardingFormType, queryOnboardingForm?.uuid]
  );

  const save = useCallback(() => {
    onSave();
  }, [onSave]);

  const addShareholder = useCallback(
    (shareholder: any) => {
      setData((data: any) => ({
        ...data,
        shareholders: [...data.shareholders, shareholder],
      }));
    },
    [setData]
  );

  useEffect(() => {
    if (eventName) reloadProfile();
  }, [reloadProfile, eventName]);

  const updateShareholder = useCallback(
    (newData: any) => {
      const updatedShareholder = {
        ...data.shareholders.find(
          ({ general: { email } }: any) => email === params.emailRef
        ),
        ...newData,
      };
      const arrayWithoutUpdateShareholder = data.shareholders.filter(
        ({ general: { email } }: any) => email !== params.emailRef
      );
      const newArray = [...arrayWithoutUpdateShareholder, updatedShareholder];
      setData({ ...data, shareholders: newArray });
    },
    [setData, data, params.emailRef]
  );

  const deleteShareholder = useCallback(
    (currentEmail: string) => {
      const arrayWithoutShareholder = data.shareholders.filter(
        ({ general: { email } }: any) => email !== currentEmail
      );
      setData({ ...data, shareholders: arrayWithoutShareholder });
    },
    [setData, data]
  );

  useEffect(() => {
    setData((data: any) => ({
      ...data,
      ...shareHolderData,
    }));
  }, [shareHolderData]);

  return {
    loadingForm,
    save,
    savingForm,
    saving,
    movingForward,
    eventName,
    addShareholder,
    updateShareholder,
    deleteShareholder,
    formPropsShareholder,
    data,
    updateData,
    setData,
  };
};
