import {
  Button,
  DefaultComponentProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FlexGrid,
  Link,
  Typography,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { ReactNode } from 'react';

export type InfoDialogProps = {
  onClose: () => void;
  open: boolean;
  title: string | ReactNode;
  description: string | ReactNode;
  buttonText?: string;
  help?: boolean;
} & DefaultComponentProps;

export const InfoDialog = ({
  onClose,
  open,
  className,
  style,
  description,
  title,
  buttonText,
  help,
}: InfoDialogProps) => {
  const { ACCEPT, BUSINESS_HELP_EMAIL, IF_THE_PROBLEM_PERSISTS_CONTACT_US_IN } =
    useTranslator();

  return (
    <Dialog className={className} style={style} onClose={onClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FlexGrid container direction='column' spacing={3}>
          <FlexGrid item>
            <Typography variant='paragraph'>{description}</Typography>
          </FlexGrid>
          {help && (
            <FlexGrid item container direction='column' spacing={1}>
              <FlexGrid item>{IF_THE_PROBLEM_PERSISTS_CONTACT_US_IN}</FlexGrid>
              <FlexGrid item>
                <Link underlined href={`mailto:${BUSINESS_HELP_EMAIL}`}>
                  {BUSINESS_HELP_EMAIL}
                </Link>
              </FlexGrid>
            </FlexGrid>
          )}
        </FlexGrid>
      </DialogContent>
      <DialogActions>
        <FlexGrid
          container
          fullHeight
          fullWidth
          justify='space-between'
          alignItems='flex-end'
          style={{ marginTop: '20px' }}
        >
          <FlexGrid item style={{ marginLeft: 'auto' }}>
            <Button onClick={onClose} size='normal'>
              {buttonText || ACCEPT}
            </Button>
          </FlexGrid>
        </FlexGrid>
      </DialogActions>
    </Dialog>
  );
};
