import {
  FlexGrid,
  Typography,
  Form,
  useForm,
  Button,
  Image,
  FormItemProps,
  useThemeProvider,
} from '@albo-ui/core';
import { useTranslator } from '../../providers/translator-provider';
import { useHistory, useLocation } from 'react-router';
import { useValidators } from '../../utils/validators';
import { useEffect, useMemo, useState } from 'react';
import { FormItems } from '../../components-quick-win/form-items';
import { usePasswordRecovery } from '../../providers/password-recovery-provider';
import { OnBoardingLoader } from '../../components-quick-win/on-boarding-loader';
import logoPrimarioIndigo from '../../images/Logo_Primario_Indigo.png';
import { FiEyeOff, FiEye } from 'react-icons/fi';
import { LinkExpired } from '../link-expired';

const EyeSlashIcon = <FiEyeOff size='20px' />;
const EyeIcon = <FiEye size='20px' />;

export const PasswordRecovery = () => {
  const validators = useValidators();
  const history = useHistory();
  const formProps = useForm({ password: '', confirmPassword: '' });
  const location = useLocation();
  const search = location.search;
  const { theme } = useThemeProvider();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [hidePassword, setHidePassword] = useState(true);
  const [hidePasswordConfirm, setHidePasswordConfirm] = useState(true);
  const { changePassword, passwordChanged,role, loading, error } =
    usePasswordRecovery();

  const {
    RESET_YOUR_PASSWORD,
    WRITE_YOUR_NEW_PASSWORD,
    CONFIRM_YOUR_NEW_PASSWORD,
    UPDATE_PASSWORD,
    HAVING_SECURE_PASSWORD_HELP_YOU_PROTECT_YOUR_ACCOUNT,
    MEET_PASSWORD_REQUIREMENTS,
    DO_NOT_USE_ALBO_WORD_OR_CLIENT_IDENTIFICATION,
    DO_NOT_USE_MORE_THAN_TWO_EQUAL_CHARACTERS,
    USE_EIGHT_OR_MORE_CHARACTERS,
    USE_MIXED_LETTERS_NUMBERS_SPECIAL_CHARACTERS,
    DO_NOT_USE_MORE_THAN_TWO_SECUENCIAL_CHARACTERS,
    PASSWORD_DOES_NOT_MATCH,
    GO_THROUGH_PASSWORD_PROCESS_AGAIN_REMEMBER_LINK_HAS_AN_EXPIRATION_OF_24_HRS,
  } = useTranslator();

  const onUpdatePassword = async () => {
    if (formProps.hasErrors) {
      formProps.displayErrors();
    } else {
      changePassword(formProps.data.password, query.get('token') as string);
      formProps.setData({ password: '', confirmPassword: '' });
    }
  };

  const validatePasswords = (value: string) => {
    const passwordsDoNotMatch =
      formProps.data.password !== formProps.data.confirmPassword &&
      formProps.data.password.length !== 0 &&
      formProps.data.confirmPassword?.length !== 0;

    return passwordsDoNotMatch ? PASSWORD_DOES_NOT_MATCH : undefined;
  };

  function onPasswordIconClick() {
    setHidePassword(!hidePassword);
  }

  function onPasswordConfirmIconClick() {
    setHidePasswordConfirm(!hidePasswordConfirm);
  }

  const inputs: FormItemProps<string>[] = [
    {
      placeholder: WRITE_YOUR_NEW_PASSWORD,
      name: 'password',
      type: 'input',
      inputType: hidePassword ? 'password' : 'default',
      pattern: 'password',
      required: true,
      inputSize: '100%',
      icon: hidePassword ? EyeSlashIcon : EyeIcon,
      onClickIcon: onPasswordIconClick,
      validators: validators.getPasswordValidators(),
    },
    {
      placeholder: CONFIRM_YOUR_NEW_PASSWORD,
      name: 'confirmPassword',
      type: 'input',
      inputSize: '100%',
      pattern: 'password',
      inputType: hidePasswordConfirm ? 'password' : 'default',
      required: true,
      icon: hidePasswordConfirm ? EyeSlashIcon : EyeIcon,
      onClickIcon: onPasswordConfirmIconClick,
      validators: [validators.isEmpty, validatePasswords],
    },
  ];

  const requirementsList = [
    USE_EIGHT_OR_MORE_CHARACTERS,
    USE_MIXED_LETTERS_NUMBERS_SPECIAL_CHARACTERS,
    DO_NOT_USE_MORE_THAN_TWO_EQUAL_CHARACTERS,
    DO_NOT_USE_MORE_THAN_TWO_SECUENCIAL_CHARACTERS,
    DO_NOT_USE_ALBO_WORD_OR_CLIENT_IDENTIFICATION,
  ];

  useEffect(() => {
    if (passwordChanged){
      if(role === 'OPS_ANALYST'){
        history.push('/ops-password-changed');
      }else{
        history.push('/password-changed');
      }
      
    } 
  }, [passwordChanged,role, history]);

  if (error === 'AUTHORIZATION_NOT_ALLOWED')
    return (
      <LinkExpired
        defaultLink
        description={
          GO_THROUGH_PASSWORD_PROCESS_AGAIN_REMEMBER_LINK_HAS_AN_EXPIRATION_OF_24_HRS
        }
      />
    );

  if (loading) return <OnBoardingLoader isLoading={loading} />;

  return (
    <div style={{ height: '100vh' }}>
      <FlexGrid container fullWidth fullHeight>
        <FlexGrid
          container
          fullHeight
          fullWidth
          direction='column'
          spacing={10}
          padding={10}
          style={{ backgroundColor: 'white', width: '40%' }}
        >
          <FlexGrid item style={{ marginLeft: '-9px' }}>
            <Image src={logoPrimarioIndigo} size='125px' />
          </FlexGrid>
          <FlexGrid
            container
            spacing={6}
            direction='column'
            fullWidth
            fullHeight
          >
            <FlexGrid item>
              <Typography variant='smallTitle' color='charcoal'>
                {RESET_YOUR_PASSWORD}
              </Typography>
            </FlexGrid>
            <Form {...formProps} onSubmit={onUpdatePassword}>
              <FlexGrid item container direction='column' spacing={3}>
                <FormItems inputs={inputs} inputSize='100%' />
              </FlexGrid>
            </Form>

            <FlexGrid item>
              <Button onClick={onUpdatePassword} size='fullWidth'>
                {UPDATE_PASSWORD}
              </Button>
            </FlexGrid>
          </FlexGrid>
        </FlexGrid>
        <FlexGrid
          item
          container
          style={{ width: '60%', height: '100%', padding: theme.spacesPx[10] }}
          direction='column'
          spacing={3}
          alignItems='center'
          justify='center'
        >
          <FlexGrid item>
            <Typography variant='h1' color='primary'>
              {HAVING_SECURE_PASSWORD_HELP_YOU_PROTECT_YOUR_ACCOUNT}
            </Typography>
          </FlexGrid>
          <FlexGrid item fullWidth>
            <Typography variant='h2'>{MEET_PASSWORD_REQUIREMENTS}</Typography>
          </FlexGrid>
          {requirementsList.map((text) => (
            <FlexGrid item container fullWidth alignItems='center' spacing={1}>
              <FlexGrid item>
                <i className='ibon-check oxford'></i>
              </FlexGrid>
              <FlexGrid item>
                <Typography variant='paragraph'>{text}</Typography>
              </FlexGrid>
            </FlexGrid>
          ))}
        </FlexGrid>
      </FlexGrid>
    </div>
  );
};
