import styled from 'styled-components';
import { Typography, Image } from '@albo-ui/core';

type themeProvider = {
  theme: any;
};

export const ListItem = styled.li<themeProvider>`
  ${({ theme }) => `
  font-size: ${theme.typography.paragraph.fontSize};
  color: ${theme.colors.white};`}
`;

export const StyledTypography = styled(Typography)<themeProvider>`
  font-weight: 400;
  position: relative;
  left: -10px;
`;

export const TextContainer = styled.div<themeProvider>`
  ${({ theme }) => `
  font-size: ${theme.typography.smallTitle.fontSize};
  color: ${theme.colors.white};
  line-height: ${theme.spacesPx[3]}
  `}
`;

export const BottomImage = styled(Image)<themeProvider>`
  position: absolute;
  bottom: -20px;
  left: 13%;
`;

export const BottomImageCircle = styled(Image)<themeProvider>`
  position: absolute;
  bottom: -220px;
  left: -50px;
`;

export const BottomGradient = styled.div<themeProvider>`
  position: absolute;
  width: 100%;
  height: 20%;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    to bottom,
    transparent 0%,
    ${({ theme }) => theme.colors.primary} 100%
  );
`;
